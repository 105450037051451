import { Directive, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[appOmniCurrencyChars]'
})
export class OmniCurrencyCharsDirective {

  /**
   * Form Group to be updated
   */
  @Input() formGroupDir: FormGroup | undefined;

  /**
   * Form Control name to be updated
   */
  @Input() formControlNameDir: string | undefined;

  /**
   * Maximum allowed amount
   */
  @Input() maxAmount: number | undefined;

  /**
   * Element of the input field
   */
  @Input() inputField: HTMLInputElement = {} as HTMLInputElement;

  /**
   * Flag for triggering the formatting to blur
   */
  @Input() isOnBlur: boolean | undefined = false;

  private previousValue: number = 0;

  constructor() { }

  /**
   * Removes non numerical characters
   * Inserts currency in front when removed
   * Prevents input if exceeds maximum amount
   * @param event
   */
  @HostListener('input', ['$event']) onInput(event: any): void {
    let inputValue: string = event.target.value;
    let selectionIndex = inputValue.length - (this.inputField?.selectionStart || 0);
    const numericPattern: RegExp = /[^\.\d]/g;
    const periodPattern: RegExp = /\./;
    const decimalPattern: RegExp = /(.+\.\d{2}).+/;
    const thousandPattern: RegExp = /(\d)(?=(\d{3}(\.(\d)*(\d)*)*)+$)/g;

    inputValue = inputValue.replace(numericPattern, "");
    // Remove extra periods
    while (inputValue.split(".").length > 2) {
      inputValue = inputValue.replace(periodPattern, "");
    }
    // Maximum decimal place
    inputValue = inputValue.replace(decimalPattern, "$1");

    // Prevent input when exceeding maximum amount allowed
    if (this.maxAmount && +inputValue > this.maxAmount) {
      inputValue = `${this.previousValue}`;
      event.target.value = inputValue;
    }
    this.previousValue = +inputValue;

    // Limit to max value
    if(!this.isOnBlur && event.target.max) {
      if (parseInt(inputValue) > parseFloat(event.target.max.replace(',',''))) {
        inputValue = `${event.target.max}`;
        event.target.value = inputValue;
      }
    }
    // Insert comma as thousand separator
    if (!this.isOnBlur) {
      inputValue = inputValue.replace(thousandPattern, "$1,");
      event.target.value = inputValue;
    }

    // Move cursor to previous placement
    if (selectionIndex) {
      this.inputField.selectionStart = inputValue.length - selectionIndex;
      this.inputField.selectionEnd = inputValue.length - selectionIndex;
    }
    this.updateFormAndValidity(event.target.value);
  }

  /**
   * Parses field to proper currency format
   * @param event
   */
  @HostListener('blur', ['$event']) onBlur(event: any): void {
    this.previousValue = 0;
    let inputValue = event.target.value;
    const commaPattern: RegExp = /\,/;
    const thousandPattern: RegExp = /(\d)(?=(\d{3}(\.(\d)*(\d)*)*)+$)/g;

    if (!inputValue) {
      inputValue = "";
    } else {
      inputValue = `${parseFloat(inputValue.replace(commaPattern, "") || 0).toFixed(2)}`;
    }
    if(this.isOnBlur && event.target.max) {
      if (parseInt(inputValue) > parseFloat(event.target.max.replace(',',''))) {
        inputValue = `${event.target.max}`;
        event.target.value = inputValue;
      }
    }
    if (this.isOnBlur) {
      inputValue = inputValue.replace(thousandPattern, "$1,");
      event.target.value = inputValue;
    }
    event.target.value = inputValue;
    event.target.dispatchEvent(new Event('input', {
      bubbles: true,
    }));
  }

  /**
   * Updates the FormGroup and FormControl when available
   * @param value
   */
  updateFormAndValidity(value: any) {
    if (this.formGroupDir && this.formControlNameDir) {
      this.formGroupDir.controls[this.formControlNameDir].setValue(value, { emitEvent: false });
      this.formGroupDir.updateValueAndValidity();
    }
  }
}
