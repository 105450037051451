<head>
  <link rel="preload" href="/Roboto-Bold.ttf" as="font" type="font/ttf" crossorigin>
  <link rel="preload" href="/Roboto-Regular.ttf" as="font" type="font/ttf" crossorigin>
</head>

<div class="omnicare-diagnostics">
  <div class="omnicare-diagnostics-header" [innerHTML]="diagnosticsHeader"></div>
  <div class="omnicare-diagnostics-body">
    <div class="omnicare-diagnostics-body-diagnostic-list" *ngFor="let diagnostic of diagnosticsList">
      <div [class]="['omnicare-diagnostics-body-diagnostic', diagnostic.status]">
        {{getTitleByStatus(diagnostic, diagnostic.status)}}
        <img class="omnicare-diagnostics-icon" [src]="getImageByStatus(diagnostic.status)" />
      </div>
      <div class="omnicare-diagnostics-body-diagnostic-subs"
        *ngIf="['active', 'fail'].includes(diagnostic.status)">
        <div
          [class]="['omnicare-diagnostics-body-diagnostic-sub', subDiagnostic.status]"
          *ngFor="let subDiagnostic of diagnostic.diagnostics">
          {{getTitleByStatus(subDiagnostic, subDiagnostic.status)}}
          <img class="omnicare-diagnostics-icon" [src]="getImageByStatus(subDiagnostic.status)" />
        </div>
      </div>
    </div>
  </div>
</div>

<img hidden *ngFor="let img of imageStorage" [src]="img" (load)="imageLoaded()" />

<omni-dialog-modal
class="omnicare-diagnostics-modal"
  [title]="failTitle"
  [description]="failDescription"
  [confirmButtonLabel]="failConfirmLabel"
  [cancelButtonLabel]="failCancelLabel"
  [confirmButtonAction]="failConfirmAction"
  [cancelButtonAction]="failCancelAction"
  [confirmButtonStyle]="failConfirmStyle"
  [cancelButtonStyle]="failCancelStyle"
  [show]="!['','completed'].includes(showDialogModal)"
></omni-dialog-modal>

<omni-dialog-modal
  class="omnicare-diagnostics-modal omnicare-diagnostics-success"
  [icon]="diagnosticsSuccessModal.icon"
  [title]="diagnosticsSuccessModal.title"
  [description]="diagnosticsSuccessModal.description"
  [confirmButtonLabel]="diagnosticsSuccessModal.confirmButtonLabel"
  [cancelButtonLabel]="diagnosticsSuccessModal.cancelButtonLabel"
  [confirmButtonAction]="successConfirmButton()"
  [cancelButtonAction]="successCancelButton()"
  [show]="showDialogModal === 'completed'"
></omni-dialog-modal>

<app-omni-loading-modal [showModal]="loading" [message]="''"></app-omni-loading-modal>
