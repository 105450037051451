import { Component } from '@angular/core';
import { DEFAULT_BARRING_ERROR } from 'src/app/common/constants/omni-barring-error.constants';
import { OmniCommonService } from 'src/app/services/omni-common.services';

@Component({
  selector: 'app-omni-barring-error',
  templateUrl: './omni-barring-error.component.html',
  styleUrls: ['./omni-barring-error.component.scss']
})
export class OmniBarringErrorComponent {

  public errorDetails = DEFAULT_BARRING_ERROR;

  constructor(
    public commonService: OmniCommonService,
  ) { }

}
