import { AfterViewChecked, Component, ElementRef, EventEmitter, Injectable, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbDateStruct, NgbDatepickerI18n, NgbDatepickerI18nDefault } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { WEEK_DAY_LABELS } from 'src/app/common/constants/obi-date-picker-modal.constant';

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18nDefault {
  override getWeekdayLabel(weekday: number): string {
    return WEEK_DAY_LABELS[weekday - 1];
  }
}

@Component({
  selector: 'app-obi-date-picker-modal',
  templateUrl: './obi-date-picker-modal.component.html',
  styleUrls: ['./obi-date-picker-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
})
export class ObiDatePickerModalComponent implements AfterViewChecked {

  @Input() show: boolean = false;

  @Input() selectedDate: NgbDateStruct | null = null;

  @Output() dateSelected = new EventEmitter<NgbDateStruct>();

  @Output() modalClosed = new EventEmitter<void>();

  @ViewChild('container') container: ElementRef | undefined;

  private currDate = moment().utc();

  public maxDate: NgbDateStruct = {
    year: +this.currDate.format('YYYY'),
    month: +this.currDate.format('M'),
    day: +this.currDate.format('D') - 1
  };

  public minDate: NgbDateStruct = {
    year: +this.currDate.format('YYYY') - 3,
    month: +this.currDate.format('M'),
    day: +this.currDate.format('D') - 1
  };

  /**
   * Fires an event when a date is selected in the date picker
   * @param date date selected
   */
  onDateSelect(date: NgbDateStruct) {
    this.selectedDate = date;
    this.dateSelected.emit(date);
    this.modalClosed.emit();
  }

  /**
   * Checks if passed date is current date
   * @param date
   * @returns
   */
  isCurrentDay (date: any) {
    let result = false;
    if (
      date.day === this.maxDate.day + 1 &&
      date.month === this.maxDate.month &&
      date.year === this.maxDate.year
    ) {
      result = true;
    }
    return result;
  }

  ngAfterViewChecked(): void {
    if (this.show && this.container?.nativeElement) {
      const topOffset = this.container?.nativeElement.getBoundingClientRect().top - this.container?.nativeElement.offsetTop;
      const leftOffset = this.container?.nativeElement.getBoundingClientRect().left + this.container?.nativeElement.offsetLeft;
      this.container.nativeElement.style.top = `-${topOffset}px`;
      this.container.nativeElement.style.left = `-${leftOffset}px`;
    }
  }
}
