import { ObiPreCheck } from "src/app/components/obi/obi-pre-check/obi-pre-check.interface";

export const OBI_PRE_CHECK_HEADER = "We’re here to help! <br /> Let’s check what’s happening with your plan.";

export const OBI_PRE_CHECK_SUCCESS = {
  confirmButtonLabel: "Submit bill inquiry",
  cancelButtonLabel: "Cancel",
  confirmButtonUrl: "/obi/billInquiry",
};

export const OBI_PRE_CHECK_CANCEL = {
  title: "Are you sure you want to cancel your bill inquiry?",
  cancelButtonUrl: "/omnicare/navigate?destination=dashboard",
  confirmButtonLabel: "No",
  cancelButtonLabel: "Yes",
};

export const OBI_PRE_CHECK_FOOTER_SUBMIT = "Submit bill inquiry";

export const OBI_PRE_CHECK_FOOTER_CANCEL = "Cancel";

export const OBI_PRE_CHECK_VALIDATION_TIMER: number = 0;

export const OBI_PRE_CHECK_SUB_VALIDATION_TIMER: number = 1000;

export const OBI_PRE_CHECK_TITLE_REGEX: RegExp = /{(.+?)}/g;

export const OBI_PRE_CHECK_DECIMAL_REGEX: RegExp = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;

export const OBI_PRE_CHECK_LIST: ObiPreCheck[] = [
  {
    id: "account",
    title_inactive: "Account check",
    title_initial: "Account check",
    title_active: "Account check",
    title_fail: "Account check",
    title_fail_continue: "Account is temporarily disconnected",
    title_completed: "Account is active",
    status: "initial",
    pre_checks: [
      {
        id: "account_status",
        title_inactive: "Checking for account status...",
        title_active: "Checking for account status...",
        title_fail: "Account is temporarily disconnected",
        title_completed: "Checking for account status...",
        status: "active",
        validation: {
          validationKey: "status",
          validValidationValues: [/^65$/],
          failTitle: "Your account is temporarily disconnected",
          failDescription: "If you wish to be reconnected, you can send a request via Facebook Messenger.",
          failConfirmLabel: "Chat with us on Messenger",
          failCancelLabel: "Go back to dashboard",
          failConfirmUrl: "/omnicare/navigate?destination=messenger",
          failCancelUrl: "/omnicare/navigate?destination=dashboard",
        },
      },
    ]
  },
  {
    id: "balance",
    title_inactive: "Balance check",
    title_active: "Balance check",
    title_fail: "Balance check",
    title_fail_continue: "Balance check",
    title_completed: "No amount to pay",
    status: "inactive",
    pre_checks: [
      {
        id: "balance_overdue",
        title_inactive: "Checking for overdue balance...",
        title_active: "Checking for overdue balance...",
        title_fail: "You have an overdue balance",
        title_fail_continue: "You have an overdue balance",
        title_completed: "Checking for overdue balance...",
        status: "active",
        validation: {
          validationKey: "outstandingBalance",
          invalidValidationValues: [/[1-9]/g],
          failTitle: "You have an overdue balance",
          failDescription: "You may continue to submit a bill inquiry, <br />but don’t forget to settle your balance to avoid disconnection.",
          failConfirmLabel: "Pay now",
          failCancelLabel: "Proceed to bill inquiry",
          failConfirmUrl: "/omnicare/navigate?destination=payment.overdue",
          failCancelUrl: "/obi/billInquiry",
        },
      },
    ]
  },
];

export const OBI_PRE_CHECK_DISCONNECTED_OB = {
  validation: {
    failTitle: "You have an overdue balance",
    failDescription: "You may continue to submit a payment inquiry, but don’t forget to settle your balance to avoid disconnection.",
    failConfirmLabel: "Pay now",
    failCancelLabel: "Submit a payment inquiry",
    failConfirmUrl: "/omnicare/navigate?destination=payment.overdue",
    failCancelUrl: "/obi/billInquiry",
  }
};
