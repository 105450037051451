export const OBI_TRACK_MY_REQUEST_TAB_VALUES = {
  PROGRESS_TAB_KEY: "progress",
  HISTORY_TAB_KEY: "history"
}

export const OBI_TRACK_MY_REQUEST_DEFAULT_SOURCE = 3;

export const OBI_TRACK_MY_REQUEST_DEFAULT_ATTRIBUTES = 'mcnt';

export const OBI_TRACK_MY_REQUEST_BROADBAND_TAG = 'broadband';

export const OBI_TRACK_MY_REQUEST_MOBILE_TAG = 'mobile';

export const OBI_TRACK_MY_REQUEST_TAB_STATUS = {
  progress: [
    { title: 'Open', status: ['NEW CASE', 'OPEN']},
    { title: 'Assigned to agent', status: ['ASSIGNED TO AN AGENT', 'SOLVING', 'UNDER REVIEW', 'APPROVED', 'REJECTED']},
    { title: 'Unreachable', status: ['UNCONTACTED - 1ST ATTEMPT', 'UNCONTACTED - 2ND ATTEMPT', 'UNCONTACTED - 3RD ATTEMPT']},
    { title: 'Pending Requirements', status: ['AWAITING CUSTOMER INFORMATION']},
    { title: 'Escalated', status: ['ESCALATED - BEYOND SLA']},
  ],
  history: [
    { title: 'Closed', status: ['CLOSED BY SYSTEM', 'SOLVED PENDING CONFIRMATION', 'CLOSED']},
    { title: 'Cancelled', status: ['CANCELLED DUE TO NO RESPONSE', 'CANCELLED DUE TO UNCONTACTED']},
  ]
};

/**
 * Set this to true to enable view request status
 */
export const IS_VIEW_REQUEST_STATUS_ENABLE = true;