<div class="obi-bill-charges__modal" *ngIf="open">
  <div class="obi-bill-charges__modal-header">
    <img
      class="obi-bill-charges__btn-close"
      [src]="closeIcon"
      (click)="toggleModal()" />
  </div>
  <div class="obi-bill-charges__modal-content">
    <div
      class="obi-bill-charges__modal-content-section"
      *ngFor="let biliCharge of billChargesInfo">
      <h2 class="obi-bill-charges__title" [innerHTML]="biliCharge.title"></h2>
      <div
        class="obi-bill-charges__item"
        *ngFor="let charge of biliCharge.charges">
        <div
          class="obi-bill-charges__item-title"
          [innerHTML]="charge.title"></div>
        <div
          class="obi-bill-charges__item-text"
          [innerHTML]="sanitizeText(charge.text)"></div>
      </div>
    </div>
  </div>
</div>
