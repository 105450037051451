<head>
  <link rel="preload" href="/Roboto-Bold.ttf" as="font" type="font/ttf" crossorigin>
  <link rel="preload" href="/Roboto-Regular.ttf" as="font" type="font/ttf" crossorigin>
</head>

<div class="obi-pre-check">
  <div class="obi-pre-check-header" [innerHTML]="obiPreCheckHeader"></div>
  <div class="obi-pre-check-body">
    <div class="obi-pre-check-body-pre-check-list" *ngFor="let preCheck of obiPreCheckList">
      <div [class]="['obi-pre-check-body-pre-check', preCheck.status]">
        {{getTitleByStatus(preCheck, preCheck.status)}}
        <img class="obi-pre-check-icon" [src]="getImageByStatus(preCheck.status)" />
      </div>
      <div class="obi-pre-check-body-pre-check-subs"
        *ngIf="['active', 'fail'].includes(preCheck.status)">
        <div
          [class]="['obi-pre-check-body-pre-check-sub', subPreCheck.status]"
          *ngFor="let subPreCheck of preCheck.pre_checks">
          {{getTitleByStatus(subPreCheck, subPreCheck.status)}}
          <img class="obi-pre-check-icon" [src]="getImageByStatus(subPreCheck.status)" />
        </div>
      </div>
    </div>
  </div>
  <div class="obi-pre-check-footer" *ngIf="showDialogModal === 'completed'">
    <div class="obi-pre-check-footer-submit">
      <button class="obi-pre-check-footer-button submit"
      [innerHTML]="obiPreCheckSubmit.confirmButtonLabel"
      (click)="submitBillInquiry()"></button>
    </div>
    <div class="obi-pre-check-footer-cancel">
      <button class="obi-pre-check-footer-button cancel"
      [innerHTML]="obiPreCheckSubmit.cancelButtonLabel"
      (click)="toggleCancelModal()"></button>
    </div>
  </div>
</div>

<img hidden *ngFor="let img of imageStorage" [src]="img" (load)="imageLoaded()" />

<omni-dialog-modal
class="obi-pre-check-modal"
  [title]="failTitle"
  [description]="failDescription"
  [confirmButtonLabel]="failConfirmLabel"
  [cancelButtonLabel]="failCancelLabel"
  [confirmButtonAction]="failConfirmAction"
  [cancelButtonAction]="failCancelAction"
  [confirmButtonStyle]="failConfirmStyle"
  [cancelButtonStyle]="failCancelStyle"
  [show]="!['','completed'].includes(showDialogModal)"
></omni-dialog-modal>

<omni-dialog-modal
class="obi-pre-check-cancel-modal"
  [title]="obiPreCheckCancel.title"
  [confirmButtonLabel]="obiPreCheckCancel.confirmButtonLabel"
  [cancelButtonLabel]="obiPreCheckCancel.cancelButtonLabel"
  [confirmButtonAction]="obiPreCheckCancelConfirmAction"
  [cancelButtonAction]="obiPreCheckCancelCancelAction"
  [show]="isCancel"
></omni-dialog-modal>

<app-omni-loading-modal [showModal]="loading" [message]="''"></app-omni-loading-modal>
