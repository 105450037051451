<div class="omni-platform-down">
    <div class="omni-platform-down__wrapper">
        <div class="omni-platform-down__content">
        <img [src]="errorImage" alt="" class="omni-platform-down__img">
        <div class="omni-platform-down__content-text-wrapper">
            <p class="omni-platform-down__title" [innerHTML]="errorTitle"></p>
            <p class="omni-platform-down__subtext" [innerHTML]="errorSubtext"></p>
        </div>
        </div>
    
        <div class="omni-platform-down__btn-wrapper">
        <a [attr.href]="goToDashboardURL">
            <button class="omni-platform-down__btn-success" [innerHTML]="gotoDashboardLabel"></button>
        </a>
        </div>
    </div>
</div>