import { Component } from '@angular/core';
import { PAGE_URLS } from '../../../common/constants/omni-global.constant';
import { OMNI_TS_FAILED_INIT_VALUES } from '../../../common/constants/omni-troubleshooting-failed.constant';

const { GOTO_DASHBOARD_URL, FB_MSGR_BB_NETWORK_URL } = PAGE_URLS;
const {
  TS_IMAGE,
  TS_TITLE,
  TS_SUBTEXT,
  GO_TO_DASHBOARD_LABEL,
  CHAT_WITH_FB_MSGR,
} = OMNI_TS_FAILED_INIT_VALUES;

@Component({
  selector: 'app-omni-troubleshooting-failed',
  templateUrl: './omni-troubleshooting-failed.component.html',
  styleUrls: ['./omni-troubleshooting-failed.component.scss'],
})
export class OmniTroubleshootingFailedComponent {
  public tsImage: string = TS_IMAGE;
  public tsTitle: string = TS_TITLE;
  public tsSubtext: string = TS_SUBTEXT;

  public goToDashboardURL: string = GOTO_DASHBOARD_URL;
  public goToDashboardLabel: string = GO_TO_DASHBOARD_LABEL;
  public chatWithMsgr: string = CHAT_WITH_FB_MSGR;
  public fbMsgrURL: string = FB_MSGR_BB_NETWORK_URL;
}
