<div [class]="['obi-time-picker-modal', show ? '': 'hidden']" #container>
  <div class="obi-time-picker-modal-overlay disable-scrolling" *ngIf="show"></div>
  <div class="obi-time-picker-modal-container">
    <div class="obi-time-picker-modal-selector-separator top"></div>
    <div class="obi-time-picker-modal-selector-separator bottom"></div>
    <div class="obi-time-picker-modal-body">
      <div class="obi-time-picker-modal-hours-container snap-scroll-y"
        (touchstart)="onTouchStart(hoursContainer)"
        (touchend)="onTouchEnd(hoursContainer)"
        (touchmove)="onScroll(hoursContainer, 'hour')"
        (scroll)="onScroll(hoursContainer, 'hour')"
        #hoursContainer>
        <div class="obi-time-picker-modal-hours">
          <span
            [class]="['obi-time-picker-modal-hour', this.selectedHour === hour ? 'selected' : '']"
            *ngFor="let hour of hours" [innerHTML]="hour"></span>
        </div>
      </div>
      <div class="obi-time-picker-modal-separator">
        :
      </div>
      <div class="obi-time-picker-modal-minutes-container snap-scroll-y"
        (touchstart)="onTouchStart(minutesContainer)"
        (touchend)="onTouchEnd(minutesContainer)"
        (touchmove)="onScroll(minutesContainer, 'minute')"
        (scroll)="onScroll(minutesContainer, 'minute')"
        #minutesContainer>
        <div class="obi-time-picker-modal-minutes">
          <span
            [class]="['obi-time-picker-modal-minute', this.selectedMin === minute ? 'selected' : '']"
            *ngFor="let minute of minutes" [innerHTML]="minute"></span>
        </div>
      </div>
      <div class="obi-time-picker-modal-ams-container snap-scroll-y"
        (touchstart)="onTouchStart(amsContainer)"
        (touchend)="onTouchEnd(amsContainer)"
        (touchmove)="onScroll(amsContainer, 'am')"
        (scroll)="onScroll(amsContainer, 'am')"
        #amsContainer>
        <div class="obi-time-picker-modal-ams">
          <span
            [class]="['obi-time-picker-modal-am', this.selectedAm === am ? 'selected' : '']"
            *ngFor="let am of ams"  [innerHTML]="am"></span>
        </div>
      </div>
    </div>
    <div class="obi-time-picker-modal-footer">
      <div class="obi-time-picker-modal-button cancel" (click)="toggleModal()">
        Cancel
      </div>
      <div class="obi-time-picker-modal-button apply" (click)="onTimeConfirm()">
        Apply
      </div>
    </div>
  </div>
</div>
