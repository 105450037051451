<div class="omnicare-dialog-background" *ngIf="show"></div>
<div class="omnicare-dialog-modal" *ngIf="show">
  <div class="omnicare-dialog-modal-icon" *ngIf="icon">
    <img [src]="icon" />
  </div>
  <div 
    class="omnicare-dialog-modal-title" 
    *ngIf="title" 
    [innerHtml]="title"
  ></div>
  <div 
    class="omnicare-dialog-modal-desc" 
    *ngIf="description" 
    [innerHtml]="description"
  ></div>
  <div class="omnicare-dialog-modal-btn-wrapper">
    <div 
      [class]="'omnicare-dialog-modal-confirm ' + confirmButtonStyle" 
      *ngIf="confirmButtonLabel && confirmButtonAction" 
      (click)="confirmButtonAction()" 
      [innerHtml]="confirmButtonLabel"
    ></div>
    <div 
    [class]="'omnicare-dialog-modal-cancel ' + cancelButtonStyle" 
      *ngIf="cancelButtonLabel && cancelButtonAction" 
      (click)="cancelButtonAction()" 
      [innerHtml]="cancelButtonLabel"
    ></div>
  </div>

</div>