import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { OMNI_SCHED_TECHNICIAN_SCHED_INIT_VALUES } from '../../../common/constants/omni-sched-technician-concern.constant';
import {
  PAGE_URLS,
  SESSION_KEYS,
} from '../../../common/constants/omni-global.constant';
import { OmniSessionService } from '../../../services/omni-session.service';
import { OmniSchedTechnicianService } from 'src/app/services/omni-sched-technician.service';

const {
  BTN_NEXT_LABEL,
  BTN_CANCEL_LABEL,
  CONCERN_DETAILS_LABEL,
  CONCERN_LABEL,
  CONCERN_NOTICE,
  CONFIRMATION_DIALOG_TITLE,
  CONFIRM_BUTTON_LABEL,
  CANCEL_BUTTON_LABEL,
  TXTAREA_MAX_LENGTH,
  TXTAREA_MIN_HEIGHT,
  TXTAREA_PLACE_HOLDER_VALUE,
} = OMNI_SCHED_TECHNICIAN_SCHED_INIT_VALUES;

const { GOTO_DASHBOARD_URL, SCHEDULE_TECHNICIAN_PAGE_URL } = PAGE_URLS;

@Component({
  selector: 'app-omni-sched-technician-concern',
  templateUrl: './omni-sched-technician-concern.component.html',
  styleUrls: ['./omni-sched-technician-concern.component.scss'],
})
export class OmniSchedTechnicianConcernComponent implements OnInit {
  @ViewChild('concernDetailstxtareaRef')
  textareaRef!: ElementRef<HTMLTextAreaElement>; // Template reference variable
  loading: boolean = false;

  public omniSchedTechConcernNotice: string = CONCERN_NOTICE;
  public omniScheTechConcernLabel: string = CONCERN_LABEL;
  public omniScheTechConcernDetailsLabel: string = CONCERN_DETAILS_LABEL;

  public concernSubject: string = '';
  public concernDetails: string = '';
  txtAreaMinHeight: number = TXTAREA_MIN_HEIGHT; // Minimum height in pixels
  txtAreaMaxLength: number = TXTAREA_MAX_LENGTH;
  txtAreaRemainingChars: number = this.txtAreaMaxLength;
  txtAreaCharCount: number = 0;
  txtAreaPlaceHolderValue: string = TXTAREA_PLACE_HOLDER_VALUE;

  confirmationDialogTitle: string = CONFIRMATION_DIALOG_TITLE;
  confirmButtonLabel: string = CONFIRM_BUTTON_LABEL;
  cancelButtonLabel: string = CANCEL_BUTTON_LABEL;
  confirmation: boolean = false;

  public btnNextLabel: string = BTN_NEXT_LABEL;
  public btnCancelLabel: string = BTN_CANCEL_LABEL;
  public gotoSchedTechnicianURL: string = SCHEDULE_TECHNICIAN_PAGE_URL;

  constructor(
    private router: Router,
    private omniSessionService: OmniSessionService,
    private omniSchedTechnicianService: OmniSchedTechnicianService
  ) {}

  ngOnInit(): void {
    //Get user data Concern Subject
    this.concernSubject = this.omniSessionService.getData(
      SESSION_KEYS.OMNI_CONCERN_TYPE
    );
  }

  /**
   * Shows the confirmation modal
   * TODO: integration with common confirmation modal
   */
  showConfirmationModal() {
    this.confirmation = true;
  }

  /**
   * Compute the remaining character for the textarea
   */
  public txtAreaInput(event: any) {
    if (!event?.target) return;

    const inputValue = event.target.value;
    const maxLength = this.txtAreaMaxLength;
    let sanitizedValue = this.sanitizeTxtAreaInput(inputValue);
    const sanitizedValueLength = sanitizedValue?.replace(/(\r\n)/g, "\n")?.length | 0;

    if (sanitizedValueLength > maxLength) {
      sanitizedValue = sanitizedValue.substring(0, maxLength);
      event.target.value = sanitizedValue;
    } else {
      this.concernDetails = sanitizedValue;
      this.txtAreaCharCount = sanitizedValueLength;
      this.txtAreaRemainingChars = maxLength - sanitizedValue.length;
      this.adjustTextareaHeight();
    }
  }

  /**
   * Handles keydown event on concern details textarea
   * Prevents further input when reached max length except for non appending keys
   * @param event
   */
  public txtAreaKeydown(event: any) {
    let sanitizedValue = this.sanitizeTxtAreaInput(event.target.value);
    const sanitizedValueLength = sanitizedValue?.replace(/(\r\n)/g, "\n")?.length | 0;
    if (sanitizedValueLength === this.txtAreaMaxLength &&
      ((event.keyCode >= 48 && event.keyCode <= 90) || [110,188,220,189,191,32,13].includes(event.keyCode)) &&
      !event.ctrlKey && !event.altKey) {
      event.preventDefault();
    }
  }

  /**
   * Handles paste event on concern details textarea
   * Limits the pasted value to max length of the textarea
   * @param event
   */
  public txtAreaPaste(event: any) {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    let prevValue = `${this.concernDetails}`;
    prevValue = prevValue.replace(/(\r\n)/g, "\n");
    if (clipboardData) {
      const sanitizedData = this.sanitizeTxtAreaInput(clipboardData.getData('text'));
      const sanitizedDataNewLineCount = sanitizedData.match(/(\r\n)/g)?.length || 0;
      const clippedData = sanitizedData
        .substring(0, this.txtAreaMaxLength - this.txtAreaCharCount + (event.target.selectionEnd - event.target.selectionStart) + sanitizedDataNewLineCount);
      const newConcernDetails =
      `${prevValue.slice(0, event.target.selectionStart)}${clippedData}${prevValue.slice(event.target.selectionEnd)}`;
      this.concernDetails = this.sanitizeTxtAreaInput(newConcernDetails);
      event.target.value = this.concernDetails;
      const sanitizedValueLength = this.concernDetails?.replace(/(\r\n)/g, "\n")?.length | 0;
      this.txtAreaCharCount = sanitizedValueLength;
      this.txtAreaRemainingChars = this.txtAreaMaxLength - sanitizedValueLength;
      this.adjustTextareaHeight();
    }
  }

  /**
   * Adjust textarea (concern details) height based on user input
   */
  public adjustTextareaHeight() {
    const textarea = this.textareaRef.nativeElement;
    textarea.style.height = this.txtAreaMinHeight + 'px';

    textarea.style.height =
      Math.max(this.txtAreaMinHeight, textarea.scrollHeight) + 'px';
  }

  /**
   * Save concern details and
   * redirect user to schedule technician page
   * @returns
   */
  public gotoSchedTechnician() {
    this.omniSessionService.setData(
      SESSION_KEYS.OMNI_CONCERN_DETAILS,
      this.concernDetails
    );
    this.loading = true;
    this.omniSchedTechnicianService.navigateToSchedTechnician();
  }

  /**
   * Handles confirmation modal click on 'No' button
   */
  handleConfirmation() {
    return () => {
      this.confirmation = false;
    };
  }

  /**
   * Handles confirmation modal click on 'Yes' button
   */
  handleConfirmationCancel() {
    return () => {
      window.location.href = GOTO_DASHBOARD_URL;
    };
  }

  sanitizeTxtAreaInput(inputValue: string): string {
    return inputValue.replace(/[^a-zA-Z0-9.,\-?! \r\n]/g, '');
  }
}
