<div class="omni-track-repair">
  <div class="omni-track-repair-nav">
    <div
      class="omni-track-repair-nav-item"
      (click)="changeActiveNav($event, recentKey)"
      [class.active]="activeTab === recentKey">
      Recent
    </div>
    <div
      class="omni-track-repair-nav-item"
      (click)="changeActiveNav($event, historyKey)"
      [class.active]="activeTab === historyKey">
      History
    </div>
  </div>
  <div
    class="omni-track-repair-list"
    *ngIf="workOrdersList?.length && !loading">
    <div
      class="omni-track-repair-list-item"
      *ngFor="let workOrder of workOrdersList"
      (click)="onSelectedWorkOrder(workOrder)">
      <div
        class="omni-track-repair-list-item-status"
        [class.history]="activeTab === historyKey">
        {{ workOrder?.displayStatus }}
      </div>
      <div class="omni-track-repair-list-item-title">{{ workOrder?.id }}</div>
      <div class="omni-track-repair-list-item-sched">
        {{ workOrder?.displayAppointmentDate }}
      </div>
    </div>
  </div>
  <div
    class="omni-track-repair-empty"
    *ngIf="!workOrdersList?.length && !loading">
    <img
      class="omni-track-repair-empty-icon"
      src="../../../../assets/icons/omni-track-repair-none.svg"
      alt="No Repair History" />
    <div class="omni-track-repair-empty-title">No request yet.</div>
  </div>
</div>

<app-omni-view-status 
  [modalOpen]="isViewWorkOder" 
  [workOrder]="workOrderDetails" 
  (modalClose)="onSelectedWorkOrder({})">
</app-omni-view-status>

<app-omni-loading-modal [showModal]="loading"></app-omni-loading-modal>
