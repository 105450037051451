import {
  OmniScheduleSlot,
  OmniSearchAppointmentSlotReqBody,
  WHEN,
} from 'src/app/interfaces/omni-sched-technician.interface';
import { UpdateTicketDetailsRequest } from 'src/app/components/omnicare/omni-sched-technician/omni-sched-technician.interface'

//#region initial labels
export const OMNI_SCHED_TECHNICIAN_INIT_VALUES = {
  CANCEL_BUTTON_LABEL: 'Yes', //cancelButtonLabel
  CONCERN_NOTICE: 'We need more details so our technician can assist you.', //omniSchedTechConcernNotice
  CONCERN_LABEL: 'Concern', //omniScheTechConcernLabel
  CONCERN_DETAILS: 'Modem issue detected.', // default concern details
  CONCERN_DETAILS_LABEL: 'Tell us more about your concern ', //omniScheTechConcernDetailsLabel
  CONFIRMATION_DIALOG_TITLE:
    'Are you sure you want to cancel your request for a technician visit?', //confirmationDialogTitle
  CONFIRM_BUTTON_LABEL: 'No', //confirmButtonLabel
  GUIDE_TITLE: 'Scheduling a technician reminder', //guideTitle
  GUIDE_TEXT_WITH_SLOT:
    'Real-time scheduling of technician is accessible 24/7, but scheduling options may vary based on technician availability.', //guideText,
  GUIDE_TEXT_NO_SLOT:
    'Real-time scheduling of our technician is only available between 8 AM and 5 PM daily.<br/><br/>For requests beyond 5 PM, we can cater to you at the earliest on the next day.',
  PAGE_TITLE: 'Choose a date and time for technician visit', //pageTitle
  TIME: {
    AM: '9:00 AM -<br/>12:00 NN',
    PM: '1:00 PM -<br/>5:00 PM',
  },
  TXTAREA_MIN_HEIGHT: 96,
  TXTAREA_MAX_LENGTH: 500,
  TXTAREA_PLACE_HOLDER_VALUE: 'You can use English or Taglish.',
  SCHED_TODAY_LABEL: 'TODAY',
  SCHED_NEXT_DAY_LABEL: 'NEXT DAY',
  SCHED_IN_TWO_DAYS_LABEL: 'IN 2 DAYS',

  BTN_NEXT_LABEL: 'Next',
  BTN_CANCEL_LABEL: 'Cancel',

  API_CHANNEL: "GBP",
  API_TARGET_TYPE: "2",
  API_WORKFLOW_OPERATION: "0",

  OTHER_DATE_TILE: "Select other available date",
  OTHER_DATE_SUBTILE: "Having trouble finding a slot that works for you? You may also choose your preferred date for the technician visit.",

  OMNI_CREATECASE_SOURCENUMBER: "1"
};
//#endregion

//#region scheduling constants
export const WHEN_TODAY: WHEN = 'TODAY';
export const WHEN_TOMORROW: WHEN = 'TOMORROW';
export const WHEN_THEREAFTER: WHEN = 'THEREAFTER';

export const SCHED_PATTERN: OmniScheduleSlot[] = [
  {
    label: OMNI_SCHED_TECHNICIAN_INIT_VALUES.SCHED_TODAY_LABEL,
    when: WHEN_TODAY,
    time: OMNI_SCHED_TECHNICIAN_INIT_VALUES.TIME.AM,
  },
  {
    label: OMNI_SCHED_TECHNICIAN_INIT_VALUES.SCHED_TODAY_LABEL,
    when: WHEN_TODAY,
    time: OMNI_SCHED_TECHNICIAN_INIT_VALUES.TIME.PM,
  },
  {
    label: OMNI_SCHED_TECHNICIAN_INIT_VALUES.SCHED_NEXT_DAY_LABEL,
    when: WHEN_TOMORROW,
    time: OMNI_SCHED_TECHNICIAN_INIT_VALUES.TIME.AM,
  },
  {
    label: OMNI_SCHED_TECHNICIAN_INIT_VALUES.SCHED_NEXT_DAY_LABEL,
    when: WHEN_TOMORROW,
    time: OMNI_SCHED_TECHNICIAN_INIT_VALUES.TIME.PM,
  },
  {
    label: OMNI_SCHED_TECHNICIAN_INIT_VALUES.SCHED_IN_TWO_DAYS_LABEL,
    when: WHEN_THEREAFTER,
    time: OMNI_SCHED_TECHNICIAN_INIT_VALUES.TIME.AM,
  },
  {
    label: OMNI_SCHED_TECHNICIAN_INIT_VALUES.SCHED_IN_TWO_DAYS_LABEL,
    when: WHEN_THEREAFTER,
    time: OMNI_SCHED_TECHNICIAN_INIT_VALUES.TIME.PM,
  },
];
//#endregion

//#region API requests
export const OMIN_SEARCH_APPOINTMENT_SLOT_REQ: OmniSearchAppointmentSlotReqBody =
  {
    orderId: '',
    orderActionId: '',
    serviceRequiredDate: '',
    accountNumber: '',
    customerFirstName: '',
    customerLastName: '',
    primaryContactFirstName: '',
    primaryContactLastName: '',
    contactEmailAddress: '',
    contactPreferredMethod: 'Mobile',
    customerType: 'Consumer',
    customerSubType: 'Regular',
    billingOfferName: 'string',
    orderActionType: 'PR',
    reasonCode: '24HRepair',
    subscriptionType: 'Regular',
    crmUserId: '24hrrepair',
    skillSet: 'Repair',
    channel: 'NG124HR',
    noOfAddresses: '1',
    hasSoftbook: 'true',
    primaryResource: '',
    severity: 'Medium',
    caseTypeLevel1: 'string',
    caseTypeLevel2: 'string',
    caseTypeLevel3: 'string',
    caseTypeLevel4: 'string',
    caseTypeLevel5: 'string',
    accessType: '',
    serviceType: 'BBWL',
    notes: [
      {
        noteDate: '',
        noteAuthor: 'System API',
        noteText: '',
      },
    ],
    installationDetails: [
      {
        houseBuildingNo: ',',
        streetName: '',
        city: '',
        barangay: ',',
        province: '',
        postalCode: ',',
        country: 'Philippines',
        region: '',
        latitude: '',
        longitude: '',
      },
    ],
    relatedParty: {
      relatedPartyId: 'string',
      relatedPartyRole: 'string',
      relatedPartyName: 'string',
    },
    contactMobileNo: '',
    phoneNumber: '',
    sourceNumber: '1',
  };
//#endregion

export const MONTHS_NAME = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

export const OMNI_SCHED_TECHNICIAN_CASE_CREATE_TYPE1 = { //concern_type = I can't connect to the internet
  CONCERN_TYPE: "I can’t connect to the internet",
  TITLE: 'G1_FOR ONSITE REPAIR_NO INTERNET NO DIAL TONE',
  CASE_TYPE_LEVEL1: 'TROUBLE TICKET',
  CASE_TYPE_LEVEL1_REFID: 'TT_TROUBLE TICKET',
  CASE_TYPE_LEVEL2: 'WTB-VOICE AND DATA RELATED',
  CASE_TYPE_LEVEL2_REFID: 'WTB-VOICE AND DATA RELATED',
  CASE_TYPE_LEVEL3: 'NO VOICE NO DATA',
  CASE_TYPE_LEVEL3_REFID: 'NO VOICE NO DATA',
  CASE_TYPE_LEVEL4: 'NO INTERNET NO DIAL TONE',
  CASE_TYPE_LEVEL4_REFID: 'NO INTERNET NO DIAL TONE',
  CASE_TYPE_LEVEL5: 'ENDORSED TO CFS',
  CASE_TYPE_LEVEL5_REFID: 'ENDORSED TO CFS',
  WORK_FLOW_TARGET: 'WIRELINE IROC CFT',
  SEVERITY: 'Medium',
  PRIORITY: 'Medium',
  STATUS: 'NEW'
}


export const OMNI_SCHED_TECHNICIAN_CASE_CREATE_TYPE2 = { //concern_type = I CANT USE MY TELEPHONE
  CONCERN_TYPE: "I can’t use my telephone",
  TITLE: 'G1_FOR ONSITE REPAIR_UPON HANDSET PICK UP',
  CASE_TYPE_LEVEL1: 'TROUBLE TICKET',
  CASE_TYPE_LEVEL1_REFID: 'TT_TROUBLE TICKET',
  CASE_TYPE_LEVEL2: 'WTV-VOICE RELATED',
  CASE_TYPE_LEVEL2_REFID: 'WTV_VOICE RELATED',
  CASE_TYPE_LEVEL3: 'NO DIAL / BUSY TONE',
  CASE_TYPE_LEVEL3_REFID: 'NO DIAL / BUSY TONE',
  CASE_TYPE_LEVEL4: 'UPON HANDSET PICK  UP',
  CASE_TYPE_LEVEL4_REFID: 'UPON HANDSET PICK  UP',
  CASE_TYPE_LEVEL5: 'ENDORSED TO CFS',
  CASE_TYPE_LEVEL5_REFID: 'ENDORSED TO CFS',
  WORK_FLOW_TARGET: 'WIRELINE IROC CFT',
  SEVERITY: 'Medium',
  PRIORITY: 'Medium',
  STATUS: 'NEW'
}

export const OMNI_SCHED_TECHNICIAN_CASE_CREATE_TYPE3 = { //concern_type = I'm experiencing slow internet connections
  CONCERN_TYPE: "I’m experiencing slow internet connection",
  TITLE: 'G1_FOR ONSITE REPAIR_ACROSS ALL SITES',
  CASE_TYPE_LEVEL1: 'TROUBLE TICKET',
  CASE_TYPE_LEVEL1_REFID: 'TT_TROUBLE TICKET',
  CASE_TYPE_LEVEL2: 'WTC-CONNECTION RELATED',
  CASE_TYPE_LEVEL2_REFID: 'WTC_CONNECTION RELATED',
  CASE_TYPE_LEVEL3: 'SLOW BROWSING',
  CASE_TYPE_LEVEL3_REFID: 'SLOW BROWSING',
  CASE_TYPE_LEVEL4: 'ACROSS ALL SITES',
  CASE_TYPE_LEVEL4_REFID: 'ACROSS ALL SITES',
  CASE_TYPE_LEVEL5: 'ENDORSED TO CFS',
  CASE_TYPE_LEVEL5_REFID: 'ENDORSED TO CFS',
  WORK_FLOW_TARGET: 'WIRELINE IROC CFT',
  SEVERITY: 'Medium',
  PRIORITY: 'Medium',
  STATUS: 'NEW'
}

export const OMNI_SCHED_TECHNICIAN_CASE_CREATE_LOGNOTE_LABEL = {
  MOBILE_NUMBER_LABEL: 'Mobile Number',
  ALTERNATIVE_MOBILE_NUMBER_LABEL: 'Alternative Mobile Number',
  CHANNEL_LABEL: 'Channel',
  CHANEL_VALUE: 'NG1 24HR Repair',

  ACCOUNT_STATUS_LABEL: 'Account Status',
  OUTAGE_RESULT_LABEL: 'Outage Result',
  FUP_INDICATOR_LABEL: 'FUP Indicator',
  LINE_STATUS_LABEL: 'Line Status',
  MODEM_STATUS_LABEL: 'Modem Status'
}

export const OMNI_UPDATE_TICKET_DETAILS_REQ :UpdateTicketDetailsRequest = {
  ticketStatus: 'Solving',
  sourceSystem: '24HRRepair',
  note: '24HRRepair',
  queueId: 'TP Wireline Tier2'
}

export const UPDATE_TICKET_FLEXIBLE_ATTRIBUTE = 'X_APPOINTMENT_ID_TT_CASE';