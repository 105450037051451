import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
  Output,
  Input,
  EventEmitter,
} from '@angular/core';
import {
  OMNI_RESCHEDULE_REQUEST_CONCERN_MIN_HEIGHT,
  OMNI_RESCHEDULE_REQUEST_CONCERN_MAX,
  OMNI_RESCHEDULE_REQUEST_ISSUE_TYPES,
  OMNI_RESCHEDULE_REQUEST_SUCCESS,
  OMNI_RESCHEDULE_REQUEST_CANCEL,
  OMNI_RESCHEDULE_REQUEST_LABEL,
  OMNI_REQUEST_TIME,
  OMNI_UPDATE_TICKET_DETAILS_REQ_RESCHED,
} from 'src/app/common/constants/omni-reschedule-request.constants';
import { PAGE_URLS } from 'src/app/common/constants/obi-global.constants';
import { OmniCommonService } from 'src/app/services/omni-common.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { OmniSessionService } from 'src/app/services/omni-session.service';
import { OmniScheduleObject } from 'src/app/interfaces/omni-sched-technician.interface';
import { OmniSchedTechnicianService } from '../../../services/omni-sched-technician.service';
import { OmniTrackMyRepairService } from '../omni-track-my-repair/omni-track-my-repair.service';

import { OMNI_SCHED_TECHNICIAN_INIT_VALUES } from 'src/app/common/constants/omni-sched-technician.constant';
import {
  SESSION_KEYS,
  CXS_API_URLS,
} from 'src/app/common/constants/omni-global.constant';
import * as moment from 'moment';
import {
  APPOINTMENT_DATE_FORMAT,
  APPOINTMENT_DATE_MAPPING,
  OMNI_TRACK_STATUS,
} from 'src/app/common/constants/omni-track-my-repair.constant';
import { OmniTrackMyRepairDateMapping } from '../omni-track-my-repair/omni-track-my-repair.interface';
import { Observable, of, throwError, EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { mergeMap, delay, retry } from 'rxjs/operators';

const { API_CHANNEL, API_TARGET_TYPE } = OMNI_SCHED_TECHNICIAN_INIT_VALUES;

@Component({
  selector: 'app-obi-bill-inquiry',
  templateUrl: './omni-reschedule-request.component.html',
  styleUrls: ['./omni-reschedule-request.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OmniRescheduleRequestComponent implements AfterViewInit {
  @ViewChild('concernTextareaRef')
  textareaRef!: ElementRef<HTMLTextAreaElement>; // Template reference variable
  @Output() dateSelected = new EventEmitter<string>();
  @Output() timeSelected = new EventEmitter<string>();
  @Input() selectedDate: string | null = null;
  @Input() selectedTime: string | null = null;

  selectedCalendarDate: NgbDateStruct | null = null;

  /**
   * Label spiels for the form
   */
  public omniRescheduleRequestLabels = OMNI_RESCHEDULE_REQUEST_LABEL;

  /**
   * Spiels for cancel modal
   */
  public omniRescheduleRequestCancel = OMNI_RESCHEDULE_REQUEST_CANCEL;

  /**
   * Spiels for success modal
   */
  public omniRescheduleRequestSuccess = OMNI_RESCHEDULE_REQUEST_SUCCESS;

  /**
   * List of issue types for issue dropdown
   */
  public omniRescheduleRequestReasonTypes = OMNI_RESCHEDULE_REQUEST_ISSUE_TYPES;

  /**
   * List of time types for time dropdown
   */
  public omniTimeTypes = OMNI_REQUEST_TIME;

  /**
   * Data object for the Repair Confirmed
   */
  public workOrderDetails: any;

  /**
   * Flag to show workOrderDetails modal
   */
  public showWorkOrderDetails = false;

  /**
   * Flag for displaying reason types dropdown
   */
  public isReasonTypeShow = false;

  /**
   * Flag for displaying time dropdown
   */
  public isTimeSelectShow = false;

  /**
   * Flag for omni date picker condition
   */
  public isReschedule = true;

  /**
   * Flag for displaying calendar
   */
  public showCalendar = false;

  /**
   * Action for cancel modal confirm button
   */
  public omniRescheduleRequestCancelConfirmAction = () => {};

  /**
   * Action for cancel modal cancel button
   */
  public omniRescheduleRequestCancelCancelAction = () => {};

  /**
   * Flag for displaying cancel modal
   */
  public isCancel = false;

  /**
   * Flag for displaying cancel modal
   */
  public isLoading = false;

  /**
   * Flag for displaying success modal
   */
  public isSubmitted = false;

  /**
   * Max char length of concern textarea
   */
  concernTextareaMaxLength = OMNI_RESCHEDULE_REQUEST_CONCERN_MAX;

  /**
   * Max char length of concern textarea
   */
  concernTextareaMinHeight = OMNI_RESCHEDULE_REQUEST_CONCERN_MIN_HEIGHT;

  /**
   * Character count of concern field
   */
  concernTextareaCharCount = 0;

  /**
   * Character count left allowed for concern field
   */
  concernTextareaRemainingChars = 100;

  accountId: string = '';
  orderActionId: string = '';
  currentTechSchedDate: string = '';

  /**
   * FormGroup to manage the bill inquiry form
   */
  public rescheduleRequest: FormGroup = new FormGroup({
    reasonType: new FormControl('', [Validators.required]),
    time: new FormControl('', [Validators.required]),
    date: new FormControl('', [Validators.required]),
    concern: new FormControl(''),
  });

  constructor(
    public commonService: OmniCommonService,
    private sessionService: OmniSessionService,
    private omniSchedTechnicianService: OmniSchedTechnicianService,
    private omniTrackMyRepairService: OmniTrackMyRepairService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.accountId = this.sessionService.getData(
      SESSION_KEYS.OMNI_ACCOUNT_NUMBER_KEY
    );

    this.orderActionId = this.sessionService.getData(
      SESSION_KEYS.OMNI_RESCHED_ORDER_ACTION_ID_KEY
    );

    this.currentTechSchedDate = this.sessionService.getData(
      SESSION_KEYS.OMNI_CURRENT_SCHED_DATE
    );
  }

  ngAfterViewInit(): void {
    this.omniRescheduleRequestCancelConfirmAction = () =>
      this.toggleCancelModal();
    this.omniRescheduleRequestCancelCancelAction = () =>
      this.commonService.navigate(
        this.omniRescheduleRequestCancel.cancelButtonUrl
      );
  }

  /**
   * Compute the remaining character for the textarea
   */
  public txtAreaInput(event: any) {
    if (!event?.target) return;

    const inputValue = event.target.value;
    const maxLength = this.concernTextareaMaxLength;
    let sanitizedValue = this.sanitizeTxtAreaInput(inputValue);
    const sanitizedValueLength =
      sanitizedValue?.replace(/(\r\n)/g, '\n')?.length | 0;

    if (sanitizedValueLength > maxLength) {
      sanitizedValue = sanitizedValue.substring(0, maxLength);
      event.target.value = sanitizedValue;
    } else {
      this.rescheduleRequest.patchValue({
        concern: sanitizedValue,
      });
      this.concernTextareaCharCount = sanitizedValueLength;
      this.concernTextareaRemainingChars = maxLength - sanitizedValue.length;
      this.adjustTextareaHeight();
    }
  }

  /**
   * Handles keydown event on concern details textarea
   * Prevents further input when reached max length except for non appending keys
   * @param event
   */
  public txtAreaKeydown(event: any) {
    let sanitizedValue = this.sanitizeTxtAreaInput(event.target.value);
    const sanitizedValueLength =
      sanitizedValue?.replace(/(\r\n)/g, '\n')?.length | 0;
    if (
      sanitizedValueLength === this.concernTextareaMaxLength &&
      ((event.keyCode >= 48 && event.keyCode <= 90) ||
        [110, 188, 220, 189, 191, 32, 13].includes(event.keyCode)) &&
      !event.ctrlKey &&
      !event.altKey
    ) {
      event.preventDefault();
    }
  }

  /**
   * Handles paste event on concern details textarea
   * Limits the pasted value to max length of the textarea
   * @param event
   */
  public txtAreaPaste(event: any) {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    let prevValue = `${this.rescheduleRequest.controls['concern'].value}`;
    prevValue = prevValue.replace(/(\r\n)/g, '\n');
    if (clipboardData) {
      const sanitizedData = this.sanitizeTxtAreaInput(
        clipboardData.getData('text')
      );
      const sanitizedDataNewLineCount =
        sanitizedData.match(/(\r\n)/g)?.length || 0;
      const clippedData = sanitizedData.substring(
        0,
        this.concernTextareaMaxLength -
          this.concernTextareaCharCount +
          (event.target.selectionEnd - event.target.selectionStart) +
          sanitizedDataNewLineCount
      );
      const newConcernDetails = `${prevValue.slice(
        0,
        event.target.selectionStart
      )}${clippedData}${prevValue.slice(event.target.selectionEnd)}`;
      this.rescheduleRequest.patchValue({
        concern: this.sanitizeTxtAreaInput(newConcernDetails),
      });
      event.target.value = this.rescheduleRequest.controls['concern'].value;
      const sanitizedValueLength =
        this.rescheduleRequest.controls['concern'].value?.replace(
          /(\r\n)/g,
          '\n'
        )?.length | 0;
      this.concernTextareaCharCount = sanitizedValueLength;
      this.concernTextareaRemainingChars =
        this.concernTextareaMaxLength - sanitizedValueLength;
      this.adjustTextareaHeight();
    }
  }

  /**
   * Adjust textarea (concern details) height based on user input
   */
  public adjustTextareaHeight() {
    const textarea = this.textareaRef.nativeElement;
    textarea.style.height = this.concernTextareaMinHeight + 'px';

    textarea.style.height =
      Math.max(this.concernTextareaMinHeight, textarea.scrollHeight) + 2 + 'px';
  }

  /**
   * Clears inputValue of all characters not allowed
   * @param inputValue
   * @returns
   */
  sanitizeTxtAreaInput(inputValue: string): string {
    return inputValue.replace(/[^a-zA-Z0-9.,\-?! \r\n]/g, '');
  }

  /**
   * Patches the current form group with the selected item
   * @param reasonType
   */
  onSelectReasonType(reasonType: string) {
    this.rescheduleRequest.patchValue({
      reasonType: reasonType,
    });
    this.rescheduleRequest.patchValue({
      concern: '',
    });
    this.toggleReasonTypes();
  }

  /**
   * Patches the current form group with the selected item
   * @param time
   */
  onSelectTime(time: string) {
    this.rescheduleRequest.patchValue({
      time: time,
    });
    this.toggleTimeSelect();
  }

  /**
   * Parses data needed for submitting bill inquiry
   * Submits parsed data to API
   * Redirects user after successful submission
   */
  submitRescheduleRequest() {
    this.isLoading = true;

    const formattedDateSched = this.convertDateFormat(
      this.selectedDate ? this.selectedDate : ''
    );
    const timeSlotAMorPM = this.checkTimeSlotAMorPM(
      this.rescheduleRequest.get('time')?.value
    );

    let reschedNotes = `Reason for rescheduling: ${
      this.rescheduleRequest.get('reasonType')?.value ?? ''
    }`;
    const concernValue = this.rescheduleRequest.get('concern')?.value;
    if (concernValue) {
      reschedNotes += `: ${concernValue}`;
    }

    const schedNotes = `${this.sessionService.getData(
      SESSION_KEYS.OMNI_RESCHED_NOTES_KEY
    )} | ${reschedNotes}`;
    //Confirm Repair request Object
    const schedData: OmniScheduleObject = {
      accountId: this.accountId,
      channel: API_CHANNEL,
      orderId: `${this.orderActionId}-1`,
      orderActionId: this.orderActionId,
      targetType: API_TARGET_TYPE,
      notes: schedNotes,
      preferredAppointmentSlot: {
        date: formattedDateSched,
        slot: timeSlotAMorPM,
      },
      accountNumber: this.accountId,
    };

    this.omniSchedTechnicianService
      .postAppointmentBooking(schedData)
      .subscribe({
        next: (response: any) => {
          if (response?.result.statusCode == '200') {
            /**
             * OM24H-883: Call Update TicketDetails API
             */
            this.reschedUpdateTicketDetails();
          } else {
            return this.commonService.navigate(
              PAGE_URLS.GENERIC_ERROR_PAGE_URL
            );
          }
        },
        error: error => {
          this.commonService.handleAPIError(error);
        },
      });
  }

  /**
   * Redirects user to dashboard
   */
  gotoDashboard() {
    this.isLoading = true;
    this.commonService.navigate(PAGE_URLS.GOTO_DASHBOARD_URL);
  }

  /**
   * Shows/hides cancel modal
   */
  toggleCancelModal() {
    this.isCancel = !this.isCancel;
  }

  /**
   * Shows/hides issue types dropdown
   */
  toggleReasonTypes() {
    this.isReasonTypeShow = !this.isReasonTypeShow;
  }

  /**
   * Shows/hides issue types dropdown
   */
  toggleTimeSelect() {
    if (this.rescheduleRequest.get('date')?.value) {
      this.isTimeSelectShow = !this.isTimeSelectShow;
    }
  }

  /**
   * Shows the calendar date picker modal
   */
  showCalendarPicker() {
    this.showCalendar = true;
  }

  /**
   * Closes the calendar date picker modal
   */
  handleCalendarClosed() {
    this.showCalendar = false;
  }

  /**
   * Action for modal "Track my repair status" button
   */
  omniRescheduleRequestSuccessTrackAction = () => {
    this.isLoading = true;
    this.showWorkOrderDetails = false;
    this.omniTrackMyRepairService
      .getWorkOrderDetails(this.accountId)
      .subscribe({
        next: res => {
          const workOrderList = res.result?.GBPWorkOrder;
          if (workOrderList) {
            const workOrder = this.getWorkOrderById(
              workOrderList,
              this.orderActionId
            );
            if (workOrder) {
              this.workOrderDetails = workOrder;
              this.workOrderDetails.displayStatus = this.mapWorkOrderStatus(
                workOrder.status || '',
                workOrder.subStatus
              );
              const appointmentDateWithTime =
                workOrder.appointmentDate.includes(':')
                  ? workOrder.appointmentDate
                  : `${workOrder.appointmentDate} 00:00:00`;
              this.workOrderDetails.displayAppointmentDate =
                this.mapAppointmentDate(
                  `${appointmentDateWithTime} ${workOrder.appointmentTime}`
                );
              this.isSubmitted = false;
              this.isLoading = false;
              this.showWorkOrderDetails = true;

              //Clear form data
              this.rescheduleRequest.reset();
            } else {
              this.commonService.navigate(PAGE_URLS.GENERIC_ERROR_PAGE_URL);
            }
          } else {
            this.commonService.navigate(PAGE_URLS.GENERIC_ERROR_PAGE_URL);
          }
        },
        error: error => {
          this.commonService.handleAPIError(error);
          this.isLoading = false;
        },
      });
  };

  /**
   * Action for cancel modal cancel button
   */
  omniRescheduleRequestSuccessDashboardAction() {
    this.isLoading = true;
    window.location.href = PAGE_URLS.GOTO_DASHBOARD_URL;
  }

  /**
   * Handles the date selection of calendar date picker
   * Sets the selected date on the input text box
   * @param date date selected
   */
  handleDateSelection(date: NgbDateStruct) {
    if (date) {
      this.rescheduleRequest.patchValue({
        time: '',
      });
      // add prefix 0 for months/days below 10
      let month: string = date.month < 10 ? `0${date.month}` : `${date.month}`;
      let day: string = date.day < 10 ? `0${date.day}` : `${date.day}`;
      this.selectedDate = `${month}/${day}/${date.year}`;

      this.rescheduleRequest.patchValue({
        date: this.selectedDate,
      });

      this.dateSelected.emit(this.selectedDate);
    }
  }

  /**
   * Timeslot check if AM/PM for APM request
   * @param schedTime
   * @returns
   */
  checkTimeSlotAMorPM(schedTime: string): string {
    return schedTime.toUpperCase().includes('AM') ? 'AM' : 'PM';
  }

  /**
   * Convert dateformat
   * @param schedDate
   * @returns
   */
  convertDateFormat(schedDate: string): string {
    if (!moment(schedDate, 'MM/DD/YYYY', true).isValid()) {
      return 'Invalid Date';
    }

    const parsedDate = moment(schedDate, 'MM/DD/YYYY');
    const formattedDate = parsedDate.format('YYYY-MM-DD');

    return formattedDate;
  }

  /**
   * Get WorkOrder By Id
   * @param workOrders
   * @param id
   * @returns
   */
  getWorkOrderById(workOrders: any[], id: String) {
    return workOrders.find(workOrder => workOrder.id === id);
  }

  /**
   * Maps the work order status from response to defined display text
   * @param status
   * @returns
   */
  mapWorkOrderStatus(status: string, subStatus?: string): string {
    let result = '';

    const matchingEntry = OMNI_TRACK_STATUS.find(entry => {
      return (
        entry.status === status &&
        (subStatus === undefined || entry.subStatus.includes(subStatus))
      );
    });

    if (matchingEntry) {
      result = matchingEntry.title;
    }

    return result;
  }

  /**
   * Maps the custom appointment date from response to defined display text
   * @param date
   * @returns
   */
  mapAppointmentDate(date: string) {
    let result = date;
    const appointmentDate = moment(date, APPOINTMENT_DATE_FORMAT);
    const appointmentDateMapping = APPOINTMENT_DATE_MAPPING.filter(
      (schedule: OmniTrackMyRepairDateMapping) =>
        schedule.flag === appointmentDate.format('A')
    );
    if (appointmentDateMapping?.length) {
      result = `${appointmentDate.format('MMMM DD, YYYY')}, ${
        appointmentDateMapping[0].schedule
      }`;
    }
    return result;
  }

  /**
   * OM24H-883: Call Update ticketDetails API for reschedule scenario
   */
  reschedUpdateTicketDetails() {
    let apiCallCounter = 0;
    const MAX_API_CALL = 4;

    let updateTicketDetailsReq = OMNI_UPDATE_TICKET_DETAILS_REQ_RESCHED;
    updateTicketDetailsReq.note = `${updateTicketDetailsReq.note} ${
      this.selectedDate
    } ${this.rescheduleRequest.get('time')?.value}`;

    this.putUpdateTicketDetails(OMNI_UPDATE_TICKET_DETAILS_REQ_RESCHED)
      .pipe(
        mergeMap(response => {
          const statusCode = response?.statusCode;
          if (statusCode === 204) {
            //Stop loading screen
            //Show successful resced technician visit modal
            this.isLoading = false;
            this.isSubmitted = true;
            return of(response); // Return a successful response
          } else {
            apiCallCounter++;
            if (apiCallCounter >= MAX_API_CALL) {
              //Stop loading screen
              //Show successful resced technician visit modal
              this.isLoading = false;
              this.isSubmitted = true;
              return EMPTY;
            } else {
              return throwError('Non-204 status code'); // Trigger retry
            }
          }
        }),
        retry(MAX_API_CALL - 1), // Retry up to 3 times
        delay(1000) // Delay before retrying
      )
      .subscribe({
        error: error => {
          this.commonService.handleAPIError(error);
        },
      });
  }

  /**
   * Update Ticket Details
   *
   * @param body
   * @returns
   */
  putUpdateTicketDetails(body: any): Observable<any> {
    const apiURL = `${CXS_API_URLS.UPDATE_TICKET_DETAILS}/${this.orderActionId}`;
    return this.http.put<any>(apiURL, body);
  }
}
