export const OMNI_ALL_GOOD = {
  icon: "/assets/icons/welcome-icon.svg",
  title: "All good!",
  body: "<div>Your internet connection is working.</div><div>If you have any concerns or need further support, just visit Help Center anytime.</div>",
  buttonLabel: "Go back to dashboard",
  buttonNavigationUrl: "/omnicare/navigate?destination=dashboard",
};

export const OMNI_WELCOME_BACK = {
  icon: "/assets/icons/welcome-icon.svg",
  title: "Welcome back!",
  body: "<div>Your internet connection is now working.</div><div>If you have any concerns or need further support, just visit Help Center anytime.</div>",
  buttonLabel: "Go back to dashboard",
  buttonNavigationUrl: "/omnicare/navigate?destination=dashboard",
};