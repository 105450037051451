import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { OmniSessionService } from '../../../services/omni-session.service';
import { OmniSchedTechnicianService } from '../../../services/omni-sched-technician.service';
import { OmniScheduleObject } from '../../../interfaces/omni-sched-technician.interface';
import { Observable, of, throwError, EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { mergeMap, delay, retry } from 'rxjs/operators';
import { GoogleTagManagerService } from 'src/app/services/google-tag-manager-service.service';

import {
  OMNI_SCHED_TECHNICIAN_INIT_VALUES,
  OMIN_SEARCH_APPOINTMENT_SLOT_REQ,
  MONTHS_NAME,
  OMNI_UPDATE_TICKET_DETAILS_REQ,
  UPDATE_TICKET_FLEXIBLE_ATTRIBUTE
} from '../../../common/constants/omni-sched-technician.constant';

import {
  PAGE_URLS,
  SESSION_KEYS,
  CXS_API_URLS
} from '../../../common/constants/omni-global.constant';

import {
  OmniScheduleCard,
  OmniSearchAppointmentSlotReqBody,
} from '../../../interfaces/omni-sched-technician.interface';
import { OmniCommonService } from 'src/app/services/omni-common.services';

import {
  OMNI_SCHED_TECHNICIAN_MODAL_NO_TAG,
  OMNI_SCHED_TECHNICIAN_MODAL_YES_TAG,
  OMNI_SCHED_TECHNICIAN_CANCEL_TAG,
  OMNI_SCHED_TECHNICIAN_SUBMIT_TAG,
} from 'src/app/common/constants/omni-gtm-events.constants';

//#region constants
const {
  CANCEL_BUTTON_LABEL,
  CONFIRM_BUTTON_LABEL,
  CONFIRMATION_DIALOG_TITLE,
  GUIDE_TITLE,
  GUIDE_TEXT_WITH_SLOT,
  GUIDE_TEXT_NO_SLOT,
  PAGE_TITLE,
  API_CHANNEL,
  API_TARGET_TYPE,
  OTHER_DATE_TILE,
  OTHER_DATE_SUBTILE,
} = OMNI_SCHED_TECHNICIAN_INIT_VALUES;
//#endregion

@Component({
  selector: 'app-omni-sched-technician',
  templateUrl: './omni-sched-technician.component.html',
  styleUrls: ['./omni-sched-technician.component.scss'],
})
export class OmniSchedTechnicianComponent implements OnInit {
  constructor(
    private router: Router,
    private omniSessionService: OmniSessionService,
    private omniSchedTechnicianService: OmniSchedTechnicianService,
    private commonService: OmniCommonService,
    private http: HttpClient,
    private gtmService: GoogleTagManagerService
  ) {}

  schedules: OmniScheduleCard[] = [];
  selectedSchedule: OmniScheduleCard | null = null;
  withSlots: boolean = true;
  loading: boolean = false;
  confirmation: boolean = false;
  selectedDate: string = '';
  selectedTime: string = '';

  dashboardUrl: string = PAGE_URLS.GOTO_DASHBOARD_URL;
  confirmationDialogTitle: string = CONFIRMATION_DIALOG_TITLE;
  confirmButtonLabel: string = CONFIRM_BUTTON_LABEL;
  cancelButtonLabel: string = CANCEL_BUTTON_LABEL;
  pageTitle: string = PAGE_TITLE;
  guideTitle: string = GUIDE_TITLE;
  guideText: string = '';
  accordionTitle: string = OTHER_DATE_TILE;
  accordionSubtitle: string = OTHER_DATE_SUBTILE;

  userData: any = {};
  appointmentSlotReq: OmniSearchAppointmentSlotReqBody =
    OMIN_SEARCH_APPOINTMENT_SLOT_REQ;
  
  orderActionId: string = '';

  ngOnInit(): void {
    //Get user data in the session service
    this.userData = this.omniSessionService.getUserData() || {};
    this.withSlots = this.userData.withSlots;
    this.schedules = this.userData.schedules;

    //Update guideText spiel depends on schedule availability (with Slot or No Slot)
    this.guideText = this.withSlots ? GUIDE_TEXT_WITH_SLOT : GUIDE_TEXT_NO_SLOT;
  }

  /**
   * Triggered when schedule card is clicked. Selects/unselects the schedule.
   * @param schedule schedule card being clicked
   */
  selectSchedule(schedule: OmniScheduleCard): void {
    this.selectedSchedule = schedule == this.selectedSchedule ? null : schedule;
    // clear datepicker selection
    this.selectedDate = '';
    this.selectedTime = '';
  }

  /**
   * Submits schedule request
   */
  submitRequest() {
    this.loading = true; // Show the loading modal

    // OM24H-949: capture GTM event
    this.captureGTMEvent(OMNI_SCHED_TECHNICIAN_SUBMIT_TAG);

    //Confirm Repair request Object
    let schedData: OmniScheduleObject = {
      accountId: '',
      channel: API_CHANNEL,
      orderId: '',
      orderActionId: '',
      targetType: API_TARGET_TYPE,
      notes: '',
      preferredAppointmentSlot: {
        date: '',
        slot: '',
      },
      accountNumber: ''
    };
    const accountNumber = this.omniSessionService.getData(SESSION_KEYS.OMNI_ACCOUNT_NUMBER_KEY);
    this.orderActionId = this.omniSessionService.getData(SESSION_KEYS.OMNI_ORDER_ACTION_ID);
    schedData.orderActionId = this.orderActionId;
    schedData.orderId = this.omniSessionService.getData(SESSION_KEYS.OMNI_ORDER_ID);
    schedData.accountId = accountNumber;
    schedData.notes = `Concern: ${this.omniSessionService.getData(SESSION_KEYS.OMNI_CONCERN_TYPE)} | Description: ${this.omniSessionService.getData(SESSION_KEYS.OMNI_CONCERN_DETAILS)}`;


    let formattedDateSched;
    let timeSlotAMorPM;
    if (this.selectedSchedule) {
      formattedDateSched = this.formatSchedDate(
        this.selectedSchedule?.day || ''
      );
      timeSlotAMorPM = this.checkTimeSlotAMorPM(
        this.selectedSchedule?.time || ''
      );
    } else {
      formattedDateSched = this.convertNoSlotDateFormat(this.selectedDate);
      timeSlotAMorPM = this.checkTimeSlotAMorPM(this.selectedTime);
    }

    schedData.preferredAppointmentSlot.date = formattedDateSched;
    schedData.preferredAppointmentSlot.slot = timeSlotAMorPM;
    schedData.accountNumber = accountNumber;

    this.omniSchedTechnicianService
      .postAppointmentBooking(schedData)
      .subscribe({
        next: (response: any) => {
          const appointmentId = response?.result.appointmentId;
          this.omniSessionService.setData(
            SESSION_KEYS.OMNI_APPOINTMENT_SLOT_KEY,
            appointmentId
          );
          if (response?.result.statusCode == '200') {
            
            const workOrderId = this.omniSessionService.getData(
              SESSION_KEYS.OMNI_ORDER_ACTION_ID
            );
            this.omniSessionService.setData(
              SESSION_KEYS.OMNI_APPOINTMENT_SLOT_KEY,
              workOrderId
            );
            this.saveSelectedSchedule();

            //OM24H-909: Call Update ticket details
            return this.confirmAppointmentUpdateTicketDetails(appointmentId);
          } else {
            return this.router.navigate([PAGE_URLS.GENERIC_ERROR_PAGE_URL]);
          }
        },
        error: (error) => {
          this.commonService.handleAPIError(error);
        }
      });
  }

  /**
   * Shows the confirmation modal
   */
  showConfirmationModal() {
    // OM24H-949: capture GTM event
    this.captureGTMEvent(OMNI_SCHED_TECHNICIAN_CANCEL_TAG);

    this.confirmation = true;
  }

  /**
   * Handles confirmation modal click on 'No' button
   */
  handleConfirmation() {
    return () => {
      // OM24H-949: capture GTM event
      this.captureGTMEvent(OMNI_SCHED_TECHNICIAN_MODAL_YES_TAG);
      
      this.confirmation = false;
    };
  }

  /**
   * Handles confirmation modal click on 'Yes' button
   */
  handleConfirmationCancel() {
    return () => {
      // OM24H-949: capture GTM event
      this.captureGTMEvent(OMNI_SCHED_TECHNICIAN_MODAL_NO_TAG);

      this.confirmation = false;
      window.location.href = this.dashboardUrl;
    };
  }

  /**
   * Handles time selection event of the time picker dropdown
   * @param time selected time
   */
  handleTimeSelection(time: string) {
    this.selectedTime = time;
  }

  /**
   * Handles date selection event of the calendar date picker
   * @param date selected date
   */
  handleDateSelection(date: string) {
    this.selectedTime = '';
    this.selectedDate = date;
    // clear selected sched card
    this.selectedSchedule = null;
  }

  /**
   * Save selected schedule in the session data
   */
  saveSelectedSchedule() {
    let formattedDate: string | null = '';
    let formattedTime: string | null = '';

    if (this.selectedSchedule) {
      // format date
      let selectedDate: moment.Moment = moment(
        this.selectedSchedule?.day,
        'MMMM DD'
      );
      selectedDate.year(moment().year());
      formattedDate = selectedDate.format('MMMM DD, YYYY');

      // format time
      const timeParts = this.selectedSchedule?.time.split('-');
      const startTime = timeParts && timeParts[0] ? timeParts[0].trim() : '';
      const endTime =
        timeParts && timeParts[1]
          ? timeParts[1].trim().replace('<br/>', '')
          : '';
      formattedTime = `${startTime} - ${endTime}`;
    } else {
      formattedDate = moment(this.selectedDate, 'MM/DD/YYYY').format(
        'MMMM DD, YYYY'
      );
      formattedTime = this.selectedTime;
    }

    //Save selected schedule in Session user data
    this.userData.schedule_repair_date = formattedDate;
    this.userData.schedule_repair_time = formattedTime;
    this.omniSessionService.setUserData(this.userData);
  }

  /**
   * This function format appointment date for API body request
   * @param schedDate
   * @returns
   */
  formatSchedDate(schedDate: string): string {
    const currentDate = new Date();
    const currentYear = new Date().getFullYear();
    const parts = schedDate.split(' ');

    if (parts.length === 2) {
      const month = parts[0].toUpperCase();
      const day = parseInt(parts[1]);
      let formattedDate: any;

      //Check if next schedule date will be next year.
      const monthIndex = this.getMonthIndex(month);
      if (currentDate.getMonth() === 11 && monthIndex === 0) {
        formattedDate = new Date(Date.UTC(currentYear + 1, monthIndex, day));
      } else {
        formattedDate = new Date(Date.UTC(currentYear, monthIndex, day));
      }

      // Check if the Date object is valid
      if (!isNaN(formattedDate.getTime())) {
        // Format the Date object as 'yyyy-MM-dd'
        return formattedDate.toISOString().split('T')[0];
      }
    }

    return '';
  }

  /**
   * This function get the month index for date format
   * @param monthName
   * @returns
   */
  getMonthIndex(monthName: string): number {
    return MONTHS_NAME.indexOf(monthName);
  }

  /**
   * Timeslot check if AM/PM for APM request
   * @param schedTime
   * @returns
   */
  checkTimeSlotAMorPM(schedTime: string): string {
    return schedTime.toUpperCase().includes('AM') ? 'AM' : 'PM';
  }

  /**
   * Convert dateformat for no time slot available
   * @param schedDate
   * @returns
   */
  convertNoSlotDateFormat(schedDate: string): string {
    // Remove spaces and split the input date string by slashes
    const cleanedDate = schedDate.replace(/\s+/g, '');
    const parts = cleanedDate.split('/');

    // Ensure we have exactly three parts (month, day, and year)
    if (parts.length === 3) {
      const formattedDate = moment(parts.join('/'), 'MM/DD/YYYY').format(
        'YYYY-MM-DD'
      );
      return formattedDate;
    }

    return '';
  }

  /**
   * OM24H-909: Call Update ticket details
   */
  confirmAppointmentUpdateTicketDetails(appointmentId:string){
    let apiCallCounter = 0;
    const MAX_API_CALL = 4;
    

    const { queueId, ...updateTicketDetailsReq } = OMNI_UPDATE_TICKET_DETAILS_REQ;
    updateTicketDetailsReq.flexibleAttributes = [{
      name: UPDATE_TICKET_FLEXIBLE_ATTRIBUTE,
      value: appointmentId
    }];

    this.putUpdateTicketDetails(updateTicketDetailsReq).pipe(
      mergeMap(response => {
        const statusCode = response?.statusCode;
        if (statusCode === 204) {
          this.loading = false;
          this.router.navigate([
            PAGE_URLS.REQUEST_CONFIRMATION_PAGE_URL,
          ]);
          return of(response); // Return a successful response
        } else {
          apiCallCounter++;
          if (apiCallCounter >= MAX_API_CALL) {
            this.loading = false;
            this.router.navigate([
              PAGE_URLS.REQUEST_CONFIRMATION_PAGE_URL,
            ]);
            return EMPTY;
          } else {
            return throwError('Non-204 status code'); // Trigger retry
          }
        }
      }),
      retry(MAX_API_CALL - 1), // Retry up to 3 times
      delay(1000) // Delay before retrying
    ).subscribe({
      error: error => {
        this.loading = false;
        return this.router.navigate([
          PAGE_URLS.REQUEST_CONFIRMATION_PAGE_URL,
        ]);
      }
    });
  }

  /**
   * Update Ticket Details
   *
   * @param body
   * @returns
   */
  putUpdateTicketDetails(body: any): Observable<any> {
    const apiURL = `${CXS_API_URLS.UPDATE_TICKET_DETAILS}/${this.orderActionId}`;
    return this.http.put<any>(apiURL, body);
  }

  /**
   * OM24H-949: Captures Google Tag events and push these tags to the dataLayer
   * @param eventInfo contains initial event info to be pushed
   */
  captureGTMEvent(eventInfo: any) {
    const orderStatus = this.omniSessionService.getData(
      SESSION_KEYS.OMNI_ORDER_STATUS
    );

    const {
      outageResult,
      lineStatus,
      modemStatus,
    } = this.userData;

    const additionalData = {
      promo_category: this.commonService.determineAccountStatusForTagging('promo_category', this.userData),
      status: this.commonService.determineAccountStatusForTagging('status', this.userData),
      account_status: this.commonService.determineAccountStatusForTagging('account_status', this.userData),
      outage_status: this.commonService.determineDiagStatusForTagging('outage_status', outageResult),
      fiber_cuts_issue: this.commonService.determineDiagStatusForTagging('fiber_cuts_issue', lineStatus),
      fiber_losses_issue: this.commonService.determineDiagStatusForTagging('fiber_losses_issue', lineStatus),
      modem_line: this.commonService.determineDiagStatusForTagging('modem_line', modemStatus),
      modem_power: this.commonService.determineDiagStatusForTagging('modem_power', modemStatus),
      reschedule: orderStatus == 'open' ? 'yes' : 'no',
    };

    this.gtmService.captureGTMEvent(eventInfo, false, additionalData);
  }
}
