<div [ngClass]="['omni-basic-ts', isFullyDisplayed ? 'disable-scrolling' : '']">
  <div class="omni-basic-ts__content">
    <p
      class="omni-basic-ts__content-title"
      [innerHTML]="omniBasicTSContentTitle"></p>

    <ol>
      <li class="omni-basic-ts__list" *ngFor="let ts of tsSteps">
        <p class="omni-basic-ts__list-title" [innerHTML]="ts.title"></p>
        <p class="omni-basic-ts__list-text" [innerHTML]="ts.text"></p>
      </li>
    </ol>
  </div>

  <div class="omni-basic-ts__btn-wrapper">
    <p class="omni-basic-ts__btn-confirmtxt" [innerHTML]="confirmFixTxt"></p>

    <button
      (click)="redirectToWelcomeBackPage()"
      class="omni-basic-ts__btn-success"
      [innerHTML]="btnYesLabel"></button>

    <button
      (click)="redirectToSchedTechnicianPageConcern()"
      class="omni-basic-ts__btn-cancel"
      [innerHTML]="btnNoLabel"></button>
    <div #footerDiv></div>
  </div>
</div>
