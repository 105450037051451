export const PAGE_URLS = {
  GOTO_DASHBOARD_URL: '/omnicare/navigate?destination=dashboard',
  GENERIC_ERROR_PAGE_URL: '/omnicare/error',
  BILL_INQUIRY: '/obi/billInquiry',
  FB_MSGR_URL: '/omnicare/navigate?destination=messenger',
  PAYMENT_OVERDUE_URL: '/omnicare/navigate?destination=payment.overdue',
};

export const SESSION_KEYS = {
  OBI_AUTH_TOKEN_KEY: 'omni_auth_token',
  OBI_USER_TOKEN_KEY: 'omni_user_token',
  OBI_USER_DATA_KEY: 'omni_user_data',
  OBI_ACCOUNT_NUMBER_KEY: 'account_number',
  OBI_BRAND_KEY: 'brand',
  OBI_SEGMENT_KEY: 'segment',
  OBI_BILLING_ARRANGEMENT_ID: 'billing_arrangement_id',
  OBI_MOBILE_NUMBER: 'mobile_number',
  OBI_LANDLINE_NUMBER: 'mobile_landline',
  OBI_APPOINTMENT_SLOT_KEY: 'appointment_slot',
  OBI_CONCERN_TYPE: 'concern_type',
  OBI_CONCERN_DETAILS: 'concern_details',
  OBI_DEVICE_ID: 'device_id',
  OBI_PLATFORM: 'platform',
  OBI_ORDER_ACTION_ID: 'order_action_id',
  OBI_ORDER_ID: 'order_id',
  OBI_ORDER_STATUS: 'order_status',
  OBI_VERIFICATION_TOKEN: 'verification_token',
  OBI_ACCOUNT_ALIAS: 'account_alias',
  OBI_SOURCE_TYPE: 'source_type',
  OBI_ATTRIBUTES: 'attributes',
  OBI_DISPUTE_AMOUNT: 'dispute_amount',
  OBI_BILL_DETAILS: 'bill_details',
  OBI_CUSTOMER_ID: 'customer_id',
  OBI_BILL_INQUIRY_FORM: 'bill_inquiry_form',
  OBI_API_ERROR_EVENT: 'obi_api_error_event',
  OBI_TAG: 'obi_tag',
};

export const CXS_API_URLS = {
  GENERATE_AUTH_TOKEN: '/v1/channels/oauth/token',
  GENERATE_USER_TOKEN: '/v1/userManagement/mobile/login',
  DECRYPT_DATA: '/v1/dataManagement/parse',
  BILLING_DIAGNOSIS: '/v3/accountManagement/accounts/billing',
  ACCOUNT_DIAGNOSIS:
    '/v2/accountManagement/accounts/diagnostic?accountNumber={1}',
  ACCOUNT_DETAILS_V3: '/v3/accountManagement/accounts',
  SEARCH_APPOINTMENT_SLOT:
    '/v1/workforceManagement/workOrders/appointments/search',
  CONFIRM_APPOINTMENT_BOOKING: '/v1/workforceManagement/appointments/confirm',
  WORK_ORDER_DETAILS:
    '/v1/workforceManagement/workOrders?sourceNumber=2&accountNumber={1}',
  CREATE_CASE: '/v1/ticketManagement/createCase',
  WORK_ORDER_DETIALS_LATEST:
    '/v1/workforceManagement/workOrders?sourceNumber=0&accountNumber={1}',
  GET_BILLING_STATEMENTS_PDF: '/v2/customerAccount/billingStatements',
  GET_BILL_CASE: '/v1/ticketManagement/disputes',
};

export const CXS_API_NAMES = {
  CREATE_CASE: 'CreateCase',
  GET_ACCOUNT_DETAILS_V3: 'GetAccountDetailsV3',
  GET_BILLING_DETAILS_V3: 'GetBillingDetailsV3',
};

export const API_RESPONSE_ERROR = [
  {
    status: /3\d\d/,
    code: /|./,
    url: PAGE_URLS.GENERIC_ERROR_PAGE_URL,
  },
  {
    status: /401/,
    code: /|./,
    url: PAGE_URLS.GOTO_DASHBOARD_URL,
  },
  {
    status: /404/,
    code: /40402/,
    url: null,
  },
  {
    status: /4\d\d/,
    code: /|./,
    url: PAGE_URLS.GENERIC_ERROR_PAGE_URL,
  },
  {
    status: /5\d\d/,
    code: /|./,
    url: PAGE_URLS.GENERIC_ERROR_PAGE_URL,
  },
];

export const OBI_GTM_CONFIG: any = {
  event_category: 'bill_dispute_ng1',
  event_action: 'click',
  user_id: 'AB132132112',
  outbound_link: 'URL',
  event: 'hm_push_event',
};
