import { Injectable } from "@angular/core";
import { OmniSessionService } from "./omni-session.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { API_RESPONSE_ERROR, CXS_API_URLS, OMNI_GTM_DIAGNOSTIC_STATUS_MAP, PAGE_URLS, SESSION_KEYS } from "../common/constants/omni-global.constant";
import { SESSION_KEYS as OBI_SESSION_KEYS } from "../common/constants/obi-global.constants";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OmniCommonService {

  constructor(
    private sessionService: OmniSessionService,
    private http: HttpClient,
    private router: Router,
  ) {}


  /**
   * Decrypt, validate and store intial query param
   */
  validateInitParams(encryptedString: any, postValidationFunc: Function, isObi: boolean = false) {
    try {
      this.decryptExternalData(encryptedString).subscribe({
        next: (decryptedData) => {
          const {
            accountNumber,
            accountAlias,
            attributes,
            brand,
            billDetails,
            segment,
            sourceType,
            mobileNumber,
            concernType,
            deviceID,
            disputeAmount,
            platform,
            workOrder,
            authToken,
            userToken,
            customerId,
            barringIndicator } = decryptedData;
          let accountNumberKey = SESSION_KEYS.OMNI_ACCOUNT_NUMBER_KEY;
          let brandKey = SESSION_KEYS.OMNI_BRAND_KEY;
          let segmentKey = SESSION_KEYS.OMNI_SEGMENT_KEY;
          let mobileNumberKey = SESSION_KEYS.OMNI_MOBILE_NUMBER;
          let concernTypeKey = SESSION_KEYS.OMNI_CONCERN_TYPE;
          let deviceIDKey = SESSION_KEYS.OMNI_DEVICE_ID;
          let platformKey = SESSION_KEYS.OMNI_PLATFORM;
          let orderActionIdKey = SESSION_KEYS.OMNI_ORDER_ACTION_ID;
          let statusKey = SESSION_KEYS.OMNI_ORDER_STATUS;
          let barringIndicatorKey = SESSION_KEYS.OMNI_BARRING_INDICATOR;

          if (isObi) {
            accountNumberKey = OBI_SESSION_KEYS.OBI_ACCOUNT_NUMBER_KEY;
            brandKey = OBI_SESSION_KEYS.OBI_BRAND_KEY;
            segmentKey = OBI_SESSION_KEYS.OBI_SEGMENT_KEY;
            mobileNumberKey = OBI_SESSION_KEYS.OBI_MOBILE_NUMBER;
            concernTypeKey = OBI_SESSION_KEYS.OBI_CONCERN_TYPE;
            deviceIDKey = OBI_SESSION_KEYS.OBI_DEVICE_ID;
            platformKey = OBI_SESSION_KEYS.OBI_PLATFORM;
            orderActionIdKey = OBI_SESSION_KEYS.OBI_ORDER_ACTION_ID;
            statusKey = OBI_SESSION_KEYS.OBI_ORDER_STATUS;
            this.sessionService.setData(OBI_SESSION_KEYS.OBI_ACCOUNT_ALIAS, accountAlias);
            this.sessionService.setData(OBI_SESSION_KEYS.OBI_SOURCE_TYPE, sourceType);
            this.sessionService.setData(OBI_SESSION_KEYS.OBI_ATTRIBUTES, attributes);
            this.sessionService.setData(OBI_SESSION_KEYS.OBI_DISPUTE_AMOUNT, disputeAmount);
            this.sessionService.setData(OBI_SESSION_KEYS.OBI_BILL_DETAILS, billDetails);
            this.sessionService.setData(OBI_SESSION_KEYS.OBI_CUSTOMER_ID, customerId);
          }
          this.sessionService.setData(accountNumberKey, accountNumber);
          this.sessionService.setData(brandKey, brand);
          this.sessionService.setData(segmentKey, segment);
          this.sessionService.setData(mobileNumberKey, mobileNumber);
          this.sessionService.setData(concernTypeKey, concernType);
          this.sessionService.setData(deviceIDKey, deviceID);
          this.sessionService.setData(platformKey, platform);
          this.sessionService.setData(barringIndicatorKey, barringIndicator);
          this.sessionService.setData(orderActionIdKey, workOrder?.orderActionId);
          this.sessionService.setData(statusKey, workOrder?.status);
          this.sessionService.setAuthToken(authToken);
          this.sessionService.setUserToken(userToken);

          if (decryptedData?.verificationToken) {
            this.sessionService.setData(OBI_SESSION_KEYS.OBI_VERIFICATION_TOKEN, decryptedData?.verificationToken);
          }

          // TODO: validate checking if source is from NG1
          postValidationFunc();
        },
        error: () => {
          this.router.navigate([PAGE_URLS.GENERIC_ERROR_PAGE_URL]);
        }
      });
    } catch (error) {
      this.router.navigate([PAGE_URLS.GENERIC_ERROR_PAGE_URL]);
    }
  }

  /**
   * Decrypts string using external key
   * @param encryptedString
   * @returns
   */
  decryptExternalData(encryptedString: string): Observable<any> {
    const url = CXS_API_URLS.DECRYPT_DATA;
    const body = {
      data: encryptedString
    };
    return this.http.post(url, body);
  }

  /**
   * Handles generic API failure
   * @param error
   */
  handleAPIError(response: any) {
    API_RESPONSE_ERROR.every((error) => {
      if (error.status.test(response?.status) && error.code.test(response?.error?.error?.code)) {
        if (error?.url) {
          if (error.url.startsWith('/omnicare/navigate')) {
            window.location.href = error.url;
          } else {
            this.router.navigate([error.url]);
          }
        }
        return false;
      }
      return true;
    });
  }

  /**
   * Navigate to provided URL
   */
  navigate(url: string = "") {
    if (((url.startsWith('/omnicare') && !url.startsWith('/omnicare/navigate')) ||
    (url.startsWith('/obi') && !url.startsWith('/obi/navigate'))) &&
    !url.startsWith(PAGE_URLS.WELCOME_BACK_PAGE_URL)) {
      this.router.navigate([url]);
    } else {
      window.location.href = url;
    }
  }

  /**
   * Flattens object to remove nested objects
   * @param obj
   * @returns
   */
  flatMap(obj: any) {
    const objClone = { ...obj };
    Object.keys(objClone).forEach((key: string) => {
      if(objClone[key].constructor === {}.constructor) {
        Object.keys(objClone[key]).forEach((subKey: string) => {
          if(objClone[key][subKey].constructor === {}.constructor) {
            const nestedMap = this.flatMap(objClone[key]);
            Object.keys(nestedMap).forEach((nestedKey) => {
              objClone[`${key}_${nestedKey}`] = nestedMap[nestedKey];
            });
          } else if (objClone[key][subKey].constructor === [].constructor) {
            objClone[key][subKey].forEach((arrayItem: any, index: number) => {
              objClone[`${key}_${subKey}_${index}`] = arrayItem;
            });
          } else if (`${objClone[key][subKey]}`.includes("|")) {
              objClone[key][subKey].split("|").forEach((arrayItem: string, index: number) => {
                objClone[`${key}_${subKey}_${index}`] = arrayItem;
              });
          } else {
            objClone[`${key}_${subKey}`] = objClone[key][subKey];
          }
          delete objClone[key][subKey];
        });
        delete objClone[key];
      } else if (objClone[key].constructor === [].constructor) {
        objClone[key].forEach((arrayItem: any, index: number) => {
          objClone[`${key}_${index}`] = arrayItem;
        });
        delete objClone[key];
      } else if (`${objClone[key]}`.includes("|")) {
        objClone[key].split("|").forEach((arrayItem: string, index: number) => {
          objClone[`${key}_${index}`] = arrayItem;
        });
        delete objClone[key];
      }
    });
    return objClone;
  }

  convertToDashFormat(value: string) {
    let result  = value;
    const lowerRegex = /( [a-zA-Z])/g;
    const upperRegex = /(?!^[A-Z])([A-Z])/g;
    const spaceRegex = /\s/g;
    const replaceFunc = (match: string) => `-${match.trim().toLowerCase()}`;
    result = result.replace(lowerRegex, replaceFunc);
    result = result.replace(upperRegex, replaceFunc);
    result = result.replace(spaceRegex, replaceFunc);
    result = result.toLowerCase();
    return result;
  }

  /**
   * Format amount to ₱#,###.##
   * @param value
   * @returns
   */
  formatAmount(value: string) {
    const amount = parseFloat(value);

    if (isNaN(amount)) {
      return value;
    }

    if (Number.isInteger(amount)) {
        return "₱" + amount + ".00";
    } else {
        let amountString = amount.toFixed(2);
        return "₱" + amountString;
    }
  }

  /**
   * Format mobile number to 639xxxxxxxxx
   * @param number
   * @returns
   */
  formatMobileNumber(number: string): string {
    if(!number) {
      return '';
    }
    const cleanedNumber = number.replace(/\D/g, '');
    if (cleanedNumber.startsWith('9')) {
      return '+63' + cleanedNumber;
    } else if (cleanedNumber.startsWith('09')) {
      return '+63' + cleanedNumber.substring(1);
    } else {
      return '+' + cleanedNumber;
    }
  }

  /**
   * Determine status for GTM tagging
   * @param statusKey GTM status key
   * @param status API status to extract GTM status from
   * @returns GTM status used for events tagging
   */
  determineDiagStatusForTagging(statusKey: string, status: string): string {
    return OMNI_GTM_DIAGNOSTIC_STATUS_MAP.get(statusKey)?.includes(status)
      ? 'yes'
      : 'no';
  }

  /**
   * Determine the account status (promo_category, status, account_status) for GTM tagging
   * @param statusKey GTM status key (promo_category, status, account_status)
   * @param userData Contains the userData object from the session
   * @returns GTM status (promo_category, status, account_status) used for events tagging
   */
  determineAccountStatusForTagging(statusKey: string, userData: any): string {
    const PROMO_CATEGORY = ['Unlimited', 'Data not found', 'No data found'];
    const OVERDUE_STATUS = ['Overdue'];
    const ACCOUNT_STATUS = ['Connected'];

    let gtmStatus = '';
    switch (statusKey) {
      case 'promo_category':
        gtmStatus = PROMO_CATEGORY.includes(userData?.dataAllocation)
          ? 'unli plan'
          : 'not unli plan';
        break;
      case 'status':
        gtmStatus = OVERDUE_STATUS.includes(userData?.balanceStatus)
          ? ACCOUNT_STATUS.includes(userData?.accountStatus)
            ? 'overdue_with_connection'
            : 'overdue_disconnected'
          : 'no_overdue';
        break;
      case 'account_status':
        gtmStatus = ACCOUNT_STATUS.includes(userData?.accountStatus)
          ? 'active'
          : 'temp_disconnected';
        break;

      default:
        break;
    }
    return gtmStatus;
  }
}
