<div [class]="['obi-date-picker-container', show ? '': 'hidden']" #container>
  <div class="obi-date-picker-overlay  disable-scrolling" *ngIf="show"></div>
  <div class="obi-date-picker-calendar-container">
    <div class="obi-date-picker-calendar-header">
      <div class="obi-date-picker-calendar-month" [innerHTML]=""></div>
    </div>
    <ngb-datepicker
      [(ngModel)]="selectedDate"
      class="obi-date-picker-custom-calendar"
      outsideDays="collapsed"
      [dayTemplate]="customDay"
      [firstDayOfWeek]="7"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [navigation]="'arrows'"
      outsideDays="visible"
      ></ngb-datepicker>
  </div>
</div>

<ng-template
  #customDay
  let-date="date"
  let-currentMonth="currentMonth"
  let-selected="selected"
  let-disabled="disabled"
>
  <span
    [class]="['obi-date-picker-custom-calendar-day', isCurrentDay(date) ? 'current-day': '']"
    [class.obi-date-picker-custom-calendar-day--selected]="selected"
    [class.obi-date-picker-custom-calendar-day--disabled]="disabled"
    [class.outside-day]="date.month !== currentMonth"
    (click)="onDateSelect(date)"
  >
    {{ date.day }}
  </span>
</ng-template>
