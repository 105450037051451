export const OMNI_ERROR_INIT_VALUES = {
    ERROR_IMAGE: '../../../../assets/images/omni-error-state-img-v2.png',
    ERROR_TITLE: 'We are having issues right now.',
    ERROR_SUBTEXT: 'We are fixing a few things to improve your experience. Please try again later.<br/><br/>You may also chat with us via FB Messenger.',
    MAYBE_LATER_LABEL_BTN_LABEL: 'Maybe later',
    TRY_AGAIN_LABEL: 'Let’s try again',
    CHAT_WITH_FB_MSGR: 'Chat with us via FB Messenger'
};

export const COMING_SOON_VALUES = {
    ERROR_IMAGE: '../../../../assets/images/omni-coming-soon-state-img.svg',
    ERROR_TITLE: 'Coming soon!',
    ERROR_SUBTEXT: 'We\'re working on something wonderful <br/>to help you stay connected.<br/><br/>In the meantime, you may chat with us on <br/>Facebook Messenger.',
    MAYBE_LATER_LABEL_BTN_LABEL: 'Go back to dashboard',
    TRY_AGAIN_LABEL: 'Go back to dashboard',
    CHAT_WITH_FB_MSGR: 'Chat with us via FB Messenger'
};
