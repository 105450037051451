import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError, EMPTY } from 'rxjs';
import { mergeMap, delay, retry } from 'rxjs/operators';
import {
  CXS_API_URLS,
  PAGE_URLS,
  SESSION_KEYS,
} from 'src/app/common/constants/omni-global.constant';
import { OmniSessionService } from './omni-session.service';
import * as moment from 'moment';
import {
  OmniAppointmentSlotInstallDetails,
  OmniScheduleCard,
  OmniScheduleSlot,
  OmniSearchAppointmentSlotReqBody,
  WHEN,
  OmniCaseCreateObject,
  OmniCaseCreateConfig,
  OmniScheduleObject,
} from '../interfaces/omni-sched-technician.interface';
import {
  OMIN_SEARCH_APPOINTMENT_SLOT_REQ,
  OMNI_SCHED_TECHNICIAN_INIT_VALUES,
  SCHED_PATTERN,
  WHEN_THEREAFTER,
  WHEN_TOMORROW,
  OMNI_SCHED_TECHNICIAN_CASE_CREATE_TYPE1,
  OMNI_SCHED_TECHNICIAN_CASE_CREATE_TYPE2,
  OMNI_SCHED_TECHNICIAN_CASE_CREATE_TYPE3,
  OMNI_SCHED_TECHNICIAN_CASE_CREATE_LOGNOTE_LABEL,
  OMNI_UPDATE_TICKET_DETAILS_REQ,
} from '../common/constants/omni-sched-technician.constant';
import { OmniCommonService } from './omni-common.services';

const { API_WORKFLOW_OPERATION, OMNI_CREATECASE_SOURCENUMBER } =
  OMNI_SCHED_TECHNICIAN_INIT_VALUES;

@Injectable({ providedIn: 'root' })
export class OmniSchedTechnicianService {
  constructor(
    private router: Router,
    private omniSessionService: OmniSessionService,
    private http: HttpClient,
    private commonService: OmniCommonService
  ) {}

  today: moment.Moment = moment();
  accountNumber: string = '';
  brand: string = '';
  segment: string = '';
  appointmentSlots: any[] = [];
  userData: any = {};
  orderActionId: string = '';
  orderStatus: string = '';
  concern: string = '';
  concernDetails: string = '';
  appointmentSlotReq: OmniSearchAppointmentSlotReqBody =
    OMIN_SEARCH_APPOINTMENT_SLOT_REQ;

  ticketId: string = '';

  /**
   * Retrieve parameters from session
   */
  retrieveParameters() {
    this.accountNumber = this.omniSessionService.getData(
      SESSION_KEYS.OMNI_ACCOUNT_NUMBER_KEY
    );
    this.brand = this.omniSessionService.getData(SESSION_KEYS.OMNI_BRAND_KEY);
    this.segment = this.omniSessionService.getData(
      SESSION_KEYS.OMNI_SEGMENT_KEY
    );

    this.orderActionId = this.omniSessionService.getData(
      SESSION_KEYS.OMNI_ORDER_ACTION_ID
    );
    this.orderStatus = this.omniSessionService.getData(
      SESSION_KEYS.OMNI_ORDER_STATUS
    );

    this.concern =
      this.omniSessionService.getData(SESSION_KEYS.OMNI_CONCERN_TYPE) || '';
    this.concernDetails =
      this.omniSessionService.getData(SESSION_KEYS.OMNI_CONCERN_DETAILS) ||
      OMNI_SCHED_TECHNICIAN_INIT_VALUES.CONCERN_DETAILS;
  }

  /**
   * Retrieves account details by executing GetAccountDetailsV3 API
   * and executes SearchAppointmentSlot API to generate schedules;
   * or executes confirmAppointment API when order status is 'open'
   */
  navigateToSchedTechnician() {
    this.retrieveParameters();
    // Get user data in the session service
    this.userData = this.omniSessionService.getUserData() || {};

    if (this.orderStatus) {
      /**
       * OM24H-615: Update Sched Technician new flow
       * Additional checking if workOrder already exist
       * If workOrder status is Completed or Cancelled or Open
       * Sched Technician Original Flow
       * Else: Inform native to display the existing technician Schedule Modal window
       *
       */
      if (
        this.orderStatus.toLowerCase() == 'completed' ||
        this.orderStatus.toLowerCase() == 'cancelled'
      ) {
        /* Sched Technician BAU FLow */
        this.getAccountDetails();
      } else if (this.orderStatus.toLowerCase() == 'open') {
        const schedData: OmniScheduleObject = {
          orderActionId: this.orderActionId,
          preferredAppointmentSlot: {
            date: this.today.clone().subtract(1, 'days').format('YYYY-MM-DD'),
            slot: this.today.format('A'),
          },
          accountNumber: this.accountNumber,
        };

        //Call confirm Appointment re-schedule
        this.postAppointmentBooking(schedData).subscribe({
          next: (response: any) => {
            this.generateAndSaveSchedule(response.result, this.orderActionId);
          },
          error: (response: any) => {
            // if response is error, but appointmentSlots are given generate schedule
            if (response.error && response.error.appointmentSlots) {
              this.generateAndSaveSchedule(response.error, this.orderActionId);
            } else {
              this.commonService.handleAPIError(response);
            }
          },
        });
      } else {
        window.location.href = PAGE_URLS.EXISTING_TECHNICIAN_VISIT;
      }
    } else {
      //this.orderStatus is null: BAU: Sched Technician
      /* Sched Technician BAU FLow */
      this.getAccountDetails();
    }
  }

  /**
   * Assigns values to the SearchAppointmentSlot API request body
   * @param result results of the GetAccountDetailsV3 API
   */
  assignValuesToAppointmentSlotReq(
    result: any,
    caseId: string,
    logNote: string
  ) {
    let { firstName, lastName, email, landlineNumber, mobileNumber } =
      result ?? {};

    this.appointmentSlotReq.accountNumber = this.accountNumber;
    this.appointmentSlotReq.customerFirstName = firstName;
    this.appointmentSlotReq.customerLastName = lastName;
    this.appointmentSlotReq.primaryContactFirstName = firstName;
    this.appointmentSlotReq.primaryContactLastName = lastName;
    this.appointmentSlotReq.contactEmailAddress = email;
    this.appointmentSlotReq.primaryResource = landlineNumber;

    this.appointmentSlotReq.serviceRequiredDate = this.today.format(
      'YYYY-MM-DDTHH:mm:ss.SSSZ'
    );

    this.appointmentSlotReq.notes[0].noteDate = this.today.format(
      'YYYY-MM-DDTHH:mm:ss.SSSZ'
    );
    this.appointmentSlotReq.notes[0].noteText = `Concern: ${this.concern} | Description: ${this.concernDetails} | ${logNote}`;

    //OM24H-616: Update value of orderId and OrderActionId using CreateCase.caseId.
    this.appointmentSlotReq.orderId = `${caseId}-1`;
    this.appointmentSlotReq.orderActionId = caseId;

    this.appointmentSlotReq.accessType = this.userData.productTechnology;

    //OM24H-799: GetAccountDetails.mobileNumber/passed value from Native
    const userSessionMobileNumber = this.omniSessionService.getData(
      SESSION_KEYS.OMNI_MOBILE_NUMBER
    );
    this.appointmentSlotReq.contactMobileNo =
      mobileNumber || userSessionMobileNumber;
    this.appointmentSlotReq.phoneNumber =
      mobileNumber || userSessionMobileNumber;

    const {
      CASE_TYPE_LEVEL1,
      CASE_TYPE_LEVEL2,
      CASE_TYPE_LEVEL3,
      CASE_TYPE_LEVEL4,
      CASE_TYPE_LEVEL5,
    } = this.getCreateCaseConfig(
      this.omniSessionService
        .getData(SESSION_KEYS.OMNI_CONCERN_TYPE)
        .toUpperCase()
    );
    //OM24H-775: Add caseTypeLevels, latitude, longitude and billingOfferName to searchAppointmentSlots request
    this.appointmentSlotReq.caseTypeLevel1 = CASE_TYPE_LEVEL1;
    this.appointmentSlotReq.caseTypeLevel2 = CASE_TYPE_LEVEL2;
    this.appointmentSlotReq.caseTypeLevel3 = CASE_TYPE_LEVEL3;
    this.appointmentSlotReq.caseTypeLevel4 = CASE_TYPE_LEVEL4;
    this.appointmentSlotReq.caseTypeLevel5 = CASE_TYPE_LEVEL5;
    this.appointmentSlotReq.billingOfferName = this.userData.subscribedProduct;

    //#region assign values for installation details
    let {
      subdetails_houseNo,
      subdetails_street,
      subdetails_city,
      subdetails_province,
      subdetails_postalCode,
      subdetails_floorNo,
      subdetails_building,
      subdetails_subdivisionVillage,
      subdetails_country,
    } = this.userData ?? {};

    let installationDetails: OmniAppointmentSlotInstallDetails = {
      houseBuildingNo: subdetails_floorNo || subdetails_houseNo || subdetails_building || ',',
      streetName: subdetails_street || ',',
      city: subdetails_city || ',',
      barangay: subdetails_subdivisionVillage || ',',
      province: subdetails_province || ',',
      postalCode: subdetails_postalCode || ',',
      country: subdetails_country || ',',
      region: subdetails_province || ',',
      latitude: this.truncateDecimalPlaces(this.userData.dpLat) || '0',
      longitude: this.truncateDecimalPlaces(this.userData.dpLong) || '0',
    };
    this.appointmentSlotReq.installationDetails[0] = installationDetails;
    //#endregion
  }

  truncateDecimalPlaces(inputString: any): string {
    const decimalPlaces = 13;
    var decimalIndex = inputString ? inputString.indexOf('.') : -1;
    if (decimalIndex !== -1) {
      // Extract the integer and decimal parts
      var integerPart = inputString.substring(0, decimalIndex);
      var decimalPart = inputString.substring(decimalIndex + 1);

      // Truncate or pad the decimal part to the specified decimal places
      decimalPart = decimalPart.substring(0, decimalPlaces);
      return integerPart + '.' + decimalPart;
    } else {
      // If there is no decimal point, return the original string
      return inputString;
    }
  }

  /**
   * Searches for appoinmnent slots by executing SearchAppoinmentSlot API
   * then generates schedules based on response.
   */
  searchAndGenerateSlots(): void {
    this.searchAppointmentSlot(this.appointmentSlotReq).subscribe({
      next: (response: any) => {
        const result = response.result;
        if (result.statusCode === '200') {
          this.generateAndSaveSchedule(result);
        } else if (result.statusCode === '409') {
          window.location.href = PAGE_URLS.EXISTING_TECHNICIAN_VISIT;
        } else {
          //OM24H-857: Call UpdateTicketDetails
          this.updateTicketDetails();
        }
      },
      error: () => {
        //OM24H-857: Call UpdateTicketDetails
        this.updateTicketDetails();
      },
    });
  }

  /**
   * Executes generateSchedule function to generate schedules, and
   * saves results to userData in the session
   * @param result API results containing the appoinment slots
   * @param orderActionId OPTIONAL: existing orderActionId
   */
  generateAndSaveSchedule(result: any, orderActionId?: string) {
    if (!result.appointmentSlots) {
      this.router.navigate([PAGE_URLS.GENERIC_ERROR_PAGE_URL]);
    } else {
      this.appointmentSlots = result.appointmentSlots;
      this.userData.appointmentSlots = this.appointmentSlots;
      this.userData.withSlots = true;
      this.userData.schedules = this.generateSchedule();
      this.omniSessionService.setUserData(this.userData);

      this.omniSessionService.setData(
        SESSION_KEYS.OMNI_ORDER_ACTION_ID,
        orderActionId || result.orderActionId
      );
      this.omniSessionService.setData(
        SESSION_KEYS.OMNI_ORDER_ID,
        result.orderId
      );
      this.omniSessionService.setData(
        SESSION_KEYS.OMNI_CONCERN_DETAILS,
        this.concernDetails
      );

      const queryParams = {
        orderActionId: orderActionId || result.orderActionId,
      };
      this.router.navigate([PAGE_URLS.SCHEDULE_TECHNICIAN_PAGE_URL], {
        queryParams,
      });
    }
  }

  /**
   * Calculates the date of the WHEN type based on Today's date.
   *
   * @param when of type WHEN, possible values: "TODAY" | "TOMORROW" | "THEREAFTER"
   * @returns the calculated date of WHEN
   */
  determineDateOfWhen(when: WHEN): moment.Moment {
    let whenDate: moment.Moment = this.today.clone();
    switch (when) {
      case WHEN_TOMORROW:
        whenDate = whenDate.add(1, 'days');
        break;
      case WHEN_THEREAFTER:
        whenDate = whenDate.add(2, 'days');
        break;
      default:
        break;
    }
    return whenDate;
  }

  /**
   * Creates the schedule cards displayed on the page, based on the schedule
   * slot pattern given.
   *
   * @param pattern schedule slot pattern
   * @returns list of schedule cards to be displayed on the page
   */
  createSchedules(startIdx: number, endIdx?: number): OmniScheduleCard[] {
    let pattern: OmniScheduleSlot[] = SCHED_PATTERN;
    let scheds: OmniScheduleCard[] = [];

    endIdx = endIdx ? endIdx : pattern.length;
    for (let index = startIdx; index < endIdx; index++) {
      const schedSlot = pattern[index];

      // get schedule slot
      let whenDate: moment.Moment = this.determineDateOfWhen(schedSlot.when);
      let appointmentSlot = this.appointmentSlots.find(
        slot => slot.date == whenDate.format('YYYY-MM-DD')
      );

      if (appointmentSlot) {
        // check if slots are available
        let availableFlag: boolean =
          schedSlot.time === OMNI_SCHED_TECHNICIAN_INIT_VALUES.TIME.AM
            ? appointmentSlot.slots.morning > 0
            : appointmentSlot.slots.evening > 0;

        // add to schedule card if available
        if (availableFlag) {
          scheds.push({
            header: schedSlot.label,
            day: this.determineDateOfWhen(schedSlot.when).format('MMMM D'),
            time: schedSlot.time,
          });

          if (scheds.length === 3) {
            // stop loop if number of sched card hits maximum
            break;
          }
        }
      }
    }
    return scheds;
  }

  /**
   * Generates the schedule slots to be displayed on the page
   * @returns list of available schedule slots
   */
  generateSchedule(): any {
    const morningStart = moment('12:00 AM', 'h:mm A');
    const morningEnd = moment('5:59 AM', 'h:mm A');
    const afternoonStart = moment('6:00 AM', 'h:mm A');
    const afternoonEnd = moment('11:59 AM', 'h:mm A');

    let scheds: OmniScheduleCard[] = [];
    if (this.today.isBetween(morningStart, morningEnd)) {
      scheds = this.createSchedules(0, 5);
    } else if (this.today.isBetween(afternoonStart, afternoonEnd)) {
      scheds = this.createSchedules(1);
    } else {
      scheds = this.createSchedules(2);
    }

    if (scheds.length < 1) {
      // redirect to no slots page if no slots available
      this.userData.withSlots = false;
    }

    return scheds;
  }

  /**
   * Retrieves account details from the CXS API using a provided account number.
   * Executes the CXS API - GetAccountDetailsV3.
   *
   * @param accountNumber - The account number for which to fetch details.
   * @returns An Observable that emits the account details in the response.
   */
  getAccountDetailsV3(
    accountNumber: string,
    brand: string,
    segment: string
  ): Observable<any> {
    const url = CXS_API_URLS.ACCOUNT_DETAILS_V3.replace('{1}', accountNumber)
      .replace('{2}', brand)
      .replace('{3}', segment);
    return this.http.get(url);
  }

  /**
   * Searches for appointment slots based on the provided request body.
   * Executes the CXS API - SearchAppointmentSlot.
   *
   * @param body - The request body containing search criteria.
   * @returns An Observable that emits the search results in the response.
   */
  searchAppointmentSlot(body: any): Observable<any> {
    const url = CXS_API_URLS.SEARCH_APPOINTMENT_SLOT;
    return this.http.post(url, body);
  }

  /**
   * Submit Confirm Appointment Booking
   * Executes the CXS API - ConfirmAppointmentBooking
   *
   * @param data - Contains appointment schedule
   * @returns
   */
  postAppointmentBooking(data: any): Observable<any> {
    const apiURL = CXS_API_URLS.CONFIRM_APPOINTMENT_BOOKING;
    return this.http.post<any>(apiURL, data);
  }

  /**
   * Create Case
   * Execute the CXS API - Createcase
   *
   * @param body - Contains case creation details
   * @returns
   */
  postCreateCase(body: any): Observable<any> {
    const apiURL = CXS_API_URLS.CREATE_CASE;
    return this.http.post<any>(apiURL, body);
  }

  /**
   * Get Account Details:
   */
  getAccountDetails() {
    // getaccountdetails is executed before checking for
    // order status to retrieve firstname and lastname
    this.getAccountDetailsV3(
      this.accountNumber,
      this.brand,
      this.segment
    ).subscribe({
      next: (accountDetails: any) => {
        //Save customer Details: firstName, lastName, mobileNumber, alternativeMobileNumber
        let { firstName, lastName, mobileNumber, alternativeMobileNumber, landlineNumber } =
          accountDetails.result;
        this.userData.first_name = firstName;
        this.userData.last_name = lastName;
        this.userData.mobile_number = mobileNumber;
        this.userData.alternative_mobile_number = alternativeMobileNumber;

        this.omniSessionService.setUserData(this.userData);
        
        // OM24H-902: call RetrieveSubscriberDetails API before CreateCase
        this.retrieveSubscriberDetails(landlineNumber, accountDetails);
      },
      error: error => {
        this.commonService.handleAPIError(error);
      },
    });
  }

  /**
   * Call createCase API and search Appointment API
   */
  createCaseAndSearchAppointment(accountDetails: any) {
    const {
      TITLE,
      CASE_TYPE_LEVEL1,
      CASE_TYPE_LEVEL1_REFID,
      CASE_TYPE_LEVEL2,
      CASE_TYPE_LEVEL2_REFID,
      CASE_TYPE_LEVEL3,
      CASE_TYPE_LEVEL3_REFID,
      CASE_TYPE_LEVEL4,
      CASE_TYPE_LEVEL4_REFID,
      CASE_TYPE_LEVEL5,
      CASE_TYPE_LEVEL5_REFID,
      WORK_FLOW_TARGET,
      SEVERITY,
      PRIORITY,
      STATUS,
    } = this.getCreateCaseConfig(
      this.omniSessionService
        .getData(SESSION_KEYS.OMNI_CONCERN_TYPE)
        .toUpperCase()
    );

    //Set value for the logNote
    const {
      MOBILE_NUMBER_LABEL,
      ALTERNATIVE_MOBILE_NUMBER_LABEL,
      CHANNEL_LABEL,
      CHANEL_VALUE,
      ACCOUNT_STATUS_LABEL,
      OUTAGE_RESULT_LABEL,
      FUP_INDICATOR_LABEL,
      LINE_STATUS_LABEL,
      MODEM_STATUS_LABEL,
    } = OMNI_SCHED_TECHNICIAN_CASE_CREATE_LOGNOTE_LABEL;

    /**
     * OM24H-798: Additional values for logNote
     */
    const {
      accountStatus,
      outageResult,
      fupIndicator,
      lineStatus,
      modemStatus,
    } = this.userData;
    const accountDiagnosticUserDetails = `${ACCOUNT_STATUS_LABEL}=${accountStatus} | ${OUTAGE_RESULT_LABEL}=${outageResult} | ${FUP_INDICATOR_LABEL}=${fupIndicator} | ${LINE_STATUS_LABEL}=${lineStatus} | ${MODEM_STATUS_LABEL}=${modemStatus}`;
    const logNote = `${MOBILE_NUMBER_LABEL}=${this.userData.mobile_number} | ${ALTERNATIVE_MOBILE_NUMBER_LABEL}=${this.userData.alternative_mobile_number} | ${CHANNEL_LABEL}=${CHANEL_VALUE} | ${accountDiagnosticUserDetails}`;

    const caseCreateData: OmniCaseCreateObject = {
      subscription: accountDetails.result?.landlineNumber,
      workflowOperation: API_WORKFLOW_OPERATION,
      title: TITLE || '',
      caseTypeLevel1: CASE_TYPE_LEVEL1 || '',
      caseTypeLevel1RefId: CASE_TYPE_LEVEL1_REFID || '',
      caseTypeLevel2: CASE_TYPE_LEVEL2 || '',
      caseTypeLevel2RefId: CASE_TYPE_LEVEL2_REFID || '',
      caseTypeLevel3: CASE_TYPE_LEVEL3 || '',
      caseTypeLevel3RefId: CASE_TYPE_LEVEL3_REFID || '',
      caseTypeLevel4: CASE_TYPE_LEVEL4 || '',
      caseTypeLevel4RefId: CASE_TYPE_LEVEL4_REFID || '',
      caseTypeLevel5: CASE_TYPE_LEVEL5 || '',
      caseTypeLevel5RefId: CASE_TYPE_LEVEL5_REFID || '',
      workflowTarget: WORK_FLOW_TARGET || '',
      severity: SEVERITY || '',
      priority: PRIORITY || '',
      status: STATUS || '',
      logNote: logNote,
      issueStartDate: this.today
        .utcOffset(8)
        .format('YYYY-MM-DDTHH:mm:ss.SSS\\Z'),
      sourceNumber: OMNI_CREATECASE_SOURCENUMBER,
      accountNumber: this.accountNumber,
    };

    //Call CreateCase API
    this.postCreateCase(caseCreateData).subscribe({
      next: (response: any) => {
        const caseId = response?.result?.caseId;
        if (caseId) {
          //Save to cache: caseId for work Order Number
          this.omniSessionService.setData(
            SESSION_KEYS.OMNI_APPOINTMENT_SLOT_KEY,
            caseId
          );
          this.ticketId = caseId;

          //Call SearchAppointment API and Generate Available Slots
          this.assignValuesToAppointmentSlotReq(
            accountDetails.result,
            caseId,
            logNote
          );
          this.searchAndGenerateSlots();
        } else {
          this.router.navigate([PAGE_URLS.GENERIC_ERROR_PAGE_URL]);
        }
      },
      error: error => {
        this.commonService.handleAPIError(error);
      },
    });
  }

  /**
   * Generates the create case object based on the concerntype
   * @returns OmniCaseCreateConfig
   */
  getCreateCaseConfig(omniConcernType: string) {
    //Create object for API request CreateCase API
    //request body value depend on concerntype

    let caseCreateConfig: OmniCaseCreateConfig = {
      TITLE: '',
      CASE_TYPE_LEVEL1: '',
      CASE_TYPE_LEVEL1_REFID: '',
      CASE_TYPE_LEVEL2: '',
      CASE_TYPE_LEVEL2_REFID: '',
      CASE_TYPE_LEVEL3: '',
      CASE_TYPE_LEVEL3_REFID: '',
      CASE_TYPE_LEVEL4: '',
      CASE_TYPE_LEVEL4_REFID: '',
      CASE_TYPE_LEVEL5: '',
      CASE_TYPE_LEVEL5_REFID: '',
      WORK_FLOW_TARGET: '',
      SEVERITY: '',
      PRIORITY: '',
      STATUS: '',
    };

    if (
      omniConcernType ===
      OMNI_SCHED_TECHNICIAN_CASE_CREATE_TYPE1.CONCERN_TYPE.toUpperCase()
    ) {
      caseCreateConfig = OMNI_SCHED_TECHNICIAN_CASE_CREATE_TYPE1;
    } else if (
      omniConcernType ===
      OMNI_SCHED_TECHNICIAN_CASE_CREATE_TYPE2.CONCERN_TYPE.toUpperCase()
    ) {
      caseCreateConfig = OMNI_SCHED_TECHNICIAN_CASE_CREATE_TYPE2;
    } else if (
      omniConcernType ===
      OMNI_SCHED_TECHNICIAN_CASE_CREATE_TYPE3.CONCERN_TYPE.toUpperCase()
    ) {
      caseCreateConfig = OMNI_SCHED_TECHNICIAN_CASE_CREATE_TYPE3;
    }
    return caseCreateConfig;
  }

  /**
   * Update caseCreate ticket
   * @param searchAppointmetSlotResult
   */
  updateTicketDetails() {
    let apiCallCounter = 0;
    const MAX_API_CALL = 4;

    this.putUpdateTicketDetails(OMNI_UPDATE_TICKET_DETAILS_REQ)
      .pipe(
        mergeMap(response => {
          const statusCode = response?.statusCode;
          if (statusCode === 204) {
            /**
             * OM24H-857: UpdateTicketDetails called if SearchAppointSlot response
             * is error but not 409
             */
            this.router.navigate([PAGE_URLS.GENERIC_ERROR_PAGE_URL]);
            return of(response); // Return a successful response
          } else {
            apiCallCounter++;
            if (apiCallCounter >= MAX_API_CALL) {
              /**
               * OM24H-857: UpdateTicketDetails called if SearchAppointSlot response
               * is error but not 409
               */
              this.router.navigate([PAGE_URLS.GENERIC_ERROR_PAGE_URL]);
              return EMPTY;
            } else {
              return throwError('Non-204 status code'); // Trigger retry
            }
          }
        }),
        retry(MAX_API_CALL - 1), // Retry up to 3 times
        delay(1000) // Delay before retrying
      )
      .subscribe({
        error: error => {
          this.commonService.handleAPIError(error);
        },
      });
  }

  /**
   * Update Ticket Details
   *
   * @param body
   * @returns
   */
  putUpdateTicketDetails(body: any): Observable<any> {
    const apiURL = `${CXS_API_URLS.UPDATE_TICKET_DETAILS}/${this.ticketId}`;
    return this.http.put<any>(apiURL, body);
  }
  
  /**
   * Calls RetrieveSubscriberDetails API to retrieve subscriber address information.
   * After successful call, will proceed with calling CreateCase and SearchAppointment APIs
   * @param landlineNumber
   * @param accountDetails 
   */
  retrieveSubscriberDetails(landlineNumber: string, accountDetails: any) {
    this.getRetrieveSubscriberDetails(landlineNumber).subscribe({
      next: (response: any) => {
        const result = response?.result;
        // add results to userData
        if (result) {
          this.userData.subdetails_houseNo = result.houseNo;
          this.userData.subdetails_street = result.street;
          this.userData.subdetails_city = result.city;
          this.userData.subdetails_province = result.province;
          this.userData.subdetails_postalCode = result.postalCode;
          this.userData.subdetails_floorNo = result.floorNo;
          this.userData.subdetails_building = result.building;
          this.userData.subdetails_subdivisionVillage = result.subdivisionVillage;
          this.userData.subdetails_country = result.country;
        }

        //Ticket: OM24H-616: Call CaseCreate and SearchAppointment API
        this.createCaseAndSearchAppointment(accountDetails);
      },
      error: error => {
        this.commonService.handleAPIError(error);
      },
    });
  }

  getRetrieveSubscriberDetails(landlineNumber: string): Observable<any> {
    const url = CXS_API_URLS.RETRIEVE_SUBSCRIBER_DETAILS.replace('{1}', landlineNumber);
    return this.http.get(url);
  }

}
