import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OmniSessionService } from 'src/app/services/omni-session.service';
import { OmniCommonService } from 'src/app/services/omni-common.services';
import {
  OBI_PRE_CHECK_HEADER,
  OBI_PRE_CHECK_LIST,
  OBI_PRE_CHECK_SUB_VALIDATION_TIMER,
  OBI_PRE_CHECK_TITLE_REGEX,
  OBI_PRE_CHECK_VALIDATION_TIMER,
  OBI_PRE_CHECK_SUCCESS,
  OBI_PRE_CHECK_CANCEL,
  OBI_PRE_CHECK_DISCONNECTED_OB,
} from 'src/app/common/constants/obi-pre-check.constants';
import {
  CXS_API_NAMES,
  PAGE_URLS,
  SESSION_KEYS,
} from 'src/app/common/constants/obi-global.constants';
import { ObiPreCheck, ObiPreCheckValidation } from './obi-pre-check.interface';
import { ObiPreCheckService } from './obi-pre-check.service';
import { GoogleTagManagerService } from 'src/app/services/google-tag-manager-service.service';
import {
  OBI_PRE_CHECK_ACCOUNT_DASHBOARD_BUTTON_TAG,
  OBI_PRE_CHECK_ACCOUNT_ERROR_TAG,
  OBI_PRE_CHECK_ACCOUNT_FB_BUTTON_TAG,
  OBI_PRE_CHECK_ACCOUNT_TAG,
  OBI_PRE_CHECK_BALANCE_INQUIRY_BUTTON_TAG,
  OBI_PRE_CHECK_BALANCE_PAY_BUTTON_TAG,
  OBI_PRE_CHECK_BALANCE_PROCEED_BUTTON_TAG,
  OBI_PRE_CHECK_BILLING_ERROR_TAG,
  OBI_PRE_CHECK_BILLING_TAG,
  OBI_PRE_CHECK_BILL_INQUIRY_TAG,
  OBI_PRE_CHECK_CANCEL_NO_TAG,
  OBI_PRE_CHECK_CANCEL_TAG,
  OBI_PRE_CHECK_CANCEL_YES_TAG,
  OBI_PRE_CHECK_COMPLETE_TAG,
} from 'src/app/common/constants/obi-gtm-events.constants';

@Component({
  selector: 'app-obi-pre-check',
  templateUrl: './obi-pre-check.component.html',
  styleUrls: ['./obi-pre-check.component.scss'],
})
export class ObiPreCheckComponent {
  /**
   * Import list of OBI pre-checks
   */
  public obiPreCheckList = OBI_PRE_CHECK_LIST;

  /**
   * Import header spiel
   */
  public obiPreCheckHeader = OBI_PRE_CHECK_HEADER;

  /**
   * Import footer submit button spiel
   */
  public obiPreCheckSubmit = OBI_PRE_CHECK_SUCCESS;

  /**
   * Import cancel modal spiel
   */
  public obiPreCheckCancel = OBI_PRE_CHECK_CANCEL;

  /**
   * Import cancel modal confirm action
   */
  public obiPreCheckCancelConfirmAction?: Function = () => {};
  /**
   * Import cancel modal cancelaction
   */
  public obiPreCheckCancelCancelAction?: Function = () => {};

  /**
   * Flag to indicate cancel button has been clicked
   */
  public isCancel = false;

  /**
   * Broadband account number of the subscriber
   */
  private accountNumber: string = '';

  /**
   * Mobile number of the subscriber
   */
  private mobileNumber: string = '';

  /**
   * Landline number of the subscriber
   */
  private landlineNumber: string = '';
  /**
   * Brand of the customer
   */
  private brand: string = '';
  /**
   * Segment of the customer
   */
  private segment: string = '';
  /**
   * BillingArrangementId of customer
   */
  private billingArrangementId: string = '';
  /**
   * HTML Text of fail dialog modal Title
   */
  public failTitle?: string = '';
  /**
   * HTML Text of fail dialog modal Description
   */
  public failDescription?: string = '';
  /**
   * HTML Text of confirmation button
   */
  public failConfirmLabel?: string = '';
  /**
   * HTML Text of cancellation button
   */
  public failCancelLabel?: string = '';
  /**
   * Action when clicking confirm button
   */
  public failConfirmAction?: Function = () => {};
  /**
   * Action when clicking cancel button
   */
  public failCancelAction?: Function = () => {};
  /**
   * Style tag of confirmation button
   */
  public failConfirmStyle?: string = '';
  /**
   * Style tag of cancellation button
   */
  public failCancelStyle?: string = '';

  /**
   * Flag when event tagging during account check is done
   */
  public isAccountCheckTagged: boolean = false;;

  /**
   * Flag when event tagging during billing check is done
   */
  public isBillingCheckTagged: boolean = false;

  /**
   * Identifier for what dialog modal to display
   * overdue - Payment overdue
   * disconnected - Temporarily disconnected
   * outage - Reported outage
   * network - Network setting
   * fiber_loss - Fiber loss
   * fiber_cuts - Fiber cuts
   * modem_line - Modem line
   * modem_workload - Modem high workload
   * success - Success diagnosing process
   */
  public showDialogModal = '';

  private isDisconnected: any = {};

  /**
   * Object containing response result from API calls
   */
  resultList: any;

  /**
   * Boolean flag for showing loading modal
   */
  loading: boolean = false;

  /**
   * Stores all images required by the page for display
   */
  imageStorage: string[] = [
    '/assets/icons/obi-pre-check-initial.gif',
    '/assets/icons/obi-pre-check-active.gif',
    '/assets/icons/obi-pre-check-inactive.svg',
    '/assets/icons/obi-pre-check-fail.svg',
    '/assets/icons/obi-pre-check-fail_continue.svg',
    '/assets/icons/obi-pre-check-completed.svg',
    '/assets/icons/obi-pre-check-success.svg',
  ];

  imageLoadedCount: number = 0;

  constructor(
    private route: ActivatedRoute,
    private sessionService: OmniSessionService,
    public commonService: OmniCommonService,
    private preCheckService: ObiPreCheckService,
    private gtmService: GoogleTagManagerService
  ) {
    this.sessionService.setData(SESSION_KEYS.OBI_TAG, true);
  }

  imageLoaded() {
    this.imageLoadedCount++;
    if (this.imageStorage.length === this.imageLoadedCount) {
      this.obiPreCheckCancelConfirmAction = () => this.toggleCancelModal();
      this.obiPreCheckCancelCancelAction = () => {
        this.pushGtmEvent(OBI_PRE_CHECK_CANCEL_YES_TAG);
        this.commonService.navigate(this.obiPreCheckCancel.cancelButtonUrl);
      }
      this.retrieveParams(() => this.preCheckAccount());
    }
  }

  /**
   * Retrieves, decrypt and stores query param
   */
  private retrieveParams(postValidationFunc: Function) {
    // Retrieve queryParams from URL
    this.route.queryParams.subscribe(params => {
      const encryptedData = decodeURIComponent(params['data']);
      if (!encryptedData) {
        this.commonService.navigate(PAGE_URLS.GENERIC_ERROR_PAGE_URL);
      }
      this.commonService.validateInitParams(
        encryptedData,
        () => {
          this.accountNumber = this.sessionService.getData(
            SESSION_KEYS.OBI_ACCOUNT_NUMBER_KEY
          );
          this.mobileNumber = this.sessionService.getData(
            SESSION_KEYS.OBI_MOBILE_NUMBER
          );
          this.landlineNumber = this.sessionService.getData(
            SESSION_KEYS.OBI_LANDLINE_NUMBER
          );
          this.brand = this.sessionService.getData(SESSION_KEYS.OBI_BRAND_KEY);
          this.segment = this.sessionService.getData(
            SESSION_KEYS.OBI_SEGMENT_KEY
          );
          this.billingArrangementId = this.sessionService.getData(
            SESSION_KEYS.OBI_BILLING_ARRANGEMENT_ID
          );
          postValidationFunc();
        },
        true
      );
    });
  }

  /**
   * Calls for the getAccountDetailsV3 and trigger response validation
   */
  private preCheckAccount() {
    this.preCheckService
      .getAccountDetailsV3(
        this.accountNumber,
        this.mobileNumber,
        this.landlineNumber,
        this.brand,
        this.segment
      )
      .subscribe({
        next: (accountRes: any) => {
          const accountResult = accountRes;
          this.resultList = {
            ...this.resultList,
            ...this.commonService.flatMap(accountResult.result),
          };
          this.preCheckService
            .getBillingDiagnosis(
              this.accountNumber,
              this.mobileNumber,
              this.landlineNumber,
              this.brand,
              this.segment
            )
            .subscribe({
              next: (billingRes: any) => {
                const billingResult = billingRes;
                this.obiPreCheckList[0].status = 'active';
                this.resultList = {
                  ...this.resultList,
                  ...this.commonService.flatMap(billingResult.result),
                };
                this.sessionService.setUserData(this.resultList);
                this.processPreCheckRequirements(this.obiPreCheckList);
                this.validateResponse('');
              },
              error: error => {
                const errorObj = {
                  error_code: error?.result?.code ?? error?.error?.code,
                  api_name: CXS_API_NAMES.GET_BILLING_DETAILS_V3,
                };
                this.pushGtmEvent(OBI_PRE_CHECK_BILLING_ERROR_TAG, errorObj);
                this.commonService.handleAPIError(error);
              },
            });
        },
        error: error => {
          const errorObj = {
            error_code: error?.result?.code ?? error?.error?.code,
            api_name: CXS_API_NAMES.GET_ACCOUNT_DETAILS_V3,
          };
          this.pushGtmEvent(OBI_PRE_CHECK_ACCOUNT_ERROR_TAG, errorObj);
          this.commonService.handleAPIError(error);
        },
      });
  }

  /**
   * Validates and removes pre-check list pre_checks when failed requirements
   */
  private processPreCheckRequirements(preCheckList: ObiPreCheck[] = []) {
    for (let index = preCheckList.length; index >= 0; index--) {
      if (this.validateRequirement(preCheckList[index])) {
        preCheckList.splice(index, 1);
      } else if (
        preCheckList[index]?.pre_checks &&
        preCheckList[index]?.pre_checks?.length
      ) {
        this.processPreCheckRequirements(preCheckList[index].pre_checks);
      }
    }
  }

  /**
   * Validates if the pre-check item will be removed from the list based on the requirements
   * @param preCHeck
   */
  private validateRequirement(preCheck: ObiPreCheck) {
    let result = false;
    if (preCheck?.requirements) {
      preCheck?.requirements?.forEach(requirement => {
        let invalidValuesValidation = true;
        if (requirement?.invalidValidationValues) {
          requirement.invalidValidationValues.forEach((validation: RegExp) => {
            if (validation.test(this.resultList[requirement.validationKey])) {
              invalidValuesValidation = false;
            }
          });
        }
        let validValuesValidation = false;
        if (requirement?.validValidationValues) {
          requirement?.validValidationValues?.forEach((validation: RegExp) => {
            if (validation.test(this.resultList[requirement.validationKey])) {
              validValuesValidation = true;
            }
          });
        } else {
          validValuesValidation = true;
        }

        result = invalidValuesValidation && validValuesValidation;
      });
    }
    return result;
  }

  /**
   * Validates resultList based of configuration
   * @param startingId
   */
  private validateResponse(startingId?: string) {
    let timeoutQueue = 1;
    this.obiPreCheckList.forEach((preCheck: ObiPreCheck) => {
      switch (preCheck.id) {
        case 'account':
          if (!this.isAccountCheckTagged) {
            this.pushGtmEvent(OBI_PRE_CHECK_ACCOUNT_TAG);
            this.isAccountCheckTagged = true;
          }
          break;
        case 'balance':
          if (!this.isBillingCheckTagged) {
            this.pushGtmEvent(OBI_PRE_CHECK_BILLING_TAG);
            this.isBillingCheckTagged = true;
          }
          break;
      }
      preCheck.pre_checks?.forEach((subPreCheck: ObiPreCheck) => {
        if (!startingId || startingId === subPreCheck.id) {
          startingId = '';
          const timeout = setTimeout(() => {
            if (!this.showDialogModal) {
              // Validate if invalidValidationValues and validValidationValues contains value of validationKey prop
              // Uses the sub pre-check id to display dialog modal based of the error
              // Validate invalid values
              let invalidValuesValidation = true;
              if (
                subPreCheck &&
                subPreCheck?.validation &&
                subPreCheck?.validation?.invalidValidationValues
              ) {
                subPreCheck.validation.invalidValidationValues?.forEach(
                  (validation: RegExp) => {
                    if (
                      subPreCheck?.validation?.validationKey &&
                      validation.test(
                        this.resultList[subPreCheck?.validation?.validationKey]
                      )
                    ) {
                      invalidValuesValidation = false;
                    }
                  }
                );
              }
              // Validate valid values
              let validValuesValidation = false;
              if (
                subPreCheck &&
                subPreCheck?.validation &&
                subPreCheck?.validation?.validValidationValues
              ) {
                subPreCheck.validation.validValidationValues?.forEach(
                  (validation: RegExp) => {
                    if (
                      subPreCheck?.validation?.validationKey &&
                      validation.test(
                        this.resultList[subPreCheck?.validation?.validationKey]
                      )
                    ) {
                      validValuesValidation = true;
                    }
                  }
                );
              } else {
                validValuesValidation = true;
              }

              if (invalidValuesValidation && validValuesValidation) {
                this.setSubPreCheckSuccess(preCheck, subPreCheck);
              } else {
                this.setPreCheckFail(preCheck, subPreCheck);
              }
            }
            clearTimeout(timeout);
          }, OBI_PRE_CHECK_SUB_VALIDATION_TIMER * timeoutQueue);
          timeoutQueue++;
        }
      });
    });
  }

  /**
   * Generated fail dialog modal content based of pre-check data
   * @param id
   * @param diagnosis
   */
  private generateFailDialogModal(
    id: string,
    preCheckValidation: ObiPreCheckValidation
  ) {
    this.failTitle = preCheckValidation?.failTitle;
    this.failDescription = preCheckValidation?.failDescription;
    this.failConfirmLabel = preCheckValidation?.failConfirmLabel;
    this.failCancelLabel = preCheckValidation?.failCancelLabel;
    this.failConfirmStyle = preCheckValidation?.failConfirmStyle;
    this.failCancelStyle = preCheckValidation?.failCancelStyle;
    this.failConfirmAction = this.generateButtonAction(
      id,
      preCheckValidation?.failConfirmUrl
    );
    this.failCancelAction = this.generateButtonAction(
      id,
      preCheckValidation?.failCancelUrl
    );
    this.showDialogModal = id;
  }

  /**
   * Generates the function run when modal button is clicked
   * @param id
   * @param action
   * @returns
   */
  private generateButtonAction(id: string, action?: string): Function {
    let buttonAction: Function = () => {};
    if (action) {
      if (action === 'continue') {
        buttonAction = () => {
          this.pushGtmEventBasedOnAction(id, action);
          this.proceedChecking(id);
        };
      } else {
        buttonAction = () => {
          this.pushGtmEventBasedOnAction(id, action);
          this.commonService.navigate(action);
        };
      }
    }
    return buttonAction;
  }

  /**
   * Proceeds with the checking starting from error
   * @param lastValidationId
   */
  proceedChecking(lastValidationId: string) {
    let startingValidationId = lastValidationId;
    let isNext = false;
    this.obiPreCheckList.forEach((preCheck: ObiPreCheck) => {
      preCheck.pre_checks?.forEach((subPreCheck: ObiPreCheck) => {
        if (isNext) {
          startingValidationId = subPreCheck.id;
          preCheck.status = 'active';
          subPreCheck.status = 'active';
          isNext = false;
        }
        if (subPreCheck.id === lastValidationId) {
          preCheck.status = 'fail_continue';
          isNext = true;
        }
      });
    });
    this.showDialogModal = '';
    if (startingValidationId === lastValidationId) {
      this.showDialogModal = 'completed';
    } else {
      this.validateResponse(startingValidationId);
    }
  }

  /**
   * Clears all setTimeout currently running
   */
  private clearTimeouts() {
    let id = window.setTimeout(function () {}, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  /**
   * Processes the pre-check list to set provided sub pre-check to complete
   * @param preCheck
   * @param subPreCheck
   */
  private setSubPreCheckSuccess(
    preCheck: ObiPreCheck,
    subPreCheck: ObiPreCheck
  ) {
    const preCheckIndex = this.obiPreCheckList.indexOf(preCheck);
    // Validation passes and sets current sub pre-check to completed
    subPreCheck.status = 'completed';

    // Checks if the next active sub pre-check would be in under the same pre-check or the next one
    if (
      preCheck.pre_checks?.indexOf(subPreCheck) ===
      (preCheck.pre_checks?.length || 0) - 1
    ) {
      this.clearTimeouts();
      const preCheckTimeout = setTimeout(() => {
        preCheck.status = 'completed';
        const nextPreCheck = this.obiPreCheckList[preCheckIndex + 1];
        if (nextPreCheck) {
          nextPreCheck.status = 'active';
          if (
            preCheckIndex < this.obiPreCheckList.length - 1 &&
            nextPreCheck?.pre_checks?.length
          ) {
            this.validateResponse(nextPreCheck.pre_checks[0].id);
          }
        } else {
          if (Object.keys(this.isDisconnected).length !== 0) {
            // Show disconnected modal after OB pre check when account is disconnected. Ref: OBI-200 AC. 3
            this.generateFailDialogModal(
              this.isDisconnected.subPreCheck.id,
              this.isDisconnected.subPreCheck.validation
            );
          } else {
            this.showDialogModal = 'completed';
            this.pushGtmEvent(OBI_PRE_CHECK_COMPLETE_TAG);
          }
        }
        clearTimeout(preCheckTimeout);
      }, OBI_PRE_CHECK_VALIDATION_TIMER);
    } else if (preCheck.pre_checks) {
      const preCheckIndex = preCheck.pre_checks?.indexOf(subPreCheck);
      preCheck.pre_checks[(preCheckIndex || 0) + 1].status = 'active';
    }
  }

  /**
   * Processes the pre-check list to set provided sub pre-check to fail
   * @param preCheck
   * @param subPreCheck
   */
  private setPreCheckFail(preCheck: ObiPreCheck, subPreCheck: ObiPreCheck) {
    this.clearTimeouts();
    if (!subPreCheck?.sub_validations && subPreCheck.validation) {
      subPreCheck.status = 'fail';
      preCheck.status = 'fail';
      if (subPreCheck.id === 'account_status') {
        // Store preCheck when Account is disconnected and proceed to next checking
        this.isDisconnected = { preCheck: preCheck, subPreCheck: subPreCheck };
        this.proceedChecking(subPreCheck.id);
      } else {
        if (Object.keys(this.isDisconnected).length !== 0) {
          // Show Disconnected + overdue balance modal ref: OBI-200 AC. 4
          this.generateFailDialogModal(
            subPreCheck.id,
            OBI_PRE_CHECK_DISCONNECTED_OB.validation
          );
        } else {
          // Show Overdue balance modal ref: OBI-200 AC. 2
          this.generateFailDialogModal(subPreCheck.id, subPreCheck.validation);
        }
      }
    } else if (subPreCheck?.sub_validations) {
      subPreCheck.sub_validations.forEach(subValidation => {
        // Invalidate valid values
        let invalidValuesValidation = true;
        if (subValidation && subValidation?.invalidValidationValues) {
          subValidation.invalidValidationValues?.forEach(
            (validation: RegExp) => {
              if (
                subPreCheck?.validation?.validationKey &&
                validation.test(
                  this.resultList[subPreCheck?.validation?.validationKey]
                )
              ) {
                invalidValuesValidation = false;
              }
            }
          );
        }
        // Validate valid values
        let validValuesValidation = false;
        if (subValidation && subValidation?.validValidationValues) {
          subValidation.validValidationValues?.forEach((validation: RegExp) => {
            if (
              subPreCheck?.validation?.validationKey &&
              validation.test(
                this.resultList[subPreCheck?.validation?.validationKey]
              )
            ) {
              validValuesValidation = true;
            }
          });
        } else {
          validValuesValidation = true;
        }

        if (invalidValuesValidation && validValuesValidation) {
          subValidation.status = 'completed';
        } else {
          subValidation.status = 'fail';
          subPreCheck.status = 'fail';
          preCheck.status = 'fail';
          this.generateFailDialogModal(subPreCheck.id, subValidation);
          if (subValidation?.failConfirmTimer && this.failConfirmAction) {
            this.setTimerTriggerAction(
              this.failConfirmAction,
              subValidation.failConfirmTimer
            );
          } else if (subValidation?.failCancelTimer && this.failCancelAction) {
            this.setTimerTriggerAction(
              this.failCancelAction,
              subValidation.failCancelTimer
            );
          }
        }
      });
      if (subPreCheck?.status !== 'fail') {
        this.setSubPreCheckSuccess(preCheck, subPreCheck);
      }
    }
  }

  /**
   * Adds a settimeout function for triggering action set by config
   * @param millisecons
   */
  setTimerTriggerAction(action: Function, ms: number) {
    const timerTimeout = setTimeout(() => {
      action();
      clearTimeout(timerTimeout);
    }, ms);
  }

  /**
   * Replaces curly brace pattern with key
   * @param text
   */
  parseRegex(text: string) {
    return (
      text?.replace(
        OBI_PRE_CHECK_TITLE_REGEX,
        (match, key) => this.resultList[key]
      ) || ''
    );
  }

  /**
   * Generate pre-check text displayed based on the status
   * @param preCheck
   * @param status
   * @returns
   */
  getTitleByStatus(preCheck: any, status: string): string {
    return this.parseRegex(preCheck[`title_${status}`]);
  }

  /**
   * Generate image path for each pre-check status
   * @param status
   * @returns
   */
  getImageByStatus(status: string): string {
    return `/assets/icons/obi-pre-check-${status}.${
      ['active', 'initial'].includes(status) ? 'gif' : 'svg'
    }`;
  }

  /**
   * Navigate to bill inquiry
   */
  submitBillInquiry() {
    this.commonService.navigate(this.obiPreCheckSubmit.confirmButtonUrl);
    this.pushGtmEvent(OBI_PRE_CHECK_BILL_INQUIRY_TAG);
  }

  /**
   * Shows/hides cancel modal
   */
  toggleCancelModal() {
    this.isCancel = !this.isCancel;
    if (this.isCancel) {
      this.pushGtmEvent(OBI_PRE_CHECK_CANCEL_TAG);
    } else {
      this.pushGtmEvent(OBI_PRE_CHECK_CANCEL_NO_TAG);
    }
  }

  /**
   * Pushes event tagging to GTM
   */
  pushGtmEvent(config: any, additionalData?: any, isError: boolean = false) {
    if (isError) {
      this.sessionService.setData(
        SESSION_KEYS.OBI_API_ERROR_EVENT,
        additionalData
      );
    }
    this.gtmService.captureGTMEvent(config, true, additionalData);
  }

  /**
   * Pushes event tagging to GTM based on the action
   * @param id pre-check id
   * @param action action assigned to the modal button
   */
  pushGtmEventBasedOnAction(id: string, action: string) {
    const isAccount = id === 'account_status';
    const additionalData = {
      account_status:
        parseInt(this.sessionService.getUserData().status) === 65
          ? 'Active'
          : 'Temp_Disconnected',
    };

    let gtmTag: any;
    switch (action) {
      case PAGE_URLS.FB_MSGR_URL:
        gtmTag = isAccount ? OBI_PRE_CHECK_ACCOUNT_FB_BUTTON_TAG : undefined;
        break;
      case PAGE_URLS.GOTO_DASHBOARD_URL:
        gtmTag = isAccount ? OBI_PRE_CHECK_ACCOUNT_DASHBOARD_BUTTON_TAG : undefined;
        break;
      case PAGE_URLS.PAYMENT_OVERDUE_URL:
        gtmTag = isAccount ? undefined : OBI_PRE_CHECK_BALANCE_PAY_BUTTON_TAG;
        break;
      case PAGE_URLS.BILL_INQUIRY:
        gtmTag = isAccount ? undefined : OBI_PRE_CHECK_BALANCE_INQUIRY_BUTTON_TAG;
        break;
      case 'continue':
        gtmTag = isAccount ? undefined : OBI_PRE_CHECK_BALANCE_PROCEED_BUTTON_TAG;
        break;
    }

    if (gtmTag) {
      this.pushGtmEvent(gtmTag, additionalData);
    }
  }
}
