<div class="omni-confirmed-request" id="omni-req-confirmed-div-id">
  <div class="omni-confirmed-request__receipt">
    <div class="omni-confirmed-request-header">
      <p
        class="omni-confirmed-request__title"
        [innerHTML]="confirmRequestTitle"></p>
      <p
        class="omni-confirmed-request__timestamp"
        [innerHTML]="confirmRequestTimestamp"></p>
    </div>

    <div class="omni-confirmed-request__receipt-border-img-top-decor"></div>
    <div class="omni-confirmed-request__receipt-box shadow">
      <div class="omni-confirmed-request__receipt-wrapper">
        <div class="omni-confirmed-request__receipt-header">
          <img
            class="omni-confirmed-request__receipt-download-icon"
            [src]="omniReqConfirmedDownloadIcon"
            (click)="downloadRequestConfirmation()" />
          <p
            class="omni-confirmed-request__receipt-greet"
            [innerHTML]="receiptGreetLabel"></p>
          <p
            class="omni-confirmed-request__receipt-title"
            [innerHTML]="receiptTitle"></p>
          <div class="omni-confirmed-request__receipt-reminder">
            <p
              class="omni-confirmed-request__receipt-reminder-title"
              [innerHTML]="reminderTitle"></p>
            <p
              class="omni-confirmed-request__receipt-reminder-subtext"
              [innerHTML]="reminderSubtext"></p>
          </div>
        </div>

        <div class="omni-confirmed-request__receipt-content">
          <div class="omni-confirmed-request__account-details">
            <div class="omni-confirmed-request__account-details-biller-wrapper">
              <p
                class="omni-confirmed-request__account-details-biller"
                [innerHTML]="billerLabel"></p>
              <div class="omni-confirmed-request__account-details-brand">
                <img
                  class="omni-confirmed-request__account-details-brand-icon"
                  [src]="omniReqConfirmedBrand.brand_icon" />
                <span
                  class="omni-confirmed-request__account-details-brand-name"
                  [innerHTML]="omniReqConfirmedBrand.brand_name"></span>
              </div>
            </div>

            <p class="omni-confirmed-request__account-details-line"></p>
            <div class="omni-confirmed-request__account-details-content">
              <div
                class="omni-confirmed-request__account-details-content-wraper"
                *ngFor="let accountDetail of accountDetails">
                <p
                  class="omni-confirmed-request__account-details-content-label"
                  [innerHTML]="accountDetail.label"></p>
                <p
                  class="omni-confirmed-request__account-details-content-value"
                  [innerHTML]="accountDetail.value"></p>
              </div>
            </div>
          </div>

          <div class="omni-confirmed-request__concern-details">
            <p class="omni-confirmed-request__concern-details-label">CONCERN</p>
            <p
              class="omni-confirmed-request__concern-details-value"
              [innerHTML]="concernSubject"></p>

            <p class="omni-confirmed-request__concern-details-label">
              DESCRIPTION
            </p>
            <p
              class="omni-confirmed-request__concern-details-value"
              [innerHTML]="concernDetailsDisplay"></p>

            <p class="omni-confirmed-request__concern-details-label">
              WORK ORDER NO.
            </p>

            <div class="omni-confirmed-request__concern-details-work-order">
              <p
                class="omni-confirmed-request__concern-details-value-work-order"
                #workOrderNumTxt>
                {{ workOrderNumber }}
              </p>
              <div
                class="omni-confirmed-request__concern-details-work-order-copy-icon-container"
                (click)="copyWorkOrderNumTxt()">
                <img
                  class="omni-confirmed-request__concern-details-work-order-copy-icon"
                  [src]="omniReqConfirmedCopyIcon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- receipt-box -->
    <div class="omni-confirmed-request__receipt-border-img-bottom-decor"></div>

    <div class="omni-confirmed-request__btn-wrapper">
      <button
        class="omni-confirmed-request__btn-success"
        [innerHTML]="gotoDashboardLabel"
        (click)="gotoDashboard()"></button>
    </div>
  </div>
</div>

<app-omni-snackbar
  [isShowSubText]="snackBarIsShowSubtext"
  [duration]="snackBarDuration"
  [title]="snackBarTitle"
  [subText]="snackBarSubText"></app-omni-snackbar>
