import { Component, OnInit } from '@angular/core';

import {
  COMING_SOON_VALUES,
  OMNI_ERROR_INIT_VALUES,
} from '../../../common/constants/omni-error.constants';
import {
  PAGE_URLS,
  SESSION_KEYS as OMNI_SESSION_KEYS,
} from '../../../common/constants/omni-global.constant';
import { SESSION_KEYS as OBI_SESSION_KEYS } from '../../../common/constants/obi-global.constants';
import { Router } from '@angular/router';
import { OmniSessionService } from 'src/app/services/omni-session.service';
import { GoogleTagManagerService } from 'src/app/services/google-tag-manager-service.service';
import * as OMNI_EVENT_TAGS from '../../../common/constants/omni-gtm-events.constants';
import * as OBI_EVENT_TAGS from '../../../common/constants/obi-gtm-events.constants';

const {
  ERROR_IMAGE,
  ERROR_TITLE,
  ERROR_SUBTEXT,
  MAYBE_LATER_LABEL_BTN_LABEL,
  TRY_AGAIN_LABEL,
  CHAT_WITH_FB_MSGR,
} = OMNI_ERROR_INIT_VALUES;

const { GOTO_DASHBOARD_URL, FB_MSGR_URL, FB_MSGR_BB_NETWORK_URL } = PAGE_URLS;

const { OBI_GENERIC_ERROR_CHAT_TAG, OBI_GENERIC_ERROR_RETRY_TAG } =
  OBI_EVENT_TAGS;

const { OMNI_GENERIC_ERROR_CHAT_TAG, OMNI_GENERIC_ERROR_RETRY_TAG } =
  OMNI_EVENT_TAGS;

@Component({
  selector: 'app-omni-error',
  templateUrl: './omni-error.component.html',
  styleUrls: ['./omni-error.component.scss'],
})
export class OmniErrorComponent implements OnInit {
  public errorImage: string = ERROR_IMAGE;
  public errorTitle: string = ERROR_TITLE;
  public errorSubtext: string = ERROR_SUBTEXT;
  public maybeLaterLabel: string = MAYBE_LATER_LABEL_BTN_LABEL;
  public goToDashboardURL: string = GOTO_DASHBOARD_URL;
  public tryAgainLabel: string = TRY_AGAIN_LABEL;
  public chatWithMsgr: string = CHAT_WITH_FB_MSGR;
  public fbMsgrURL: string = FB_MSGR_URL;
  private errorEvent: any;
  private isObi: boolean = false;
  public chatWithMsgrTag: any = OMNI_GENERIC_ERROR_CHAT_TAG;
  public tryAgainTag: any = OMNI_GENERIC_ERROR_RETRY_TAG;

  constructor(
    private router: Router,
    private sessionService: OmniSessionService,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit(): void {
    if (this.router.url === PAGE_URLS.COMING_SOON_PAGE_URL) {
      this.errorImage = COMING_SOON_VALUES.ERROR_IMAGE;
      this.errorTitle = COMING_SOON_VALUES.ERROR_TITLE;
      this.errorSubtext = COMING_SOON_VALUES.ERROR_SUBTEXT;
      this.maybeLaterLabel = COMING_SOON_VALUES.MAYBE_LATER_LABEL_BTN_LABEL;
      this.goToDashboardURL = GOTO_DASHBOARD_URL;
      this.tryAgainLabel = COMING_SOON_VALUES.TRY_AGAIN_LABEL;
      this.chatWithMsgr = COMING_SOON_VALUES.CHAT_WITH_FB_MSGR;
      this.fbMsgrURL = FB_MSGR_BB_NETWORK_URL;
    }
    this.isObi = this.sessionService.getData(OBI_SESSION_KEYS.OBI_TAG);
    if (this.isObi) {
      this.errorEvent = this.sessionService.getData(
        OBI_SESSION_KEYS.OBI_API_ERROR_EVENT
      );
      this.chatWithMsgrTag = OBI_GENERIC_ERROR_CHAT_TAG;
      this.tryAgainTag = OBI_GENERIC_ERROR_RETRY_TAG;
    } else {
      this.errorEvent = this.sessionService.getData(
        OMNI_SESSION_KEYS.OMNI_API_ERROR_EVENT
      );
    }
  }

  /**
   * Pushes event tagging to GTM
   */
  pushGtmEvent(config: any) {
    this.gtmService.captureGTMEvent(config, this.isObi, this.errorEvent);
  }
}
