<div class="omni-troubleshooting-failed">
    <div class="omni-troubleshooting-failed__wrapper">
        <div class="omni-troubleshooting-failed__content">
            <img [src]="tsImage" alt="" class="omni-troubleshooting-failed__img">
            <div class="omni-troubleshooting-failed__content-text-wrapper">
                <p class="omni-troubleshooting-failed__title" [innerHTML]="tsTitle"></p>
                <p class="omni-troubleshooting-failed__subtext" [innerHTML]="tsSubtext"></p>
            </div>
        </div>
    
        <div class="omni-troubleshooting-failed__btn-wrapper">
            <a [attr.href]="fbMsgrURL"> 
                <button class="omni-troubleshooting-failed__btn-primary" [innerHTML]="chatWithMsgr"></button>
            </a>

            <a [attr.href]="goToDashboardURL">
                <button class="omni-troubleshooting-failed__btn-cancel" [innerHTML]="goToDashboardLabel"></button>
            </a>
        </div>
    </div>
</div>
  