export const OBI_BILL_INQUIRY_SUBMIT_TAG = {
  event_action: 'click',
  event_label: 'billinquiryformpage-button-submit-bd',
  section: 'billinquiryformpage',
  element: 'button-submit',
};

export const OBI_TICKET_RECEIPT_TAG = {
  event_action: 'landing-page',
  event_label: 'billinquirysuccesspage-landingpage-submitted-bd',
  section: 'billinquirysuccesspage',
  element: 'landingpage-submitted',
};

export const OBI_BILL_INQUIRY_CANCEL_TAG = {
  event_action: 'click',
  event_label: 'billinquiryformpage-button-cancel-bd',
  section: 'billinquiryformpage',
  element: 'button-cancel',
};

export const OBI_BILL_INQUIRY_CANCEL_YES_TAG = {
  event_action: 'click',
  event_label: 'billinquiryformpage-button-cancelmodal-yes-bd',
  section: 'billinquiryformpage',
  element: 'button-cancelmodal',
};

export const OBI_BILL_INQUIRY_CANCEL_NO_TAG = {
  event_action: 'click',
  event_label: 'billinquiryformpage-button-cancelmodal-no-bd',
  section: 'billinquiryformpage',
  element: 'button-cancelmodal',
};

export const OBI_BILL_INQUIRY_SUBMIT_ERROR_TAG = {
  event_action: 'landing-page',
  event_label: 'billinquiryformpage-landingpage-apierror-bd',
  section: 'billinquiryformpage',
  element: 'landingpage-apierror',
};

export const OBI_PRE_CHECK_ACCOUNT_TAG = {
  event_action: 'landing-page',
  event_label: 'checkaccountpage-landingpage-bd',
  section: 'checkaccountpage',
  element: 'landingpage',
};

export const OBI_PRE_CHECK_BILLING_TAG = {
  event_action: 'landing-page',
  event_label: 'checkbalancepage-landingpage-bd',
  section: 'checkbalancepage',
  element: 'landingpage',
};

export const OBI_PRE_CHECK_COMPLETE_TAG = {
  event_action: 'landing-page',
  event_label: 'diagnosiscompletepage-landingpage-bd',
  section: 'diagnosiscompletepage',
  element: 'landingpage',
};

export const OBI_PRE_CHECK_BILL_INQUIRY_TAG = {
  event_action: 'click',
  event_label: 'diagnosiscompletepage-button-submitbillinquiry-bd',
  section: 'diagnosiscompletepage',
  element: 'button-submitbillinquiry',
};

export const OBI_PRE_CHECK_CANCEL_TAG = {
  event_action: 'landing-page',
  event_label: 'diagnosiscompletepage-button-cancel-bd',
  section: 'diagnosiscompletepage',
  element: 'button-cancel',
};

export const OBI_PRE_CHECK_CANCEL_YES_TAG = {
  event_action: 'click',
  event_label: 'diagnosiscompletepage-button-modalcancel-yes-bd',
  section: 'diagnosiscompletepage',
  element: 'button-modalcancel',
};

export const OBI_PRE_CHECK_CANCEL_NO_TAG = {
  event_action: 'click',
  event_label: 'diagnosiscompletepage-button-modalcancel-no-bd',
  section: 'diagnosiscompletepage',
  element: 'button-modalcancel',
};

export const OBI_PRE_CHECK_ACCOUNT_ERROR_TAG = {
  event_action: 'landing-page',
  event_label: 'checkaccountpage-landingpage-apierror-bd',
  section: 'checkaccountpage',
  element: 'landingpage-apierror',
};

export const OBI_PRE_CHECK_BILLING_ERROR_TAG = {
  event_action: 'landing-page',
  event_label: 'checkbalancepage-landingpage-apierror-bd',
  section: 'checkbalancepage',
  element: 'landingpage-apierror',
};

export const OBI_GENERIC_ERROR_CHAT_TAG = {
  event_action: 'landing-page',
  event_label: 'billinquiryformpage-button-chatwithusviafbmessenger-bd',
  section: 'billinquiryformpage',
  element: 'button-chatwithusviafbmessenger',
};

export const OBI_GENERIC_ERROR_RETRY_TAG = {
  event_action: 'landing-page',
  event_label: 'billinquiryformpage-button-letstryagain-bd',
  section: 'billinquiryformpage',
  element: 'button-letstryagain',
};

export const OBI_PRE_CHECK_ACCOUNT_FB_BUTTON_TAG = {
  event_action: 'click',
  event_label: 'checkaccountmodal-button-chatwithusonmessenger-bd',
  section: 'checkaccountmodal',
  element: 'button-chatwithusonmessenger',
};

export const OBI_PRE_CHECK_ACCOUNT_DASHBOARD_BUTTON_TAG = {
  event_action: 'click',
  event_label: 'checkaccountmodal-button-gobacktodashboard-bd',
  section: 'checkaccountmodal',
  element: 'button-gobacktodashboard',
};

export const OBI_PRE_CHECK_BALANCE_PAY_BUTTON_TAG = {
  event_action: 'click',
  event_label: 'checkbalancemodal-button-paynow-bd',
  section: 'checkbalancemodal',
  element: 'button-paynow',
};

export const OBI_PRE_CHECK_BALANCE_PROCEED_BUTTON_TAG = {
  event_action: 'click',
  event_label: 'checkbalancemodal-button-proceedtobillinquiry-bd',
  section: 'checkbalancemodal',
  element: 'button-proceedtobillinquiry',
};

export const OBI_PRE_CHECK_BALANCE_INQUIRY_BUTTON_TAG = {
  event_action: 'click',
  event_label: 'checkbalancemodal-button-proceedtobillinquiry-bd',
  section: 'checkbalancemodal',
  element: 'button-proceedtobillinquiry',
};
