<div class="omni-sched-technician-no-slot-banner" *ngIf="showBanner">
  <img class="omni-sched-technician-no-slot-banner__img"
    [src]="noSlotBannerImg"/>
  <p class="omni-sched-technician-no-slot-banner__header"
    [innerHTML]="noSlotBannerHeader"></p>
  <p class="omni-sched-technician-no-slot-banner__subtext"
    [innerHTML]="noSlotBannerSubtext"></p>
</div>

<div class="omni-sched-technician-schedule-picker">
  <p class="omni-sched-technician-schedule-picker__header">
    {{ schedulePickerHeader }}<span class="omni-sched-technician-schedule-picker__header required"> *</span>
  </p>
  <div
    class="omni-sched-technician-schedule-picker__date"
    (click)="showCalendarPicker()"
    [class.omni-sched-technician-schedule-picker__date--selected]="selectedDate"
  >
    <div class="omni-sched-technician-schedule-picker__date-input-area">
      <label *ngIf="selectedDate">Date</label>
      <input
        class="omni-sched-technician-schedule-picker__date-input-area-input"
        [value]="selectedDate"
        [class.omni-sched-technician-schedule-picker__date-input-area-input--selected]="
          selectedDate
        "
        placeholder="Pick a date"
        readonly
      />
    </div>
    <span
      class="omni-sched-technician-schedule-picker__date-calendar-icon"
    ></span>
  </div>
</div>
<div class="omni-sched-technician-time-selection">
  <p class="omni-sched-technician-schedule-picker__header">
    {{ timeSelectionHeader }}<span class="omni-sched-technician-schedule-picker__header required"> *</span>
  </p>

  <div class="omni-sched-technician-time-selection__dropdown-wrapper">
    <div
      class="omni-sched-technician-time-selection__dropdown"
      [class.omni-sched-technician-time-selection__dropdown--disabled]="
        !selectedDate
      "
      [class.omni-sched-technician-time-selection__dropdown--selected]="
        selectedTime && !showDropdown
      "
      [class.omni-sched-technician-time-selection__dropdown--clicked]="
        showDropdown && !selectedTime
      "
      [class.omni-sched-technician-time-selection__dropdown--selected--clicked]="
        showDropdown && selectedTime
      "
    >
      <div class="omni-sched-technician-time-selection__dropdown-area">
        <label *ngIf="selectedTime">Time</label>
        <button
          class="omni-sched-technician-time-selection__dropdown-area-button"
          [class.omni-sched-technician-time-selection__dropdown-area-button--selected]="
            selectedTime
          "
          [class.omni-sched-technician-time-selection__dropdown-area-button--clicked]="
            showDropdown && !selectedTime
          "
          [class.omni-sched-technician-time-selection__dropdown-area-button--enabled]="
            selectedDate
          "
          (click)="toggleDropdown()"
          [disabled]="!selectedDate"
        >
          {{ selectedTime || "Select time" }}
        </button>
      </div>
      <span
        class="omni-sched-technician-time-selection__dropdown-arrow-icon"
        [class.omni-sched-technician-time-selection__dropdown-arrow-icon--disabled]="
          !selectedDate
        "
        [class.omni-sched-technician-time-selection__dropdown-arrow-icon--down]="
          selectedDate
        "
        [class.omni-sched-technician-time-selection__dropdown-arrow-icon--up]="
          showDropdown
        "
        (click)="selectedDate ? toggleDropdown() : ''"
      ></span>

    </div>
    <ul
      class="omni-sched-technician-time-selection__dropdown-list"
      *ngIf="showDropdown"
    >
      <li
        class="omni-sched-technician-time-selection__dropdown-list-item"
        *ngFor="let option of options"
        (click)="selectOption(option)"
      >
        {{ option }}
      </li>
    </ul>
  </div> <!-- omni-sched-technician-time-selection__dropdown-wrapper -->

</div>

<app-omni-date-picker-modal
  [showModal]="showCalendar"
  [selectedDate]="selectedCalendarDate"
  (modalClosed)="handleCalendarClosed()"
  (dateSelected)="handleDateSelection($event)"
></app-omni-date-picker-modal>