import { Observable } from 'rxjs';
import { OmniGetAccountDiagnosticsResult, OmniGetBillingDetailsResult } from './omni-diagnostics.interface';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CXS_API_URLS } from 'src/app/common/constants/omni-global.constant';
import { OmniSessionService } from 'src/app/services/omni-session.service';

@Injectable({ providedIn: 'root' })
export class OmniDiagnosticsService {

  constructor(
    private http: HttpClient,
    private sessionService: OmniSessionService
  ) {}

  getBillingDiagnosis(accountNumber: string, brand: string, segment: string): Observable<OmniGetBillingDetailsResult> {
    const url = CXS_API_URLS.BILLING_DIAGNOSIS
      .replace("{1}", accountNumber)
      .replace("{2}", brand)
      .replace("{3}", segment);
    return this.http.get(url);
  }

  getAccountDiagnosis(accountNumber: string, brand: string, segment: string): Observable<OmniGetAccountDiagnosticsResult> {
    const url = CXS_API_URLS.ACCOUNT_DIAGNOSIS
    .replace("{1}", accountNumber)
    .replace("{2}", brand)
    .replace("{3}", segment);
    return this.http.get(url);
  }

}