import { Component } from '@angular/core';

@Component({
  selector: 'app-obi-faq',
  templateUrl: './obi-faq.component.html',
  styleUrls: ['./obi-faq.component.scss']
})
export class ObiFaqComponent {

}
