import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { OBI_X_ICON } from '../../../../app/common/constants/obi-bill-charges.constants';
import {
  OMNI_TRACT_REQUEST_ICON,
  OMNI_WARNING_ICON,
  OMNI_RESCHEDULE_VALUES,
  OBI_ADDITIONAL_ROW,
  OBI_WARNING_MESSAGE,
  VIEW_STATUS_LABELS,
  IS_RESCHEDULE_REQUEST_ENABLE,
} from '../../../../app/common/constants/omni-view-status.constant';
import {
  PAGE_URLS,
  SESSION_KEYS,
} from 'src/app/common/constants/omni-global.constant';
import { OMNI_SCHED_TECHNICIAN_INIT_VALUES } from '../../../common/constants/omni-sched-technician.constant';

import { OmniCommonService } from 'src/app/services/omni-common.services';
import { OmniSessionService } from 'src/app/services/omni-session.service';
import { OmniScheduleObject } from 'src/app/interfaces/omni-sched-technician.interface';
import { OmniSchedTechnicianService } from '../../../services/omni-sched-technician.service';
import * as moment from 'moment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

const { API_CHANNEL, API_TARGET_TYPE } = OMNI_SCHED_TECHNICIAN_INIT_VALUES;
const {
  BILLER_DETAILS_LABEL,
  INQUIRY_DETAILS_LABEL,
  REFERENCE_NUMBER_LABEL,
  RESOLUTION_LABEL,
  REMINDER_LABEL,
  WORK_ORDER_DETAILS_LABEL,
  REPAIR_DETAILS_LABEL,
  WORK_ORDER_NUMBER_LABEL,
} = VIEW_STATUS_LABELS;
@Component({
  selector: 'app-omni-view-status',
  templateUrl: './omni-view-status.component.html',
  styleUrls: ['./omni-view-status.component.scss'],
})
export class OmniViewStatusComponent implements OnChanges {
  public loading: boolean = false;

  public closeIcon: any = OBI_X_ICON;

  public warningIcon: any = OMNI_WARNING_ICON;

  public requestIcon: any = OMNI_TRACT_REQUEST_ICON;

  public rescheduleRequest: any = OMNI_RESCHEDULE_VALUES;

  public additionalRow: any = [];

  public obiWarningMessages: any = OBI_WARNING_MESSAGE;

  public unpostedPaymentKeys: any = [
    'billLiner',
    'amountPaid',
    'channel',
    'dateAndTimeOfPayment',
    'referenceNumber',
    'alternativeNumber',
  ];

  public otherKeys: any = [
    'billLiner',
    'contestedAmount',
    'alternativeNumber',
    'concern',
  ];

  public resolution: any =
    "Customer's fiber cable has been replaced. Can now connect to internet.";

  public reminder: any =
    'We will wait for your confirmation in 12 hours and automatically close your work order if we find your issue did not persist.';

  public workOrderData: any = {};

  /**
   * Object that handles the description or title of every cards
   */
  public title: any = {
    itemDetails: '',
    inquiryDetails: '',
    numberType: '',
  };

  public dateFormat: string = 'MM/DD/YYYY';

  public timeFormat: string = 'hh:mm:ss A';

  /**
   * Flag to display or hide the modal
   */
  @Input() modalOpen?: boolean = false;

  /**
   * Event to close the modal
   */
  @Output() modalClose: EventEmitter<any> = new EventEmitter();

  @Input() workOrder: any = {};

  public reschedCount: number = 0;

  public workDetailNotes: any = {};

  public showRepairDetails: boolean = true;

  public isBillInquiry: boolean = false;

  public warningMessage!: SafeHtml;

  today: moment.Moment = moment();
  private notesRaw: string = '';

  public isSchedDateMoreThanOneDay: boolean = true;
  public isRescheduleRequestEnable: boolean = IS_RESCHEDULE_REQUEST_ENABLE;

  constructor(
    public commonService: OmniCommonService,
    private sessionService: OmniSessionService,
    private omniSchedTechnicianService: OmniSchedTechnicianService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const workOrderDetails = changes['workOrder'];
    if (
      this.modalOpen &&
      workOrderDetails &&
      !workOrderDetails.firstChange &&
      workOrderDetails.currentValue
    ) {
      this.populatePage(workOrderDetails.currentValue);
    }
  }

  populatePage(data: any) {
    // check if trigger is from OBI or 24Hr
    if (data.hasOwnProperty('accountName')) {
      this.showBillInquiry(data);
    } else {
      this.showWorkOrder(data);
    }
  }
  showBillInquiry(data: any) {
    const filterKeys =
      data.billLiner === 'Unposted Payment'
        ? this.unpostedPaymentKeys
        : this.otherKeys;
    this.additionalRow = OBI_ADDITIONAL_ROW.filter((row: any) =>
      filterKeys.includes(row.key)
    );

    this.isBillInquiry = true;
    this.workOrderData = this.formatBillDetails(data);
    this.title.itemDetails = BILLER_DETAILS_LABEL;
    this.title.inquiryDetails = INQUIRY_DETAILS_LABEL;
    this.title.numberType = REFERENCE_NUMBER_LABEL;
    const warningMessageValue = this.findWarningMessage(data.status);
    if (
      warningMessageValue == undefined ||
      warningMessageValue == null ||
      warningMessageValue == ''
    ) {
      this.warningMessage = '';
    } else {
      this.warningMessage = this.sanitizeHtml(warningMessageValue);
    }
  }

  showWorkOrder(data: any) {
    const { notes, rescheduleCount } = data;
    this.notesRaw = notes;
    let parts: string[] = [];
    if (notes) {
      parts = notes.split('|').map((part: any) => part.trim());
    }

    const result = parts.reduce((acc: any, part: any) => {
      const [key, value] = part.split(':').map((item: any) => item.trim());
      acc[key.toLowerCase()] = value;
      return acc;
    }, {});

    if (data.displayStatus === 'Repair Completed') {
      this.title.itemDetails = RESOLUTION_LABEL;
      this.title.inquiryDetails = REMINDER_LABEL;
    } else {
      this.title.itemDetails = WORK_ORDER_DETAILS_LABEL;
      this.title.inquiryDetails = REPAIR_DETAILS_LABEL;
    }

    this.title.numberType = WORK_ORDER_NUMBER_LABEL;
    this.reschedCount = rescheduleCount | 0;
    this.workDetailNotes = result;
    if (!this.workDetailNotes.concern || !this.workDetailNotes.description) {
      this.showRepairDetails = false;
    }
    this.workOrderData = data;
    this.checkIsSchedDateMoreThanOneDay(data);
  }

  findWarningMessage(status: string): string {
    return this.obiWarningMessages.find(
      (warningMsg: any) => warningMsg.status === status
    )?.message;
  }

  formatBillDetails(bill: any): any {
    let billDetails = bill;
    const [date, time] = bill.dateSubmitted.split(/\s+/);
    billDetails.contestedAmount = this.commonService.formatAmount(
      billDetails.contestedAmount
    );
    billDetails.billDisputedMonth = billDetails.billDisputedMonth.map(
      (month: string) => {
        const [startDate, endDate] = month.split('-');
        const formattedStartDate = moment(startDate, this.dateFormat).format(
          this.dateFormat
        );
        const formattedEndDate = moment(endDate, this.dateFormat).format(
          this.dateFormat
        );
        return `${formattedStartDate} - ${formattedEndDate}`;
      }
    );

    billDetails.dateAndTimeOfPayment = moment(
      billDetails.paymentDate + ' ' + billDetails.paymentTime,
      'MM/DD/YYYY h:mm A'
    ).format(`${this.dateFormat} hh:mm A`);
    billDetails.dateSubmitted = moment(
      date + ' ' + time,
      `${this.dateFormat} ${this.timeFormat}`
    ).format(`${this.dateFormat} ${this.timeFormat}`);
    billDetails.alternativeNumber = this.commonService.formatMobileNumber(
      billDetails.alternativeNumber
    );
    return billDetails;
  }

  toggleModal() {
    this.modalOpen = !this.modalOpen;
    this.modalClose.emit(this.modalOpen);
  }

  rescheduleVisit() {
    this.loading = true;
    //Call ConfirmAppointmentBooking
    //OM24H-218:
    const accountId = this.sessionService.getData(
      SESSION_KEYS.OMNI_ACCOUNT_NUMBER_KEY
    );
    const schedData: OmniScheduleObject = {
      accountId: accountId,
      orderActionId: this.workOrderData.id,
      orderId: `${this.workOrderData.id}-1`,
      preferredAppointmentSlot: {
        date: this.today.clone().subtract(1, 'days').format('YYYY-MM-DD'),
        slot: this.today.format('A'),
      },
      channel: API_CHANNEL,
      targetType: API_TARGET_TYPE,
      notes: this.notesRaw,
      accountNumber: accountId,
    };

    this.omniSchedTechnicianService
      .postAppointmentBooking(schedData)
      .subscribe({
        next: (response: any) => {
          this.commonService.navigate(PAGE_URLS.GENERIC_ERROR_PAGE_URL);
        },
        error: error => {
          const errorData = error?.error;
          if (errorData?.code == 409) {
            this.sessionService.setData(
              SESSION_KEYS.OMNI_RESCHED_APPOINMENTSLOTS_KEY,
              errorData?.appointmentSlots
            );

            this.sessionService.setData(
              SESSION_KEYS.OMNI_RESCHED_ORDER_ACTION_ID_KEY,
              this.workOrderData.id
            );
            this.sessionService.setData(
              SESSION_KEYS.OMNI_RESCHED_NOTES_KEY,
              this.notesRaw
            );
            this.sessionService.setData(
              SESSION_KEYS.OMNI_CURRENT_SCHED_DATE,
              this.workOrderData.appointmentDate
            );
            this.commonService.navigate(PAGE_URLS.RESCHEDULE_REQUEST);
            this.loading = false;
            this.modalOpen = false;
          } else {
            this.commonService.handleAPIError(error);
          }
        },
      });
  }

  completeRequest() {}

  incompleteRequest() {}

  /**
   * Check if current technician visit schedule is more than a day
   * @param appointmentDateStr
   */
  checkIsSchedDateMoreThanOneDay(data: any) {
    const { appointmentDate, appointmentTime } = data;
    let currentDate = moment();

    if (currentDate.hours() < 12) {
      currentDate = currentDate.hours(0).minutes(0).seconds(0).milliseconds(0); // Set to 12 AM
    } else {
      currentDate = currentDate.hours(12).minutes(0).seconds(0).milliseconds(0); // Set to 12 PM
    }

    const appointmentDateWithTime = appointmentDate.includes(':')
      ? appointmentDate
      : `${appointmentDate} 00:00:00 ${appointmentTime}`;
    const appointmentDateValue = moment(
      appointmentDateWithTime,
      'YYYY-MM-DD hh:mm:ss A'
    );
    this.isSchedDateMoreThanOneDay =
      appointmentDateValue.diff(currentDate, 'hours') > 24;
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
