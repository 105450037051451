import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'omni-dialog-modal',
  templateUrl: './omni-dialog-modal.component.html',
  styleUrls: ['./omni-dialog-modal.component.scss']
})
export class OmniDialogModalComponent {

  /**
   * Image path of icon
   */
  @Input() icon?: string = "";

  /**
   * HTML Text displayed in the Title section of the modal
   */
  @Input() title?: string = "";

  /**
   * HTML Text displayed in the Description section of the modal
   */
  @Input() description?: string = "";

  /**
   * HTML Text displayed in the confirmation button of the modal
   */
  @Input() confirmButtonLabel?: string = "";

  /**
   * HTML Text displayed in the cancel button of the modal
   */
  @Input() cancelButtonLabel?: string = "";

  /**
   * Function ran when confirmation button is clicked
   */
  @Input() confirmButtonAction?: Function = () => {};

  /**
   * Function ran when cancel button is clicked
   */
  @Input() cancelButtonAction?: Function = () => {};

  /**
   * Styling the confirmation button of the modal will follow
   */
  @Input() confirmButtonStyle?: string = "";

  /**
   * Styling the cancel button of the modal will follow
   */
  @Input() cancelButtonStyle?: string = "";

  /**
   * Function ran when cancel button is clicked
   */
  @Input() show?: boolean = false;

  constructor(
    private router: Router,
  ) { }

}
