import { Component, OnInit } from '@angular/core';
import { ObiTrackMyRequestBillInquiry } from './obi-track-my-request.interface';
import { OBI_TRACK_MY_REQUEST_BROADBAND_TAG, OBI_TRACK_MY_REQUEST_DEFAULT_ATTRIBUTES, OBI_TRACK_MY_REQUEST_DEFAULT_SOURCE, OBI_TRACK_MY_REQUEST_MOBILE_TAG, OBI_TRACK_MY_REQUEST_TAB_STATUS, OBI_TRACK_MY_REQUEST_TAB_VALUES, IS_VIEW_REQUEST_STATUS_ENABLE } from 'src/app/common/constants/obi-track-my-request.constants';
import { OmniCommonService } from 'src/app/services/omni-common.services';
import { OmniSessionService } from 'src/app/services/omni-session.service';
import { ObiTrackMyRequestService } from './obi-track-my-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PAGE_URLS, SESSION_KEYS } from 'src/app/common/constants/obi-global.constants';
import { OmniTrackMyRepairService } from '../../omnicare/omni-track-my-repair/omni-track-my-repair.service';
import { APPOINTMENT_DATE_FORMAT, APPOINTMENT_DATE_MAPPING, OMNI_TRACK_STATUS } from 'src/app/common/constants/omni-track-my-repair.constant';
import * as moment from 'moment';
import { OmniTrackMyRepairDateMapping, OmniTrackMyRepairWorkOrder } from '../../omnicare/omni-track-my-repair/omni-track-my-repair.interface';

const { HISTORY_TAB_KEY, PROGRESS_TAB_KEY } = OBI_TRACK_MY_REQUEST_TAB_VALUES;

@Component({
  selector: 'app-obi-track-my-request',
  templateUrl: './obi-track-my-request.component.html',
  styleUrls: ['./obi-track-my-request.component.scss']
})
export class ObiTrackMyRequestComponent implements OnInit {

  private billInquiryListResponse: ObiTrackMyRequestBillInquiry[] = [];

  public isViewBill: boolean = false;

  public billInquiryDetails: any = {};

  /**
   * List of bill inquiries for the current account
   */
  public billInquiryList: any = {
    progress: [],
    history: []
  };

  public hasResponseLoaded = false;

  /**
   * Page Modal loading variable
   */
  public loading: boolean = true;

  /**
   * NavTab variable
   */
  public progressKey: string = PROGRESS_TAB_KEY;
  public historyKey: string = HISTORY_TAB_KEY;
  public activeTab: string = PROGRESS_TAB_KEY;

  /**
   * Status mapping for each NavTab
   */
  public obiTrackMyRepairTabStatus: any = OBI_TRACK_MY_REQUEST_TAB_STATUS;

  /**
   * Enable view request status
   */
  public isViewRequestStatusEnable: boolean = IS_VIEW_REQUEST_STATUS_ENABLE;

  constructor(
    public commonService: OmniCommonService,
    private sessionService: OmniSessionService,
    private obiTrackMyRequestService: ObiTrackMyRequestService,
    private omniTrackMyRepairService: OmniTrackMyRepairService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.retrieveParams (() =>{
      const customerId = this.sessionService.getData(SESSION_KEYS.OBI_CUSTOMER_ID);
      if(customerId){
        this.getBillCaseList(() => {
          this.billInquiryListResponse.forEach((billInquiry) => {
            if (this.billInquiryList[this.getStatusMapping(billInquiry.status)]) {
              billInquiry.displayCreationTime = moment(billInquiry.creationTime).utc().add(8, 'hours').format('MMMM DD, YYYY | hh:mm A [PST]');
              this.billInquiryList[this.getStatusMapping(billInquiry.status)].push(billInquiry);
            }
          });
          if (
            this.hasResponseLoaded ||
            this.sessionService.getData(SESSION_KEYS.OBI_SEGMENT_KEY) !== OBI_TRACK_MY_REQUEST_BROADBAND_TAG
          ) {
            this.sortLists();
            this.loading = false;
          }
          this.hasResponseLoaded = true;
        });
      }else{
        this.hasResponseLoaded = true;
      }

      if (this.sessionService.getData(SESSION_KEYS.OBI_SEGMENT_KEY) === OBI_TRACK_MY_REQUEST_BROADBAND_TAG) {
        this.getWorkOrderList();
      }
    });
  }

  /**
   * Parses encrypted data and validates their values
   * @param postFunction
   */
  private retrieveParams(postFunction: Function) {
    this.route.queryParams.subscribe((params) => {
      const encryptedData = decodeURIComponent(params['data']);
      if (!encryptedData) {
        this.commonService.navigate(PAGE_URLS.GENERIC_ERROR_PAGE_URL);
      }
      this.commonService.validateInitParams(encryptedData, () => {
        postFunction();
      }, true);
    });
  }

  /**
   * Retrieve track my request list via API call
   */
  getBillCaseList(postGetFunc: Function) {
    const customerId = this.sessionService.getData(SESSION_KEYS.OBI_CUSTOMER_ID);
    const queryParams: any = {
      customerId: customerId
    };
    if (this.sessionService.getData(SESSION_KEYS.OBI_SEGMENT_KEY) === OBI_TRACK_MY_REQUEST_BROADBAND_TAG) {
      queryParams['accountNumber'] = this.sessionService.getData(SESSION_KEYS.OBI_ACCOUNT_NUMBER_KEY);
    } else {
      queryParams['mobileNumber'] = this.sessionService.getData(SESSION_KEYS.OBI_MOBILE_NUMBER);
    }
    this.obiTrackMyRequestService.getBillCaseList(queryParams)
      .subscribe({
        next: (response) => {
          if (response?.result?.tickets) {
            this.billInquiryListResponse = response.result.tickets;
            postGetFunc();
          }
        },
        error: (error) => {
          if (error?.error?.code !== '40014') {
            this.router.navigate([PAGE_URLS.GENERIC_ERROR_PAGE_URL]);
          }
          postGetFunc();
        }
      });
  }

  /**
   * Retrieve work order details list
   */
  getWorkOrderList() {
    this.omniTrackMyRepairService
    .getWorkOrderDetails(this.sessionService.getData(SESSION_KEYS.OBI_ACCOUNT_NUMBER_KEY))
    .subscribe({
      next: res => {
        if (
          res &&
          res.result &&
          res.result?.GBPWorkOrder
        ) {
          /**
           * OM24H-198: Track my Request - History
           */
          const workOrderList = res.result.GBPWorkOrder;

          workOrderList.forEach(workOrder => {
            const {
              status,
              subStatus,
              appointmentDate,
              appointmentTime
            } = workOrder;

            workOrder.displayStatus = this.mapWorkOrderStatus(status || '', subStatus);
            workOrder.displayAppointmentDate = this.mapAppointmentDate(`${appointmentDate} ${appointmentTime}`);
            const appointmentDateWithTime = appointmentDate.includes(':') ? appointmentDate : `${appointmentDate} 00:00:00`;
            workOrder.displayAppointmentDate = this.mapAppointmentDate(`${appointmentDateWithTime} ${appointmentTime}`);
            workOrder.sortDateTime = this.parseDateTime(workOrder.displayAppointmentDate);

            const workOrderDisplayStatus = workOrder.displayStatus.toUpperCase();
            switch (workOrderDisplayStatus) {
              case 'OPEN':
                this.router.navigate([PAGE_URLS.GENERIC_ERROR_PAGE_URL]);
                break;
              case 'REPAIR CLOSED':
                this.billInquiryList['history'].push(workOrder);
                break;

              case 'CANCELLED':
              case '':
                // excluded in the list
                break;
              default:
                this.billInquiryList['progress'].push(workOrder);
                break;
            }
          });
          if (this.hasResponseLoaded) {
            this.sortLists();
            this.loading = false;
          }
          this.hasResponseLoaded = true;
        }
      },
      error: error => {
        this.commonService.handleAPIError(error);
        this.loading = false;
      },
    });
  }

  /**
   * Sort both recent and history tab list
   */
  sortLists() {
    this.billInquiryList['history'].sort((prev: any, next: any) => {
      let prevDate = moment(prev.sortDateTime || prev.lastUpdated);
      let nextDate = moment(next.sortDateTime || next.lastUpdated);
      return nextDate.diff(prevDate);
    });
    this.billInquiryList['progress'].sort((prev: any, next: any) => {
      let prevDate = moment(prev.sortDateTime || prev.creationTime);
      let nextDate = moment(next.sortDateTime || next.creationTime);
      return nextDate.diff(prevDate);
    });
  }

  /**
   * Parses the passed string to utc format
   */
  parseDateTime(dateTime: string) {
    let result;
    if (dateTime) {
      if (dateTime.includes('T')) {
        result = moment(dateTime);
      } else {
        const dateStrArr = dateTime.split(',');
        const dateStr = `${dateStrArr[0]},${dateStrArr[1]} ${dateStrArr[2].split('-')[0].trim()}`;
        result = moment(dateStr, 'MMMM DD, YYYY h:mm:ss a');
      }
    }
    return result?.toISOString();
  }

  /**
   * Retrieves the TabNav based on the status
   * @param status
   * @returns
   */
  getStatusMapping(status: string) {
    let result = '';
    Object.keys(this.obiTrackMyRepairTabStatus).forEach(key => {
      if (this.obiTrackMyRepairTabStatus[key].some((item: any) => item.status.includes(status.toUpperCase()))) {
        result = key;
      }
    });
    return result;
  }

  /**
   * Show ui status based on api response status
   * @param status
   * @returns
   */
  getDisplayStatus(status: string) {
    let result = '';
    Object.keys(this.obiTrackMyRepairTabStatus).forEach(key => {
      this.obiTrackMyRepairTabStatus[key].forEach((item: any) => {
        if (item.status.includes(status.toUpperCase())) {
          result = item.title;
        }
      });
    });
    return result;
  }

  /**
   * Maps the work order status from response to defined display text
   * @param status
   * @returns
   */
  mapWorkOrderStatus(status: string, subStatus?: string): string {
    let result = '';

    const matchingEntry = OMNI_TRACK_STATUS.find(entry => {
      return (
        entry.status === status &&
        (subStatus === undefined || entry.subStatus.includes(subStatus))
      );
    });

    if (matchingEntry) {
      result = matchingEntry.title;
    }

    return result;
  }

  /**
   * Maps the custom appointment date from response to defined display text
   * @param date
   * @returns
   */
  mapAppointmentDate(date: string) {
    let result = date;
    const appointmentDate = moment(date, APPOINTMENT_DATE_FORMAT);
    const appointmentDateMapping = APPOINTMENT_DATE_MAPPING.filter(
      (schedule: OmniTrackMyRepairDateMapping) =>
        schedule.flag === appointmentDate.format('A')
    );
    if (appointmentDateMapping?.length) {
      result = `${appointmentDate.format('MMMM DD, YYYY')}, ${
        appointmentDateMapping[0].schedule
      }`;
    }
    return result;
  }

  /**
   * Changes the currently display tab list
   * @param event
   * @param clickTab
   */
  changeActiveNav(clickTab: string) {
    this.activeTab = clickTab;
  }

  /**
   * Displays bill inquiry details upon click
   * @param bill
   */
  onSelectedBill(bill: ObiTrackMyRequestBillInquiry) {
    const accountAlias = this.sessionService.getData(SESSION_KEYS.OBI_ACCOUNT_ALIAS);
    const accountNumber = this.sessionService.getData(SESSION_KEYS.OBI_ACCOUNT_NUMBER_KEY);
    const mobileNumber = this.sessionService.getData(SESSION_KEYS.OBI_MOBILE_NUMBER);
    const creationTime = bill?.creationTime ? new Date(bill?.creationTime) : null;
    const disputedMonth = bill.disputedMonth?.split(', ');
    const obiBillDisputes: any = {
      id: bill.caseId, // reference number to display. Ref: OBI-222
      accountName: accountAlias,
      accountNumber: accountNumber ?? mobileNumber.replace(/(\d{4})(\d{3})(\d{4})/, '$1 $2 $3'),
      dateSubmitted: `${creationTime?.toLocaleDateString()} ${creationTime?.toLocaleTimeString()}`,
      billDisputedMonth: disputedMonth,
      billLiner: bill.billLiner,
      contestedAmount: bill.contestedAmount,
      alternativeNumber: bill.alternativeMobileNumber,
      concern: bill.concern,
      status: this.getDisplayStatus(bill.status),

      //for billLiner = Unposted Payment Ref: OBI-222
      amountPaid: bill.amountPaid,
      channel: bill.channel,
      paymentDate: bill.paymentDate,
      paymentTime: bill.paymentTime,
      referenceNumber: bill.referenceNumber,
    }

    this.toggleIsViewBill();
    this.billInquiryDetails = obiBillDisputes;
  }

  /**
   * Display work order details upon clicking work order card
   * @param workOrder
   */
  onSelectedWorkOrder(workOrder: OmniTrackMyRepairWorkOrder | {}) {
    this.isViewBill = !this.isViewBill;
    this.billInquiryDetails = workOrder;
  }

  /**
   * Shows/Hides individual card details
   */
  toggleIsViewBill() {
    this.isViewBill = !this.isViewBill;
  }
}
