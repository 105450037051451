<div class="omni-sched-technician">
  <div class="omni-sched-technician__concern">
    <p
      class="omni-sched-technician__concern-notice"
      [innerHTML]="omniSchedTechConcernNotice"
    ></p>

    <label
      class="omni-sched-technician__concern-label"
      [innerHTML]="omniScheTechConcernLabel"
    ></label>
    <input
      class="omni-sched-technician__concern-input-text"
      type="text"
      [disabled]="true"
      [(ngModel)]="concernSubject"
    />

    <label class="omni-sched-technician__concern-label">
      {{ omniScheTechConcernDetailsLabel
      }}<span class="omni-sched-technician__concern-label required">*</span>
    </label>
    <div class="omni-sched-technician__concern-txtarea-wrapper">
      <textarea
        class="omni-sched-technician__concern-txtarea"
        #concernDetailstxtareaRef
        [(ngModel)]="concernDetails"
        (input)="txtAreaInput($event)"
        (keydown)="txtAreaKeydown($event)"
        (paste)="txtAreaPaste($event)"
        [placeholder]="txtAreaPlaceHolderValue"
        appOmniAlphanumericSpecialChars
      ></textarea>

      <div class="omni-sched-technician__concern-txtarea-charcounter">
        {{ txtAreaCharCount }}/{{ txtAreaMaxLength }}
      </div>
    </div>
  </div>

  <div class="omni-sched-technician__btn-wrapper">
    <button
      class="omni-sched-technician__btn-success"
      [innerHTML]="btnNextLabel"
      [disabled]="concernDetails.length == 0"
      (click)="gotoSchedTechnician()"
    ></button>

    <button
      class="omni-sched-technician__btn-cancel"
      [innerHTML]="btnCancelLabel"
      (click)="showConfirmationModal()"
    ></button>
  </div>
</div>

<app-omni-loading-modal [showModal]="loading" [message]="''"></app-omni-loading-modal>
<omni-dialog-modal
  [title]="confirmationDialogTitle"
  [confirmButtonLabel]="confirmButtonLabel"
  [cancelButtonLabel]="cancelButtonLabel"
  [show]="confirmation"
  [confirmButtonAction]="handleConfirmation()"
  [cancelButtonAction]="handleConfirmationCancel()"
></omni-dialog-modal>
