import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { OMNI_BASIC_TS_INIT_VALUES } from '../../../common/constants/omni-basic-troubleshooting.constant';
import {
  PAGE_URLS,
  REDIRECT_SCHED_TECHNICIAN_FLOW,
  SESSION_KEYS,
} from '../../../common/constants/omni-global.constant';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'src/app/services/google-tag-manager-service.service';
import { OmniCommonService } from "src/app/services/omni-common.services";

const {
  OMNI_BASIC_TS_CONTENT_TITLE,
  OMNI_BASIC_TS_LIST,
  BTN_CONFIRMTXT,
  BTN_YES_LABEL,
  BTN_NO_LABEL,
} = OMNI_BASIC_TS_INIT_VALUES;

const {
  WELCOME_BACK_PAGE_URL,
  SCHEDULE_TECHNICIAN_CONCERN_URL,
  TROUBLESHOOTING_FAILED_URL,
} = PAGE_URLS;

import {
  OMNI_BASIC_TROUBLESHOOTING_CONFIRM_TAG,
  OMNI_BASIC_TROUBLESHOOTING_CANCEL_TAG,
} from 'src/app/common/constants/omni-gtm-events.constants';

import { OmniSessionService } from '../../../services/omni-session.service';

@Component({
  selector: 'app-omni-basic-troubleshooting',
  templateUrl: './omni-basic-troubleshooting.component.html',
  styleUrls: ['./omni-basic-troubleshooting.component.scss'],
})
export class OmniBasicTroubleshootingComponent implements AfterViewInit {
  public omniBasicTSContentTitle: string = OMNI_BASIC_TS_CONTENT_TITLE;
  public tsSteps: any = OMNI_BASIC_TS_LIST;
  public confirmFixTxt: string = BTN_CONFIRMTXT;
  public btnYesLabel: string = BTN_YES_LABEL;
  public btnNoLabel: string = BTN_NO_LABEL;

  public welcomeBackPage: string = `${WELCOME_BACK_PAGE_URL}?flag=welcome`;
  public scheduleTechnicianPageConcern: string =
    SCHEDULE_TECHNICIAN_CONCERN_URL;

  /**
   * Flag if the content of the component is fully displayed in the viewport
   */
  public isFullyDisplayed = true;

  userData: any = {};

  @ViewChild('footerDiv') footerDiv: any;

  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private omniSessionService: OmniSessionService,
    public commonService: OmniCommonService,
  ) {}

  ngOnInit(): void {
    /**
     * OM24H-623: Temporary re-direct Failed troubleshooting page.
     */
    if (!REDIRECT_SCHED_TECHNICIAN_FLOW) {
      this.scheduleTechnicianPageConcern = PAGE_URLS.TROUBLESHOOTING_FAILED_URL;
    }

    //Get user data in the session service
    this.userData = this.omniSessionService.getUserData() || {};
  }

  ngAfterViewInit(): void {
    const rect = this.footerDiv.nativeElement.getBoundingClientRect();
    const topShown = rect.top >= 0;
    const bottomShown = rect.bottom <= window.innerHeight;
    this.isFullyDisplayed = topShown && bottomShown;
  }

  redirectToWelcomeBackPage(): void {
    this.captureGTMEvent(OMNI_BASIC_TROUBLESHOOTING_CONFIRM_TAG);
    this.router.navigate([WELCOME_BACK_PAGE_URL], {
      queryParams: { flag: 'welcome' },
    });
  }

  redirectToSchedTechnicianPageConcern(): void {
    this.captureGTMEvent(OMNI_BASIC_TROUBLESHOOTING_CANCEL_TAG);
    this.router.navigate([this.scheduleTechnicianPageConcern]);
  }
  /**
   * OM24H-951: Captures Google Tag events and push these tags to the dataLayer
   * @param eventInfo 
   */
  captureGTMEvent(eventInfo: any) {
    const {
      dataAllocation,
      balanceStatus,
      accountStatus,
      outageResult,
      lineStatus,
      modemStatus,
    } = this.userData;

    const additionalData = {
      promo_category: dataAllocation,
      status: balanceStatus,
      account_status: accountStatus,
      outage_status: this.commonService.determineDiagStatusForTagging('outage_status', outageResult),
      fiber_cuts_issue: this.commonService.determineDiagStatusForTagging('fiber_cuts_issue', lineStatus),
      fiber_losses_issue: this.commonService.determineDiagStatusForTagging('fiber_losses_issue', lineStatus),
      modem_line: this.commonService.determineDiagStatusForTagging('modem_line', modemStatus),
      modem_power: this.commonService.determineDiagStatusForTagging('modem_power', modemStatus),
    };

    this.gtmService.captureGTMEvent(eventInfo, false, additionalData);
  }
}
