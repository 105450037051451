import {
  OBI_X_ICON,
  OBI_BILL_CHARGES_INFO,
} from '../../../../app/common/constants/obi-bill-charges.constants';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-obi-bill-charges',
  templateUrl: './obi-bill-charges.component.html',
  styleUrls: ['./obi-bill-charges.component.scss'],
})
export class ObiBillChargesComponent {
  /**
   * Flag to display or hide the modal
   */
  @Input() open?: boolean = false;

  /**
   * Handle emitting new modal display status
   */
  @Output() handleClose: EventEmitter<any> = new EventEmitter();

  /**
   * Reused close modal icon
   */
  readonly closeIcon = OBI_X_ICON;

  /**
   * List of bill charges info objects
   */
  readonly billChargesInfo = OBI_BILL_CHARGES_INFO;

  toggleModal() {
    this.open = !this.open;
    this.handleClose.emit(this.open);
  }

  /**
   * HTML DOM Sanitizer
   * @param textVal
   * @returns
   */
  sanitizeText(textVal: string) {
    return textVal.replace(/\n/g, '<br>');
  }
}
