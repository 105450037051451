export const OMNI_TRACT_REQUEST_ICON = '../../../../assets/icons/omni-track-request.png';
export const OMNI_WARNING_ICON = '../../../../assets/icons/omni-warning-icon.png';

export const OMNI_RESCHEDULE_VALUES = Object.freeze({
    title: 'Not a good time for a technician visit?',
    description: 'You can reschedule your technician visit.',
    action: 'Reschedule my technician visit'
})

export const OBI_ADDITIONAL_ROW = [
    { title: 'Bill liner', key: 'billLiner' },
    { title: 'Amount paid', key: 'amountPaid' },
    { title: 'Payment channel', key: 'channel' },
    { title: 'Date and time of payment', key: 'dateAndTimeOfPayment' }, // paymentDate + paymentTime
    { title: 'Reference number', key: 'referenceNumber' },
    { title: 'Contested amount', key: 'contestedAmount' },
    { title: 'Alternative number', key: 'alternativeNumber' },
    { title: 'Concern', key: 'concern' }
]

export const OBI_WARNING_MESSAGE = [
    {
        status: 'Unreachable',
        message: 'We attempted to contact you but were unable to do so. Please expect another call from us soon.'
    },
    {
        status: 'Pending Requirements',
        message: 'We need additional requirements to proceed. To know the requirements and where to submit them, click <a href="https://www.globe.com.ph/help/postpaid/service-request-requirements">here</a>.'
    },
    {
        status: 'Escalated',
        message: 'We understand the importance of addressing your concerns, and we are committed to finding a solution as quickly as possible. We appreciate your patience as we work diligently to resolve the matter.'
    },
    {
        status: 'Cancelled',
        message: 'We made several attempts to contact you but we\'re unable to do so. Kindly submit another request if you require any more assistance with this issue.'
    }
]

export const VIEW_STATUS_LABELS = {
    BILLER_DETAILS_LABEL: 'Biller details',
    INQUIRY_DETAILS_LABEL: 'Inquiry details',
    REFERENCE_NUMBER_LABEL: 'REFERENCE NUMBER',

    RESOLUTION_LABEL: 'Resolution',
    REMINDER_LABEL: 'Reminder',

    WORK_ORDER_DETAILS_LABEL: 'Work order details',
    REPAIR_DETAILS_LABEL: 'Repair details',
    WORK_ORDER_NUMBER_LABEL: 'WORK ORDER NUMBER'
}
/**
 * Set this to true to enable reschedule request
 */
export const IS_RESCHEDULE_REQUEST_ENABLE = true;
