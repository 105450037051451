export const OMNI_RESCHEDULE_REQUEST_CONCERN_MAX = 100;
export const OMNI_RESCHEDULE_REQUEST_CONCERN_MIN_HEIGHT = 80;

export const OMNI_RESCHEDULE_REQUEST_LABEL = {
  reason: 'Reason for rescheduling. <span class="required">*</span>',
  date: 'Choose your new preferred date of technician visit',
  time: 'Choose your new preferred time of technician visit',
  concern: 'Tell us more about your concern <span class="required">*</span>',
};

export const OMNI_RESCHEDULE_REQUEST_CANCEL = {
  title: 'Are you sure you want to cancel?',
  cancelButtonUrl: '/omnicare/navigate?destination=dashboard',
  confirmButtonLabel: 'No',
  cancelButtonLabel: 'Yes',
};

export const OMNI_RESCHEDULE_REQUEST_SUCCESS = {
  title: 'You’ve successfully rescheduled your technician visit!',
  description: 'You can track the progress of your technician request anytime.',
  cancelButtonUrl: '/omnicare/navigate?destination=dashboard',
  confirmButtonLabel: 'Track my repair status',
  cancelButtonLabel: 'Go back to dashboard',
};

export const OMNI_RESCHEDULE_REQUEST_ISSUE_TYPES = [
  'With appointment conflicts',
  'No available representative',
  'Building/property permit not yet ready',
  'Inside wiring not yet ready'
];

export const OMNI_REQUEST_TIME = ['9:00 AM - 12:00 NN', '1:00 PM - 5:00 PM'];

export const OMNI_UPDATE_TICKET_DETAILS_REQ_RESCHED = {
  ticketStatus: 'Solving',
  sourceSystem: '24HRRepair',
  note: 'Rescheduled by customer | New date and time:',
}