export const OBI_X_ICON = '../../../../assets/icons/obi-close-icon.png';
export const OBI_BILL_CHARGES_INFO = Object.freeze([
  {
    title: 'HELP',
    charges: [
      {
        title: 'Monthly Recurring Fee',
        text: 'Includes: Pro-rated Charges. Advanced One-Month Subscription fee (OMF), Monthly Plan, Add-ons.',
      },
      {
        title: 'Excess Usage',
        text: 'Includes local and international: Mobile Data, Calls, Texts, Roaming and Value Added Services (VAS)\n\nVAS charges includes: Mobile & Web applications, Content Subscription/Netflix, HBO Go, Google, Play Store apps/In-app purchases.',
      },
      {
        title: 'One-time Charges',
        text: 'Includes: Pre-termination fee, admin fee.',
      },
      {
        title: 'Gadgets & Digital Connectors',
        text: 'Includes: Devices, Gadgets & accessories.',
      },
      {
        title: 'Bill Adjustments',
        text: 'Includes: Adjustments',
      },
      {
        title: 'Unposted payment',
        text: `A payment you've already made to your account but is yet to reflect in your account balance.`,
      },
    ],
  },
]);
