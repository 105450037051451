import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CXS_API_URLS } from "src/app/common/constants/obi-global.constants";
import { OmniGetBillingDetailsResult } from "../../omnicare/omni-diagnostics/omni-diagnostics.interface";

@Injectable({
  providedIn: "root"
})
export class ObiPreCheckService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Retrieves account details from the CXS API using a provided account number or mobile number.
   * Executes the CXS API - GetAccountDetailsV3.
   * @returns An Observable that emits the account details in the response.
   */
  getAccountDetailsV3(
    accountNumber: string,
    mobileNumber: string,
    landlineNumber: string,
    brand: string,
    segment: string
  ): Observable<any> {
    const url = CXS_API_URLS.ACCOUNT_DETAILS_V3;
    let params = new HttpParams();
    params = params.set('brand', brand);
    params = params.set('segment', segment);
    if (segment === 'broadband') {
      if (accountNumber) {
        params = params.set('accountNumber', accountNumber);
      } else {
        params = params.set('landlineNumber', landlineNumber);
      }
    } else if (segment === 'mobile') {
      params = params.set('mobileNumber', mobileNumber);
    }

    return this.http.get(url, { params });
  }

  /**
   * Retrieves billing details from the CXS API using a provided account number or mobile number.
   * Executes the CXS API - GetBillingDetailsV3.
   * @returns An Observable that emits the account details in the response.
   */
  getBillingDiagnosis(
    accountNumber: string,
    mobileNumber: string,
    landlineNumber: string,
    brand: string,
    segment: string
  ): Observable<OmniGetBillingDetailsResult> {
    const url = CXS_API_URLS.BILLING_DIAGNOSIS;
    let params = new HttpParams();
    params = params.set('brand', brand);
    params = params.set('segment', segment);
    if (segment === 'broadband') {
      if (accountNumber) {
        params = params.set('accountNumber', accountNumber);
      } else {
        params = params.set('landlineNumber', landlineNumber);
      }
    } else if (segment === 'mobile') {
      params = params.set('mobileNumber', mobileNumber);
    }

    return this.http.get(url, { params });
  }

}
