import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { OmniSnackbarComponent } from '../../omnicare/omni-snackbar/omni-snackbar.component';
import html2canvas from 'html2canvas';

import { OBI_TICKET_RECEIPT_INIT_VALUES } from '../../../common/constants/obi-ticket-receipt.constants';
import {
  PAGE_URLS,
  SESSION_KEYS,
} from '../../../common/constants/omni-global.constant';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as moment from 'moment';
import { ObiBillInquiryService } from '../obi-bill-inquiry/obi-bill-inquiry.service';

const {
  OBI_RECEIPT_HEADER,
  DOWNLOAD_ICON,
  RECEIPT_GREET_LABEL,
  RECEIPT_TITLE,
  CHECK_ICON,
  BILLER_STATUS_LABEL,
  COPY_ICON,
  REFERENCE_NUMBER,
  GOTO_DASHBOARD_BTN_LABEL,
  OBI_REQ_CONFIRMED_DIV_ID,
  RECEIPT_PREFIX_FILENAME,
  SNACKBAR_TITLE_COPY,
  SNACKBAR_SUBTEXT_COPY,
  OBI_X_ICON,
} = OBI_TICKET_RECEIPT_INIT_VALUES;

const { GOTO_DASHBOARD_URL } = PAGE_URLS;

@Component({
  selector: 'app-obi-ticket-receipt',
  templateUrl: './obi-ticket-receipt.component.html',
  styleUrls: ['./obi-ticket-receipt.component.scss'],
})
export class ObiTicketReceiptComponent implements OnChanges {
  @ViewChild('referenceNumTxt') referenceNumTxt: ElementRef | undefined;
  @ViewChild(OmniSnackbarComponent, { static: false }) snackbarComponent:
    | OmniSnackbarComponent
    | undefined;

  public closeIcon: any = OBI_X_ICON;

  public receiptHeader: string = OBI_RECEIPT_HEADER;
  public receiptTimestamp: string = moment().utc().add(8, 'hours').format('MMMM DD, YYYY, hh:mm A [PST]');

  public downloadIcon: string = DOWNLOAD_ICON;
  public receiptGreetLabel: string = RECEIPT_GREET_LABEL;
  public receiptTitle: string = RECEIPT_TITLE;
  public checkIcon: string = CHECK_ICON;

  public billStatusLabel: string = BILLER_STATUS_LABEL;
  public billStatus: string = 'Open';

  public copyIcon: string = COPY_ICON;

  public snackBarIsShowSubtext: boolean = true;
  public snackBarDuration: number = 5000;
  public snackBarTitle: string = SNACKBAR_TITLE_COPY;
  public snackBarSubText: string = SNACKBAR_SUBTEXT_COPY;

  public gotoDashboardLabel: string = GOTO_DASHBOARD_BTN_LABEL;
  public goToDashboardURL: string = GOTO_DASHBOARD_URL;

  public billInquiryData: any = {};

  /**
   * Flag to display or hide the modal
   */
  @Input() open?: boolean = false;

  constructor(
    @Inject(DOCUMENT) private dom: any,
    private domSanitizer: DomSanitizer,
    public obiBillInquiryService: ObiBillInquiryService,
  ) {}


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('open')) {
      this.receiptTimestamp = moment().utc().add(8, 'hours').format('MMMM DD, YYYY, hh:mm A [PST]');
    }
  }


  /**
   * Download ticketReceipt (Image file jpg)
   */
  downloadTicketReceipt() {
    const origReqConfirmedDiv = document.getElementById(
      OBI_REQ_CONFIRMED_DIV_ID
    );

    if (origReqConfirmedDiv) {
      //Hide excluded element
      //Excluded element list (Excluded in the Image)
      OBI_TICKET_RECEIPT_INIT_VALUES.EXCLUDED_ELEMENT.forEach(className => {
        const elements = origReqConfirmedDiv.getElementsByClassName(className);
        for (let i = 0; i < elements.length; i++) {
          elements[i].classList.add('hidden'); // Add a 'hidden' class to hide the elements
        }
      });

      //Remove box shadow
      const receipBoxElement = document.querySelectorAll(
        '.obi-confirmed-request__receipt-box'
      );
      receipBoxElement.forEach(element => {
        element.classList.remove('shadow');
      });

      //Convert HTML to Image
      html2canvas(origReqConfirmedDiv).then(canvas => {
        // Convert canvas to image data URL
        const imageDataUrl = canvas.toDataURL(`image/jpg`);

        // Create a link to download the image
        const a = document.createElement('a');
        a.href = imageDataUrl;

        //Downloaded file name
        const imageFileName = `${RECEIPT_PREFIX_FILENAME}${this.getTimestamp()}`;
        a.download = `${imageFileName}.jpg`;

        a.style.display = 'none';

        // Add the link to the document and trigger the download
        document.body.appendChild(a);
        a.click();

        // Clean up
        document.body.removeChild(a);

        //Unhide elements
        const hiddenElements = document.querySelectorAll('.hidden');
        hiddenElements.forEach(element => {
          element.classList.remove('hidden'); // Remove the 'hidden' class to make the elements visible
        });

        //Put back box shadow
        receipBoxElement[0].classList.add('shadow');
      });
    }
  }

  copyReferenceNumTxt() {
    const referenceNumTxtElem = this.dom.createElement('textarea');
    referenceNumTxtElem.style.position = 'fixed';
    referenceNumTxtElem.style.left = '0';
    referenceNumTxtElem.style.top = '0';
    referenceNumTxtElem.style.opacity = '0';
    referenceNumTxtElem.value = this.referenceNumTxt?.nativeElement.innerText;
    this.dom.body.appendChild(referenceNumTxtElem);
    referenceNumTxtElem.focus();
    referenceNumTxtElem.select();
    this.dom.execCommand('copy');
    this.dom.body.removeChild(referenceNumTxtElem);

    this.snackbarComponent?.showSnackBar();
  }

  /**
   * HTML DOM Sanitizer
   * @param concernDetailsVal
   * @returns
   */
  sanitizeConcernDetails(concernDetailsVal: string): SafeHtml {
    const formattedConcernDetails = concernDetailsVal.replace(/\n/g, '<br>');
    return this.domSanitizer.bypassSecurityTrustHtml(formattedConcernDetails);
  }

  /**
   * Format Timestamp
   * @returns
   */
  public getTimestamp() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(now.getFullYear());
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${day}${month}${year}${hours}${minutes}${seconds}`;
  }
}
