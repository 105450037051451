import { OmniDiagnostic } from "../../components/omnicare/omni-diagnostics/omni-diagnostics.interface";
import { REDIRECT_SCHED_TECHNICIAN_FLOW } from "./omni-global.constant";
import {
  OMNI_DIAGNOSTIC_ACCOUNT_FAIL_CONTINUE_TAG,
  OMNI_DIAGNOSTIC_ACCOUNT_FAIL_DASHBOARD_TAG,
  OMNI_DIAGNOSTIC_ACCOUNT_FAIL_OVERDUE_TAG,
  OMNI_DIAGNOSTIC_DISCONNECT_FAIL_DASHBOARD_TAG,
  OMNI_DIAGNOSTIC_DISCONNECT_FAIL_FB_TAG,
  OMNI_DIAGNOSTIC_FIBERMODEM_FAIL_DASHBOARD_TAG,
  OMNI_DIAGNOSTIC_FIBERMODEM_FAIL_FB_TAG,
  OMNI_DIAGNOSTIC_FIBERMODEM_FAIL_SCHEDULE_TAG,
  OMNI_DIAGNOSTIC_MODEM_FAIL_DASHBOARD_TAG,
  OMNI_DIAGNOSTIC_MODEM_FAIL_TROUBLESHOOT_TAG,
  OMNI_DIAGNOSTIC_NODATA_FAIL_DASHBOARD_TAG,
  OMNI_DIAGNOSTIC_NODATA_FAIL_PROMO_TAG,
  OMNI_DIAGNOSTIC_OUTAGE_FAIL_DASHBOARD_TAG
} from "./omni-gtm-events.constants";

export const OMNI_DIAGNOSTICS_HEADER = "We are here to help!<br />Let's check what's happening with your connection.";

export const OMNI_DIAGNOSTICS_SUCCESS_MODAL = {
  icon: "/assets/icons/diagnostic-success.svg",
  title: "All seems to be working fine with your Globe At Home service.",
  description: "Do you still need help with your connection?",
  confirmButtonLabel: "No. I’m good",
  cancelButtonLabel: "Yes, I need help",
  confirmButtonUrl: "/omnicare/welcomeback",
  cancelButtonUrl: "/omnicare/scheduleTechnicianConcern",
};

export const OMNI_DIAGNOSTICS_VALIDATION_TIMER: number = 1000;

export const OMNI_DIAGNOSTICS_SUB_VALIDATION_TIMER: number = 1000;

export const OMNI_DIAGNOSTICS_BARRING_INDICATOR_TIMER: number = 1000;

export const OMNI_DIAGNOSTICS_TITLE_REGEX: RegExp = /{(.+?)}/g;

export const OMNI_DIAGNOSTIC_DECIMAL_REGEX: RegExp = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;

export const OMNI_DIAGNOSTICS_LIST: OmniDiagnostic[] = [
  {
    id: "account",
    title_inactive: "Account status",
    title_initial: "Account status",
    title_active: "Checking account status",
    title_fail: "Checking account status",
    title_completed: "Your account is active.",
    status: "initial",
    diagnostics: [
      {
        id: "account_overdue",
        title_inactive: "Checking overdue balance...",
        title_active: "Checking overdue balance...",
        title_fail: "You have an overdue balance.",
        title_completed: "No overdue balance.",
        status: "active",
        validation: {
          validationKey: "balanceStatus",
          invalidValidationValues: ["Overdue"],
          failTitle: "You have an overdue balance.",
          failDescription: "We can reconnect you within 2 hours of payment. You may go back to dashboard and pay the full outstanding balance.",
          failConfirmLabel: "Go back to dashboard",
          failConfirmUrl: "/omnicare/navigate?destination=dashboard",
        },
        sub_validations: [
          {
            validationKey: "accountStatus",
            validValidationValues: ["Connected"],
            failTitle: "You have a remaining balance <br />from the previous bill.",
            failDescription: "To check the amount to pay, you may go back to the dashboard. After payment, we can reconnect your line within 2 hours.",
            failConfirmLabel: "Pay now",
            failCancelLabel: "Go back to dashboard",
            failConfirmUrl: "/omnicare/navigate?destination=payment.overdue",
            failCancelUrl: "/omnicare/navigate?destination=dashboard",
            failConfirmGtmConfig: OMNI_DIAGNOSTIC_ACCOUNT_FAIL_OVERDUE_TAG,
            failCancelGtmConfig: OMNI_DIAGNOSTIC_ACCOUNT_FAIL_DASHBOARD_TAG,
          },
          {
            validationKey: "accountStatus",
            invalidValidationValues: ["Connected"],
            failTitle: "You have a remaining balance <br />from the previous bill.",
            failDescription: "To avoid disconnection, please pay the full balance immediately.",
            failConfirmLabel: "Pay now",
            failCancelLabel: "Continue to check your connection",
            failConfirmUrl: "/omnicare/navigate?destination=payment.overdue",
            failCancelUrl: "continue",
            failCancelTimer: 10000,
            failConfirmGtmConfig: OMNI_DIAGNOSTIC_ACCOUNT_FAIL_OVERDUE_TAG,
            failCancelGtmConfig: OMNI_DIAGNOSTIC_ACCOUNT_FAIL_CONTINUE_TAG,
          },
        ],
      },
      {
        id: "account_disconnected",
        title_inactive: "Checking for temporary disconnection...",
        title_active: "Checking for temporary disconnection...",
        title_fail: "Account is temporarily disconnected.",
        title_completed: "Your account is active.",
        status: "inactive",
        validation: {
          validationKey: "accountStatus",
          validValidationValues: ["Connected", "Pending Disconnection - Globe", "Pending Disconnection - Subscriber"],
          failTitle: "Your account is temporarily disconnected",
          failDescription: "If you wish to be reconnected and get reconnected within 2 hours, you can send a request by calling <b>211</b> or chat with us via FB Messenger.",
          failConfirmLabel: "Chat with us via FB Messenger",
          failCancelLabel: "Go back to dashboard",
          failConfirmUrl: "/omnicare/navigate?destination=messenger",
          failCancelUrl: "/omnicare/navigate?destination=dashboard",
          failConfirmStyle: "outline",
          failConfirmGtmConfig: OMNI_DIAGNOSTIC_DISCONNECT_FAIL_FB_TAG,
          failCancelGtmConfig: OMNI_DIAGNOSTIC_DISCONNECT_FAIL_DASHBOARD_TAG,
        },
      },
      {
        id: "account_data-fup",
        title_inactive: "Checking data left...",
        title_active: "Checking data left...",
        title_fail: "Checking data left...",
        title_completed: "You have {accountDataBalance}GB left.",
        status: "inactive",
        requirements: [
          {
            validationKey: "dataAllocation",
            invalidValidationValues: ["Unlimited", "Data not found", "No data found"],
          },
          {
            validationKey: "outageResult",
            validValidationValues: ["No Service Affecting Network Incident and/or Planned Activity Found!", "Data not found", "No data found"],
          },
          {
            validationKey: "fupIndicator",
            validValidationValues: ["Account is Not FUP"],
          },
        ],
        validation: {
          validationKey: "fupIndicator",
          invalidValidationValues: ["Account is Not FUP"],
          failTitle: "Your data has been used up",
          failDescription: "Please buy promos to enjoy uninterrupted network connection.",
          failConfirmLabel: "Buy promos",
          failCancelLabel: "Go back to dashboard",
          failConfirmUrl: "/omnicare/navigate?destination=buy.promos",
          failCancelUrl: "/omnicare/navigate?destination=dashboard",
        },
        sub_validations: [
          {
            validationKey: "accountDataBalance",
            invalidValidationValues: ["0", "0.00"],
            failTitle: "Your data has been used up",
            failDescription: "Please buy promos to enjoy uninterrupted network connection.",
            failConfirmLabel: "Buy promos",
            failCancelLabel: "Go back to dashboard",
            failConfirmUrl: "/omnicare/navigate?destination=buy.promos",
            failCancelUrl: "/omnicare/navigate?destination=dashboard",
            failConfirmGtmConfig: OMNI_DIAGNOSTIC_NODATA_FAIL_PROMO_TAG,
            failCancelGtmConfig: OMNI_DIAGNOSTIC_NODATA_FAIL_DASHBOARD_TAG,
          },
        ],
      },
      {
        id: "account_data-data",
        title_inactive: "Checking data left...",
        title_active: "Checking data left...",
        title_fail: "Checking data left...",
        title_completed: "You have {accountDataBalance}GB left.",
        status: "inactive",
        requirements: [
          {
            validationKey: "dataAllocation",
            invalidValidationValues: ["Unlimited", "Data not found", "No data found"],
          },
          {
            validationKey: "outageResult",
            validValidationValues: ["No Service Affecting Network Incident and/or Planned Activity Found!", "Data not found", "No data found"],
          },
          {
            validationKey: "fupIndicator",
            invalidValidationValues: ["Account is Not FUP"],
          },
        ],
        validation: {
          validationKey: "accountDataBalance",
          invalidValidationValues: ["0", "0.00"],
          failTitle: "Your data has been used up",
          failDescription: "Please buy promos to enjoy uninterrupted network connection.",
          failConfirmLabel: "Buy promos",
          failCancelLabel: "Go back to dashboard",
          failConfirmUrl: "/omnicare/navigate?destination=buy.promos",
          failCancelUrl: "/omnicare/navigate?destination=dashboard",
          failConfirmGtmConfig: OMNI_DIAGNOSTIC_NODATA_FAIL_PROMO_TAG,
          failCancelGtmConfig: OMNI_DIAGNOSTIC_NODATA_FAIL_DASHBOARD_TAG,
        },
      },
    ]
  },
  {
    id: "network",
    title_inactive: "Network status",
    title_active: "Checking network connectivity",
    title_fail: "Checking network connectivity",
    title_completed: "Your network connectivity is up.",
    status: "inactive",
    diagnostics: [
      {
        id: "network_outage",
        title_inactive: "Checking for ongoing outage...",
        title_active: "Checking for ongoing outage...",
        title_fail: "There is an ongoing outage.",
        title_completed: "No ongoing outage.",
        status: "active",
        validation: {
          validationKey: "outageResult",
          validValidationValues: ["No Service Affecting Network Incident and/or Planned Activity Found!", "Data not found", "No data found"],
          failTitle: "There’s a reported outage in your area.",
          failDescription: "<p>Our tech team is working on it! No need to request for a technician visit.</p><p>We’ll let you know when it’s fixed.</p>",
          failConfirmLabel: "Go back to dashboard",
          failConfirmUrl: "/omnicare/navigate?destination=dashboard",
          failConfirmGtmConfig: OMNI_DIAGNOSTIC_OUTAGE_FAIL_DASHBOARD_TAG,
        },
      },
      {
        id: "network_setting",
        title_inactive: "Checking for network settings...",
        title_active: "Checking for network settings...",
        title_fail: "Checking for network settings...",
        title_completed: "Network settings are good.",
        status: "inactive",
      },
    ]
  },
  {
    id: "fiber",
    title_inactive: "Fiber line status",
    title_active: "Checking fiber line",
    title_fail: "Checking fiber line",
    title_completed: "Your fiber line is working.",
    status: "inactive",
    diagnostics: [
      {
        id: "fiber_cuts",
        title_inactive: "Checking for fiber cuts...",
        title_active: "Checking for fiber cuts...",
        title_fail: "Checking for fiber cuts...",
        title_completed: "No fiber cut has been detected",
        status: "active",
      },
      {
        id: "fiber_loss",
        title_inactive: "Checking for fiber losses...",
        title_active: "Checking for fiber losses...",
        title_fail: "Checking for fiber losses...",
        title_completed: "Fiber reading is acceptable",
        status: "inactive",
      },
      {
        id: "fiber_modem",
        title_inactive: "Checking for modem line reading...",
        title_active: "Checking for modem line reading...",
        title_fail: "Checking for modem line reading...",
        title_completed: "Modem line is working",
        status: "inactive",
        validation: {
          validationKey: "lineStatus",
          validValidationValues: ["Account has no last mile issue", "Facility is not Huawei", "Facility is not Huawei.", "No data found"],
          failTitle: "There's an issue with your fiber line",
          failDescription:
            REDIRECT_SCHED_TECHNICIAN_FLOW ?
            "<p>We detected an issue with your internet connection. You might not be able to browse or access the internet.</p><p>We have an available technician to fix your connection.</p>" :
            "<p>We detected an issue with your internet connection. You might not be able to browse or access the internet.</p><p>You may request for a technician visit by chatting with us on Facebook Messenger.</p>",
          failConfirmLabel: REDIRECT_SCHED_TECHNICIAN_FLOW ? "Schedule a technician visit" : "Chat with us on FB Messenger",
          failCancelLabel: "Go back to dashboard",
          failConfirmUrl: REDIRECT_SCHED_TECHNICIAN_FLOW ? "/omnicare/scheduleTechnician" : "/omnicare/navigate?destination=messenger.ng1BB",
          failCancelUrl: "/omnicare/navigate?destination=dashboard",
          failConfirmStyle: REDIRECT_SCHED_TECHNICIAN_FLOW ? "" : "outline",
          failConfirmGtmConfig: REDIRECT_SCHED_TECHNICIAN_FLOW ? OMNI_DIAGNOSTIC_FIBERMODEM_FAIL_SCHEDULE_TAG : OMNI_DIAGNOSTIC_FIBERMODEM_FAIL_FB_TAG,
          failCancelGtmConfig: OMNI_DIAGNOSTIC_FIBERMODEM_FAIL_DASHBOARD_TAG,
        },
      },
    ]
  },
  {
    id: "modem",
    title_inactive: "Modem status",
    title_active: "Checking modem",
    title_fail: "Checking modem",
    title_completed: "Your modem is up and running.",
    status: "inactive",
    diagnostics: [
      {
        id: "modem_power",
        title_inactive: "Checking modem power...",
        title_active: "Checking modem power...",
        title_fail: "Checking modem power...",
        title_completed: "Your modem is up.",
        status: "active",
        validation: {
          validationKey: "modemStatus",
          validValidationValues: ["Active", "Data not found", "No data found"],
          failTitle: "Your modem is experiencing issues.",
          failDescription: "Let's try to fix it with basic troubleshooting.",
          failConfirmLabel: "Start troubleshooting now",
          failCancelLabel: "Go back to dashboard",
          failConfirmUrl: "/omnicare/basictroubleshooting",
          failCancelUrl: "/omnicare/navigate?destination=dashboard",
          failConfirmGtmConfig: OMNI_DIAGNOSTIC_MODEM_FAIL_TROUBLESHOOT_TAG,
          failCancelGtmConfig: OMNI_DIAGNOSTIC_MODEM_FAIL_DASHBOARD_TAG,
        },
      }
    ]
  },
];
