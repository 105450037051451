import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  Output,
  OnInit,
} from '@angular/core';
import {
  NgbDateStruct,
  NgbCalendar,
  NgbDatepickerConfig,
  NgbDatepickerI18n,
  NgbDatepickerI18nDefault,
  NgbDate,
} from '@ng-bootstrap/ng-bootstrap';

//#region override weekday labels for datepicker
// custom weekday labels
import {
  WEEK_DAY_LABELS,
  OMNI_X_ICON,
} from '../../../common/constants/omni-date-picker-modal.constant';
import { SESSION_KEYS } from '../../../common/constants/omni-global.constant';
import { OmniSessionService } from 'src/app/services/omni-session.service';
import * as moment from 'moment';

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18nDefault {
  override getWeekdayLabel(weekday: number): string {
    return WEEK_DAY_LABELS[weekday - 1];
  }
}
//#endregion

@Component({
  selector: 'app-omni-date-picker-modal',
  templateUrl: './omni-date-picker-modal.component.html',
  styleUrls: ['./omni-date-picker-modal.component.scss'],
  providers: [{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
})
export class OmniDatePickerModalComponent implements OnInit {
  @Input() showModal: boolean = true;
  @Input() isReschedule: boolean = false;
  @Input() selectedDate: NgbDateStruct | null = null;
  @Output() dateSelected = new EventEmitter<NgbDateStruct>();
  @Output() modalClosed = new EventEmitter<void>();
  @Input() currentTechSchedDate: string = '';

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;
  hideNavigation: boolean = true;
  omniXicon: string = OMNI_X_ICON;
  markDisabled: any = null;
  appointmentSlots: any[] = [];

  constructor(
    private calendar: NgbCalendar,
    private config: NgbDatepickerConfig,
    private omniSessionService: OmniSessionService
  ) {    
    this.initializeCalendar();
  }
  ngOnInit(): void {
    this.initializeCalendar();
  }

  initializeCalendar(): void {
    if (this.isReschedule) {
      //Get current Technician visit date for reschedule flow
      const dateParts = this.currentTechSchedDate.split('-');
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]);
      const day = parseInt(dateParts[2]);
      const currentSchedDate = new NgbDate(year, month, day);

      //Set min Date as current Schedule Appointment date + 1
      this.minDate = this.calendar.getNext(currentSchedDate, 'd', 1);

      // Calculate the maximum date (5 days from minimum date)
      this.maxDate = this.calendar.getNext(currentSchedDate, 'd', 5);

      this.appointmentSlots = this.omniSessionService.getData(
        SESSION_KEYS.OMNI_RESCHED_APPOINMENTSLOTS_KEY
      );
    } else {
      // Calculate the minimum date (3 days from today)
      this.minDate = this.calendar.getNext(this.calendar.getToday(), 'd', 3);

      // Calculate the maximum date (5 days from minimum date)
      this.maxDate = this.calendar.getNext(this.calendar.getToday(), 'd', 7);
      this.appointmentSlots =
        this.omniSessionService.getUserData()?.appointmentSlots;
    }

    if (this.minDate.month !== this.maxDate.month) {
      this.config.navigation = 'arrows';
      this.hideNavigation = false;
    } else {
      this.config.navigation = 'none';
      this.hideNavigation = true;
    }

    this.markDisabled = (date: NgbDate) => {
      let appointmentSlot = this.appointmentSlots.find(
        slot =>
          slot.date ==
          moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-M-D').format(
            'YYYY-MM-DD'
          )
      );
      return !(
        appointmentSlot &&
        (appointmentSlot.slots.morning > 0 || appointmentSlot.slots.evening > 0)
      );
    };
  }

  /**
   * Fires an event when a date is selected in the date picker
   * @param date date selected
   */
  onDateSelect(date: NgbDateStruct) {
    this.selectedDate = date;
    this.dateSelected.emit(date);
    this.modalClosed.emit();
  }

  /**
   * Fires an event when the close button is clicked
   */
  closeDatepicker() {
    this.modalClosed.emit();
  }
}
