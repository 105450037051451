import { Injectable } from '@angular/core';
import { __values } from 'tslib';
import { environment } from '../../environments/environment';
import { OMNI_GTM_CONFIG } from '../common/constants/omni-global.constant';
import { OBI_GTM_CONFIG } from '../common/constants/obi-global.constants';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  private obiConfig: any = OBI_GTM_CONFIG;

  private omniConfig: any = OMNI_GTM_CONFIG;

  private browserGlobals = {
    windowRef(): any {
      return window;
    },
    documentRef(): any {
      return document;
    },
  };

  constructor() {}

  /**
   * Adds the initial GTM script on the head element before the app starts.
   * Run this on App Component - only needs to be ran once.
   */
  init() {
    this.addGtmScriptToDom();
    this.addStartEvent();
  }

  /**
   * Adds the gtm.start event
   */
  private addStartEvent() {
    this.pushOnDataLayer({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
  }

  /**
   * Add the script element to enable events tagging
   */
  private addGtmScriptToDom() {
    const gtmScript = document.createElement('script');
    gtmScript.id = 'GTMscript';
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=GTM-NMB37WB`;

    document.head.insertBefore(gtmScript, document.head.firstChild);
  }

  /**
   * Retrieves datalayer of window/document
   */
  public getDataLayer(): any[] {
    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }

  /**
   * Pushes event to datalayer
   */
  private pushOnDataLayer(obj: any): void {
    const dataLayer = this.getDataLayer();
    const index = dataLayer.findIndex(
      item =>
        (item?.event === obj?.event || item?.event === obj?.ecommerce) &&
        item?.event &&
        obj?.event
    );

    if (index > -1) dataLayer.splice(index, 1);
    dataLayer.push(obj);
  }

  /**
   * Captures Google Tag Manager events
   * @param attr CTA from Drupal
   * @param eventInfo data
   */
  captureGTMEvent(
    eventInfo: any,
    isObi: boolean = false,
    additionalData?: any
  ) {
    let config = this.omniConfig;

    if (isObi) {
      config = this.obiConfig;
    }

    if (environment.gtmPush === true) {
      try {
        const data: any = {
          event_category: config.event_category,
          event_action: eventInfo.event_action
            ? eventInfo.event_action
            : config.event_action,
          source_page_url: environment.gtmBaseUrl,
          outbound_link: eventInfo.outbound_link,
          event: config.event,
          event_label: `Label=${eventInfo.event_label}`,
          section: eventInfo.section,
          element: eventInfo.element,
        };
        if (additionalData) {
          Object.keys(additionalData).forEach(key => {
            if (additionalData[key]) {
              data[key] = additionalData[key];
            }
          });
        }

        this.clean(data);
        this.pushOnDataLayer(data);
      } catch (e) {}
    }
  }

  /**
   * Removes object with invalid values
   */
  clean(data: any): any {
    return Object.keys(data).forEach(k => {
      if (data[k] === null || data[k] === undefined) {
        delete data[k];
      }
    });
  }
}
