import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { ObiBillInquiryForm } from "src/app/components/obi/obi-bill-inquiry/obi-bill-inquiry.interface";

export const OBI_BILL_INQUIRY_TITLE = "Submit a bill inquiry";
export const OBI_BILL_INQUIRY_USER_LABEL = "View bill for";
export const OBI_BILL_INQUIRY_CONCERN_MAX = 100;
export const OBI_BILL_INQUIRY_CONCERN_MIN_HEIGHT = 80;

export const OBI_BILL_INQUIRY_LABEL = {
  inquire: "Inquire for billing:",
  billCharge: 'What’s the bill charge you would like to inquire? <span class="required">*</span>'
};

export const OBI_BILL_INQUIRY_CANCEL = {
  title: "Are you sure you want to cancel your bill inquiry?",
  cancelButtonUrl: "/omnicare/navigate?destination=dashboard",
  confirmButtonLabel: "No",
  cancelButtonLabel: "Yes",
};

export const OBI_BILL_INQUIRY_CASE_CREATE_LOGNOTE_LABEL = {
  MOBILE_NUMBER_LABEL: 'Mobile Number',
  ALTERNATIVE_MOBILE_NUMBER_LABEL: 'Alternative Mobile Number',
  CONCERN_DETAILS_LABEL: 'Concern Details',
  BILL_DETAILS_LABEL: 'Bill Number or Billing Period',
  BILL_CHARGE_LABEL: 'Dispute Details',
  AMOUNT_DISPUTE_LABEL: 'Amount of Dispute',
  ACCOUNT_NUMBER_LABEL: 'accountNumber',
  ACCOUNT_ALIAS_LABEL: 'accountAlias',
  UNPOSTED_PAYMENT_LABEL: 'Unposted payment',
  UNPOSTED_PAYMENT_AMOUNT_LABEL: 'Amount paid',
  UNPOSTED_PAYMENT_DATE_LABEL: 'Date',
  UNPOSTED_PAYMENT_TIME_LABEL: 'Time',
  UNPOSTED_PAYMENT_CHANNEL_LABEL: 'Channel',
  UNPOSTED_PAYMENT_PAYMENT_REF_LABEL: 'Payment ref no',
}

export const OBI_BILL_INQUIRY_ISSUE_TYPES = [
  "Monthly Recurring Fee (MRF)",
  "Excess Usage",
  "One-time Charges",
  "Gadgets & Digital Connectors",
  "Bill Adjustments",
  "Unposted Payment"
];

export const OBI_BILL_INQUIRY_UNPOSTED_PAYMENT_TAG = 'Unposted Payment';

export const OBI_BILL_INQUIRY_DEFAULT_VALUES = {
  sourceNumber: 2,
  workflowOperation: "1",
  status: 'NEW',
  caseTypeLevel1RefId: 'TT_TROUBLE TICKET'
};

export const OBI_BILL_INQUIRY_ISSUE_FORMS: ObiBillInquiryForm[] = [
  {
    issue: "",
    forms: [
      {
        formControlName: "amount",
        receiptLabel: "CONTESTED AMOUNT",
        formControl: new FormControl('', [
          Validators.required,
          Validators.pattern(/[^(₱0.00)]/),
        ]),
        formControlValidators: [
          Validators.required,
          Validators.pattern(/[^(₱0.00)]/),
        ],
        type: 'amount',
        max: 999999.99,
        label: 'Amount being clarified <span class="required">*</span>',
        fieldLabel: 'Dispute amount',
        placeholder: 'Dispute amount'
      },
      {
        formControlName: "altMobileNumber",
        formControl: new FormControl('', [Validators.pattern(/\d{10}/), Validators.required]),
        formControlValidators: [Validators.pattern(/\d{10}/), Validators.required],
        type: 'mobile',
        label: 'Please provide an alternative mobile number <span class="required">*</span>',
        fieldLabel: 'Mobile number',
        placeholder: '+63'
      },
      {
        formControlName: "concern",
        formControl: new FormControl('', [Validators.maxLength(OBI_BILL_INQUIRY_CONCERN_MAX), Validators.required]),
        formControlValidators: [Validators.maxLength(OBI_BILL_INQUIRY_CONCERN_MAX), Validators.required],
        type: 'textarea',
        label: 'Tell us more about your concern <span class="required">*</span>',
        placeholder: 'Please explain with as much detail as possible. English or Taglish is acceptable.'
      },
    ]
  },
  {
    issue: "Monthly Recurring Fee (MRF)",
    forms: [
      {
        formControlName: "amount",
        receiptLabel: "CONTESTED AMOUNT",
        formControl: new FormControl('', [
          Validators.required,
          Validators.pattern(/[^(₱0.00)]/),
        ]),
        formControlValidators: [
          Validators.required,
          Validators.pattern(/[^(₱0.00)]/),
        ],
        type: 'amount',
        max: 999999.99,
        label: 'Amount being clarified <span class="required">*</span>',
        fieldLabel: 'Dispute amount',
        placeholder: 'Dispute amount'
      },
      {
        formControlName: "altMobileNumber",
        formControl: new FormControl('', [Validators.pattern(/\d{10}/), Validators.required]),
        formControlValidators: [Validators.pattern(/\d{10}/), Validators.required],
        type: 'mobile',
        label: 'Please provide an alternative mobile number <span class="required">*</span>',
        fieldLabel: 'Mobile number',
        placeholder: '+63'
      },
      {
        formControlName: "concern",
        formControl: new FormControl('', [Validators.maxLength(OBI_BILL_INQUIRY_CONCERN_MAX), Validators.required]),
        formControlValidators: [Validators.maxLength(OBI_BILL_INQUIRY_CONCERN_MAX), Validators.required],
        type: 'textarea',
        label: 'Tell us more about your concern <span class="required">*</span>',
        placeholder: 'Please explain with as much detail as possible. English or Taglish is acceptable.'
      },
    ]
  },
  {
    issue: "Excess Usage",
    forms: [
      {
        formControlName: "amount",
        receiptLabel: "CONTESTED AMOUNT",
        formControl: new FormControl('', [
          Validators.required,
          Validators.pattern(/[^(₱0.00)]/),
        ]),
        formControlValidators: [
          Validators.required,
          Validators.pattern(/[^(₱0.00)]/),
        ],
        type: 'amount',
        max: 999999.99,
        label: 'Amount being clarified <span class="required">*</span>',
        fieldLabel: 'Dispute amount',
        placeholder: 'Dispute amount'
      },
      {
        formControlName: "altMobileNumber",
        formControl: new FormControl('', [Validators.pattern(/\d{10}/), Validators.required]),
        formControlValidators: [Validators.pattern(/\d{10}/), Validators.required],
        type: 'mobile',
        label: 'Please provide an alternative mobile number <span class="required">*</span>',
        fieldLabel: 'Mobile number',
        placeholder: '+63'
      },
      {
        formControlName: "concern",
        formControl: new FormControl('', [Validators.maxLength(OBI_BILL_INQUIRY_CONCERN_MAX), Validators.required]),
        formControlValidators: [Validators.maxLength(OBI_BILL_INQUIRY_CONCERN_MAX), Validators.required],
        type: 'textarea',
        label: 'Tell us more about your concern <span class="required">*</span>',
        placeholder: 'Please explain with as much detail as possible. English or Taglish is acceptable.'
      },
    ]
  },
  {
    issue: "One-time Charges",
    forms: [
      {
        formControlName: "amount",
        receiptLabel: "CONTESTED AMOUNT",
        formControl: new FormControl('', [
          Validators.required,
          Validators.pattern(/[^(₱0.00)]/),
        ]),
        formControlValidators: [
          Validators.required,
          Validators.pattern(/[^(₱0.00)]/),
        ],
        type: 'amount',
        max: 999999.99,
        label: 'Amount being clarified <span class="required">*</span>',
        fieldLabel: 'Dispute amount',
        placeholder: 'Dispute amount'
      },
      {
        formControlName: "altMobileNumber",
        formControl: new FormControl('', [Validators.pattern(/\d{10}/), Validators.required]),
        formControlValidators: [Validators.pattern(/\d{10}/), Validators.required],
        type: 'mobile',
        label: 'Please provide an alternative mobile number <span class="required">*</span>',
        fieldLabel: 'Mobile number',
        placeholder: '+63'
      },
      {
        formControlName: "concern",
        formControl: new FormControl('', [Validators.maxLength(OBI_BILL_INQUIRY_CONCERN_MAX), Validators.required]),
        formControlValidators: [Validators.maxLength(OBI_BILL_INQUIRY_CONCERN_MAX), Validators.required],
        type: 'textarea',
        label: 'Tell us more about your concern <span class="required">*</span>',
        placeholder: 'Please explain with as much detail as possible. English or Taglish is acceptable.'
      },
    ]
  },
  {
    issue: "Gadgets & Digital Connectors",
    forms: [
      {
        formControlName: "amount",
        receiptLabel: "CONTESTED AMOUNT",
        formControl: new FormControl('', [
          Validators.required,
          Validators.pattern(/[^(₱0.00)]/),
        ]),
        formControlValidators: [
          Validators.required,
          Validators.pattern(/[^(₱0.00)]/),
        ],
        type: 'amount',
        max: 999999.99,
        label: 'Amount being clarified <span class="required">*</span>',
        fieldLabel: 'Dispute amount',
        placeholder: 'Dispute amount'
      },
      {
        formControlName: "altMobileNumber",
        formControl: new FormControl('', [Validators.pattern(/\d{10}/), Validators.required]),
        formControlValidators: [Validators.pattern(/\d{10}/), Validators.required],
        type: 'mobile',
        label: 'Please provide an alternative mobile number <span class="required">*</span>',
        fieldLabel: 'Mobile number',
        placeholder: '+63'
      },
      {
        formControlName: "concern",
        formControl: new FormControl('', [Validators.maxLength(OBI_BILL_INQUIRY_CONCERN_MAX), Validators.required]),
        formControlValidators: [Validators.maxLength(OBI_BILL_INQUIRY_CONCERN_MAX), Validators.required],
        type: 'textarea',
        label: 'Tell us more about your concern <span class="required">*</span>',
        placeholder: 'Please explain with as much detail as possible. English or Taglish is acceptable.'
      },
    ]
  },
  {
    issue: "Bill Adjustments",
    forms: [
      {
        formControlName: "amount",
        receiptLabel: "CONTESTED AMOUNT",
        formControl: new FormControl('', [
          Validators.required,
          Validators.pattern(/[^(₱0.00)]/),
        ]),
        formControlValidators: [
          Validators.required,
          Validators.pattern(/[^(₱0.00)]/),
        ],
        type: 'amount',
        max: 999999.99,
        label: 'Amount being clarified <span class="required">*</span>',
        fieldLabel: 'Dispute amount',
        placeholder: 'Dispute amount'
      },
      {
        formControlName: "altMobileNumber",
        formControl: new FormControl('', [Validators.pattern(/\d{10}/), Validators.required]),
        formControlValidators: [Validators.pattern(/\d{10}/), Validators.required],
        type: 'mobile',
        label: 'Please provide an alternative mobile number <span class="required">*</span>',
        fieldLabel: 'Mobile number',
        placeholder: '+63'
      },
      {
        formControlName: "concern",
        formControl: new FormControl('', [Validators.maxLength(OBI_BILL_INQUIRY_CONCERN_MAX), Validators.required]),
        formControlValidators: [Validators.maxLength(OBI_BILL_INQUIRY_CONCERN_MAX), Validators.required],
        type: 'textarea',
        label: 'Tell us more about your concern <span class="required">*</span>',
        placeholder: 'Please explain with as much detail as possible. English or Taglish is acceptable.'
      },
    ]
  },
  {
    issue: "Unposted Payment",
    forms: [
      {
        formControlName: "amount",
        receiptLabel: "AMOUNT PAID",
        formControl: new FormControl('', [
          Validators.required,
          Validators.pattern(/[^(₱0.00)]/),
        ]),
        formControlValidators: [
          Validators.required,
          Validators.pattern(/[^(₱0.00)]/),
        ],
        type: 'amount',
        max: 999999.99,
        label: 'Amount paid <span class="required">*</span>',
        placeholder: '₱ 0.00',
      },
      {
        formControlName: "paymentChannel",
        receiptLabel: "PAYMENT CHANNEL",
        formControl: new FormControl('', [Validators.required]),
        formControlValidators: [Validators.required],
        type: 'select',
        label: 'Payment channel <span class="required">*</span>',
        placeholder: 'Select payment channel used',
        fieldLabel: 'Select payment channel used',
        options: [
          'Globe Channel (GlobeOne, Globe Paybill, new.globe.com.ph, Payment links)',
          'GCash App',
          'Banks (Over-the-counter, Online Banking, Phone Banking, ATM, Bank Kiosk)',
          'Non-banks (Cebuana, M Lhuillier, SM Payment Center, Bayad Center, ECPay, Grab, Lazada, Robinsons Dept Store, and partners ie. 7-11, Shopee, etc.)',
          'Auto-Debit (Enrolled in credit card auto-debit)'
        ],
        errors: [
          (fg: FormGroup, formErrorList: any) => {
            if (fg.get('paymentChannel')?.hasValidator(Validators.required)) {
              if (
                !fg.get('paymentChannel')?.value
              ) {
                fg.get('dateOfPayment')?.disable({
                  onlySelf: true,
                  emitEvent: false
                });
                fg.get('timeOfPayment')?.disable({
                  onlySelf: true,
                  emitEvent: false
                });
              } else {
                fg.get('dateOfPayment')?.enable({
                  onlySelf: true,
                  emitEvent: false
                });
                fg.get('timeOfPayment')?.enable({
                  onlySelf: true,
                  emitEvent: false
                });
              }
              if (
                fg.get('dateOfPayment')?.value &&
                fg.get('timeOfPayment')?.value
              ) {
                const date = fg.controls['dateOfPayment'].value;
                const time = fg.controls['timeOfPayment'].value;
                const dateTime = `${date.year}-${date.month}-${date.day} ${time}`;
                const requestedDate = moment(dateTime, "YYYY-MM-DD HH:mm a").utc();
                const prevDate = moment().utc().subtract(1, 'day').subtract(1, 'minute').startOf('minute');
                const prevTwoDate = moment().utc().subtract(2, 'day').subtract(1, 'minute').startOf('minute');
                if ([
                  'Banks (Over-the-counter, Online Banking, Phone Banking, ATM, Bank Kiosk)',
                  'Non-banks (Cebuana, M Lhuillier, SM Payment Center, Bayad Center, ECPay, Grab, Lazada, Robinsons Dept Store, and partners ie. 7-11, Shopee, etc.)',
                  'Auto-Debit (Enrolled in credit card auto-debit)']
                  .includes(fg.get('paymentChannel')?.value)
                ) {
                  if (requestedDate >= prevTwoDate) {
                    fg.get('timeOfPayment')?.setErrors({insufficientTime: true});
                    formErrorList['timeOfPayment'] = 'Please wait at least 48 hours for us to post your payment.';
                    fg.updateValueAndValidity();
                    return;
                  }
                } else {
                  if (requestedDate >= prevDate) {
                    fg.get('timeOfPayment')?.setErrors({insufficientTime: true});
                    formErrorList['timeOfPayment'] = 'Please wait at least 24 hours for us to post your payment.';
                    fg.updateValueAndValidity();
                    return;
                  }
                }
              }
            }
            delete formErrorList['timeOfPayment'];
            fg.get('timeOfPayment')?.setErrors({insufficientTime: false});
            fg.updateValueAndValidity();
          }
        ]
      },
      {
        formControlName: "dateOfPayment",
        receiptLabel: "DATE OF PAYMENT",
        formControl: new FormControl('', [Validators.required]),
        formControlValidators: [Validators.required],
        type: 'date',
        label: 'Date of payment <span class="required">*</span>',
        placeholder: 'mm/dd/yyyy',
        errors: [
          (fg: FormGroup, formErrorList: any) => {
            if (fg.get('paymentChannel')?.hasValidator(Validators.required)) {
              if (
                !fg.get('paymentChannel')?.value
              ) {
                fg.get('dateOfPayment')?.disable({
                  onlySelf: true,
                  emitEvent: false
                });
                fg.get('timeOfPayment')?.disable({
                  onlySelf: true,
                  emitEvent: false
                });
              } else {
                fg.get('dateOfPayment')?.enable({
                  onlySelf: true,
                  emitEvent: false
                });
                fg.get('timeOfPayment')?.enable({
                  onlySelf: true,
                  emitEvent: false
                });
              }
              if (
                fg.get('dateOfPayment')?.value &&
                fg.get('timeOfPayment')?.value
              ) {
                const date = fg.controls['dateOfPayment'].value;
                const time = fg.controls['timeOfPayment'].value;
                const dateTime = `${date.year}-${date.month}-${date.day} ${time}`;
                const requestedDate = moment(dateTime, "YYYY-MM-DD HH:mm a").utc();
                const prevDate = moment().utc().subtract(1, 'day').subtract(1, 'minute').startOf('minute');
                const prevTwoDate = moment().utc().subtract(2, 'day').subtract(1, 'minute').startOf('minute');
                if ([
                  'Banks (Over-the-counter, Online Banking, Phone Banking, ATM, Bank Kiosk)',
                  'Non-banks (Cebuana, M Lhuillier, SM Payment Center, Bayad Center, ECPay, Grab, Lazada, Robinsons Dept Store, and partners ie. 7-11, Shopee, etc.)',
                  'Auto-Debit (Enrolled in credit card auto-debit)']
                  .includes(fg.get('paymentChannel')?.value)
                ) {
                  if (requestedDate >= prevTwoDate) {
                    fg.get('timeOfPayment')?.setErrors({insufficientTime: true});
                    formErrorList['timeOfPayment'] = 'Please wait at least 48 hours for us to post your payment.';
                    fg.updateValueAndValidity();
                    return;
                  }
                } else {
                  if (requestedDate >= prevDate) {
                    fg.get('timeOfPayment')?.setErrors({insufficientTime: true});
                    formErrorList['timeOfPayment'] = 'Please wait at least 24 hours for us to post your payment.';
                    fg.updateValueAndValidity();
                    return;
                  }
                }
              }
            }
            delete formErrorList['timeOfPayment'];
            fg.get('timeOfPayment')?.setErrors({insufficientTime: false});
            fg.updateValueAndValidity();
          }
        ]
      },
      {
        formControlName: "timeOfPayment",
        receiptLabel: "TIME OF PAYMENT",
        formControl: new FormControl('', [Validators.required]),
        formControlValidators: [Validators.required],
        type: 'time',
        label: 'Time of payment <span class="required">*</span>',
        placeholder: 'hh:mm',
        errors: [
          (fg: FormGroup, formErrorList: any) => {
            if (fg.get('paymentChannel')?.hasValidator(Validators.required)) {
              if (
                !fg.get('paymentChannel')?.value
              ) {
                fg.get('dateOfPayment')?.disable({
                  onlySelf: true,
                  emitEvent: false
                });
                fg.get('timeOfPayment')?.disable({
                  onlySelf: true,
                  emitEvent: false
                });
              } else {
                fg.get('dateOfPayment')?.enable({
                  onlySelf: true,
                  emitEvent: false
                });
                fg.get('timeOfPayment')?.enable({
                  onlySelf: true,
                  emitEvent: false
                });
              }
              if (
                fg.get('dateOfPayment')?.value &&
                fg.get('timeOfPayment')?.value
              ) {
                const date = fg.controls['dateOfPayment'].value;
                const time = fg.controls['timeOfPayment'].value;
                const dateTime = `${date.year}-${date.month}-${date.day} ${time}`;
                const requestedDate = moment(dateTime, "YYYY-MM-DD HH:mm a").utc();
                const prevDate = moment().utc().subtract(1, 'day').subtract(1, 'minute').startOf('minute');
                const prevTwoDate = moment().utc().subtract(2, 'day').subtract(1, 'minute').startOf('minute');
                if ([
                  'Banks (Over-the-counter, Online Banking, Phone Banking, ATM, Bank Kiosk)',
                  'Non-banks (Cebuana, M Lhuillier, SM Payment Center, Bayad Center, ECPay, Grab, Lazada, Robinsons Dept Store, and partners ie. 7-11, Shopee, etc.)',
                  'Auto-Debit (Enrolled in credit card auto-debit)']
                  .includes(fg.get('paymentChannel')?.value)
                ) {
                  if (requestedDate >= prevTwoDate) {
                    fg.get('timeOfPayment')?.setErrors({insufficientTime: true});
                    formErrorList['timeOfPayment'] = 'Please wait at least 48 hours for us to post your payment.';
                    fg.updateValueAndValidity();
                    return;
                  }
                } else {
                  if (requestedDate >= prevDate) {
                    fg.get('timeOfPayment')?.setErrors({insufficientTime: true});
                    formErrorList['timeOfPayment'] = 'Please wait at least 24 hours for us to post your payment.';
                    fg.updateValueAndValidity();
                    return;
                  }
                }
              }
            }
            delete formErrorList['timeOfPayment'];
            fg.get('timeOfPayment')?.setErrors(null);
            fg.updateValueAndValidity();
          }
        ]
      },
      {
        formControlName: "referenceNo",
        receiptLabel: "PAYMENT REFERENCE NO.",
        formControl: new FormControl('', [
          Validators.required,
          Validators.maxLength(20),
          Validators.pattern("[a-zA-Z0-9.,-?]+")
        ]),
        formControlValidators: [
          Validators.required,
          Validators.maxLength(20),
          Validators.pattern("[a-zA-Z0-9.,-?]+")
        ],
        formPattern: /^[a-zA-Z0-9.,?-]*$/,
        formReplacePattern: /[^a-zA-Z0-9.,?-]/g,
        type: 'text',
        label: 'Reference number <span class="required">*</span>',
        placeholder: 'Enter reference number',
        maxLength: 20
      },
      {
        formControlName: "altMobileNumber",
        receiptLabel: "ALTERNATIVE NUMBER",
        formControl: new FormControl('', [Validators.pattern(/\d{10}/), Validators.required]),
        formControlValidators: [Validators.pattern(/\d{10}/), Validators.required],
        type: 'mobile',
        label: 'Please provide an alternative mobile number <span class="required">*</span>',
        fieldLabel: 'Mobile number',
        placeholder: '+63'
      },
    ]
  },
];
