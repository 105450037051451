<div class="omni-barring-error">
  <div class="omni-barring-error__wrapper">
    <div class="omni-barring-error__icon">
      <img [src]="errorDetails.icon" />
    </div>
    <div class="omni-barring-error__title"
      [innerHTML]="errorDetails.title"></div>
    <div class="omni-barring-error__description"
      [innerHTML]="errorDetails.description"></div>
    <div class="omni-barring-error__button-confirm"
      [innerHTML]="errorDetails.confirmLabel"
      (click)="commonService.navigate(errorDetails.confirmUrl)"></div>
    <div class="omni-barring-error__button-cancel"
      [innerHTML]="errorDetails.cancelLabel"
      (click)="commonService.navigate(errorDetails.cancelUrl)"></div>
  </div>
</div>
