import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CXS_API_URLS } from 'src/app/common/constants/obi-global.constants';

interface OmniGetBillingStatementPDF {
    result?: string;
}

@Injectable({
    providedIn: 'root'
})
export class ObiBillStatementService {

    constructor(
        private http: HttpClient,
    ) { }

    getBillingStatementPDF(accountNumber: string, mobileNumber: string, billingId: string, format: string, segment: string): Observable<OmniGetBillingStatementPDF> {
        let url = `${CXS_API_URLS.GET_BILLING_STATEMENTS_PDF}/${billingId}`;

        let params = new HttpParams();
        if (segment && segment === "broadband") {
            params = params.set('accountNumber', accountNumber);
        } else {
            params = params.set('mobileNumber', mobileNumber);
        }
        params = params.set('format', format).set('segment', segment);
        return this.http.get<OmniGetBillingStatementPDF>(url, { params });
    }

}
