import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-omni-snackbar',
  templateUrl: './omni-snackbar.component.html',
  styleUrls: ['./omni-snackbar.component.scss']
})
export class OmniSnackbarComponent implements OnInit {
  @ViewChild('snackbar', { static: true })
  snackbarElement!: ElementRef;

  @Input() 
  title: string = '';
  
  @Input() 
  subText: string = '';
  
  @Input() 
  isShowSubText: boolean = false;
  
  @Input() 
  duration: number = 5000; //5 second default duration
  
  public snackbarVisible: boolean = false;

  timerId: any;

  constructor() { }

  ngOnInit(): void {
  }


  public showSnackBar(){
    this.snackbarVisible = true;
    this.autoHideSnackbar();
  }

  public hideSnackBar(){
    this.snackbarVisible = false;
  }

  public autoHideSnackbar() {
    if(this.timerId){
      clearTimeout(this.timerId);
    }

    this.timerId = setTimeout(() => {
      this.hideSnackBar();
    }, this.duration); 
  }

}   
