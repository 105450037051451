<div class="obi-track-request">
  <div class="obi-track-request-nav">
    <div
      class="obi-track-request-nav-item"
      (click)="changeActiveNav(progressKey)"
      [class.active]="activeTab === progressKey">
      In progress
    </div>
    <div
      class="obi-track-request-nav-item"
      (click)="changeActiveNav(historyKey)"
      [class.active]="activeTab === historyKey">
      History
    </div>
  </div>
  <div
    class="obi-track-request-list"
    *ngIf="billInquiryList[activeTab]?.length && !loading">
    <div
      class="obi-track-request-list-item"
      *ngFor="let billInquiry of billInquiryList[activeTab]"
      (click)="isViewRequestStatusEnable ? (billInquiry?.sortDateTime ? onSelectedWorkOrder(billInquiry) : onSelectedBill(billInquiry)) : null">
      <div *ngIf="!billInquiry?.sortDateTime">
        <div
          [class]="['obi-track-request-list-item-status', commonService.convertToDashFormat(getDisplayStatus(billInquiry?.status))]">
          {{ getDisplayStatus(billInquiry?.status) }}
        </div>
        <div class="obi-track-request-list-item-title">{{ billInquiry?.caseId }}</div>
        <div *ngIf="billInquiry?.billLiner" class="obi-track-request-list-item-type">{{ billInquiry?.billLiner }}</div>
        <div class="obi-track-request-list-item-sched">
          {{ billInquiry.displayCreationTime }}
        </div>
        <div *ngIf="billInquiry?.contestedAmount || billInquiry?.amountPaid" class="obi-track-request-list-item-amount">
          Amount clarified: {{ billInquiry?.contestedAmount ? billInquiry?.contestedAmount : billInquiry?.amountPaid }}
        </div>
      </div>
      <div *ngIf="billInquiry?.sortDateTime">
        <div
          class="obi-track-request-list-item-status"
          [class.history]="activeTab === historyKey">
          {{ billInquiry?.displayStatus }}
        </div>
        <div class="obi-track-request-list-item-title">{{ billInquiry?.id }}</div>
        <div class="obi-track-request-list-item-sched">
          {{ billInquiry?.displayAppointmentDate }}
        </div>
      </div>
    </div>
  </div>
  <div
    class="obi-track-request-empty"
    *ngIf="!billInquiryList[activeTab]?.length && !loading">
    <img
      class="obi-track-request-empty-icon"
      src="../../../../assets/icons/obi-track-request-none.svg"
      alt="No Repair History" />
    <div class="obi-track-request-empty-title">No request yet.</div>
  </div>
</div>

<app-omni-view-status
  [modalOpen]="isViewBill"
  [workOrder]="billInquiryDetails"
  (modalClose)="toggleIsViewBill()">
</app-omni-view-status>

<app-omni-loading-modal [showModal]="loading"></app-omni-loading-modal>
