export const HOURS = [
  '&nbsp;',
  '&nbsp;',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

const minutes = [
  '&nbsp;',
  '&nbsp;',
];
for(let count = 0; count != 60; count++) {
  minutes.push(`${count < 10 ? '0': ''}${count}`);
}
export const MINUTES: string[] = minutes;

export const AMS = [
  '&nbsp;',
  '&nbsp;',
  'AM',
  'PM',
];
