import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, lastValueFrom, map } from 'rxjs';

import { environment } from '../environments/environment';
import { CXS_API_URLS, PAGE_URLS } from './common/constants/omni-global.constant';
import { Router } from '@angular/router';
import { OmniSessionService } from './services/omni-session.service';
import { SESSION_KEYS } from './common/constants/obi-global.constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private sessionService: OmniSessionService,
  ) {}

  /**
   * Intercepts all http calls to set authorization and user token
   * @param req 
   * @param next 
   * @returns 
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      url: `${environment.cxsBaseUrl}${req.url}`
    });
    const tokenGenUrl = [CXS_API_URLS.GENERATE_AUTH_TOKEN, CXS_API_URLS.GENERATE_USER_TOKEN, CXS_API_URLS.DECRYPT_DATA];
    if (!tokenGenUrl.filter((url) => req.url.includes(url))?.length) {
      return from(this.handle(req, next))
    }
    return next.handle(req);
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.sessionService.getAuthToken();
    const userToken = this.sessionService.getUserToken();
    const verificationToken = this.sessionService.getData(SESSION_KEYS.OBI_VERIFICATION_TOKEN)

    if (!authToken || !userToken) {
      this.router.navigate([PAGE_URLS.GENERIC_ERROR_PAGE_URL]);
    }

    let headers = {
      'Authorization': `${authToken}`,
      'User-Token': `${userToken}`,
      'Content-Type': `application/json`,
    } as { [key: string]: string };

    if (verificationToken) {
      headers['VerificationToken'] = verificationToken;
    }
    
    req = req.clone({
      setHeaders: headers
    });

    return await lastValueFrom(next.handle(req));
  }


}