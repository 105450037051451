import { Component, OnInit } from '@angular/core';
import { OmniTrackMyRepairService } from './omni-track-my-repair.service';
import {
  PAGE_URLS,
  SESSION_KEYS,
} from 'src/app/common/constants/omni-global.constant';
import { ActivatedRoute, Router } from '@angular/router';
import {
  OmniTrackMyRepairDateMapping,
  OmniTrackMyRepairStatusMapping,
  OmniTrackMyRepairWorkOrder,
} from './omni-track-my-repair.interface';
import {
  APPOINTMENT_DATE_FORMAT,
  APPOINTMENT_DATE_MAPPING,
  OMNI_TRACK_STATUS,
  OMNI_TRACK_MY_REPAIR_INIT_VALUES,
} from 'src/app/common/constants/omni-track-my-repair.constant';
import * as moment from 'moment';
import { OmniSessionService } from 'src/app/services/omni-session.service';
import { OmniCommonService } from 'src/app/services/omni-common.services';

const { RECENT_TAB_KEY, HISTORY_TAB_KEY } = OMNI_TRACK_MY_REPAIR_INIT_VALUES;

@Component({
  selector: 'app-omni-track-my-repair',
  templateUrl: './omni-track-my-repair.component.html',
  styleUrls: ['./omni-track-my-repair.component.scss'],
})
export class OmniTrackMyRepairComponent implements OnInit {
  /**
   * List of work orders for the current account
   */
  private accountNumber: string = '';

  /**
   * List of work orders for the current account
   */
  public workOrdersList: OmniTrackMyRepairWorkOrder[] = [];

  /**
   * workOrderListRecent
   * workOrderListHistory
   */
  public workOrderListRecent: OmniTrackMyRepairWorkOrder[] = [];
  public workOrderListHistory: OmniTrackMyRepairWorkOrder[] = [];

  /**
   * Page Modal loading variable
   */
  public loading: boolean = true;

  /**
   * NavTab variable
   */
  public recentKey: string = RECENT_TAB_KEY;
  public historyKey: string = HISTORY_TAB_KEY;
  public activeTab: string = RECENT_TAB_KEY;

  public isViewWorkOder: boolean = false;
  public workOrderDetails: any = {};

  constructor(
    private omniTrackMyRepairService: OmniTrackMyRepairService,
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: OmniSessionService,
    private commonService: OmniCommonService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const encrpyptedData = decodeURIComponent(params['data']);
      if (!encrpyptedData) {
        this.router.navigate([PAGE_URLS.GENERIC_ERROR_PAGE_URL]);
      }
      this.commonService.validateInitParams(encrpyptedData, () => {
        this.accountNumber = this.sessionService.getData(
          SESSION_KEYS.OMNI_ACCOUNT_NUMBER_KEY
        );

        this.omniTrackMyRepairService
          .getWorkOrderDetails(this.accountNumber)
          .subscribe({
            next: res => {
              if (
                res &&
                res.result &&
                res.result?.GBPWorkOrder
              ) {
                /**
                 * OM24H-198: Track my Request - History
                 */
                const GBPWorkOrderList = res.result.GBPWorkOrder;

                GBPWorkOrderList.forEach(workOrder => {
                  const {
                    status,
                    subStatus,
                    appointmentDate,
                    appointmentTime
                  } = workOrder;

                  workOrder.displayStatus = this.mapWorkOrderStatus(status || '', subStatus);
                  workOrder.displayAppointmentDate = this.mapAppointmentDate(`${appointmentDate} ${appointmentTime}`);
                  const appointmentDateWithTime = appointmentDate.includes(':') ? appointmentDate : `${appointmentDate} 00:00:00`;
                  workOrder.displayAppointmentDate = this.mapAppointmentDate(`${appointmentDateWithTime} ${appointmentTime}`);


                  const workOrderDisplayStatus = workOrder.displayStatus.toUpperCase();

                  switch (workOrderDisplayStatus) {
                    case 'OPEN':
                      this.router.navigate([PAGE_URLS.GENERIC_ERROR_PAGE_URL]);
                      break;

                    case 'REPAIR CLOSED':
                      this.workOrderListHistory.push(workOrder);
                      break;

                    case 'CANCELLED':
                    case '':
                      // excluded in the list
                      break;

                    default:
                      this.workOrderListRecent.push(workOrder);
                      break;
                  }

                });

              }
              this.loading = false;
              this.workOrdersList = this.workOrderListRecent;
            },
            error: error => {
              this.commonService.handleAPIError(error);
              this.loading = false;
            },
          });
      });
    });
  }

  /**
   * Maps the work order status from response to defined display text
   * @param status
   * @returns
   */
  mapWorkOrderStatus(status: string, subStatus?: string): string {
    let result = '';

    const matchingEntry = OMNI_TRACK_STATUS.find(entry => {
      return (
        entry.status === status &&
        (subStatus === undefined || entry.subStatus.includes(subStatus))
      );
    });

    if (matchingEntry) {
      result = matchingEntry.title;
    }

    return result;
  }

  /**
   * Maps the custom appointment date from response to defined display text
   * @param date
   * @returns
   */
  mapAppointmentDate(date: string) {
    let result = date;
    const appointmentDate = moment(date, APPOINTMENT_DATE_FORMAT);
    const appointmentDateMapping = APPOINTMENT_DATE_MAPPING.filter(
      (schedule: OmniTrackMyRepairDateMapping) =>
        schedule.flag === appointmentDate.format('A')
    );
    if (appointmentDateMapping?.length) {
      result = `${appointmentDate.format('MMMM DD, YYYY')}, ${
        appointmentDateMapping[0].schedule
      }`;
    }
    return result;
  }

  changeActiveNav(event: any, clickTab: string) {
    event.preventDefault();
    this.activeTab = clickTab;
    if (this.activeTab == HISTORY_TAB_KEY) {
      this.workOrdersList = this.workOrderListHistory;
    } else {
      this.workOrdersList = this.workOrderListRecent;
    }
  }

  onSelectedWorkOrder(workOrder: OmniTrackMyRepairWorkOrder | {}) {
    this.isViewWorkOder = !this.isViewWorkOder;
    this.workOrderDetails = workOrder;
  }
}
