import { Component } from '@angular/core';
import { OMNI_PLATFORM_DOWN_INIT_VALUES } from '../../../common/constants/omni-platform-down.constant';
import { PAGE_URLS } from "../../../common/constants/omni-global.constant";

const {
  ERROR_IMAGE,
  ERROR_TITLE,
  ERROR_SUBTEXT,
  GOTO_DASHBOARD_BTN_LABEL
} = OMNI_PLATFORM_DOWN_INIT_VALUES;

const { GOTO_DASHBOARD_URL } = PAGE_URLS;

@Component({
  selector: 'app-omni-platform-down',
  templateUrl: './omni-platform-down.component.html',
  styleUrls: ['./omni-platform-down.component.scss']
})
export class OmniPlatformDownComponent {
  public errorImage: string = ERROR_IMAGE;
  public errorTitle: string = ERROR_TITLE;
  public errorSubtext: string = ERROR_SUBTEXT;
  public gotoDashboardLabel: string = GOTO_DASHBOARD_BTN_LABEL;
  public goToDashboardURL: string = GOTO_DASHBOARD_URL;

  constructor() { }

  ngOnInit(): void {
  }
}
