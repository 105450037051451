<div
  class="omni-view-status__modal"
  [class.disable-scrolling]="modalOpen"
  *ngIf="modalOpen">
  <div class="omni-view-status__modal-item">
    <div
      class="omni-view-status__modal-item-status-card{{workOrderData.displayStatus === 'Cancelled' ? ' cancelled' : ''}}">
      <div class="omni-view-status__modal-item-status-card-view-status-icon">
        <div
          class="omni-view-status__modal-item-status-card-icon-wrapper{{workOrderData.displayStatus === 'Cancelled' ? ' cancelled' : ''}}">
          <img [src]="requestIcon" />
        </div>
      </div>
      <div class="omni-view-status__modal-item-status-card-status-txt">Status</div>
      <div class="omni-view-status__modal-item-status-card-status-label">
        {{isBillInquiry ? workOrderData.status : workOrderData.displayStatus}}
      </div>
      <div class="omni-view-status__modal-item-status-card-work-details">
        <div class="omni-view-status__modal-item-status-card-work-details-order-no-label">{{title.numberType}}</div>
        <div class="omni-view-status__modal-item-status-card-work-details-order-no-value">{{workOrderData.id}}</div>
      </div>
    </div>
    <div class="omni-view-status__modal-item-status-card warning" *ngIf="warningMessage !== undefined && warningMessage !== null && warningMessage !== ''">
      <div class="omni-view-status__modal-item-status-card-warning-wrapper">
        <div class="omni-view-status__modal-item-status-card-warning-wrapper-warning-icon">
          <img [src]="warningIcon" />
        </div>
        <div class="omni-view-status__modal-item-status-card-warning-wrapper-warning-text"
          [innerHTML]="warningMessage">
        </div>
      </div>
    </div>
    <div class="omni-view-status__modal-item-status-card">
      <div class="omni-view-status__modal-item-status-card-title">{{title.itemDetails}}</div>
      <div class="omni-view-status__modal-item-status-card-details resolution"
        *ngIf="workOrderData.displayStatus === 'Repair completed'">
        {{resolution}}
      </div>
      <div class="omni-view-status__modal-item-status-card-description{{isBillInquiry ? ' bill' : ''}}">
        {{workOrderData.displayStatus === 'Repair completed'? 'Date Resolved' : isBillInquiry ? 'Account name':
        'Technician visit date and time'}}
      </div>
      <div class="omni-view-status__modal-item-status-card-details-b{{isBillInquiry ? ' bill' : ''}}">
        {{isBillInquiry ? workOrderData.accountName : workOrderData.displayAppointmentDate}}
      </div>
      <div class="omni-view-status__modal-item-status-card-description bill" *ngIf="isBillInquiry">
        Account number
      </div>
      <div class="omni-view-status__modal-item-status-card-details-b bill">
        {{workOrderData.accountNumber}}
      </div>
    </div>
    <div class="omni-view-status__modal-item-status-card" *ngIf="workOrderData.displayStatus !== 'Repair completed' && (showRepairDetails || isBillInquiry)">
      <div class="omni-view-status__modal-item-status-card-title">{{title.inquiryDetails}}</div>
      <div class="omni-view-status__modal-item-status-card-description{{isBillInquiry ? ' secondCard' : ''}}">
        {{isBillInquiry ? 'Date and time submitted' : 'Concern'}}
      </div>
      <div class="omni-view-status__modal-item-status-card-details{{isBillInquiry ? ' bill' : ''}}">
        {{isBillInquiry ? workOrderData.dateSubmitted : workDetailNotes.concern}}
      </div>
      <div *ngIf="workOrderData?.billDisputedMonth" class="omni-view-status__modal-item-status-card-description{{isBillInquiry ? ' secondCard' : ''}}">
        {{isBillInquiry ? 'Disputed month of bill' : 'Description'}}
      </div>
      <div class="omni-view-status__modal-item-status-card-details" *ngIf="!isBillInquiry">
        {{workDetailNotes.description}}
      </div>
      <div *ngIf="isBillInquiry">
        <div *ngIf="workOrderData?.billDisputedMonth" class="omni-view-status__modal-item-status-card-details bill">
          <span *ngFor="let billDates of workOrderData.billDisputedMonth">
            {{billDates}} <br />
          </span>
        </div>
        <div *ngFor="let row of additionalRow">
          <ng-container *ngIf="workOrderData[row.key]">
            <div class="omni-view-status__modal-item-status-card-description secondCard">
              {{row.title}}
            </div>
            <div class="omni-view-status__modal-item-status-card-details bill">
              {{workOrderData[row.key]}}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="omni-view-status__modal-item-status-card" *ngIf="workOrderData.displayStatus === 'Repair completed'">
      <div class="omni-view-status__modal-item-status-card-title">Reminder</div>
      <div class="omni-view-status__modal-item-status-card-details resolution">
        {{reminder}}
      </div>
    </div>
    <div class="omni-view-status__modal-item-status-card"
      *ngIf="isRescheduleRequestEnable && (workOrderData.displayStatus === 'Repair confirmed' || workOrderData.displayStatus === 'Rescheduled') && reschedCount < 3 && isSchedDateMoreThanOneDay">
      <div class="omni-view-status__modal-item-status-card-reschedule-title">
        {{rescheduleRequest.title}}
      </div>
      <div class="omni-view-status__modal-item-status-card-reschedule-desc">
        {{rescheduleRequest.description}}
      </div>
      <div class="omni-view-status__modal-item-status-card-reschedule-btn" (click)="rescheduleVisit()">
        {{rescheduleRequest.action}}
      </div>
    </div>
    <div class="omni-view-status__modal-item-status-card shadowbg"
      *ngIf="workOrderData.displayStatus === 'Repair completed'">
      <div class="omni-view-status__modal-item-status-card-iscomplete ">
        Did we fix your issue?
      </div>
      <div class="omni-view-status__modal-item-status-card-reschedule-btn" (click)="completeRequest()">
        Yes
      </div>
      <div class="omni-view-status__modal-item-status-card-reschedule-btn outline" (click)="incompleteRequest()">
        No
      </div>
    </div>
  </div>
</div>
<app-omni-loading-modal [showModal]="loading" [message]="''"></app-omni-loading-modal>
