<div class="omni-error">
  <div class="omni-error__wrapper">
    <div class="omni-error__content">
      <img [src]="errorImage" alt="" class="omni-error__img" />
      <div class="omni-error__content-text-wrapper">
        <p class="omni-error__title" [innerHTML]="errorTitle"></p>
        <p class="omni-error__subtext" [innerHTML]="errorSubtext"></p>
      </div>
    </div>

    <div class="omni-error__btn-wrapper">
      <a [attr.href]="fbMsgrURL" (click)="pushGtmEvent(chatWithMsgrTag)">
        <button
          class="omni-error__btn-primary"
          [innerHTML]="chatWithMsgr"></button>
      </a>

      <a [attr.href]="goToDashboardURL" (click)="pushGtmEvent(tryAgainTag)">
        <button
          class="omni-error__btn-cancel"
          [innerHTML]="tryAgainLabel"></button>
      </a>
    </div>
  </div>
</div>
