import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { OMNI_SCHED_TECHNICIAN_SCHED_NO_SLOTS_INIT_VALUES } from '../../../common/constants/omni-sched-technician-no-slots.constant';
import { OmniSessionService } from 'src/app/services/omni-session.service';

const {
  SCHEDULE_PICKER_HEADER,
  TIME_SELECTION_HEADER,
  BANNER_IMG,
  BANNER_HEADER,
  BANNER_SUBTEXT,
} = OMNI_SCHED_TECHNICIAN_SCHED_NO_SLOTS_INIT_VALUES;

@Component({
  selector: 'app-omni-sched-technician-no-slots',
  templateUrl: './omni-sched-technician-no-slots.component.html',
  styleUrls: ['./omni-sched-technician-no-slots.component.scss'],
})
export class OmniSchedTechnicianNoSlotsComponent implements OnChanges {
  @Input() showBanner: boolean = true;
  @Input() clearSelection: boolean = false;
  @Output() dateSelected = new EventEmitter<string>();
  @Output() timeSelected = new EventEmitter<string>();
  @Input() selectedDate: string | null = null;
  @Input() selectedTime: string | null = null;
  selectedCalendarDate: NgbDateStruct | null = null;
  showCalendar: boolean = false;
  showDropdown = false;
  options: string[] = [];

  schedulePickerHeader: string = SCHEDULE_PICKER_HEADER;
  timeSelectionHeader: string = TIME_SELECTION_HEADER;

  noSlotBannerImg: string = BANNER_IMG;
  noSlotBannerHeader: string = BANNER_HEADER;
  noSlotBannerSubtext: string = BANNER_SUBTEXT;

  appointmentSlots: any[] = [];

  constructor(private omniSessionService: OmniSessionService) {
    this.appointmentSlots = omniSessionService.getUserData().appointmentSlots;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changedSelectedDate = changes['selectedDate'];
    if (changedSelectedDate && !changedSelectedDate.currentValue) {
      // clear calendar selection when selected date is emptied
      this.selectedCalendarDate = NgbDate.from({year: 2000, month: 1, day: 1});
      // clear and close dropdown selection
      this.showDropdown = false;
      this.selectedTime = null;
    }
  }

  /**
   * Shows the calendar date picker modal
   */
  showCalendarPicker() {
    this.showCalendar = true;
  }

  /**
   * Closes the calendar date picker modal
   */
  handleCalendarClosed() {
    this.showCalendar = false;
  }

  /**
   * Handles the date selection of calendar date picker
   * Sets the selected date on the input text box
   * @param date date selected
   */
  handleDateSelection(date: NgbDateStruct) {
    if (date) {
      // add prefix 0 for months/days below 10
      let month: string = date.month < 10 ? `0${date.month}` : `${date.month}`;
      let day: string = date.day < 10 ? `0${date.day}` : `${date.day}`;
      this.selectedDate = `${month} / ${day} / ${date.year}`;

      this.updateTimeSlotsBasedOnDate(`${date.year}-${month}-${day}`);
      this.dateSelected.emit(this.selectedDate);
    }
  }

  /**
   * Toggles (show/hide) the time selection dropdown
   */
  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  /**
   * Selects the time option in the time picker dropdown
   * @param option the time option selected
   */
  selectOption(option: string) {
    this.selectedTime = option;
    this.showDropdown = false;
    this.timeSelected.emit(this.selectedTime);
  }

  /**
   * Updates time slots selections based on the date selected
   * @param date the date selected
   */
  updateTimeSlotsBasedOnDate(date: string) {
    this.options = [];
    this.selectedTime = null;

    let appointmentSlot = this.appointmentSlots.find(slot => slot.date == date);
    if (appointmentSlot) {
      if (appointmentSlot.slots.morning > 0) {
        this.options.push('9:00 AM - 12:00 NN');
      }
      if (appointmentSlot.slots.evening > 0) {
        this.options.push('1:00 PM - 5:00 PM');
      }
    }
  }
}
