export const OMNI_GENERIC_ERROR_TAG = {
  event_action: 'landing-page',
  event_label: 'genericerrorpage-landingpage',
  section: 'genericerrorpage',
  element: 'landingpage',
};

export const OMNI_GENERIC_ERROR_CHAT_TAG = {
  event_action: 'landing-page',
  event_label: 'genericerrorpage-landingpage-chatwithusviafbmessenger',
  section: 'genericerrorpage',
  element: 'chatwithusviafbmessenger',
};

export const OMNI_GENERIC_ERROR_RETRY_TAG = {
  event_action: 'landing-page',
  event_label: 'genericerrorpage-landingpage-letstryagain',
  section: 'genericerrorpage',
  element: 'letstryagain',
};

export const OMNI_SCHED_TECHNICIAN_SUBMIT_TAG = {
  event_action: 'click',
  event_label: 'schedulepage-button-submit',
  within_24hr: 'yes',
};

export const OMNI_SCHED_TECHNICIAN_CANCEL_TAG = {
  event_action: 'click',
  event_label: 'schedulepage-button-cancel',
  within_24hr: 'yes',
};

export const OMNI_SCHED_TECHNICIAN_MODAL_YES_TAG = {
  event_action: 'click',
  event_label: 'schedulemodal-button-yes',
  within_24hr: 'yes',
};

export const OMNI_SCHED_TECHNICIAN_MODAL_NO_TAG = {
  event_action: 'click',
  event_label: 'schedulemodal-button-no',
  within_24hr: 'yes',
};

export const OMNI_REQUEST_CONFIRMED_LANDING_TAG = {
  event_action: 'landing-page',
  event_label: 'scheduletechpage-landingpage-technnicianvisitconfirmed',
  within_24hr: 'yes',
};

export const OMNI_REQUEST_CONFIRMED_DOWNLOAD_TAG = {
  event_action: 'click',
  event_label: 'technnicianvisitconfirmed-button-download',
  within_24hr: 'yes',
};

export const OMNI_REQUEST_CONFIRMED_COPY_TAG = {
  event_action: 'click',
  event_label: 'technnicianvisitconfirmed-button-copy',
  within_24hr: 'yes',
};

export const OMNI_REQUEST_CONFIRMED_GOTODASHBOARD_TAG = {
  event_action: 'click',
  event_label: 'technnicianvisitconfirmed-button-gotodashboard',
  within_24hr: 'yes',
};

export const OMNI_BASIC_TROUBLESHOOTING_CONFIRM_TAG = {
  event_action: 'click',
  event_label: 'didwefixyourconcernpage-button-yes',
  within_24hr: 'yes',
};

export const OMNI_BASIC_TROUBLESHOOTING_CANCEL_TAG = {
  event_action: 'click',
  event_label: 'didwefixyourconcernpage-button-no',
  within_24hr: 'yes',
};

export const OMNI_ALL_GOOD_TAG = {
  event_action: 'click',
  event_label: 'diagnosiscompletepage-landingpage-allgood',
  within_24hr: 'yes',
};

export const OMNI_WELCOMEBACK_TAG = {
  event_action: 'click',
  event_label: 'diagnosiscompletepage-landingpage-welcomback',
  within_24hr: 'yes',
};

export const OMNI_DIAGNOSTIC_LANDING_TAG = {
  event_action: 'landing-page',
  event_label: 'checkingdiagnosispage-landingpage',
  section: 'checkingdiagnosispage',
  element: 'landingpage',
};

export const OMNI_DIAGNOSTIC_ACCOUNT_TAG = {
  event_action: 'landing-page',
  event_label: 'checkingdiagnosispage-landingpage-accountactive',
  section: 'checkingdiagnosispage',
  element: 'landingpage',
};

export const OMNI_DIAGNOSTIC_NETWORK_TAG = {
  event_action: 'landing-page',
  event_label: 'checkingdiagnosispage-landingpage-networkgood',
  section: 'checkingdiagnosispage',
  element: 'landingpage',
};

export const OMNI_DIAGNOSTIC_FIBER_TAG = {
  event_action: 'landing-page',
  event_label: 'checkingdiagnosispage-landingpage-fiberlinegood',
  section: 'checkingdiagnosispage',
  element: 'landingpage',
};

export const OMNI_DIAGNOSTIC_COMPLETE_TAG = {
  event_action: 'landing-page',
  event_label: 'diagnosiscompletepage-landingpage-complete',
  section: 'diagnosiscompletepage',
  element: 'landingpage',
};

export const OMNI_DIAGNOSTIC_COMPLETE_MODAL_NO_TAG = {
  event_action: 'click',
  event_label: 'diagnosiscompletemodal-button-no',
  section: 'diagnosiscompletemodal',
  element: 'button',
};

export const OMNI_DIAGNOSTIC_COMPLETE_MODAL_YES_TAG = {
  event_action: 'click',
  event_label: 'diagnosiscompletemodal-button-yes',
  section: 'diagnosiscompletemodal',
  element: 'button',
};

export const OMNI_DIAGNOSTIC_ACCOUNT_FAIL_OVERDUE_TAG = {
  event_action: 'click',
  event_label: 'checkingdiagnosismodal-button-overdue-paynow',
  section: 'checkingdiagnosismodal',
  element: 'button-overdue',
};

export const OMNI_DIAGNOSTIC_ACCOUNT_FAIL_DASHBOARD_TAG = {
  event_action: 'click',
  event_label: 'checkingdiagnosismodal-button-overdue-gobacktodashboard',
  section: 'checkingdiagnosismodal',
  element: 'button-overdue',
};

export const OMNI_DIAGNOSTIC_ACCOUNT_FAIL_CONTINUE_TAG = {
  event_action: 'click',
  event_label: 'checkingdiagnosismodal-button-overdue-continuetocheckyourconnection',
  section: 'checkingdiagnosismodal',
  element: 'button-overdue',
};

export const OMNI_DIAGNOSTIC_DISCONNECT_FAIL_FB_TAG = {
  event_action: 'click',
  event_label: 'checkingdiagnosismodal-button-disconnected-chatviafbmessenger',
  section: 'checkingdiagnosismodal',
  element: 'button-disconnected',
};

export const OMNI_DIAGNOSTIC_DISCONNECT_FAIL_DASHBOARD_TAG = {
  event_action: 'click',
  event_label: 'checkingdiagnosismodal-button-disconnected-gobacktodashboard',
  section: 'checkingdiagnosismodal',
  element: 'button-disconnected',
};

export const OMNI_DIAGNOSTIC_NODATA_FAIL_PROMO_TAG = {
  event_action: 'click',
  event_label: 'checkingdiagnosismodal-button-nodata-buypromos',
  section: 'checkingdiagnosismodal',
  element: 'button-nodata',
};

export const OMNI_DIAGNOSTIC_NODATA_FAIL_DASHBOARD_TAG = {
  event_action: 'click',
  event_label: 'checkingdiagnosismodal-button-nodata-gobacktodashboard',
  section: 'checkingdiagnosismodal',
  element: 'button-nodata',
};

export const OMNI_DIAGNOSTIC_OUTAGE_FAIL_DASHBOARD_TAG = {
  event_action: 'click',
  event_label: 'checkingdiagnosismodal-button-outage-gobacktodashboard',
  section: 'checkingdiagnosismodal',
  element: 'button-outage',
};

export const OMNI_DIAGNOSTIC_FIBERMODEM_FAIL_SCHEDULE_TAG = {
  event_action: 'click',
  event_label: 'checkingdiagnosismodal-button-modemline-scheduleatechnicianvisit',
  section: 'checkingdiagnosismodal',
  element: 'button-modemline',
};

export const OMNI_DIAGNOSTIC_FIBERMODEM_FAIL_FB_TAG = {
  event_action: 'click',
  event_label: 'checkingdiagnosismodal-button-modemline-chatviafbmessenger',
  section: 'checkingdiagnosismodal',
  element: 'button-modemline',
};

export const OMNI_DIAGNOSTIC_FIBERMODEM_FAIL_DASHBOARD_TAG = {
  event_action: 'click',
  event_label: 'checkingdiagnosismodal-button-modemline-gobacktodashboard',
  section: 'checkingdiagnosismodal',
  element: 'button-modemline',
};

export const OMNI_DIAGNOSTIC_MODEM_FAIL_TROUBLESHOOT_TAG = {
  event_action: 'click',
  event_label: 'checkingdiagnosismodal-button-modemissue-starttroubleshootingnow',
  section: 'checkingdiagnosismodal',
  element: 'button-modemissue',
};

export const OMNI_DIAGNOSTIC_MODEM_FAIL_DASHBOARD_TAG = {
  event_action: 'click',
  event_label: 'checkingdiagnosismodal-button-modemissue-gobacktodashboard',
  section: 'checkingdiagnosismodal',
  element: 'button-modemissue',
};
