import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-omni-loading-modal',
  templateUrl: './omni-loading-modal.component.html',
  styleUrls: ['./omni-loading-modal.component.scss'],
})
export class OmniLoadingModalComponent {
  @Input() showModal: boolean = true;
  // TODO: replace with configurable constants
  @Input() header: string = 'Please wait';
  @Input() message: string = 'This will only take a moment.';
}
