import { Injectable } from '@angular/core';
import { PAGE_URLS, SESSION_KEYS } from '../common/constants/omni-global.constant';
import * as CryptoJS from 'crypto-js';
import { environment  } from '../../environments/environment';
const {
  OMNI_AUTH_TOKEN_KEY,
  OMNI_USER_TOKEN_KEY,
  OMNI_USER_DATA_KEY
} = SESSION_KEYS;

const enEncryptionKey = environment.aesEncryptionKey;

@Injectable({
  providedIn: 'root'
})
export class OmniSessionService {

  // Encrypt data using AES encryption
  private encryptData(data: string): string {
    return CryptoJS.AES.encrypt(data, enEncryptionKey).toString();
  }

  // Decrypt data using AES decryption
  private decryptData(data: string): string {
    const bytes = CryptoJS.AES.decrypt(data, enEncryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  // Set the authentication token in the session
  setAuthToken(authToken: string): void {
    const encryptedAuthToken = this.encryptData(authToken);
    sessionStorage.setItem(OMNI_AUTH_TOKEN_KEY, encryptedAuthToken);
  }

  // Get the authentication token from the session
  getAuthToken(): string | null {
    const encryptedAuthToken = sessionStorage.getItem(OMNI_AUTH_TOKEN_KEY);
    return encryptedAuthToken ? this.decryptData(encryptedAuthToken) : null;
  }

  //Set user token
  setUserToken(userToken: string): void {
    const encryptedUserToken = this.encryptData(userToken);
    sessionStorage.setItem(OMNI_USER_TOKEN_KEY, encryptedUserToken);
  }

  //Get user token
  getUserToken(): string | null {
    const encryptedUserToken = sessionStorage.getItem(OMNI_USER_TOKEN_KEY);
    return encryptedUserToken ? this.decryptData(encryptedUserToken) : null;
  }

  // Set user data in the session
  setUserData(data: any): void {
    const encryptedUserData = this.encryptData(JSON.stringify(data));
    sessionStorage.setItem(OMNI_USER_DATA_KEY, encryptedUserData);
  }


  /**
   * Get user data from the session
   * userData includes the following properties:
   * first_name
   * last_name
   * mobile_number
   * alternative_mobile_number
   * schedule_repair_date
   * schedule_repair_time
   * @returns 
   */
  getUserData(): any | null {
    const encryptedUserData = sessionStorage.getItem(OMNI_USER_DATA_KEY);
    return encryptedUserData ? JSON.parse(this.decryptData(encryptedUserData)) : null;
  }

  // Get generic data from the session
  getData(key: string): any | null {
    const encryptedUserData = sessionStorage.getItem(key);
    return encryptedUserData ? JSON.parse(this.decryptData(encryptedUserData)) : null;
  }

  // Set generic data in the session
  setData(key: string, data: any): void {
    if (data) {
      const encryptedUserData = this.encryptData(JSON.stringify(data));
      sessionStorage.setItem(key, encryptedUserData);
    }
  }

  // Clear the session data
  clearSession(): void {
    sessionStorage.removeItem(OMNI_AUTH_TOKEN_KEY);
    sessionStorage.removeItem(OMNI_USER_TOKEN_KEY);
    sessionStorage.removeItem(OMNI_USER_DATA_KEY);
  }
}
