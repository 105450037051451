import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  OBI_BILL_INQUIRY_CANCEL,
  OBI_BILL_INQUIRY_CONCERN_MAX,
  OBI_BILL_INQUIRY_CONCERN_MIN_HEIGHT,
  OBI_BILL_INQUIRY_ISSUE_FORMS,
  OBI_BILL_INQUIRY_ISSUE_TYPES,
  OBI_BILL_INQUIRY_LABEL,
  OBI_BILL_INQUIRY_TITLE,
  OBI_BILL_INQUIRY_USER_LABEL,
} from 'src/app/common/constants/obi-bill-inquiry.constants';
import {
  CXS_API_NAMES,
  PAGE_URLS,
  SESSION_KEYS,
} from 'src/app/common/constants/obi-global.constants';
import { OmniCommonService } from 'src/app/services/omni-common.services';
import { OmniSessionService } from 'src/app/services/omni-session.service';
import { ObiBillInquiryService } from './obi-bill-inquiry.service';
import * as moment from 'moment';
import { GoogleTagManagerService } from 'src/app/services/google-tag-manager-service.service';
import {
  OBI_BILL_INQUIRY_CANCEL_NO_TAG,
  OBI_BILL_INQUIRY_CANCEL_TAG,
  OBI_BILL_INQUIRY_CANCEL_YES_TAG,
  OBI_BILL_INQUIRY_SUBMIT_ERROR_TAG,
  OBI_BILL_INQUIRY_SUBMIT_TAG,
  OBI_TICKET_RECEIPT_TAG,
} from 'src/app/common/constants/obi-gtm-events.constants';

@Component({
  selector: 'app-obi-bill-inquiry',
  templateUrl: './obi-bill-inquiry.component.html',
  styleUrls: ['./obi-bill-inquiry.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ObiBillInquiryComponent
  implements AfterViewInit, AfterViewChecked
{
  /**
   * Title spiel for the header
   */
  public obiBillInquiryTitle = OBI_BILL_INQUIRY_TITLE;

  /**
   * Label spiel for the header input field
   */
  public obiBillingInquiryUserLabel = OBI_BILL_INQUIRY_USER_LABEL;

  /**
   * Label spiels for the form
   */
  public obiBillingInquiryLabels = OBI_BILL_INQUIRY_LABEL;

  /**
   * Spiels for cancel modal
   */
  public obiBillInquiryCancel = OBI_BILL_INQUIRY_CANCEL;

  /**
   * List of issue types for issue dropdown
   */
  public obiBillInquiryIssueTypes = OBI_BILL_INQUIRY_ISSUE_TYPES;

  /**
   * Flag for displaying issue types dropdown
   */
  public isIssueTypesShow = false;

  /**
   * Action for cancel modal confirm button
   */
  public obiBillInquiryCancelConfirmAction = () => {};

  /**
   * Action for cancel modal cancel button
   */
  public obiBillInquiryCancelCancelAction = () => {};

  /**
   * Flag for displaying cancel modal
   */
  public isCancel = false;

  /**
   * Flag for displaying cancel modal
   */
  public isLoading = false;

  /**
   * Account number of customer
   */
  public accountNumber: string = '';

  /**
   * Mobile number of customer
   */
  public mobileNumber: string = '';

  /**
   * Segment type of account
   */
  public segment: string = '';

  /**
   * Landline number of customer
   */
  public landlineNumber: string = '';

  /**
   * User first name to be displayed in header
   */
  public obiBillingInquiryUserName = '';

  /**
   * User mobile number to be displayed in header
   */
  public obiBillingInquiryUserNumber = '';

  /**
   * Billing inquiries selected
   * TODO: Integration update data
   */
  public obiBillingInquiries: any[] = [];

  public isHere: boolean = false;

  public isBilling: boolean = false;

  public isReceipt: boolean = false;

  public selectedBill: any = {};

  @ViewChild('billInquiryFormRef')
  billInquiryFormRef: ElementRef | undefined;

  /**
   * Max char length of concern textarea
   */
  concernTextareaMaxLength = OBI_BILL_INQUIRY_CONCERN_MAX;

  /**
   * Max char length of concern textarea
   */
  concernTextareaMinHeight = OBI_BILL_INQUIRY_CONCERN_MIN_HEIGHT;

  /**
   * Character count of concern field
   */
  concernTextareaCharCount = 0;

  /**
   * Character count left allowed for concern field
   */
  concernTextareaRemainingChars = 100;

  /**
   * List of forms based on the issue type
   */
  obiBillInquiryFormList = OBI_BILL_INQUIRY_ISSUE_FORMS;

  /**
   * Form to be displayed
   */
  public obiBillInquiryForm = OBI_BILL_INQUIRY_ISSUE_FORMS[0];

  /**
   * List of toggle flags for select fields
   */
  public selectFieldToggle: any = {};

  /**
   * List of toggle flags for date fields
   */
  public dateFieldToggle: any = {};

  /**
   * List of toggle flags for time fields
   */
  public timeFieldToggle: any = {};

  /**
   * List of errors for form group
   */
  public formErrorList: any = {};

  /**
   * FormGroup to manage the bill inquiry form
   */
  public billInquiryForm: FormGroup = new FormGroup({
    issueType: new FormControl('', [Validators.required]),
  });

  /**
   * Bill inquiry details to receipt
   */
  public billReceipt: any = [];

  private subscriptionList: any = {};

  constructor(
    private route: ActivatedRoute,
    public commonService: OmniCommonService,
    public sessionService: OmniSessionService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private billInquiryService: ObiBillInquiryService,
    private gtmService: GoogleTagManagerService
  ) {
    this.sessionService.setData(SESSION_KEYS.OBI_TAG, true);
    this.fetchBillInquiryFormData();
    this.generateSelectOptionToggle();
    this.generateDateOptionToggle();
    this.generateTimeOptionToggle();
    this.updateForm('');
  }

  ngAfterViewInit(): void {
    this.obiBillInquiryCancelConfirmAction = () => {
      this.toggleCancelModal();
    };
    this.obiBillInquiryCancelCancelAction = () => {
      this.commonService.navigate(this.obiBillInquiryCancel.cancelButtonUrl);
      this.pushGtmEvent(OBI_BILL_INQUIRY_CANCEL_YES_TAG);
    };
    this.onloadAdjustTextarea();
  }

  private fetchBillInquiryFormData() {
    this.segment = this.sessionService.getData(SESSION_KEYS.OBI_SEGMENT_KEY);
    this.obiBillingInquiryUserName = this.sessionService.getData(
      SESSION_KEYS.OBI_ACCOUNT_ALIAS
    );
    if (this.segment === 'mobile') {
      this.obiBillingInquiryUserNumber = this.sessionService
        .getData(SESSION_KEYS.OBI_MOBILE_NUMBER)
        .replace(/(\d{4})(\d{3})(\d{4})/, '$1 $2 $3');
    } else {
      this.obiBillingInquiryUserNumber = this.sessionService.getData(
        SESSION_KEYS.OBI_ACCOUNT_NUMBER_KEY
      );
    }
    const billDetails: any[] =
      this.sessionService.getData(SESSION_KEYS.OBI_BILL_DETAILS) ?? [];
    if (billDetails?.length) {
      billDetails.forEach(detail => {
        const billStartDate = moment(detail.billStartDate).format('M/D/YYYY');
        const billEndDate = moment(detail.billEndDate).format('M/D/YYYY');
        const amountInt = (`${detail?.totalAmount}` ?? '0')
          .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')
          .split('.');
        const amountDec = (
          amountInt?.length > 1 ? amountInt[1] + '00' : '00'
        ).substring(0, 2);
        const billDetail = {
          dates: `${billStartDate} - ${billEndDate}`,
          amount: `₱${amountInt[0]}.${amountDec}`,
          mobileNumber: this.sessionService.getData(
            SESSION_KEYS.OBI_MOBILE_NUMBER
          ),
          billId: detail.billId,
        };
        this.obiBillingInquiries.push(billDetail);
      });
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Compute the remaining character for the textarea
   */
  public txtAreaInput(event: any, formControlName: string) {
    if (!event?.target) return;

    const inputValue = event.target.value;
    const maxLength = this.concernTextareaMaxLength;
    let sanitizedValue = this.sanitizeTxtAreaInput(inputValue);
    const sanitizedValueLength =
      sanitizedValue?.replace(/(\r\n)/g, '\n')?.length | 0;

    if (sanitizedValueLength > maxLength) {
      sanitizedValue = sanitizedValue.substring(0, maxLength);
      event.target.value = sanitizedValue;
      this.billInquiryForm.patchValue({
        [formControlName]: sanitizedValue,
      });
    } else {
      this.billInquiryForm.patchValue({
        [formControlName]: sanitizedValue,
      });
      this.concernTextareaCharCount = sanitizedValueLength;
      this.concernTextareaRemainingChars = maxLength - sanitizedValue.length;
      this.adjustTextareaHeight(event.target);
    }
  }

  /**
   * Handles keydown event on concern details textarea
   * Prevents further input when reached max length except for non appending keys
   * @param event
   */
  public txtAreaKeydown(event: any) {
    let sanitizedValue = this.sanitizeTxtAreaInput(event.target.value);
    const sanitizedValueLength =
      sanitizedValue?.replace(/(\r\n)/g, '\n')?.length | 0;
    if (
      sanitizedValueLength === this.concernTextareaMaxLength &&
      ((event.keyCode >= 48 && event.keyCode <= 90) ||
        [110, 188, 220, 189, 191, 32, 13].includes(event.keyCode)) &&
      !event.ctrlKey &&
      !event.altKey
    ) {
      event.preventDefault();
    }
  }

  /**
   * Handles paste event on concern details textarea
   * Limits the pasted value to max length of the textarea
   * @param event
   */
  public txtAreaPaste(event: any, formControlName: string) {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    let prevValue = `${this.billInquiryForm.controls[formControlName].value}`;
    prevValue = prevValue.replace(/(\r\n)/g, '\n');
    if (clipboardData) {
      const sanitizedData = this.sanitizeTxtAreaInput(
        clipboardData.getData('text')
      );
      const sanitizedDataNewLineCount =
        sanitizedData.match(/(\r\n)/g)?.length || 0;
      const clippedData = sanitizedData.substring(
        0,
        this.concernTextareaMaxLength -
          this.concernTextareaCharCount +
          (event.target.selectionEnd - event.target.selectionStart) +
          sanitizedDataNewLineCount
      );
      const newConcernDetails = `${prevValue.slice(
        0,
        event.target.selectionStart
      )}${clippedData}${prevValue.slice(event.target.selectionEnd)}`;
      this.billInquiryForm.patchValue({
        [formControlName]: this.sanitizeTxtAreaInput(newConcernDetails),
      });
      event.target.value = this.billInquiryForm.controls[formControlName].value;
      const sanitizedValueLength =
        this.billInquiryForm.controls[formControlName].value?.replace(
          /(\r\n)/g,
          '\n'
        )?.length | 0;
      this.concernTextareaCharCount = sanitizedValueLength;
      this.concernTextareaRemainingChars =
        this.concernTextareaMaxLength - sanitizedValueLength;
      this.adjustTextareaHeight(event.target);
    }
  }

  /**
   * Adjust textarea (concern details) height based on user input
   */
  public adjustTextareaHeight(
    textarea: HTMLTextAreaElement,
    minHeight: number = this.concernTextareaMinHeight,
    isInput: boolean = true
  ) {
    textarea.style.height = minHeight + 2 + 'px';

    if (textarea.scrollHeight > minHeight) {
      textarea.style.height = textarea.scrollHeight + 2 + 'px';
    }

    if (isInput) {
      if (textarea.parentElement?.nextElementSibling) {
        textarea.parentElement?.nextElementSibling.scrollIntoView({
          block: 'end',
        });
      }
    }
  }

  /**
   * Clears inputValue of all characters not allowed
   * @param inputValue
   * @returns
   */
  sanitizeTxtAreaInput(inputValue: string): string {
    return inputValue.replace(/[^a-zA-Z0-9.,\-?! \r\n]/g, '');
  }

  /**
   * Patches the current form group with the selected item
   * @param issueType
   */
  onSelectIssueType(issueType: string) {
    this.billInquiryForm.patchValue({
      issueType: issueType,
    });
    this.toggleIssueTypes();
    this.updateForm(issueType);
  }

  /**
   * Triggers upon selection of option in dropdown
   * Updates form group value
   * @param formControlName
   * @param value
   */
  onSelectOption(
    formControlName: string,
    value: string,
    textarea: HTMLTextAreaElement
  ) {
    this.billInquiryForm.controls[formControlName].patchValue(value);
    this.billInquiryForm.updateValueAndValidity();
    this.timeOfPaymentValidation();
    this.toggleSelectOption(formControlName);
    this.adjustTextareaHeight(textarea, 0);
  }

  /**
   * Triggers upon selection of date in datepicker modal
   * Updates form group value
   * @param formControlName
   * @param value
   */
  onSelectDate(formControlName: string, value: NgbDateStruct) {
    this.billInquiryForm.controls[formControlName].patchValue(value);
    this.billInquiryForm.updateValueAndValidity();
    this.timeOfPaymentValidation();
  }

  /**
   * Triggers upon selection of time in timepicker modal
   * Updates form group value
   * @param formControlName
   * @param value
   */
  onSelectTime(formControlName: string, value: string) {
    this.billInquiryForm.controls[formControlName].patchValue(value);
    this.billInquiryForm.updateValueAndValidity();
  }

  /**
   * Closes toggle state of date modal
   * @param formControlName
   */
  onCloseDateModal(formControlName: string) {
    this.dateFieldToggle[formControlName] = false;
  }

  /**
   * Populates selectOptionToggle list
   */
  generateSelectOptionToggle() {
    this.obiBillInquiryFormList.forEach(issue => {
      issue.forms.forEach(form => {
        if (form.type === 'select') {
          this.selectFieldToggle[form.formControlName] = false;
        }
      });
    });
  }

  /**
   * Populates dateOptionToggle list
   */
  generateDateOptionToggle() {
    this.obiBillInquiryFormList.forEach(issue => {
      issue.forms.forEach(form => {
        if (form.type === 'date') {
          this.dateFieldToggle[form.formControlName] = false;
        }
      });
    });
  }

  /**
   * Populates timeOptionToggle list
   */
  generateTimeOptionToggle() {
    this.obiBillInquiryFormList.forEach(issue => {
      issue.forms.forEach(form => {
        if (form.type === 'time') {
          this.timeFieldToggle[form.formControlName] = false;
        }
      });
    });
  }

  /**
   * Bill Preview on PDF format
   * @param billInquiry
   */
  toggleBillingStatement(billInquiry: any) {
    this.isBilling = !this.isBilling;

    this.selectedBill = {
      billDetails: billInquiry,
      mobileNumber: billInquiry.mobileNumber,
      accountNumber: billInquiry.accountNumber,
      segment: billInquiry.segment,
    };
  }

  /**
   * Parses data needed for submitting bill inquiry
   * Submits parsed data to API
   * Redirects user after successful submission
   */
  submitBillInquiry() {
    this.isLoading = true;
    this.saveFormGroup();
    this.pushGtmEvent(OBI_BILL_INQUIRY_SUBMIT_TAG);
    this.billInquiryService
      .postCreateCase(
        this.billInquiryService.generateCaseCreateRequest(
          this.billInquiryForm.value
        )
      )
      .subscribe({
        next: result => {
          if (result?.result?.caseId) {
            this.pushGtmEvent(OBI_TICKET_RECEIPT_TAG, {
              transaction_id: result.result.caseId,
            });
            this.isReceipt = !this.isReceipt;

            this.billReceipt.accountDetails = [
              { label: 'ACCOUNT NAME', value: this.obiBillingInquiryUserName },
              {
                label:
                  this.segment === 'mobile'
                    ? 'MOBILE NUMBER'
                    : 'ACCOUNT NUMBER',
                value: this.obiBillingInquiryUserNumber,
              },
            ];
            const billDetails = this.sessionService.getData(
              SESSION_KEYS.OBI_BILL_DETAILS
            );
            const billMonths: string[] = [];
            billDetails.forEach((detail: any) => {
              const billStartDate = moment(detail.billStartDate).format(
                'M/D/YYYY'
              );
              const billEndDate = moment(detail.billEndDate).format('M/D/YYYY');
              billMonths.push(`<div>${billStartDate} - ${billEndDate}</div>`);
            });
            this.billReceipt.billDetails = [
              { label: 'DISPUTED MONTH OF BILL', value: billMonths.join('') },
              {
                label: 'BILL LINER',
                value: this.billInquiryForm.value.issueType,
              },
            ];
            this.obiBillInquiryForm.forms.forEach(form => {
              if (form?.receiptLabel && form.formControlName !== 'concern') {
                let formValue =
                  this.billInquiryForm.value[form.formControlName];
                switch (form.type) {
                  case 'amount':
                    formValue = `₱${formValue}`;
                    break;
                  case 'mobile':
                    formValue = `+63${formValue}`;
                    break;
                  case 'date':
                    formValue = `${('0' + formValue.month).slice(-2)}/${(
                      '0' + formValue.day
                    ).slice(-2)}/${formValue.year}`;
                    break;
                }
                this.billReceipt.billDetails.push({
                  label: form.receiptLabel,
                  value: formValue,
                });
              }
            });
            this.billReceipt.concern = this.billInquiryForm.value.concern;
            this.billReceipt.referenceNumber = result.result.caseId;

            this.billInquiryService.billInquiryDetails = this.billReceipt;

            this.isLoading = false;
          } else {
            const errorObj = {
              error_code: result?.result?.code ?? result?.error?.code,
              api_name: CXS_API_NAMES.CREATE_CASE,
            };
            this.pushGtmEvent(
              OBI_BILL_INQUIRY_SUBMIT_ERROR_TAG,
              errorObj,
              true
            );
            this.commonService.navigate(PAGE_URLS.GENERIC_ERROR_PAGE_URL);
          }
        },
        error: error => {
          const errorObj = {
            error_code: error?.error?.code,
            api_name: CXS_API_NAMES.CREATE_CASE,
          };
          this.pushGtmEvent(OBI_BILL_INQUIRY_SUBMIT_ERROR_TAG, errorObj, true);
          this.commonService.navigate(PAGE_URLS.GENERIC_ERROR_PAGE_URL);
        },
      });
  }

  /**
   * Stores form information in cache
   */
  saveFormGroup() {
    const { amount, altMobileNumber, concern, issueType } =
      this.billInquiryForm.value;
    const formData = {
      accountAlias: this.obiBillingInquiryUserName,
      accountNumber: this.accountNumber,
      altMobileNumber: `+63${altMobileNumber}`,
      billDetails: this.obiBillingInquiries,
      billCharge: issueType,
      concern: concern,
      disputeAmount: amount,
    };
    this.sessionService.setData(SESSION_KEYS.OBI_BILL_INQUIRY_FORM, formData);
  }

  /**
   * Shows/hides cancel modal
   */
  toggleCancelModal() {
    this.isCancel = !this.isCancel;
    if (this.isCancel) {
      this.pushGtmEvent(OBI_BILL_INQUIRY_CANCEL_TAG);
    } else {
      this.pushGtmEvent(OBI_BILL_INQUIRY_CANCEL_NO_TAG);
    }
  }

  /**
   * Shows/hides cancel modal
   */
  toggleHereModal() {
    this.isHere = !this.isHere;
  }

  /**
   * Shows/hides issue types dropdown
   */
  toggleIssueTypes() {
    this.isIssueTypesShow = !this.isIssueTypesShow;
  }

  /**
   * Shows/hides selection field dropdown
   */
  toggleSelectOption(key: string) {
    this.selectFieldToggle[key] = !this.selectFieldToggle[key];
  }

  /**
   * Shows/hides date field modal
   */
  toggleDateModal(key: string) {
    this.dateFieldToggle[key] = !this.dateFieldToggle[key];
  }

  /**
   * Shows/hides time field modal
   */
  toggleTimeModal(key: string, value: boolean | undefined) {
    if (value === undefined) {
      this.timeFieldToggle[key] = !this.timeFieldToggle[key];
    } else {
      this.timeFieldToggle[key] = value;
    }
  }

  /**
   * Updates the form displayed based on the issue type
   */
  updateForm(issueType: string) {
    this.obiBillInquiryForm =
      this.obiBillInquiryFormList.filter(form => form.issue === issueType)[0] ??
      this.obiBillInquiryFormList[0];

    // Add new validators
    this.obiBillInquiryForm.forms.forEach(form => {
      if (this.billInquiryForm.controls[form.formControlName]) {
        if (
          !this.billInquiryForm.controls[form.formControlName].validator &&
          form.formControl.validator
        ) {
          this.billInquiryForm.controls[form.formControlName].setValidators(
            form.formControlValidators ?? []
          );
        }
      } else {
        this.billInquiryForm.addControl(
          form.formControlName,
          new FormControl(
            form.formControl.value,
            form.formControlValidators ?? []
          )
        );
      }
      // Add custom validators
      if (form?.errors) {
        form.errors.forEach(validate => {
          const sub = this.billInquiryForm.controls[
            form.formControlName
          ].valueChanges.subscribe(() => {
            validate(this.billInquiryForm, this.formErrorList);
          });
          this.subscriptionList[form.formControlName] = sub;
          validate(this.billInquiryForm, this.formErrorList);
        });
      }
    });
    const currentFormKeys: string[] = this.obiBillInquiryForm.forms.flatMap(
      form => form.formControlName
    );
    // Clear non-existing validators
    Object.keys(this.billInquiryForm.controls).forEach(key => {
      if (!currentFormKeys.includes(key) && key !== 'issueType') {
        this.billInquiryForm.controls[key].setErrors(null);
        this.billInquiryForm.controls[key].clearValidators();
        this.billInquiryForm.controls[key].clearAsyncValidators();
        if (this.subscriptionList[key]) {
          this.subscriptionList[key].unsubscribe();
        }
        delete this.formErrorList[key];
      }
    });
    this.billInquiryForm.updateValueAndValidity();
  }

  onloadAdjustTextarea() {
    Array.from(
      document.getElementsByClassName('obi-bill-inquiry-field-textarea-input')
    ).forEach(textarea => {
      this.adjustTextareaHeight(
        textarea as HTMLTextAreaElement,
        this.concernTextareaMinHeight,
        false
      );
    });
  }

  /**
   * Trigger timeOfPayment Validation
   */
  timeOfPaymentValidation() {
    const timeOfPaymentValue =
      this.billInquiryForm.controls['timeOfPayment'].value;
    if (timeOfPaymentValue) {
      this.billInquiryForm.controls['timeOfPayment'].updateValueAndValidity();
    }
  }

  /**
   * Pushes event tagging to GTM
   */
  pushGtmEvent(config: any, additionalData?: any, isError: boolean = false) {
    const userData = this.sessionService.getUserData();
    const data: any = {
      account_status: userData?.accountStatus,
      status: userData?.balanceStatus,
      issue: this.billInquiryForm.get('issueType')?.value,
      dispute_amount: this.billInquiryForm.get('amount')?.value,
    };
    if (additionalData) {
      Object.keys(additionalData).forEach(key => {
        data[key] = additionalData[key];
      });
    }
    if (isError) {
      this.sessionService.setData(
        SESSION_KEYS.OBI_API_ERROR_EVENT,
        additionalData
      );
    }
    this.gtmService.captureGTMEvent(config, true, data);
  }
}
