import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AMS, HOURS, MINUTES } from 'src/app/common/constants/obi-time-picker-modal.constants';

@Component({
  selector: 'app-obi-time-picker-modal',
  templateUrl: './obi-time-picker-modal.component.html',
  styleUrls: ['./obi-time-picker-modal.component.scss']
})
export class ObiTimePickerModalComponent {

  @Input() show: boolean = false;

  @Output() timeSelected = new EventEmitter<string>();

  @Output() modalClosed = new EventEmitter<boolean>();

  @ViewChild('container') container: ElementRef | undefined;

  public hours = HOURS;

  public minutes = MINUTES;

  public ams = AMS;

  public selectedHour = "01";

  public selectedMin = "00";

  public selectedAm = 'AM';

  mouseDown = false;


  ngAfterViewChecked(): void {
    if (this.show && this.container?.nativeElement) {
      const topOffset = this.container?.nativeElement.getBoundingClientRect().top - this.container?.nativeElement.offsetTop;
      const leftOffset = this.container?.nativeElement.getBoundingClientRect().left + this.container?.nativeElement.offsetLeft;
      this.container.nativeElement.style.top = `-${topOffset}px`;
      this.container.nativeElement.style.left = `-${leftOffset}px`;
    }
  }

  /**
   * Fires an event when a date is selected in the date picker
   * @param date date selected
   */
  onTimeConfirm() {
    const parsedTime = `${this.selectedHour}:${this.selectedMin} ${this.selectedAm}`;
    this.timeSelected.emit(parsedTime);
    this.toggleModal();
  }

  /**
   * Shows/Hides modal
   */
  toggleModal() {
    this.show = !this.show;
    this.modalClosed.emit(this.show);
  }

  /**
   * Triggered when clicked on the time selector containers
   * Sets the required variables to compute for selected time
   * @param event
   * @param scrollContainer
   */
  onTouchStart(scrollContainer: HTMLElement) {
    this.mouseDown = true;
    scrollContainer.classList.remove('snap-scroll-y');
  }

  /**
   * Triggered upon releasing mouse click
   * Resets variables used for computing the selected time
   */
  onTouchEnd(scrollContainer: HTMLElement) {
    this.mouseDown = false;
    if (scrollContainer) {
      scrollContainer.classList.add('snap-scroll-y');
    }
  }

  /**
   * Triggers when mouse moves inside time selection containers
   * Allows mouse drag scrolling
   * Computes for the selected time
   * @param event
   * @param timeKey
   * @returns
   */
  onScroll(scrollContainer: HTMLElement, timeKey: string) {
    const divIndex = Math.round((scrollContainer.scrollTop / 32) + 2);
    const selectedDiv = scrollContainer.firstChild?.childNodes[divIndex];

    if (selectedDiv) {
      switch(timeKey) {
        case 'hour':
          this.selectedHour = selectedDiv?.textContent || '01';
          break;
        case 'minute':
          this.selectedMin = selectedDiv?.textContent || '01';
          break;
        case 'am':
          this.selectedAm = selectedDiv?.textContent || 'AM';
          break;
      }
    }
  }
}
