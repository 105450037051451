<form class="omni-reschedule-request" [formGroup]="rescheduleRequest">
  <div class="omni-reschedule-request-body">
    <div class="omni-reschedule-request-body-item">
      <div
        class="omni-reschedule-request-body-label"
        [innerHTML]="omniRescheduleRequestLabels.reason"></div>
      <div class="omni-reschedule-request-field">
        <div
          [class]="[
            'omni-reschedule-request-field-select',
            rescheduleRequest.get('reasonType')?.value || isReasonTypeShow
              ? ''
              : 'inactive'
          ]"
          (click)="toggleReasonTypes()">
          <div class="omni-reschedule-request-field-select-input-text">
            <label
              class="omni-reschedule-request-field-select-label"
              *ngIf="rescheduleRequest.get('reasonType')?.value"
              >Reason</label
            >
            <input
              class="omni-reschedule-request-field-select-input"
              type="text"
              placeholder="Choose a reason"
              formControlName="reasonType"
              readonly />
          </div>
          <img
            class="omni-reschedule-request-field-select-input-icon"
            src="/assets/icons/arrow-up.svg"
            *ngIf="isReasonTypeShow" />
          <img
            class="omni-reschedule-request-field-select-input-icon"
            src="/assets/icons/arrow-down.svg"
            *ngIf="
              rescheduleRequest.get('reasonType')?.value && !isReasonTypeShow
            " />
          <img
            class="omni-reschedule-request-field-select-input-icon"
            src="/assets/icons/arrow-down-disabled.svg"
            *ngIf="
              !rescheduleRequest.get('reasonType')?.value && !isReasonTypeShow
            " />
        </div>
        <div
          class="omni-reschedule-request-field-select-list-overlay"
          *ngIf="isReasonTypeShow"
          (click)="toggleReasonTypes()"></div>
        <div
          class="omni-reschedule-request-field-select-list"
          *ngIf="isReasonTypeShow">
          <div
            [class]="[
              'omni-reschedule-request-field-select-item',
              rescheduleRequest.get('reasonType')?.value === isReasonTypeShow
                ? 'selected'
                : ''
            ]"
            *ngFor="let reasonType of omniRescheduleRequestReasonTypes"
            [innerHTML]="reasonType"
            (click)="onSelectReasonType(reasonType)"></div>
        </div>
      </div>
    </div>
    <div
      class="omni-reschedule-request-body-item"
      *ngIf="rescheduleRequest.get('reasonType')?.value === 'Others'">
      <div
        class="omni-reschedule-request-body-label"
        [innerHTML]="omniRescheduleRequestLabels.concern"></div>
      <div class="omni-reschedule-request-field" (click)="concern.focus()">
        <div class="omni-reschedule-request-field-textarea">
          <textarea
            class="omni-reschedule-request-field-textarea-input"
            #concernTextareaRef
            (input)="txtAreaInput($event)"
            (keydown)="txtAreaKeydown($event)"
            (paste)="txtAreaPaste($event)"
            placeholder="Please specify *"
            formControlName="concern"
            [formGroupDir]="rescheduleRequest"
            [formControlNameDir]="'concern'"
            appOmniAlphanumericSpecialChars
            #concern></textarea>
        </div>
        <div class="omni-reschedule-request-field-textarea-charcounter">
          {{ concernTextareaCharCount }}/{{ concernTextareaMaxLength }}
        </div>
      </div>
    </div>
    <div class="omni-reschedule-request-body-item">
      <div
        class="omni-reschedule-request-body-label"
        [innerHTML]="omniRescheduleRequestLabels.date"></div>
      <div class="omni-reschedule-request-field">
        <div
          [class]="[
            'omni-reschedule-request-field-text',
            showCalendar ? '' : 'inactive'
          ]"
          (click)="showCalendarPicker()">
          <div class="omni-reschedule-request-field-select-input-text">
            <label
              class="omni-reschedule-request-field-select-label"
              *ngIf="rescheduleRequest.get('date')?.value"
              >Date</label
            >
            <input
              class="omni-reschedule-request-field-select-input"
              type="text"
              placeholder="Pick a date"
              [value]="selectedDate"
              formControlName="date"
              readonly />
          </div>
          <img
            class="omni-reschedule-request-field-text-input-icon"
            src="/assets/icons/calendar-icon.svg" />
        </div>
      </div>
    </div>
    <div class="omni-reschedule-request-body-item">
      <div
        class="omni-reschedule-request-body-label"
        [innerHTML]="omniRescheduleRequestLabels.time"></div>

      <div class="omni-reschedule-request-field">
        <div
          [class]="[
            'omni-reschedule-request-field-select-time',
            rescheduleRequest.get('time')?.value || isTimeSelectShow
              ? ''
              : 'inactive',
            rescheduleRequest.get('date')?.value ? '' : 'disabled'
          ]"
          (click)="toggleTimeSelect()">
          <div class="omni-reschedule-request-field-select-input-text">
            <label
              class="omni-reschedule-request-field-select-label"
              *ngIf="rescheduleRequest.get('time')?.value"
              >Time</label
            >
            <input
              class="omni-reschedule-request-field-select-input"
              [class]="[
                'omni-reschedule-request-field-select-input',
                rescheduleRequest.get('date')?.value ? '' : 'disabled'
              ]"
              type="text"
              placeholder="Select time"
              formControlName="time"
              readonly />
          </div>
          <img
            class="omni-reschedule-request-field-select-input-icon"
            src="/assets/icons/arrow-up.svg"
            *ngIf="isTimeSelectShow" />
          <img
            class="omni-reschedule-request-field-select-input-icon"
            src="/assets/icons/arrow-down.svg"
            *ngIf="rescheduleRequest.get('time')?.value && !isTimeSelectShow" />
          <img
            class="omni-reschedule-request-field-select-input-icon"
            src="/assets/icons/arrow-down-disabled.svg"
            *ngIf="
              !rescheduleRequest.get('time')?.value && !isTimeSelectShow
            " />
        </div>

        <div
          class="omni-reschedule-request-field-select-list-overlay"
          *ngIf="isTimeSelectShow"
          (click)="toggleTimeSelect()"></div>
        <div
          class="omni-reschedule-request-field-select-list-time"
          *ngIf="isTimeSelectShow">
          <div
            [class]="[
              'omni-reschedule-request-field-select-item',
              rescheduleRequest.get('time')?.value === time ? 'selected' : ''
            ]"
            *ngFor="let time of omniTimeTypes"
            [innerHTML]="time"
            (click)="onSelectTime(time)"></div>
        </div>
      </div>
    </div>

    <div class="omni-reschedule-request-footer">
      <div
        [class]="[
          'omni-reschedule-request-footer-button',
          rescheduleRequest.valid ? '' : 'disabled'
        ]"
        (click)="rescheduleRequest.valid ? submitRescheduleRequest() : null">
        Reschedule technician visit
      </div>
      <div
        class="omni-reschedule-request-footer-button outline"
        (click)="toggleCancelModal()">
        Cancel
      </div>
    </div>
  </div>
</form>

<omni-dialog-modal
  class="omni-reschedule-request-cancel-modal"
  [title]="omniRescheduleRequestCancel.title"
  [confirmButtonLabel]="omniRescheduleRequestCancel.confirmButtonLabel"
  [cancelButtonLabel]="omniRescheduleRequestCancel.cancelButtonLabel"
  [confirmButtonAction]="omniRescheduleRequestCancelConfirmAction"
  [cancelButtonAction]="omniRescheduleRequestCancelCancelAction"
  [show]="isCancel"></omni-dialog-modal>

<omni-dialog-modal
  class="omni-reschedule-request-ss-modal"
  [title]="omniRescheduleRequestSuccess.title"
  [description]="omniRescheduleRequestSuccess.description"
  [confirmButtonLabel]="omniRescheduleRequestSuccess.confirmButtonLabel"
  [cancelButtonLabel]="omniRescheduleRequestSuccess.cancelButtonLabel"
  [confirmButtonAction]="omniRescheduleRequestSuccessTrackAction"
  [cancelButtonAction]="omniRescheduleRequestSuccessDashboardAction"
  [show]="isSubmitted"></omni-dialog-modal>

<app-omni-loading-modal
  [message]="''"
  [showModal]="isLoading"></app-omni-loading-modal>

<app-omni-date-picker-modal
  [showModal]="showCalendar"
  [selectedDate]="selectedCalendarDate"
  [isReschedule]="isReschedule"
  (modalClosed)="handleCalendarClosed()"
  (dateSelected)="handleDateSelection($event)"
  [currentTechSchedDate]="currentTechSchedDate">
</app-omni-date-picker-modal>

<app-omni-view-status
  [modalOpen]="showWorkOrderDetails"
  [workOrder]="workOrderDetails">
</app-omni-view-status>