import { OmniTrackMyRepairDateMapping, OmniTrackMyRepairStatusMapping } from "src/app/components/omnicare/omni-track-my-repair/omni-track-my-repair.interface";

export const OMNI_TRACK_STATUS: OmniTrackMyRepairStatusMapping[] = [
  {
    title: "Open", //This will not be reflected in Track my repair page
    status: 'Open',
    subStatus: [""]
  },
  {
    title: "Repair confirmed",
    status: 'Pending',
    subStatus:["For Visit"]
  },
  {
    title: "Repair confirmed",
    status: 'Unassigned',
    subStatus:[""]
  },
  {
    title: "Technician is on the way",
    status: 'Pending',
    subStatus: ["On The Way"]
  },
  {
    title: "Repair closed",
    status: 'Completed',
    subStatus: [""]
  },
  {
    title: "Cancelled",
    status: 'Cancelled',
    subStatus: [""]
  },
  {
    title: "Ongoing repair",
    status: 'Ongoing',
    subStatus: [
      "",
      "UnHold",
      "On Hold"
    ]
  },
  {
    title: "Rescheduled",
    status: 'Delayed',
    subStatus: ["",
      "With Visit",
      "Without Visit",
      "With Start Activity",
      "For Cancellation",
      "For Reschedule",
    ]
  }
];

export const APPOINTMENT_DATE_MAPPING: OmniTrackMyRepairDateMapping[] = [
  {
    flag: "AM",
    schedule: "9:00 AM - 12:00 NN"
  },
  {
    flag: "PM",
    schedule: "1:00 PM - 5:00 PM"
  }
];

export const APPOINTMENT_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss A";

export const OMNI_TRACK_MY_REPAIR_INIT_VALUES = {
  RECENT_TAB_KEY: "recent",
  HISTORY_TAB_KEY: "history"
}
