export const PAGE_URLS = {
  GOTO_DASHBOARD_URL: '/omnicare/navigate?destination=dashboard',
  WELCOME_BACK_PAGE_URL: '/omnicare/welcomeback',
  SCHEDULE_TECHNICIAN_CONCERN_URL: '/omnicare/scheduleTechnicianConcern',
  SCHEDULE_TECHNICIAN_PAGE_URL: '/omnicare/scheduleTechnician',
  SCHEDULE_TECHNICIAN_NO_SLOT_PAGE_URL: '/omnicare/noScheduleSlotTechnician',
  REQUEST_CONFIRMATION_PAGE_URL: '/omnicare/requestconfirmed',
  BASIC_TROUBLESHOOTING_PAGE_URL: '/omnicare/basictroubleshooting',
  GENERIC_ERROR_PAGE_URL: '/omnicare/error',
  COMING_SOON_PAGE_URL: '/omnicare/comingsoon',
  DIAGNOSTIC_PAGE_URL: '/omnicare/diagnostics',
  FB_MSGR_URL: '/omnicare/navigate?destination=messenger.ng1',
  FB_MSGR_BB_NETWORK_URL: '/omnicare/navigate?destination=messenger.ng1BB',
  EXISTING_TECHNICIAN_VISIT:
    '/omnicare/navigate?destination=reportconnection.existing',
  TROUBLESHOOTING_FAILED_URL: '/omnicare/troubleshootingfailed',
  RESCHEDULE_REQUEST: '/omnicare/rescheduleRequest',
  BARRING_ERROR: '/omnicare/error/barring',
};

export const SESSION_KEYS = {
  OMNI_AUTH_TOKEN_KEY: 'omni_auth_token',
  OMNI_USER_TOKEN_KEY: 'omni_user_token',
  OMNI_USER_DATA_KEY: 'omni_user_data',
  OMNI_ACCOUNT_NUMBER_KEY: 'account_number',
  OMNI_BRAND_KEY: 'brand',
  OMNI_SEGMENT_KEY: 'segment',
  OMNI_MOBILE_NUMBER: 'mobile_number',
  OMNI_APPOINTMENT_SLOT_KEY: 'appointment_slot',
  OMNI_CONCERN_TYPE: 'concern_type',
  OMNI_CONCERN_DETAILS: 'concern_details',
  OMNI_DEVICE_ID: 'device_id',
  OMNI_PLATFORM: 'platform',
  OMNI_ORDER_ACTION_ID: 'order_action_id',
  OMNI_ORDER_ID: 'order_id',
  OMNI_ORDER_STATUS: 'order_status',
  OMNI_RESCHED_APPOINMENTSLOTS_KEY: 'resched_appointment_slot',
  OMNI_RESCHED_ORDER_ACTION_ID_KEY: 'resched_order_action_id',
  OMNI_RESCHED_NOTES_KEY: 'resched_notes_key',
  OMNI_CURRENT_SCHED_DATE: 'resched_current_sched_date',
  OMNI_BARRING_INDICATOR: 'barring_indicator',
  OMNI_API_ERROR: 'bill_api_error',
  OMNI_API_ERROR_EVENT: 'omni_api_error_event',
};

export const CXS_API_URLS = {
  GENERATE_AUTH_TOKEN: '/v1/channels/oauth/token',
  GENERATE_USER_TOKEN: '/v1/userManagement/mobile/login',
  DECRYPT_DATA: '/v1/dataManagement/parse',
  BILLING_DIAGNOSIS:
    '/v3/accountManagement/accounts/billing?accountNumber={1}&brand={2}&segment={3}',
  ACCOUNT_DIAGNOSIS:
    '/v2/accountManagement/accounts/diagnostic?accountNumber={1}',
  ACCOUNT_DETAILS_V3:
    '/v3/accountManagement/accounts?accountNumber={1}&brand={2}&segment={3}',
  SEARCH_APPOINTMENT_SLOT:
    '/v1/workforceManagement/workOrders/appointments/search',
  CONFIRM_APPOINTMENT_BOOKING: '/v1/workforceManagement/appointments/confirm',
  WORK_ORDER_DETAILS:
    '/v1/workforceManagement/workOrders?sourceNumber=2&accountNumber={1}',
  CREATE_CASE: '/v1/ticketManagement/createCase',
  WORK_ORDER_DETIALS_LATEST:
    '/v1/workforceManagement/workOrders?sourceNumber=0&accountNumber={1}',
  UPDATE_TICKET_DETAILS: '/v1/ticketManagement/tickets',
  RETRIEVE_SUBSCRIBER_DETAILS:
    '/v2/customerManagement/subscribers/contacts?landlineNumber={1}',
};

export const API_RESPONSE_ERROR = [
  {
    status: /3\d\d/,
    code: /|./,
    url: PAGE_URLS.GENERIC_ERROR_PAGE_URL,
  },
  {
    status: /401/,
    code: /|./,
    url: PAGE_URLS.GOTO_DASHBOARD_URL,
  },
  {
    status: /404/,
    code: /40402/,
    url: null,
  },
  {
    status: /4\d\d/,
    code: /|./,
    url: PAGE_URLS.GENERIC_ERROR_PAGE_URL,
  },
  {
    status: /5\d\d/,
    code: /|./,
    url: PAGE_URLS.GENERIC_ERROR_PAGE_URL,
  },
];

/**
 * OM24H-622:
 * OM24H-623:
 * OM24H-624:
 * Temporary re-direct to failed Troubleshooting page
 * Update this value to true for BAU flow: Schedule a Technician
 */
export const REDIRECT_SCHED_TECHNICIAN_FLOW: boolean = true;

export const OMNI_GTM_CONFIG: any = {
  event_category: '24hr_repair_ng1',
  event_action: 'click',
  source_page_url: 'https://www.24hrrepair.globe.com.ph',
  user_id: 'AB132132112',
  outbound_link: 'URL',
  event: 'hm_push_event',
};

/**
 * Mapping of Diagnostic API status to GTM events tagging
 */
export const OMNI_GTM_DIAGNOSTIC_STATUS_MAP = new Map([
  ['outage_status', ['No Service Affecting Network Incident and/or Planned Activity Found!', 'Data not found', 'No data found']],
  ['fiber_cuts_issue', ['Account has no last mile issue', 'Facility is not Huawei', 'Facility is not Huawei.', 'No data found']],
  ['fiber_losses_issue', ['Account has no last mile issue', 'Facility is not Huawei', 'Facility is not Huawei.', 'No data found']],
  ['modem_line', ['Active', 'Data not found', 'No data found']],
  ['modem_power', ['Active', 'Data not found', 'No data found']],
])
