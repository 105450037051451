import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OmniTrackMyRepairWorkOrderResponse } from "./omni-track-my-repair.interface";
import { Observable } from "rxjs";
import { CXS_API_URLS } from "src/app/common/constants/omni-global.constant";

@Injectable({ providedIn: 'root' })
export class OmniTrackMyRepairService {

  constructor(
    private http: HttpClient
  ) {}

  /**
   * Retrieves user's current active work order details using account number
   */
  getWorkOrderDetails(accountNumber: string): Observable<OmniTrackMyRepairWorkOrderResponse> {
    const url = CXS_API_URLS.WORK_ORDER_DETAILS.replace("{1}", encodeURIComponent(accountNumber));
    return this.http.get(url);
  }
}
