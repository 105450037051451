export const OMNI_REQ_CONFIRMED_INIT_VALUES = {
  OMNI_REQ_CONFIRM_TITLE: 'Technician visit confirmed', //confirmRequestTitle
  OMNI_REQ_CONFIRMED_TIMESTAMP: 'December 20 2021, 9:54 PM', //confirmRequestTimestamp


  DOWNLOAD_ICON: '../../../../assets/icons/omni-download-icon.png', //omniReqConfirmedDownloadIcon
  RECEIPT_GREET_LABEL: 'HURRAY!', //receiptGreetLabel
  RECEIPT_TITLE: 'Your technician visit has been scheduled!', //receiptTitle
  CHECK_ICON: '../../../../assets/icons/omni-check-icon.png', //omniReqConfirmedCheckIcon

  RECEIPT_PREFIX_FILENAME: 'GlobeOne-Repair-',

  OMNI_REQ_CONFIRMED_DIV_ID: 'omni-req-confirmed-div-id',
  OMNI_REQ_CONFIRMED_EXTRA_PADDING: '60px',
  EXCLUDED_ELEMENT: [
      'omni-confirmed-request__receipt-download-icon',
      'omni-confirmed-request__concern-details-work-order-copy-icon-container',
      'omni-confirmed-request__btn-wrapper'
    ],

    OMNI_REQ_CONFIRMED_COPY_Z_INDEX: '-99px',
    SNACKBAR_TITLE_DOWNLOAD: 'Receipt downloaded',
    SNACKBAR_TITLE_COPY: 'Copied to clipboard',
    SNACKBAR_SUBTEXT_DOWLOAD: 'Got to your Gallery to view the image version of your transaction receipt.',
    SNACKBAR_ACTION_DOWNLOAD: 'download',
    SNACKBAR_ACTION_COPY: 'copy',


    BILLER_LABEL: 'SERVICES', //billerLabel
    OMNI_REQ_CONFIRMED_BRAND: { //omniReqConfirmedBrand
      brand_icon: '../../../../assets/icons/omni-globe-icon.png',
      brand_name: 'Globe At Home'
    },

    ACCOUNT_DETAILS: [
      { label: 'ACCOUNT NAME', value: 'April Cruz' },
      { label: 'ACCOUNT NUMBER', value: '11102931281'},
      { label: 'TECH VISIT DATE', value: 'March 31, 2023' },
      { label: 'TECH VISIT TIME', value: '1:00 - 5:00 PM'}
    ],

    CONCERN_DETAILS: {  //concernDetails
      concern: "I can't connect to the internet",
      //description: 'Modem issue detected',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum condimentum odio sit amet volutpat. Morbi gravida nisi eleifend tincidunt viverra. Integer libero dui, ultrices sit amet gravida vitae, placerat sit amet ligula. Duis in mattis elit. Mauris vel sapien eget enim consequat ornare non quis tellus. Quisque id magna pellentesque, ornare sapien a, vehicula dolor. Nulla facilisi.'
    },

    COPY_ICON: '../../../../assets/icons/omni-copy-icon.png', //omniReqConfirmedCopyIcon

    WORK_ORDER_NUMBER: 'GBP-231024XXXXXXXXXX-2',

    GOTO_DASHBOARD_BTN_LABEL: 'Go to dashboard',

    REMINDER_TITLE: 'One last thing!',

    REMINDER_SUBTEXT: 'Tap download to keep it in photos or </br>click copy and save it in your notes.'
};
