import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import {
  OBI_BILL_INQUIRY_CASE_CREATE_LOGNOTE_LABEL,
  OBI_BILL_INQUIRY_DEFAULT_VALUES,
  OBI_BILL_INQUIRY_UNPOSTED_PAYMENT_TAG,
} from 'src/app/common/constants/obi-bill-inquiry.constants';
import {
  CXS_API_URLS,
  SESSION_KEYS,
} from 'src/app/common/constants/obi-global.constants';
import { OmniSessionService } from 'src/app/services/omni-session.service';

@Injectable({ providedIn: 'root' })
export class ObiBillInquiryService {
  public billInquiryDetails: any;

  constructor(
    private http: HttpClient,
    private sessionService: OmniSessionService
  ) {}

  generateCaseCreateRequest(body: any) {
    const {
      MOBILE_NUMBER_LABEL,
      ALTERNATIVE_MOBILE_NUMBER_LABEL,
      CONCERN_DETAILS_LABEL,
      BILL_DETAILS_LABEL,
      BILL_CHARGE_LABEL,
      AMOUNT_DISPUTE_LABEL,
      ACCOUNT_NUMBER_LABEL,
      ACCOUNT_ALIAS_LABEL,
      UNPOSTED_PAYMENT_AMOUNT_LABEL,
      UNPOSTED_PAYMENT_DATE_LABEL,
      UNPOSTED_PAYMENT_TIME_LABEL,
      UNPOSTED_PAYMENT_CHANNEL_LABEL,
      UNPOSTED_PAYMENT_PAYMENT_REF_LABEL,
    } = OBI_BILL_INQUIRY_CASE_CREATE_LOGNOTE_LABEL;

    const userData = this.sessionService.getUserData();
    const landlineNumber = userData?.landlineNumber;

    const segment = this.sessionService.getData(SESSION_KEYS.OBI_SEGMENT_KEY);
    let mobileNumber: string = this.sessionService.getData(
      SESSION_KEYS.OBI_MOBILE_NUMBER
    );
    const billDetails = this.sessionService.getData(
      SESSION_KEYS.OBI_BILL_DETAILS
    );
    const accountAlias = this.sessionService.getData(
      SESSION_KEYS.OBI_ACCOUNT_ALIAS
    );
    const accountNumber =
      this.sessionService.getData(SESSION_KEYS.OBI_ACCOUNT_NUMBER_KEY) ??
      userData?.accountNumber;
    const customerId = this.sessionService.getData(
      SESSION_KEYS.OBI_CUSTOMER_ID
    );
    const billSched: string[] = [];
    billDetails.forEach((bill: any) => {
      const startDate = moment(bill.billStartDate).format('M/D/YYYY');
      const endDate = moment(bill.billEndDate).format('M/D/YYYY');
      billSched.push(`${startDate}-${endDate}`);
    });
    const { sourceNumber, workflowOperation, status, caseTypeLevel1RefId } =
      OBI_BILL_INQUIRY_DEFAULT_VALUES;
    let dateOfPayment = null;
    if (body?.dateOfPayment) {
      dateOfPayment = moment({
        month: body.dateOfPayment.month - 1,
        day: body.dateOfPayment.day,
        year: body.dateOfPayment.year,
      });
    }

    const logNotesArr = [];
    logNotesArr.push(
      mobileNumber ? `${MOBILE_NUMBER_LABEL}: ${mobileNumber}` : null,
      body.altMobileNumber
        ? `${ALTERNATIVE_MOBILE_NUMBER_LABEL}: 0${body.altMobileNumber}`
        : null,
      body.concern ? `${CONCERN_DETAILS_LABEL}: ${body.concern}` : null,
      billSched?.length
        ? `${BILL_DETAILS_LABEL}: ${billSched.join(', ')}`
        : null,
      body?.issueType ? `${BILL_CHARGE_LABEL}: ${body.issueType}` : null,
      body.amount ? `${AMOUNT_DISPUTE_LABEL}: ${body.amount}` : null,
      accountNumber ? `${ACCOUNT_NUMBER_LABEL}: ${accountNumber}` : null,
      accountAlias ? `${ACCOUNT_ALIAS_LABEL}: ${accountAlias}` : null
    );
    if (body.issueType === OBI_BILL_INQUIRY_UNPOSTED_PAYMENT_TAG) {
      logNotesArr.push(
        body.amount ? `${UNPOSTED_PAYMENT_AMOUNT_LABEL}: ${body.amount}` : null,
        dateOfPayment
          ? `${UNPOSTED_PAYMENT_DATE_LABEL}: ${moment(dateOfPayment).format(
              'MM/DD/YYYY'
            )}`
          : null,
        body.timeOfPayment
          ? `${UNPOSTED_PAYMENT_TIME_LABEL}: ${body.timeOfPayment}`
          : null,
        body.paymentChannel
          ? `${UNPOSTED_PAYMENT_CHANNEL_LABEL}: ${body.paymentChannel}`
          : null,
        body.referenceNo
          ? `${UNPOSTED_PAYMENT_PAYMENT_REF_LABEL}: ${body.referenceNo}`
          : null
      );
    }
    const logNotes = logNotesArr.filter(note => note).join(' | ');

    if (`${mobileNumber}`.startsWith('0')) {
      mobileNumber = mobileNumber.substring(1);
    }

    const obiCreateCaseObj = {
      sourceNumber: sourceNumber,
      subscription: segment === 'broadband' ? landlineNumber : mobileNumber,
      workflowOperation: workflowOperation,
      status: status,
      issueStartDate: moment().toISOString(),
      logNote: logNotes,
      segment: segment,
      billLiner: body.issueType,
      customerId: customerId,
      caseTypeLevel1RefId: caseTypeLevel1RefId,
    };

    if (segment === 'mobile') {
      return {
        ...obiCreateCaseObj,
        mobileNumber: mobileNumber,
      };
    } else {
      return {
        ...obiCreateCaseObj,
        accountNumber: accountNumber,
      };
    }
  }

  /**
   * Create Case
   * Execute the CXS API - Createcase
   *
   * @param body - Contains case creation details
   * @returns
   */
  postCreateCase(body: any): Observable<any> {
    const apiURL = CXS_API_URLS.CREATE_CASE;
    return this.http.post<any>(apiURL, body);
  }
}
