<div class="obi-bill-statement__modal" *ngIf="open">
    <div class="obi-bill-statement__modal-header">
      <img
        class="obi-bill-statement__btn-close"
        [src]="closeIcon"
        (click)="toggleModal()" />
    </div>
    <div class="obi-bill-statement__modal-content">
        <pdf-viewer
            *ngIf="displayPdf"
            [src]="pdfSrc"
            [render-text]="true"
            [original-size]="false"
            [fit-to-page]="true"
            (error)="onError($event)"
            (after-load-complete)="onLoadComplete($event)"
            style="width: 100%; height: 100%">
        </pdf-viewer>
    </div>
</div>
<app-omni-loading-modal [showModal]="loading" [message]="''"></app-omni-loading-modal>
  