import { Component, HostListener } from '@angular/core';
import { GoogleTagManagerService } from './services/google-tag-manager-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'omni-care-v1';

  constructor (
    private gtmService: GoogleTagManagerService
  ) {}

  @HostListener('window:load')
  onLoad() {
    try {
      this.gtmService.init();
    } catch (e) { }
  }

}
