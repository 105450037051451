import { Directive, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[appOmniMobileNumberFormat]'
})
export class OmniMobileNumberFormatDirective {

  /**
   * Form Group to be updated
   */
  @Input() formGroupDir: FormGroup | undefined;

  /**
   * Form Control name to be updated
   */
  @Input() formControlNameDir: string | undefined;

  /**
   * Element of the input field
   */
  @Input() inputField: HTMLInputElement = {} as HTMLInputElement;

  constructor() { }

  /**
   * Removes non numerical characters
   * Inserts currency in front when removed
   * @param event
   */
  @HostListener('input', ['$event']) onInput(event: any): void {
    let inputValue: string = event.target.value;
    const maxChars = 10;
    const periodRegex = /\./g;
    const nonNumberRegex = /[^\d]/g;
    let selectionIndex = inputValue.length - (this.inputField?.selectionStart || 0);

    // Removes extra symbols allowed during parsing
    inputValue = inputValue.replace(periodRegex, "");
    inputValue = inputValue.replace(nonNumberRegex, "");
    if (event.inputType === "insertFromPaste" &&
      inputValue.length > maxChars &&
      (inputValue.startsWith("63") ||
      inputValue.startsWith("09"))) {

      const prefixLength = inputValue.startsWith("09") ? 1 : 2;
      inputValue = inputValue.substring(prefixLength);
    }
    inputValue = inputValue.substring(0, maxChars);
    event.target.value = inputValue;

    // Move cursor to previous placement
    if (selectionIndex) {
      this.inputField.selectionStart = inputValue.length - selectionIndex;
      this.inputField.selectionEnd = inputValue.length - selectionIndex;
    }
    this.updateFormAndValidity(event.target.value);
  }

  /**
   * Updates the FormGroup and FormControl when available
   * @param value
   */
  updateFormAndValidity(value: any) {
    if (this.formGroupDir && this.formControlNameDir) {
      this.formGroupDir.controls[this.formControlNameDir].setValue(value);
      this.formGroupDir.updateValueAndValidity();
    }
  }

}
