import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OmniBasicTroubleshootingComponent } from './components/omnicare/omni-basic-troubleshooting/omni-basic-troubleshooting.component';
import { OmniSchedTechnicianComponent } from './components/omnicare/omni-sched-technician/omni-sched-technician.component';
import { OmniRequestConfirmedComponent } from './components/omnicare/omni-request-confirmed/omni-request-confirmed.component';
import { OmniWelcomeComponent } from './components/omnicare/omni-welcome/omni-welcome.component';
import { OmniDiagnosticsComponent } from './components/omnicare/omni-diagnostics/omni-diagnostics.component';
import { OmniErrorComponent } from './components/omnicare/omni-error/omni-error.component';
import { OmniSchedTechnicianNoSlotsComponent } from './components/omnicare/omni-sched-technician-no-slots/omni-sched-technician-no-slots.component';
import { OmniSchedTechnicianConcernComponent } from './components/omnicare/omni-sched-technician-concern/omni-sched-technician-concern.component';
import { OmniPlatformDownComponent } from './components/omnicare/omni-platform-down/omni-platform-down.component';
import { OmniTroubleshootingFailedComponent } from './components/omnicare/omni-troubleshooting-failed/omni-troubleshooting-failed.component';
import { OmniWorkorderDetailsComponent } from './components/omnicare/omni-workorder-details/omni-workorder-details.component';
import { OmniRescheduleRequestComponent } from './components/omnicare/omni-reschedule-request/omni-reschedule-request.component';

import { ObiFaqComponent } from './components/obi/obi-faq/obi-faq.component';
import { ObiPreCheckComponent } from './components/obi/obi-pre-check/obi-pre-check.component';
import { ObiBillInquiryComponent } from './components/obi/obi-bill-inquiry/obi-bill-inquiry.component';
import { ObiTrackMyRequestComponent } from './components/obi/obi-track-my-request/obi-track-my-request.component';
import { OmniBarringErrorComponent } from './components/omnicare/omni-barring-error/omni-barring-error.component';

const routes: Routes = [
  {
    component: OmniBasicTroubleshootingComponent,
    path: 'omnicare/basictroubleshooting',
  },
  {
    component: OmniWelcomeComponent,
    path: 'omnicare/welcomeback',
  },
  {
    component: OmniSchedTechnicianComponent,
    path: 'omnicare/scheduleTechnician',
  },
  {
    component: OmniSchedTechnicianConcernComponent,
    path: 'omnicare/scheduleTechnicianConcern',
  },
  {
    component: OmniSchedTechnicianNoSlotsComponent,
    path: 'omnicare/noScheduleSlotTechnician',
  },
  {
    component: OmniRequestConfirmedComponent,
    path: 'omnicare/requestconfirmed',
  },
  {
    component: OmniDiagnosticsComponent,
    path: 'omnicare/diagnostics',
  },
  {
    component: OmniErrorComponent,
    path: 'omnicare/error',
  },
  {
    component: OmniErrorComponent,
    path: 'omnicare/comingsoon',
  },
  {
    component: ObiTrackMyRequestComponent,
    path: 'omnicare/trackMyRepair',
  },
  {
    component: OmniPlatformDownComponent,
    path: 'omnicare/platformDown',
  },
  {
    component: OmniTroubleshootingFailedComponent,
    path: 'omnicare/troubleshootingfailed',
  },
  {
    component: OmniWorkorderDetailsComponent,
    path: 'omnicare/workorderdetails',
  },
  {
    component: OmniRescheduleRequestComponent,
    path: 'omnicare/rescheduleRequest',
  },
  {
    component: OmniBarringErrorComponent,
    path: 'omnicare/error/barring',
  },
  {
    component: ObiFaqComponent,
    path: 'obi/faq',
  },
  {
    component: ObiPreCheckComponent,
    path: 'obi/precheck',
  },
  {
    component: ObiBillInquiryComponent,
    path: 'obi/billInquiry',
  },
  {
    component: ObiTrackMyRequestComponent,
    path: 'obi/trackMyRequest',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
