import { Component } from '@angular/core';
import { OmniDiagnosticsService } from './omni-diagnostics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OmniDiagnostic, OmniDiagnosticValidation } from './omni-diagnostics.interface';
import { OMNI_DIAGNOSTICS_BARRING_INDICATOR_TIMER, OMNI_DIAGNOSTICS_HEADER, OMNI_DIAGNOSTICS_LIST, OMNI_DIAGNOSTICS_SUB_VALIDATION_TIMER, OMNI_DIAGNOSTICS_SUCCESS_MODAL, OMNI_DIAGNOSTICS_TITLE_REGEX, OMNI_DIAGNOSTICS_VALIDATION_TIMER, OMNI_DIAGNOSTIC_DECIMAL_REGEX } from '../../../common/constants/omni-diagnostics.constant';
import { PAGE_URLS, SESSION_KEYS, REDIRECT_SCHED_TECHNICIAN_FLOW } from 'src/app/common/constants/omni-global.constant';
import { OmniSessionService } from 'src/app/services/omni-session.service';
import { OmniCommonService } from 'src/app/services/omni-common.services';
import { OmniSchedTechnicianService } from 'src/app/services/omni-sched-technician.service';
import { GoogleTagManagerService } from 'src/app/services/google-tag-manager-service.service';
import { OMNI_DIAGNOSTIC_ACCOUNT_FAIL_CONTINUE_TAG, OMNI_DIAGNOSTIC_ACCOUNT_TAG, OMNI_DIAGNOSTIC_COMPLETE_MODAL_NO_TAG, OMNI_DIAGNOSTIC_COMPLETE_MODAL_YES_TAG, OMNI_DIAGNOSTIC_COMPLETE_TAG, OMNI_DIAGNOSTIC_FIBER_TAG, OMNI_DIAGNOSTIC_LANDING_TAG, OMNI_DIAGNOSTIC_NETWORK_TAG, OMNI_GENERIC_ERROR_TAG } from 'src/app/common/constants/omni-gtm-events.constants';

@Component({
  selector: 'app-omni-diagnostics',
  templateUrl: './omni-diagnostics.component.html',
  styleUrls: ['./omni-diagnostics.component.scss']
})
export class OmniDiagnosticsComponent {

  /**
   * Import list of diagnostics done
   */
  public diagnosticsList = OMNI_DIAGNOSTICS_LIST;

  /**
   * Import header spiel
   */
  public diagnosticsHeader = OMNI_DIAGNOSTICS_HEADER;

  /**
   * Import success modal configuration
   */
  public diagnosticsSuccessModal = OMNI_DIAGNOSTICS_SUCCESS_MODAL;

  /**
   * Broadband account number of the subscriber
   */
  private accountNumber: string = "";
  /**
   * Brand of the customer
   */
  private brand: string = "";
  /**
   * Segment of the customer
   */
  private segment: string = "";
  /**
   * HTML Text of fail dialog modal Title
   */
  public failTitle?: string = "";
  /**
   * HTML Text of fail dialog modal Description
   */
  public failDescription?: string = "";
  /**
   * HTML Text of confirmation button
   */
  public failConfirmLabel?: string = "";
  /**
   * HTML Text of cancellation button
   */
  public failCancelLabel?: string = "";
  /**
   * Action when clicking confirm button
   */
  public failConfirmAction?: Function = () => {};
  /**
   * Action when clicking cancel button
   */
  public failCancelAction?: Function = () => {};
  /**
   * Style tag of confirmation button
   */
  public failConfirmStyle?: string = "";
  /**
   * Style tag of cancellation button
   */
  public failCancelStyle?: string = "";

  /**
   * Identifier for what dialog modal to display
   * overdue - Payment overdue
   * disconnected - Temporarily disconnected
   * outage - Reported outage
   * network - Network setting
   * fiber_loss - Fiber loss
   * fiber_cuts - Fiber cuts
   * modem_line - Modem line
   * modem_workload - Modem high workload
   * success - Success diagnosing process
   */
  public showDialogModal = "";

  /**
   * Object containing response result from API calls
   */
  resultList: any;

  /**
   * Boolean flag for showing loading modal
   */
  loading: boolean = false;

  /**
   * Stores all images required by the page for display
   */
  imageStorage: string[] = [
    '/assets/icons/diagnostic-initial.gif',
    '/assets/icons/diagnostic-active.gif',
    '/assets/icons/diagnostic-inactive.svg',
    '/assets/icons/diagnostic-fail.svg',
    '/assets/icons/diagnostic-completed.svg',
    '/assets/icons/diagnostic-success.svg',
  ];

  imageLoadedCount: number = 0;

  constructor(
    private route: ActivatedRoute,
    private omniDiagnosticsService: OmniDiagnosticsService,
    private sessionService: OmniSessionService,
    private commonService: OmniCommonService,
    private schedTechnicianService: OmniSchedTechnicianService,
    private router: Router,
    private gtmService: GoogleTagManagerService
  ) { }

  ngOnInit(): void {
    /**
     * OM24H-624: Temporary re-direct Failed troubleshooting page.
     */
    if(!REDIRECT_SCHED_TECHNICIAN_FLOW){
      this.diagnosticsSuccessModal.cancelButtonUrl = PAGE_URLS.TROUBLESHOOTING_FAILED_URL;
    }
    this.pushGtmEvent(OMNI_DIAGNOSTIC_LANDING_TAG);
  }

  imageLoaded() {
    this.imageLoadedCount++;
    if(this.imageStorage.length === this.imageLoadedCount){
      this.retrieveParams(() => this.diagnoseAccount());
    }
  }

  /**
   * Retrieves, decrypt and stores query param
   */
  private retrieveParams(postValidationFunc: Function) {
    // Retrieve queryParams from URL
    this.route.queryParams.subscribe((params) => {
      const encrpyptedData = decodeURIComponent(params['data']);
      if (!encrpyptedData) {
        this.router.navigate([PAGE_URLS.GENERIC_ERROR_PAGE_URL]);
      }
      this.commonService.validateInitParams(encrpyptedData, () => {
        this.accountNumber = this.sessionService.getData(SESSION_KEYS.OMNI_ACCOUNT_NUMBER_KEY);
        this.brand = this.sessionService.getData(SESSION_KEYS.OMNI_BRAND_KEY);
        this.segment = this.sessionService.getData(SESSION_KEYS.OMNI_SEGMENT_KEY);
        if (this.sessionService.getData(SESSION_KEYS.OMNI_BARRING_INDICATOR) === 'Y') {
          setTimeout(() => {
            this.commonService.navigate(PAGE_URLS.BARRING_ERROR);
          }, OMNI_DIAGNOSTICS_BARRING_INDICATOR_TIMER);
        } else {
          postValidationFunc();
        }
      });
    });
  }

  /**
   * Calls for the GetAccountDiagnosticDetailsV2 and trigger response validation
   */
  private diagnoseAccount() {
    this.omniDiagnosticsService.getBillingDiagnosis(this.accountNumber, this.brand, this.segment).subscribe({
      next: (billingResult: any) => {
        // OM24H-885: Removed billingAddress before storing to user data
        delete billingResult.result.billingAddress;
        this.resultList = { ...this.resultList, ...this.commonService.flatMap(billingResult.result) };
        this.omniDiagnosticsService.getAccountDiagnosis(this.accountNumber, this.brand, this.segment).subscribe({
          next: (accountResult: any) => {
            this.diagnosticsList[0].status = 'active';
            this.resultList = { ...this.resultList, ...this.commonService.flatMap(accountResult.result) };
            this.sessionService.setUserData(this.resultList);
            this.processResultData();
            this.processDiagnosticRequirements(this.diagnosticsList);
            this.validateResponse("");
          },
          error: (error) => {
            const additionalData = {
              api_name: 'GetGetAccountDiagnosticDetails',
              error_code: error?.error?.code
            };
            this.pushGtmEvent(OMNI_GENERIC_ERROR_TAG, additionalData, true);
            this.commonService.handleAPIError(error);
          }
        });
      },
      error: (error) => {
        const additionalData = {
          api_name: 'GetBillingDetails',
          error_code: error?.error?.code
        };
        this.pushGtmEvent(OMNI_GENERIC_ERROR_TAG, additionalData, true);
        this.commonService.handleAPIError(error);
      }
    });
  }

  /**
   * Validates and removes diagnostic list diagnostic when failed requirements
   */
  private processDiagnosticRequirements(diagnosticsList: OmniDiagnostic[] = []) {
    for (let index = diagnosticsList.length; index >= 0; index--) {
      if (this.validateRequirement(diagnosticsList[index])) {
        diagnosticsList.splice(index, 1);
      } else  if (diagnosticsList[index]?.diagnostics && diagnosticsList[index]?.diagnostics?.length) {
        this.processDiagnosticRequirements(diagnosticsList[index].diagnostics);
      }
    }
  }

  /**
   * Appends object to result list based on computation list config
   */
  private processResultData() {
    const dataAllocation = +this.resultList["dataAllocation"].match(OMNI_DIAGNOSTIC_DECIMAL_REGEX);
    const dataUsage = +this.resultList["dataUsage"].match(OMNI_DIAGNOSTIC_DECIMAL_REGEX);
    this.resultList["accountDataBalance"] = `${(dataUsage > dataAllocation ? 0 : dataAllocation - dataUsage).toFixed(2)}`;
  }

  /**
   * Validates if the diagnostic item will be removed from the list based on the requirements
   * @param diagnostic
   */
  private validateRequirement(diagnostic: OmniDiagnostic) {
    let result = false;
    if (diagnostic?.requirements) {
      diagnostic?.requirements?.forEach((requirement) => {
        if (
          (requirement.invalidValidationValues &&
            requirement.invalidValidationValues.includes(this.resultList[requirement.validationKey])) ||
          (requirement.validValidationValues &&
          !requirement.validValidationValues.includes(this.resultList[requirement.validationKey]))
        ) {
          result = true;
        }
      });
    }
    return result;
  }

  /**
   * Validates resultList based of configuration
   * @param startingId
   */
  private validateResponse(startingId?: string) {
    let timeoutQueue = 1;
    this.diagnosticsList.forEach((diagnostic: OmniDiagnostic) => {
        diagnostic.diagnostics?.forEach((subDiagnostic: OmniDiagnostic) => {
          if (!startingId || startingId === subDiagnostic.id) {
            startingId = "";
            const timeout = setTimeout(() => {
              if (!this.showDialogModal) {
                // Validate if invalidValidationValues and validValidationValues contains value of validationKey prop
                // Uses the sub-diagnostic id to display dialog modal based of the error
                if ((subDiagnostic?.validation &&
                  (subDiagnostic?.validation?.validationKey) &&
                  ((subDiagnostic?.validation?.invalidValidationValues &&
                  subDiagnostic?.validation?.invalidValidationValues?.includes(this.resultList[subDiagnostic?.validation?.validationKey])) ||
                  (subDiagnostic?.validation?.validValidationValues &&
                  !subDiagnostic?.validation?.validValidationValues?.includes(this.resultList[subDiagnostic?.validation?.validationKey])))) ||
                  (subDiagnostic?.validation?.validationKey &&
                    !Object.keys(this.resultList).includes(subDiagnostic?.validation?.validationKey))
                ) {
                  this.setDiagnosticFail(diagnostic, subDiagnostic);
                } else {
                  this.setSubDiagnosticSuccess(diagnostic, subDiagnostic);
                }
              }
              clearTimeout(timeout);
            }, (OMNI_DIAGNOSTICS_SUB_VALIDATION_TIMER * timeoutQueue));
            timeoutQueue++;
          }
        });
    });
  }

  /**
   * Navigate to provided URL
   */
  navigate(url: string = "") {
    return () => window.location.href = url;
  }

  /**
   * Generated fail dialog modal content based of diagnostic data
   * @param id
   * @param diagnosis
   */
  private generateFailDialogModal(id: string, diagnostic: OmniDiagnosticValidation) {
    this.failTitle = diagnostic?.failTitle;
    this.failDescription = diagnostic?.failDescription;
    this.failConfirmLabel = diagnostic?.failConfirmLabel;
    this.failCancelLabel = diagnostic?.failCancelLabel;
    this.failConfirmStyle = diagnostic?.failConfirmStyle;
    this.failCancelStyle = diagnostic?.failCancelStyle;
    this.failConfirmAction = this.generateButtonAction(id, diagnostic?.failConfirmUrl, diagnostic?.failConfirmGtmConfig);
    this.failCancelAction = this.generateButtonAction(id, diagnostic?.failCancelUrl, diagnostic?.failCancelGtmConfig);
    this.showDialogModal = id;
  }

  /**
   * Generates the function run when modal button is clicked
   * @param id
   * @param action
   * @returns
   */
  private generateButtonAction(id: string, action?: string, gtmConfig?:any): Function {
    let buttonAction: Function = () => {};
    if (action) {
      if (action === "continue") {
        buttonAction = () => {
          if (gtmConfig) {
            this.pushDiagnosticGtm(id, gtmConfig);
          }
          this.proceedChecking(id);
        };
      } else if (action === "/omnicare/scheduleTechnician") {
        buttonAction = () => {
          this.showDialogModal = '';
          this.loading = true;
          if (gtmConfig) {
            this.pushDiagnosticGtm(id, gtmConfig);
          }
        this.schedTechnicianService.navigateToSchedTechnician();
        };
      } else {
        buttonAction = () => {
          if (gtmConfig) {
            this.pushDiagnosticGtm(id, gtmConfig);
          }
          this.commonService.navigate(action);
        };
      }
    }
    return buttonAction;
  }

  /**
   * Proceeds with the checking starting from error
   * @param lastValidationId
   */
  proceedChecking(lastValidationId: string) {
    let startingValidationId = lastValidationId;
    let isNext = false;
    this.diagnosticsList.forEach((diagnostic: OmniDiagnostic) => {
      diagnostic.diagnostics?.forEach((subDiagnostic: OmniDiagnostic) => {
        if (isNext) {
          startingValidationId = subDiagnostic.id;
          diagnostic.status = "active";
          subDiagnostic.status = "active";
          isNext = false;
        }
        if (subDiagnostic.id === lastValidationId) {
          isNext = true;
        }
      });
    });
    this.showDialogModal = "";
    this.validateResponse(startingValidationId);
  }

  /**
   * Clears all setTimeout currently running
   */
  private clearTimeouts() {
    let id = window.setTimeout(function() {}, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  /**
   * Processes the diagnostics list to set provided sub-diagnostic to complete
   * @param diagnostic
   * @param subDiagnostic
   */
  private setSubDiagnosticSuccess(diagnostic: OmniDiagnostic, subDiagnostic: OmniDiagnostic) {
    const diagnosticsIndex = this.diagnosticsList.indexOf(diagnostic);
    // Validation passes and sets current sub-diagnostic to completed
      subDiagnostic.status = "completed";

    // Checks if the next active sub-diagnostic would be in under the same diagnostic or the next one
    if (diagnostic.diagnostics?.indexOf(subDiagnostic) === ((diagnostic.diagnostics?.length || 0) - 1)) {
      this.clearTimeouts();
      const diagnosticTimeout = setTimeout(() => {
        diagnostic.status = "completed";
        const nextDiagnostic = this.diagnosticsList[diagnosticsIndex + 1];
        this.pushDiagnosticGtm(diagnostic.id);
        if (nextDiagnostic) {
          nextDiagnostic.status = "active";
          if (diagnosticsIndex < (this.diagnosticsList.length - 1) && nextDiagnostic?.diagnostics?.length) {
            this.validateResponse(nextDiagnostic.diagnostics[0].id);
          }
        } else {
          this.showDialogModal = "completed";
        }
        clearTimeout(diagnosticTimeout);
      }, OMNI_DIAGNOSTICS_VALIDATION_TIMER);
    } else if (diagnostic.diagnostics) {
      const diagnosticsIndex = diagnostic.diagnostics?.indexOf(subDiagnostic);
      diagnostic.diagnostics[(diagnosticsIndex || 0) + 1].status = "active";
    }
  }

  /**
   * Processes the diagnostics list to set provided sub-diagnostic to fail
   * @param diagnostic
   * @param subDiagnostic
   */
  private setDiagnosticFail(diagnostic: OmniDiagnostic, subDiagnostic: OmniDiagnostic) {
    this.clearTimeouts();
    if (!subDiagnostic?.sub_validations && subDiagnostic.validation) {
      subDiagnostic.status = "fail";
      diagnostic.status = "fail";
      this.generateFailDialogModal(subDiagnostic.id, subDiagnostic.validation);
    } else if (subDiagnostic?.sub_validations) {
      subDiagnostic.sub_validations.forEach((subValidation) => {
        if (subValidation?.validationKey &&
          ((subValidation?.invalidValidationValues &&
          subValidation?.invalidValidationValues?.includes(this.resultList[subValidation?.validationKey])) ||
          (subValidation?.validValidationValues &&
          !subValidation?.validValidationValues?.includes(this.resultList[subValidation?.validationKey])))) {
            subValidation.status = "fail";
            subDiagnostic.status = "fail";
            diagnostic.status = "fail";
            this.generateFailDialogModal(subDiagnostic.id, subValidation);
            if (subValidation?.failConfirmTimer && this.failConfirmAction) {
              this.setTimerTriggerAction(this.failConfirmAction, subValidation.failConfirmTimer);
            } else if (subValidation?.failCancelTimer && this.failCancelAction) {
              this.setTimerTriggerAction(this.failCancelAction, subValidation.failCancelTimer);
            }
          } else {
            subValidation.status = "completed";
          }
      });
      if (subDiagnostic?.status !== "fail") {
        this.setSubDiagnosticSuccess(diagnostic, subDiagnostic);
      }
    }
  }

  /**
   * Adds a settimeout function for triggering action set by config
   * @param millisecons
   */
  setTimerTriggerAction(action: Function, ms: number) {
    const timerTimeout = setTimeout(() => {
      action();
      clearTimeout(timerTimeout);
    }, ms);
  }

  /**
   * Replaces curly brace pattern with key
   * @param text
   */
  parseRegex(text: string) {
    return text.replace(OMNI_DIAGNOSTICS_TITLE_REGEX, (match, key) => this.resultList[key]);
  }

  /**
   * Generate diagnostic text displayed based on the status
   * @param diagnostic
   * @param status
   * @returns
   */
  getTitleByStatus(diagnostic: any, status: string): string {
    return this.parseRegex(diagnostic[`title_${status}`]);
  }

  /**
   * Generate image path for each diagnostic status
   * @param status
   * @returns
   */
  getImageByStatus(status: string): string {
    return `/assets/icons/diagnostic-${status}.${['active', 'initial'].includes(status) ? 'gif' : 'svg'}`;
  }

  /**
   * Action taken when confirm button clicked in success modal
   */
  successConfirmButton () {
    return () =>  {
      this.pushGtmEvent(OMNI_DIAGNOSTIC_COMPLETE_MODAL_NO_TAG);
      this.commonService.navigate(this.diagnosticsSuccessModal.confirmButtonUrl);
    };
  }

  /**
   * Action taken when cancel button clicked in success modal
   */
  successCancelButton () {
    return () => {
      this.pushGtmEvent(OMNI_DIAGNOSTIC_COMPLETE_MODAL_YES_TAG);
      this.commonService.navigate(this.diagnosticsSuccessModal.cancelButtonUrl);
    };
  }

  /**
   * Pushes event tagging to GTM
   */
  pushGtmEvent(config: any, additionalData?: any, isError: boolean = false) {
    const userData = this.sessionService.getUserData();
    const accountStatus = userData?.accountStatus !== 'Connected' ? 'temp_disconnected' : 'active';
    const status = userData?.balanceStatus === 'Overdue' ?
      (userData?.accountStatus === 'Connected' ? 'overdue_with_connection' : 'overdue_disconnected') :
      'no_overdue';
    const data: any = {
      promo_category: ["Unlimited", "Data not found", "No data found"].includes(userData?.dataAllocation) ?
        'unli plan':
        'not unli plan',
      status: status,
      account_status: accountStatus,
    };
    if (additionalData) {
      Object.keys(additionalData).forEach(key => {
        data[key] = additionalData[key];
      });
    }
    if (isError) {
      this.sessionService.setData(
        SESSION_KEYS.OMNI_API_ERROR_EVENT,
        additionalData
      );
    }
    this.gtmService.captureGTMEvent(config, false, data);
  }

  /**
   * Generate event tagging object for GTM
   */
  pushDiagnosticGtm(diagnosticId: string, config?: any) {
    const userData = this.sessionService.getUserData();
    let additionalData: any = {};
    additionalData['modem_line'] = ["Account has no last mile issue", "Facility is not Huawei", "Facility is not Huawei.", "No data found"]
      .includes(userData?.lineStatus) ?
      'no' :
      'yes';
    additionalData['fiber_cuts_issue'] = 'no';
    additionalData['fiber_losses_issue'] = 'no';
    additionalData['outage_status'] = ["No Service Affecting Network Incident and/or Planned Activity Found!", "Data not found", "No data found"]
      .includes(userData?.outageResult) ?
      'no' :
      'yes';

    if (!config) {
      switch (diagnosticId) {
        case 'modem':
          this.pushGtmEvent(OMNI_DIAGNOSTIC_COMPLETE_TAG, additionalData);
          break;
        case 'fiber':
          delete additionalData['modem_line'];
          this.pushGtmEvent(OMNI_DIAGNOSTIC_FIBER_TAG, additionalData);
          break;
        case 'network':
          delete additionalData['modem_line'];
          delete additionalData['fiber_cuts_issue'];
          delete additionalData['fiber_losses_issue'];
          this.pushGtmEvent(OMNI_DIAGNOSTIC_NETWORK_TAG, additionalData);
          break;
        case 'account':
          delete additionalData['modem_line'];
          delete additionalData['fiber_cuts_issue'];
          delete additionalData['fiber_losses_issue'];
          delete additionalData['outage_status'];
          this.pushGtmEvent(OMNI_DIAGNOSTIC_ACCOUNT_TAG);
          break;
      }
    } else {
      switch (diagnosticId) {
        case 'fiber':
          delete additionalData['modem_line'];
          break;
        case 'network':
          delete additionalData['modem_line'];
          delete additionalData['fiber_cuts_issue'];
          delete additionalData['fiber_losses_issue'];
          break;
        case 'account':
          delete additionalData['modem_line'];
          delete additionalData['fiber_cuts_issue'];
          delete additionalData['fiber_losses_issue'];
          delete additionalData['outage_status'];
          break;
      }
      this.pushGtmEvent(config, additionalData);
    }
  }

}
