import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbModule,
  NgbDatepickerModule,
  NgbAccordionModule,
} from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AppComponent } from './app.component';
import { OmniBasicTroubleshootingComponent } from './components/omnicare/omni-basic-troubleshooting/omni-basic-troubleshooting.component';
import { OmniRequestConfirmedComponent } from './components/omnicare/omni-request-confirmed/omni-request-confirmed.component';
import { OmniSchedTechnicianComponent } from './components/omnicare/omni-sched-technician/omni-sched-technician.component';
import { OmniSnackbarComponent } from './components/omnicare/omni-snackbar/omni-snackbar.component';
import { OmniAlphanumericSpecialCharsDirective } from './directives/omni-alphanumeric-special-chars.directive';
import { OmniWelcomeComponent } from './components/omnicare/omni-welcome/omni-welcome.component';
import { OmniDialogModalComponent } from './components/omnicare/omni-dialog-modal/omni-dialog-modal.component';
import { OmniDiagnosticsComponent } from './components/omnicare/omni-diagnostics/omni-diagnostics.component';
import { OmniSchedTechnicianConcernComponent } from './components/omnicare/omni-sched-technician-concern/omni-sched-technician-concern.component';
import { OmniLoadingModalComponent } from './components/omnicare/omni-loading-modal/omni-loading-modal.component';
import { OmniSchedTechnicianNoSlotsComponent } from './components/omnicare/omni-sched-technician-no-slots/omni-sched-technician-no-slots.component';
import { OmniDatePickerModalComponent } from './components/omnicare/omni-date-picker-modal/omni-date-picker-modal.component';
import { OmniErrorComponent } from './components/omnicare/omni-error/omni-error.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth-interceptor';
import { OmniTrackMyRepairComponent } from './components/omnicare/omni-track-my-repair/omni-track-my-repair.component';
import { OmniPlatformDownComponent } from './components/omnicare/omni-platform-down/omni-platform-down.component';
import { OmniTroubleshootingFailedComponent } from './components/omnicare/omni-troubleshooting-failed/omni-troubleshooting-failed.component';
import { OmniWorkorderDetailsComponent } from './components/omnicare/omni-workorder-details/omni-workorder-details.component';
import { ObiFaqComponent } from './components/obi/obi-faq/obi-faq.component';
import { ObiPreCheckComponent } from './components/obi/obi-pre-check/obi-pre-check.component';
import { ObiBillInquiryComponent } from './components/obi/obi-bill-inquiry/obi-bill-inquiry.component';
import { OmniCurrencyCharsDirective } from './directives/omni-currency-chars.directive';
import { OmniMobileNumberFormatDirective } from './directives/omni-mobile-number-format.directive';
import { OmniRescheduleRequestComponent } from './components/omnicare/omni-reschedule-request/omni-reschedule-request.component';
import { ObiTrackMyRequestComponent } from './components/obi/obi-track-my-request/obi-track-my-request.component';
import { ObiTicketReceiptComponent } from './components/obi/obi-ticket-receipt/obi-ticket-receipt.component';
import { ObiBillChargesComponent } from './components/obi/obi-bill-charges/obi-bill-charges.component';
import { ObiBillStatementComponent } from './components/obi/obi-bill-statement/obi-bill-statement.component';
import { ObiDatePickerModalComponent } from './components/obi/obi-date-picker-modal/obi-date-picker-modal.component';
import { ObiTimePickerModalComponent } from './components/obi/obi-time-picker-modal/obi-time-picker-modal.component';
import { OmniViewStatusComponent } from './components/omnicare/omni-view-status/omni-view-status.component';
import { OmniBarringErrorComponent } from './components/omnicare/omni-barring-error/omni-barring-error.component';

@NgModule({
  declarations: [
    AppComponent,
    OmniBasicTroubleshootingComponent,
    OmniRequestConfirmedComponent,
    OmniSchedTechnicianComponent,
    OmniSnackbarComponent,
    OmniAlphanumericSpecialCharsDirective,
    OmniWelcomeComponent,
    OmniDialogModalComponent,
    OmniDiagnosticsComponent,
    OmniSchedTechnicianConcernComponent,
    OmniLoadingModalComponent,
    OmniSchedTechnicianNoSlotsComponent,
    OmniDatePickerModalComponent,
    OmniErrorComponent,
    OmniTrackMyRepairComponent,
    OmniPlatformDownComponent,
    OmniTroubleshootingFailedComponent,
    OmniWorkorderDetailsComponent,
    ObiFaqComponent,
    ObiPreCheckComponent,
    ObiBillInquiryComponent,
    OmniCurrencyCharsDirective,
    OmniMobileNumberFormatDirective,
    ObiTrackMyRequestComponent,
    ObiTicketReceiptComponent,
    ObiBillChargesComponent,
    ObiBillStatementComponent,
    ObiDatePickerModalComponent,
    ObiTimePickerModalComponent,
    OmniViewStatusComponent,
    OmniRescheduleRequestComponent,
    OmniBarringErrorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    NgbDatepickerModule,
    NgbAccordionModule,
    HttpClientModule,
    ReactiveFormsModule,
    PdfViewerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
