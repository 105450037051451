import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[appOmniAlphanumericSpecialChars]'
})
export class OmniAlphanumericSpecialCharsDirective {

  /**
   * Form Group to be updated
   */
  @Input() formGroupDir: FormGroup | undefined;

  /**
   * Form Control name to be updated
   */
  @Input() formControlNameDir: string | undefined;

  /**
   * Pattern to be followed
   */
  @Input() formPattern: RegExp | undefined;

  /**
   * Pattern used to replace not accepted pattern
   */
  @Input() formReplacePattern: RegExp | undefined;

  /**
   * Element of the input field
   */
  @Input() inputField: HTMLInputElement = {} as HTMLInputElement;

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInput(event: any): void {
    const inputValue: string = event.target.value;
    let pattern: RegExp = /^[a-zA-Z0-9.,\-?! \r\n]*$/; // Regex pattern for alphanumeric and specified special characters
    let selectionIndex = inputValue.length - (this.inputField?.selectionStart || 0);
    let selectionDecrease = 0;
    if (this.formPattern) {
      pattern = this.formPattern;
    }

    let replacePattern: RegExp = /[^a-zA-Z0-9.,\-?! \r\n]/g;
    if (this.formReplacePattern) {
      replacePattern = this.formReplacePattern;
    }
    if (!pattern.test(inputValue)) {
      selectionDecrease = inputValue.match(replacePattern)?.length || 0;
      // If input doesn't match the pattern, remove invalid characters
      event.target.value = inputValue.replace(replacePattern, '');
    }

    // Move cursor to previous placement
    if (selectionIndex) {
      this.inputField.selectionStart = inputValue.length - selectionIndex - selectionDecrease;
      this.inputField.selectionEnd = inputValue.length - selectionIndex - selectionDecrease;
    }
    this.updateFormAndValidity(event.target.value);
  }

  /**
   * Updates the FormGroup and FormControl when available
   * @param value
   */
  updateFormAndValidity(value: any) {
    if (this.formGroupDir && this.formControlNameDir) {
      this.formGroupDir.controls[this.formControlNameDir].setValue(value);
      this.formGroupDir.updateValueAndValidity();
    }
  }

}
