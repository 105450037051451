import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";

import { OmniSnackbarComponent } from "../omni-snackbar/omni-snackbar.component";
import html2canvas from "html2canvas";

import { OMNI_REQ_CONFIRMED_INIT_VALUES } from "../../../common/constants/omni-request-confirmed.constant";
import { PAGE_URLS, SESSION_KEYS } from "../../../common/constants/omni-global.constant";
import { OmniSessionService } from "../../../services/omni-session.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { GoogleTagManagerService } from "src/app/services/google-tag-manager-service.service";
import {
  OMNI_REQUEST_CONFIRMED_COPY_TAG,
  OMNI_REQUEST_CONFIRMED_DOWNLOAD_TAG,
  OMNI_REQUEST_CONFIRMED_GOTODASHBOARD_TAG,
  OMNI_REQUEST_CONFIRMED_LANDING_TAG,
} from 'src/app/common/constants/omni-gtm-events.constants';
import { OmniCommonService } from "src/app/services/omni-common.services";

const {
  OMNI_REQ_CONFIRM_TITLE,
  OMNI_REQ_CONFIRMED_TIMESTAMP,
  DOWNLOAD_ICON,
  RECEIPT_GREET_LABEL,
  RECEIPT_TITLE,
  CHECK_ICON,
  BILLER_LABEL,
  OMNI_REQ_CONFIRMED_BRAND,
  ACCOUNT_DETAILS,
  CONCERN_DETAILS,
  COPY_ICON,
  GOTO_DASHBOARD_BTN_LABEL,
  SNACKBAR_ACTION_DOWNLOAD,
  SNACKBAR_TITLE_DOWNLOAD,
  SNACKBAR_SUBTEXT_DOWLOAD,
  SNACKBAR_TITLE_COPY,
  SNACKBAR_ACTION_COPY,
  OMNI_REQ_CONFIRMED_DIV_ID,
  RECEIPT_PREFIX_FILENAME,
  WORK_ORDER_NUMBER,
  REMINDER_TITLE,
  REMINDER_SUBTEXT
} = OMNI_REQ_CONFIRMED_INIT_VALUES;

const { GOTO_DASHBOARD_URL } = PAGE_URLS;


@Component({
  selector: "app-omni-request-confirmed",
  templateUrl: "./omni-request-confirmed.component.html",
  styleUrls: ["./omni-request-confirmed.component.scss"],
})
export class OmniRequestConfirmedComponent implements OnInit {
  @ViewChild("workOrderNumTxt") workOrderNumTxt: ElementRef | undefined;
  @ViewChild(OmniSnackbarComponent, { static: false }) snackbarComponent:
    | OmniSnackbarComponent
    | undefined;

  public confirmRequestTitle: string = OMNI_REQ_CONFIRM_TITLE;
  public confirmRequestTimestamp: string = OMNI_REQ_CONFIRMED_TIMESTAMP;

  public omniReqConfirmedDownloadIcon: string = DOWNLOAD_ICON;
  public receiptGreetLabel: string = RECEIPT_GREET_LABEL;
  public receiptTitle: string = RECEIPT_TITLE;
  public omniReqConfirmedCheckIcon: string = CHECK_ICON;

  public billerLabel: string = BILLER_LABEL;
  public omniReqConfirmedBrand: any = OMNI_REQ_CONFIRMED_BRAND;
  public accountDetails: any[] = ACCOUNT_DETAILS;

  public concernSubject: string = CONCERN_DETAILS.concern;
  public concernDetails: string = CONCERN_DETAILS.description;
  public concernDetailsDisplay!: SafeHtml;

  public omniReqConfirmedCopyIcon: string = COPY_ICON;
  public workOrderNumber: string = WORK_ORDER_NUMBER;

  public gotoDashboardLabel: string = GOTO_DASHBOARD_BTN_LABEL;
  public goToDashboardURL: string = GOTO_DASHBOARD_URL;

  public showSnackBarComponent: boolean = false;
  public snackBarIsShowSubtext: boolean = false;
  public snackBarDuration: number = 5000;
  public snackBarTitle: string = "";
  public snackBarSubText: string = "";
  public snackBarShow: boolean = true;

  public reminderTitle: string = REMINDER_TITLE;
  public reminderSubtext: string = REMINDER_SUBTEXT;

  userData: any = null;

  constructor(
    @Inject(DOCUMENT) private dom: any,
    private omniSessionService: OmniSessionService,
    private domSanitizer: DomSanitizer,
    public commonService: OmniCommonService,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit(): void {
    // Get and display current date time
    this.confirmRequestTimestamp = this.formatCurrentTimeStamp();

    // Get user data from the session service
    this.userData = this.omniSessionService.getUserData();

    if (!this.userData) {
      return; // Exit early if user data is not available
    }

    // OM24H-950: Added GTM event for landing page
    this.captureGTMEvent(OMNI_REQUEST_CONFIRMED_LANDING_TAG);

    // Update account details and schedule of visit
    this.accountDetails[0].value = this.userData?.first_name + ' ' + this.userData?.last_name;
    this.accountDetails[1].value = this.omniSessionService.getData(SESSION_KEYS.OMNI_ACCOUNT_NUMBER_KEY);
    this.accountDetails[2].value = this.userData?.schedule_repair_date;
    this.accountDetails[3].value = this.userData?.schedule_repair_time;

    // Update concern details
    this.concernSubject = this.omniSessionService.getData(SESSION_KEYS.OMNI_CONCERN_TYPE);
    this.concernDetails = this.omniSessionService.getData(SESSION_KEYS.OMNI_CONCERN_DETAILS);
    this.concernDetailsDisplay = this.sanitizeConcernDetails(this.concernDetails);


    //Get appointment slot ID in the session
    this.workOrderNumber = this.omniSessionService.getData(SESSION_KEYS.OMNI_APPOINTMENT_SLOT_KEY) ;
  }

  ngOnDestroy(): void {}

  /**
   * Show snack bar when download and copy function is triggered
   * @param actionTrigger
   */
  public showSnackBar(actionTrigger: string) {
    if (SNACKBAR_ACTION_DOWNLOAD == actionTrigger) {
      this.snackBarIsShowSubtext = true;
      this.snackBarTitle = SNACKBAR_TITLE_DOWNLOAD;
      this.snackBarSubText = SNACKBAR_SUBTEXT_DOWLOAD;
    } else {
      this.snackBarIsShowSubtext = false;
      this.snackBarTitle = SNACKBAR_TITLE_COPY;
      this.snackBarSubText = "";
    }
    this.snackbarComponent?.showSnackBar();
  }

  /**
   * Copy worOrderNumber
   */
  public copyWorkOrderNumTxt() {
    this.captureGTMEvent(OMNI_REQUEST_CONFIRMED_COPY_TAG);

    const workOrderNumTxtElem = this.dom.createElement("textarea");
    workOrderNumTxtElem.style.position = "fixed";
    workOrderNumTxtElem.style.left = "0";
    workOrderNumTxtElem.style.top = "0";
    workOrderNumTxtElem.style.opacity = "0";
    workOrderNumTxtElem.value = this.workOrderNumTxt?.nativeElement.innerText;
    this.dom.body.appendChild(workOrderNumTxtElem);
    workOrderNumTxtElem.focus();
    workOrderNumTxtElem.select();
    this.dom.execCommand("copy");
    this.dom.body.removeChild(workOrderNumTxtElem);

    this.showSnackBar(SNACKBAR_ACTION_COPY);
  }

  /**
   * Download Request form confirmation (Image file jpg)
   */
  downloadRequestConfirmation() {
    this.captureGTMEvent(OMNI_REQUEST_CONFIRMED_DOWNLOAD_TAG);

    const origReqConfirmedDiv = document.getElementById(
      OMNI_REQ_CONFIRMED_DIV_ID
    );

    if (origReqConfirmedDiv) {
      //Hide excluded element
      //Excluded element list (Excluded in the Image)
      OMNI_REQ_CONFIRMED_INIT_VALUES.EXCLUDED_ELEMENT.forEach((className) => {
        const elements = origReqConfirmedDiv.getElementsByClassName(className);
        for (let i = 0; i < elements.length; i++) {
          elements[i].classList.add("hidden"); // Add a 'hidden' class to hide the elements
        }
      });

      //Remove box shadow
      const receipBoxElement = document.querySelectorAll(
        ".omni-confirmed-request__receipt-box"
      );
      receipBoxElement.forEach((element) => {
        element.classList.remove("shadow");
      });

      //Convert HTML to Image
      html2canvas(origReqConfirmedDiv).then((canvas) => {
        // Convert canvas to image data URL
        const imageDataUrl = canvas.toDataURL(`image/jpg`);

        // Create a link to download the image
        const a = document.createElement("a");
        a.href = imageDataUrl;

        //Downloaded file name
        const imageFileName = `${RECEIPT_PREFIX_FILENAME}${this.getTimestamp()}`;
        a.download = `${imageFileName}.jpg`;

        a.style.display = "none";

        // Add the link to the document and trigger the download
        document.body.appendChild(a);
        a.click();

        // Clean up
        document.body.removeChild(a);

        //Unhide elements
        const hiddenElements = document.querySelectorAll(".hidden");
        hiddenElements.forEach((element) => {
          element.classList.remove("hidden"); // Remove the 'hidden' class to make the elements visible
        });

        // Remove the added padding from the
        //origReqConfirmedDiv.style.paddingBottom = '';

        //Put back box shadow
        receipBoxElement[0].classList.add("shadow");
      });
    }
  }

  /**
   * Format Timestamp
   * @returns
   */
  public getTimestamp() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = String(now.getFullYear());
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${day}${month}${year}${hours}${minutes}${seconds}`;
  }

  /**
   * Format Header timestamp
   * @returns
   */
  public formatCurrentTimeStamp(){
    const currentDate = new Date();

    // Define the months in an array for easy formatting
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const month = months[currentDate.getMonth()];
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();

    let hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;

    const formattedDateTime = `${month} ${day} ${year}, ${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    return formattedDateTime;

  }

  sanitizeConcernDetails(concernDetailsVal: string ): SafeHtml {
    const formattedConcernDetails = concernDetailsVal.replace(/\n/g, '<br>');
    return this.domSanitizer.bypassSecurityTrustHtml(formattedConcernDetails);
  }

  /**
   * Navigates to the dashboard.
   * Includes GTM tagging (OM24H-950)
   */
  gotoDashboard() {
    this.captureGTMEvent(OMNI_REQUEST_CONFIRMED_GOTODASHBOARD_TAG);
    this.commonService.navigate(this.goToDashboardURL);
  }

  /**
   * OM24H-950: Captures Google Tag events and push these tags to the dataLayer
   * @param eventInfo contains initial event info to be pushed
   */
  captureGTMEvent(eventInfo: any) {
    const orderStatus = this.omniSessionService.getData(
      SESSION_KEYS.OMNI_ORDER_STATUS
    );

    const {
      outageResult,
      lineStatus,
      modemStatus,
    } = this.userData;

    const additionalData = {
      promo_category: this.commonService.determineAccountStatusForTagging('promo_category', this.userData),
      status: this.commonService.determineAccountStatusForTagging('status', this.userData),
      account_status: this.commonService.determineAccountStatusForTagging('account_status', this.userData),
      outage_status: this.commonService.determineDiagStatusForTagging('outage_status', outageResult),
      fiber_cuts_issue: this.commonService.determineDiagStatusForTagging('fiber_cuts_issue', lineStatus),
      fiber_losses_issue: this.commonService.determineDiagStatusForTagging('fiber_losses_issue', lineStatus),
      modem_line: this.commonService.determineDiagStatusForTagging('modem_line', modemStatus),
      modem_power: this.commonService.determineDiagStatusForTagging('modem_power', modemStatus),
      reschedule: orderStatus == 'open' ? 'yes' : 'no',
      transaction_id: this.workOrderNumber
    };

    this.gtmService.captureGTMEvent(eventInfo, false, additionalData);
  }
}
