export const OMNI_SCHED_TECHNICIAN_SCHED_INIT_VALUES = {
  CANCEL_BUTTON_LABEL: "Yes", //cancelButtonLabel
  CONCERN_NOTICE: "We need more details so our technician can assist you.", //omniSchedTechConcernNotice
  CONCERN_LABEL: "Concern", //omniScheTechConcernLabel
  CONCERN_DETAILS_LABEL: "Tell us more about your concern ", //omniScheTechConcernDetailsLabel
  CONFIRMATION_DIALOG_TITLE: "Are you sure you want to cancel your request for a technician visit?", //confirmationDialogTitle
  CONFIRM_BUTTON_LABEL: "No", //confirmButtonLabel
  GUIDE_TITLE: "Scheduling a technician reminder", //guideTitle
  GUIDE_TEXT:
    "Real-time scheduling of our technician is only available between 8 AM and 5 PM daily.<br/><br/>Scheduling beyond 5 PM is subject for scheduled booking on the next day.", //guideText
  PAGE_TITLE: "Choose a date and time for technician visit", //pageTitle
  TIME: {
    AM: "9:00 AM -<br/>12:00 NN",
    PM: "1:00 PM -<br/>5:00 PM",
  },
  TXTAREA_MIN_HEIGHT: 96,
  TXTAREA_MAX_LENGTH: 100,
  TXTAREA_PLACE_HOLDER_VALUE: "You can use English or Taglish.",
  SCHED_TODAY_LABEL: "TODAY",
  SCHED_NEXT_DAY_LABEL: "NEXT DAY",
  SCHED_IN_TWO_DAYS_LABEL: "IN 2 DAYS",

  BTN_NEXT_LABEL: "Next",
  BTN_CANCEL_LABEL: "Cancel",
};
