<div [class]="['omni-date-picker-modal-container', showModal ? 'disable-scrolling': '', showModal ? '': 'hidden']">
  <div class="omni-date-picker-modal-container__content">
    <h1>Pick a date</h1>
    <img
      class="omni-date-picker-modal-container__close-btn"
      (click)="closeDatepicker()"
      [src]="omniXicon"
    />
    <ngb-datepicker
      [(ngModel)]="selectedDate"
      class="omni-date-picker-custom-calendar"
      [class.omni-date-picker-custom-calendar--hidden-nav]="hideNavigation"
      outsideDays="collapsed"
      [dayTemplate]="customDay"
      [firstDayOfWeek]="7"
      [markDisabled]="markDisabled"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [navigation]="hideNavigation ? 'none' : 'arrows'"
    ></ngb-datepicker>
  </div>
</div>

<ng-template
  #customDay
  let-date="date"
  let-currentMonth="currentMonth"
  let-selected="selected"
  let-disabled="disabled"
>
  <span
    class="omni-date-picker-custom-calendar-day"
    [class.omni-date-picker-custom-calendar-day--selected]="selected"
    [class.omni-date-picker-custom-calendar-day--disabled]="disabled"
    (click)="onDateSelect(date)"
  >
    {{ date.day }}
  </span>
</ng-template>
