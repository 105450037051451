<div class="omni-sched-technician">
  <div class="omni-sched-technician-content">
    <div *ngIf="withSlots">
      <h1>
        {{ pageTitle }}
        <span class="omni-sched-technician-content__red-asterisk"> *</span>
      </h1>
      <div class="omni-sched-technician-schedules">
        <div
          *ngFor="let schedule of schedules"
          (click)="selectSchedule(schedule)"
          [ngClass]="{
            'omni-sched-technician-schedules__card': true,
            'selected': schedule === selectedSchedule
          }"
        >
          <h1>
            {{ schedule.header }}
          </h1>
          <h2>
            {{ schedule.day }}
          </h2>
          <h3 [innerHTML]="schedule.time">
            <!-- {{ schedule.time }} -->
          </h3>
        </div>
      </div>
    </div>
    <div *ngIf="!withSlots">
      <app-omni-sched-technician-no-slots
        (dateSelected)="handleDateSelection($event)"
        (timeSelected)="
          handleTimeSelection($event)
        "></app-omni-sched-technician-no-slots>
    </div>
    <div *ngIf="withSlots">
      <div ngbAccordion [destroyOnHide]="false">
        <div ngbAccordionItem="dateSelector" #dateSelector="ngbAccordionItem">
          <h2 ngbAccordionHeader>
            <button
              ngbAccordionButton
              class="omni-sched-technician-accordion__button">
              {{ accordionTitle }}
              <i
                class="omni-sched-technician-accordion__button-arrow-icon"
                [class.omni-sched-technician-accordion__button-arrow-icon--down]="
                  dateSelector.collapsed
                "
                [class.omni-sched-technician-accordion__button-arrow-icon--up]="
                  !dateSelector.collapsed
                "></i>
            </button>
            <p class="omni-sched-technician-accordion__subtitle">
              {{ accordionSubtitle }}
            </p>
          </h2>
          <div ngbAccordionCollapse>
            <div
              ngbAccordionBody
              class="omni-sched-technician-accordion__content"
              [class.omni-sched-technician-accordion__content--hidden]="
                dateSelector.collapsed
              ">
              <ng-template>
                <app-omni-sched-technician-no-slots
                  (dateSelected)="handleDateSelection($event)"
                  (timeSelected)="handleTimeSelection($event)"
                  [showBanner]="false"
                  [selectedDate]="selectedDate"
                  [selectedTime]="selectedTime">
                </app-omni-sched-technician-no-slots>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="withSlots" 
        class="omni-sched-technician-guide">
      <p class="omni-sched-technician-guide__header">
        {{ guideTitle }}
      </p>
      <p class="omni-sched-technician-guide__text" [innerHTML]="guideText"></p>
    </div>
  </div>
  <div class="omni-sched-technician-buttons">
    <button
      class="omni-sched-technician-buttons__submit-btn"
      [disabled]="
        (!selectedSchedule || selectedTime || selectedDate) &&
        (selectedSchedule || !selectedTime || !selectedDate)
      "
      (click)="submitRequest()">
      Submit
    </button>
    <button
      class="omni-sched-technician-buttons__cancel-btn"
      (click)="showConfirmationModal()"
    >
      Cancel
    </button>
  </div>
</div>

<app-omni-loading-modal [showModal]="loading" [message]="''"></app-omni-loading-modal>
<omni-dialog-modal
  [title]="confirmationDialogTitle"
  [confirmButtonLabel]="confirmButtonLabel"
  [cancelButtonLabel]="cancelButtonLabel"
  [show]="confirmation"
  [confirmButtonAction]="handleConfirmation()"
  [cancelButtonAction]="handleConfirmationCancel()"
></omni-dialog-modal>

