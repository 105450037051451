<div class="obi-confirmed-request__modal" *ngIf="open">
  <div class="obi-confirmed-request__modal-header">
    <a [attr.href]="goToDashboardURL">
      <img class="obi-confirmed-request__btn-close" [src]="closeIcon" />
    </a>
  </div>
  <div class="obi-confirmed-request" id="obi-req-confirmed-div-id">
    <div class="obi-confirmed-request__receipt">
      <div class="obi-confirmed-request-header">
        <p class="obi-confirmed-request__title" [innerHTML]="receiptHeader"></p>
        <p
          class="obi-confirmed-request__timestamp"
          [innerHTML]="receiptTimestamp"></p>
      </div>

      <div class="obi-confirmed-request__receipt-border-img-top-decor"></div>
      <div class="obi-confirmed-request__receipt-box shadow">
        <div class="obi-confirmed-request__receipt-wrapper">
          <div class="obi-confirmed-request__receipt-header">
            <img
              class="obi-confirmed-request__receipt-download-icon"
              [src]="downloadIcon"
              (click)="downloadTicketReceipt()" />
            <p
              class="obi-confirmed-request__receipt-greet"
              [innerHTML]="receiptGreetLabel"></p>
            <p
              class="obi-confirmed-request__receipt-title"
              [innerHTML]="receiptTitle"></p>
            <img class="obi-confirmed-request__check-icon" [src]="checkIcon" />
          </div>

          <div class="obi-confirmed-request__receipt-content">
            <div class="obi-confirmed-request__account-details">
              <div
                class="obi-confirmed-request__account-details-biller-wrapper">
                <p
                  class="obi-confirmed-request__account-details-biller"
                  [innerHTML]="billStatusLabel"></p>

                <p
                  class="obi-confirmed-request__account-details-content-value"
                  [innerHTML]="billStatus"></p>
              </div>

              <p class="obi-confirmed-request__account-details-line"></p>
              <div class="obi-confirmed-request__account-details-content">
                <div
                  class="obi-confirmed-request__account-details-content-wraper"
                  *ngFor="let accountDetail of obiBillInquiryService.billInquiryDetails.accountDetails">
                  <p
                    class="obi-confirmed-request__account-details-content-label"
                    [innerHTML]="accountDetail.label"></p>
                  <p
                    class="obi-confirmed-request__account-details-content-value"
                    [innerHTML]="accountDetail.value"></p>
                </div>
              </div>
            </div>

            <div class="obi-confirmed-request__concern-details">
              <div
                class="obi-confirmed-request__concern-details-bill-content-wrapper"
                *ngFor="let billDetail of obiBillInquiryService.billInquiryDetails.billDetails">
                <p
                  class="obi-confirmed-request__concern-details-bill-content-label"
                  [innerHTML]="billDetail.label"></p>
                <p
                  class="obi-confirmed-request__concern-details-bill-content-value"
                  [innerHTML]="billDetail.value"></p>
              </div>

              <p class="obi-confirmed-request__concern-details-label"
                *ngIf="obiBillInquiryService.billInquiryDetails?.concern">
                CONCERN
              </p>
              <p
                class="obi-confirmed-request__concern-details-value"
                [innerHTML]="sanitizeConcernDetails(obiBillInquiryService.billInquiryDetails.concern)"
                *ngIf="obiBillInquiryService.billInquiryDetails?.concern"></p>

              <p class="obi-confirmed-request__concern-details-label-refnum">
                REFERENCE NO.
              </p>

              <div
                class="obi-confirmed-request__concern-details-reference-number">
                <p
                  class="obi-confirmed-request__concern-details-value-reference-number"
                  [innerHTML]="obiBillInquiryService.billInquiryDetails.referenceNumber"
                  #referenceNumTxt>
                </p>
                <div
                  class="obi-confirmed-request__concern-details-reference-number-copy-icon-container"
                  (click)="copyReferenceNumTxt()">
                  <img
                    class="obi-confirmed-request__concern-details-reference-number-copy-icon"
                    [src]="copyIcon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- receipt-box -->
      <div class="obi-confirmed-request__receipt-border-img-bottom-decor"></div>

      <div class="obi-confirmed-request__btn-wrapper">
        <a [attr.href]="goToDashboardURL">
          <button
            class="obi-confirmed-request__btn-success"
            [innerHTML]="gotoDashboardLabel"></button>
        </a>
      </div>
    </div>
  </div>
</div>

<app-omni-snackbar
  [isShowSubText]="snackBarIsShowSubtext"
  [duration]="snackBarDuration"
  [title]="snackBarTitle"
  [subText]="snackBarSubText"></app-omni-snackbar>
