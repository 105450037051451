<div #snackbar
    [ngClass]="{'omni-snackbar': true,
    'show': snackbarVisible
    }"
    tabindex="0">
    <img src="../../../../assets/icons/omni-success-check-icon.png" alt="" class="omni-snackbar__check-icon"/>
    <div class="omni-snackbar__text">
        <p class="omni-snackbar__title"
            [innerHTML]="title"
            >

        </p>
        <p  *ngIf="isShowSubText"
            class="omni-snackbar__sub-text"
            [innerHTML]="subText">
        </p>
    </div>
</div>
