import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CXS_API_URLS } from "src/app/common/constants/obi-global.constants";
import { ObiTrackMyRequestGetBillCase } from "./obi-track-my-request.interface";

@Injectable({ providedIn: 'root' })
export class ObiTrackMyRequestService {

  constructor(
    private http: HttpClient
  ) {}

  getBillCaseList(queryParams: any): Observable<ObiTrackMyRequestGetBillCase> {
    let params = new HttpParams();
    Object.keys(queryParams).forEach((key) => {
      params = params.set(key, queryParams[key]);
    });

    return this.http.get(
      CXS_API_URLS.GET_BILL_CASE,
      { params }
    );
  }
}
