export const OMNI_BASIC_TS_INIT_VALUES = {
    OMNI_BASIC_TS_CONTENT_TITLE: 'Let’s start getting you back online by performing these basic troubleshooting steps:',

    OMNI_BASIC_TS_LIST: [
        {title: 'Turn off and on your modem', text: 'Turn off the modem, unplug it from the power source, and wait for about 60 seconds before plugging it back. Allow the modem to fully turn on (when the Power, WiFi, and PON lights are green) before testing the connection again.'},
        {title: 'Check the modem lights', text: 'Depending on your modem’s model, the light indicators could be labelled POS, LOS, or DSL. These modem lights should be steady in green or blue light.  <br/> <br/> If the light is red, please turn the modem off and on (Step 1).'},
        {title: 'Connect to the 5GHz WiFi signal', text: 'If you are currently connected to the 2.4GHz connection and you see a 5GHz WiFi signal from your modem, connect to the 5GHz signal to improve your browsing speed at a closer distance.'}
    ],

    BTN_CONFIRMTXT: 'Did we fix your concern?',
    BTN_YES_LABEL: 'Yes',
    BTN_NO_LABEL: 'No',
};