<form [class]="['obi-bill-inquiry', isHere ? 'disable-scrolling': '']" [formGroup]="billInquiryForm" #billInquiryFormRef>
  <div class="obi-bill-inquiry-header">
    <div class="obi-bill-inquiry-header-title">
      <span
        class="obi-bill-inquiry-header-title-desc"
        [innerHTML]="obiBillInquiryTitle"></span>
    </div>
    <div class="obi-bill-inquiry-header-user">
      <div class="obi-bill-inquiry-header-user-input">
        <span
          class="obi-bill-inquiry-header-user-label"
          [innerHTML]="obiBillingInquiryUserLabel"></span>
        <div class="obi-bill-inquiry-header-user-data">
          <span
            class="obi-bill-inquiry-header-user-data-name"
            [innerHTML]="obiBillingInquiryUserName + ' '"></span>
          <span
            class="obi-bill-inquiry-header-user-data-number"
            [innerHTML]="obiBillingInquiryUserNumber"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="obi-bill-inquiry-body">
    <div class="obi-bill-inquiry-body-item">
      <div
        class="obi-bill-inquiry-body-label"
        [innerHTML]="obiBillingInquiryLabels.inquire"></div>
      <div
        class="obi-bill-inquiry-field obi-bill-inquiry-field-billing"
        *ngFor="let obiBillingInquiry of obiBillingInquiries"
        (click)="toggleBillingStatement(obiBillingInquiry)">
        <div>
          <div
            class="obi-bill-inquiry-field-date"
            [innerHTML]="obiBillingInquiry?.dates"></div>
          <div
            class="obi-bill-inquiry-field-amount"
            [innerHTML]="obiBillingInquiry?.amount"></div>
        </div>
        <img
          class="obi-bill-inquiry-body-item-icon"
          src="/assets/icons/arrow-right-full.svg"
          alt="Select" />
      </div>
    </div>
    <div class="obi-bill-inquiry-body-item">
      <div
        class="obi-bill-inquiry-body-label"
        [innerHTML]="obiBillingInquiryLabels.billCharge"></div>
      <div class="obi-bill-inquiry-field">
        <div
          [class]="[
            'obi-bill-inquiry-field-select',
            billInquiryForm.get('issueType')?.value || isIssueTypesShow
              ? ''
              : 'inactive'
          ]"
          (click)="toggleIssueTypes()">
          <div class="obi-bill-inquiry-field-select-input-text">
            <label
              class="obi-bill-inquiry-field-select-label"
              *ngIf="billInquiryForm.get('issueType')?.value"
              >Select issue</label
            >
            <input
              class="obi-bill-inquiry-field-select-input"
              type="text"
              placeholder="Select issue"
              formControlName="issueType"
              readonly />
          </div>
          <img
            class="obi-bill-inquiry-field-select-input-icon"
            src="/assets/icons/arrow-down.svg"
            *ngIf="
              billInquiryForm.get('issueType')?.value || isIssueTypesShow
            " />
          <img
            class="obi-bill-inquiry-field-select-input-icon"
            src="/assets/icons/arrow-down-disabled.svg"
            *ngIf="
              !billInquiryForm.get('issueType')?.value && !isIssueTypesShow
            " />
        </div>
        <div
          class="obi-bill-inquiry-field-select-list-overlay"
          *ngIf="isIssueTypesShow"
          (click)="toggleIssueTypes()"></div>
        <div
          class="obi-bill-inquiry-field-select-list"
          *ngIf="isIssueTypesShow">
          <div
            [class]="[
              'obi-bill-inquiry-field-select-item',
              billInquiryForm.get('issueType')?.value === issueType
                ? 'selected'
                : ''
            ]"
            *ngFor="let issueType of obiBillInquiryIssueTypes"
            [innerHTML]="issueType"
            (click)="onSelectIssueType(issueType)"></div>
        </div>
      </div>
      <div class="obi-bill-inquiry-body-note">
        <img
          class="obi-bill-inquiry-body-note-icon"
          src="/assets/icons/obi-info-icon.svg" />
        Learn more about your bill charges&nbsp;
        <span
          class="obi-bill-inquiry-body-note-link"
          (click)="toggleHereModal()"
          >here</span
        >.
      </div>
    </div>

    <div *ngFor="let form of obiBillInquiryForm.forms">
      <div class="obi-bill-inquiry-body-item" *ngIf="form.type === 'text'">
        <div
          class="obi-bill-inquiry-body-label"
          [innerHTML]="form.label"></div>
        <div class="obi-bill-inquiry-field" (click)="text.focus()">
          <div
            class="obi-bill-inquiry-field-text"
            [class.disabled]="billInquiryForm.get(form.formControlName)?.disabled">
            <label class="obi-bill-inquiry-field-text-label"
              [innerHTML]="form.fieldLabel" *ngIf="form.fieldLabel"></label>
            <div class="obi-bill-inquiry-field-text-input-field">
              <input
                class="obi-bill-inquiry-field-text-input"
                type="text"
                [readonly]="billInquiryForm.get(form.formControlName)?.disabled"
                [placeholder]="form?.placeholder || ''"
                [formControlName]="form.formControlName"
                [maxlength]="form?.maxLength || null"
                [formGroupDir]="billInquiryForm"
                [formControlNameDir]="form.formControlName"
                appOmniAlphanumericSpecialChars
                [formPattern]="form?.formPattern"
                [formReplacePattern]="form?.formReplacePattern"
                [inputField]="text"
                #text />
            </div>
          </div>
        </div>
      </div>
      <div class="obi-bill-inquiry-body-item" *ngIf="form.type === 'amount'">
        <div
          class="obi-bill-inquiry-body-label"
          [innerHTML]="form.label"></div>
        <div class="obi-bill-inquiry-field" (click)="amount.focus()">
          <div
            class="obi-bill-inquiry-field-text"
            [class.disabled]="billInquiryForm.get(form.formControlName)?.disabled">
            <label class="obi-bill-inquiry-field-text-label"
              [innerHTML]="form.fieldLabel" *ngIf="form.fieldLabel"></label>
            <div class="obi-bill-inquiry-field-text-input-field">
              <input
                class="obi-bill-inquiry-field-text-input obi-bill-inquiry-field-text-input-prefix amount"
                type="text"
                value="₱"
                readonly />
              <input
                class="obi-bill-inquiry-field-text-input"
                type="text"
                [readonly]="billInquiryForm.get(form.formControlName)?.disabled"
                [placeholder]="form?.placeholder || ''"
                [formControlName]="form.formControlName"
                [maxAmount]="form.max"
                [formGroupDir]="billInquiryForm"
                [formControlNameDir]="form.formControlName"
                [inputField]="amount"
                [isOnBlur]="form?.isOnBlurFormat"
                inputmode="decimal"
                appOmniCurrencyChars
                #amount />
            </div>
          </div>
        </div>
      </div>
      <div class="obi-bill-inquiry-body-item" *ngIf="form.type === 'mobile'">
        <div
          class="obi-bill-inquiry-body-label"
          [innerHTML]="form.label"></div>
        <div class="obi-bill-inquiry-field" (click)="mobileNumber.focus()">
          <div
            class="obi-bill-inquiry-field-text"
            [class.disabled]="billInquiryForm.get(form.formControlName)?.disabled">
            <label class="obi-bill-inquiry-field-text-label"
              [innerHTML]="form.fieldLabel" *ngIf="form.fieldLabel"></label>
            <div class="obi-bill-inquiry-field-text-input-field">
              <input
                class="obi-bill-inquiry-field-text-input obi-bill-inquiry-field-text-input-prefix"
                type="text"
                readonly
                size="1"
                [value]="form.placeholder" />
              <input
                class="obi-bill-inquiry-field-text-input"
                type="text"
                inputmode="numeric"
                [readonly]="billInquiryForm.get(form.formControlName)?.disabled"
                [placeholder]="form?.placeholder || ''"
                [formControlName]="form.formControlName"
                [formGroupDir]="billInquiryForm"
                [formControlNameDir]="form.formControlName"
                [inputField]="mobileNumber"
                appOmniMobileNumberFormat
                #mobileNumber />
            </div>
          </div>
        </div>
      </div>
      <div class="obi-bill-inquiry-body-item" *ngIf="form.type === 'textarea'">
        <div
          class="obi-bill-inquiry-body-label"
          [innerHTML]="form.label"></div>
        <div class="obi-bill-inquiry-field">
          <div
            class="obi-bill-inquiry-field-textarea"
            [class.disabled]="billInquiryForm.get(form.formControlName)?.disabled">
            <label class="obi-bill-inquiry-field-text-label"
              [innerHTML]="form.fieldLabel" *ngIf="form.fieldLabel"></label>
            <textarea
              class="obi-bill-inquiry-field-textarea-input"
              [readonly]="billInquiryForm.get(form.formControlName)?.disabled"
              #concernTextareaRef
              (input)="txtAreaInput($event, form.formControlName)"
              (keydown)="txtAreaKeydown($event)"
              (paste)="txtAreaPaste($event, form.formControlName)"
              [placeholder]="form.placeholder"
              [formControlName]="form.formControlName"
              [formGroupDir]="billInquiryForm"
              [formControlNameDir]="form.formControlName"
              appOmniAlphanumericSpecialChars></textarea>
          </div>
          <div class="obi-bill-inquiry-field-textarea-charcounter">
            {{ concernTextareaCharCount }}/{{ concernTextareaMaxLength }}
          </div>
        </div>
      </div>
      <div class="obi-bill-inquiry-body-item" *ngIf="form.type === 'select'">
        <div
          class="obi-bill-inquiry-body-label"
          [innerHTML]="form.label"></div>
        <div class="obi-bill-inquiry-field">
          <div
            class="obi-bill-inquiry-field-select"
            [class.disabled]="billInquiryForm.get(form.formControlName)?.disabled"
            [class.inactive]="billInquiryForm.get(form.formControlName)?.value || this.selectFieldToggle[form.formControlName]"
            (click)="billInquiryForm.get(form.formControlName)?.disabled ? null: toggleSelectOption(form.formControlName)"
            #selectField>
            <div
              class="obi-bill-inquiry-field-select-input-text">
              <label
                class="obi-bill-inquiry-field-select-label"
                *ngIf="billInquiryForm.get(form.formControlName)?.value"
                [innerHTML]="form.fieldLabel"></label
              >
              <textarea
                class="obi-bill-inquiry-field-select-input obi-bill-inquiry-field-select-textarea"
                type="text"
                [placeholder]="form?.placeholder || ''"
                [formControlName]="form.formControlName"
                readonly
                #selectTextarea></textarea>
            </div>
            <img
              class="obi-bill-inquiry-field-select-input-icon"
              src="/assets/icons/arrow-down.svg"
              *ngIf="
                (billInquiryForm.get(form.formControlName)?.value ||
                this.selectFieldToggle[form.formControlName]) &&
                !billInquiryForm.get(form.formControlName)?.disabled
              " />
            <img
              class="obi-bill-inquiry-field-select-input-icon"
              src="/assets/icons/arrow-down-disabled.svg"
              *ngIf="
                (!billInquiryForm.get(form.formControlName)?.value &&
                !this.selectFieldToggle[form.formControlName]) ||
                billInquiryForm.get(form.formControlName)?.disabled
              " />
          </div>
          <div
            class="obi-bill-inquiry-field-select-list-overlay"
            *ngIf="this.selectFieldToggle[form.formControlName]"
            (click)="billInquiryForm.get(form.formControlName)?.disabled ? null: toggleSelectOption(form.formControlName)"></div>
          <div
            class="obi-bill-inquiry-field-select-list"
            *ngIf="this.selectFieldToggle[form.formControlName]"
            [style.top]="selectField.offsetHeight + 'px'">
            <div
              [class]="[
                'obi-bill-inquiry-field-select-item',
                billInquiryForm.get(form.formControlName)?.value === option
                  ? 'selected'
                  : ''
              ]"
              *ngFor="let option of form.options"
              [innerHTML]="option"
              (click)="onSelectOption(form.formControlName, option, selectTextarea)"></div>
          </div>
        </div>
      </div>
      <div class="obi-bill-inquiry-body-item" *ngIf="form.type === 'date'">
        <div
          class="obi-bill-inquiry-body-label"
          [innerHTML]="form.label"></div>
        <div class="obi-bill-inquiry-field">
          <div class="obi-bill-inquiry-field-text"
            [class.disabled]="billInquiryForm.get(form.formControlName)?.disabled"
            (click)="billInquiryForm.get(form.formControlName)?.disabled ? '': toggleDateModal(form.formControlName)">
            <label class="obi-bill-inquiry-field-text-label"
              [innerHTML]="form.fieldLabel" *ngIf="form.fieldLabel"></label>
            <div class="obi-bill-inquiry-field-text-input-field">
              <input
                class="obi-bill-inquiry-field-text-input"
                type="text"
                [placeholder]="form?.placeholder || ''"
                [value]="billInquiryForm.get(form.formControlName)?.value ?
                ('0' + billInquiryForm.get(form.formControlName)?.value?.month).slice(-2) + '/'+
                ('0' + billInquiryForm.get(form.formControlName)?.value?.day).slice(-2) + '/' +
                billInquiryForm.get(form.formControlName)?.value?.year :
                ''"
                readonly />
              <img class="obi-bill-inquiry-field-text-input-icon"
                src="/assets/icons/calendar-icon.svg" />
              </div>
            </div>
            <app-obi-date-picker-modal
              [show]="dateFieldToggle[form.formControlName]"
              (dateSelected)="onSelectDate(form.formControlName, $event)"
              (modalClosed)="onCloseDateModal(form.formControlName)" />
        </div>
      </div>
      <div class="obi-bill-inquiry-body-item" *ngIf="form.type === 'time'">
        <div
          class="obi-bill-inquiry-body-label"
          [innerHTML]="form.label"></div>
        <div class="obi-bill-inquiry-field">
          <div class="obi-bill-inquiry-field-text"
            [class.disabled]="billInquiryForm.get(form.formControlName)?.disabled"
            (click)="billInquiryForm.get(form.formControlName)?.disabled ? '': toggleTimeModal(form.formControlName, true)">
            <label class="obi-bill-inquiry-field-text-label"
              [innerHTML]="form.fieldLabel" *ngIf="form.fieldLabel"></label>
            <div class="obi-bill-inquiry-field-text-input-field">
              <input
                class="obi-bill-inquiry-field-text-input"
                type="text"
                [placeholder]="form?.placeholder || ''"
                [value]="billInquiryForm.get(form.formControlName)?.value"
                readonly />
            </div>
          </div>
          <div
            class="obi-bill-inquiry-field-text-input-error"
            *ngIf="formErrorList[form.formControlName]"
            [innerHTML]="formErrorList[form.formControlName]"></div>
          <app-obi-time-picker-modal
            [show]="timeFieldToggle[form.formControlName]"
            (timeSelected)="onSelectTime(form.formControlName, $event)"
            (modalClosed)="toggleTimeModal(form.formControlName, $event)" />
        </div>
      </div>
    </div>
  </div>
  <div class="obi-bill-inquiry-footer">
    <div
      [class]="[
        'obi-bill-inquiry-footer-button',
        billInquiryForm.valid ? '' : 'disabled'
      ]"
      (click)="billInquiryForm.valid ? submitBillInquiry() : null">
      Submit
    </div>
    <div
      class="obi-bill-inquiry-footer-button outline"
      (click)="toggleCancelModal()">
      Cancel
    </div>
  </div>
</form>

<app-obi-bill-charges [open]="isHere"
(handleClose)="toggleHereModal()">
</app-obi-bill-charges>

<app-obi-bill-statement [open]="isBilling" [itemData]="selectedBill"
(close)="toggleBillingStatement({})">
</app-obi-bill-statement>

<app-obi-ticket-receipt [open]="isReceipt">
</app-obi-ticket-receipt>

<omni-dialog-modal
  class="obi-bill-inquiry-cancel-modal"
  [title]="obiBillInquiryCancel.title"
  [confirmButtonLabel]="obiBillInquiryCancel.confirmButtonLabel"
  [cancelButtonLabel]="obiBillInquiryCancel.cancelButtonLabel"
  [confirmButtonAction]="obiBillInquiryCancelConfirmAction"
  [cancelButtonAction]="obiBillInquiryCancelCancelAction"
  [show]="isCancel"></omni-dialog-modal>

<app-omni-loading-modal
  [showModal]="isLoading"
  [message]="'This will only take a moment.'"></app-omni-loading-modal>
