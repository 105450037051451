import { Component, Input, OnInit } from '@angular/core';
import {
  OMNI_ALL_GOOD,
  OMNI_WELCOME_BACK,
} from '../../../common/constants/omni-welcome.constants';
import { ActivatedRoute } from '@angular/router';
import { GoogleTagManagerService } from 'src/app/services/google-tag-manager-service.service';
import { OmniCommonService } from 'src/app/services/omni-common.services';
import { OmniSessionService } from '../../../services/omni-session.service';
import {
  OMNI_ALL_GOOD_TAG,
  OMNI_WELCOMEBACK_TAG,
} from 'src/app/common/constants/omni-gtm-events.constants';

@Component({
  selector: 'app-omni-welcome',
  templateUrl: './omni-welcome.component.html',
  styleUrls: ['./omni-welcome.component.scss'],
})
export class OmniWelcomeComponent implements OnInit {
  /**
   * Icon displayed on top
   */
  public icon = '';

  /**
   * HTML Text displayed in the dialog box for Title
   */
  public title = '';
  /**
   * HTML Text displayed in the dialog box for Body
   */
  public body = '';
  /**
   * HTML Text displayed for the button label
   */
  public buttonLabel = '';
  /**
   * URL string where the button would redirect the user
   */
  public buttonNavigationUrl = '';

  userData: any = {};

  constructor(
    private route: ActivatedRoute,
    private gtmService: GoogleTagManagerService,
    private omniSessionService: OmniSessionService,
    public commonService: OmniCommonService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      //Get user data in the session service
      this.userData = this.omniSessionService.getUserData() || {};

      // define default values for spiels
      let spielConfig = OMNI_ALL_GOOD;
      // Check for flag params for other spiel configuration
      if (params['flag'] === 'welcome') {
        spielConfig = OMNI_WELCOME_BACK;
        this.captureGTMEvent(OMNI_WELCOMEBACK_TAG);
      } else {
        this.captureGTMEvent(OMNI_ALL_GOOD_TAG);
      }
      const { icon, title, body, buttonLabel, buttonNavigationUrl } =
        spielConfig;
      this.icon = icon;
      this.title = title;
      this.body = body;
      this.buttonLabel = buttonLabel;
      this.buttonNavigationUrl = buttonNavigationUrl;
    });
  }

  /**
   * Navigate to provided URL
   */
  navigate(url: string) {
    window.location.href = url;
  }

  /**
   * OM24H-947 & 948: Captures Google Tag events and push these tags to the dataLayer
   * @param eventInfo
   */
  captureGTMEvent(eventInfo: any) {
    const {
      dataAllocation,
      balanceStatus,
      accountStatus,
      outageResult,
      lineStatus,
      modemStatus,
    } = this.userData;

    const additionalData = {
      promo_category: dataAllocation,
      status: balanceStatus,
      account_status: accountStatus,
      outage_status: this.commonService.determineDiagStatusForTagging(
        'outage_status',
        outageResult
      ),
      fiber_cuts_issue: this.commonService.determineDiagStatusForTagging(
        'fiber_cuts_issue',
        lineStatus
      ),
      fiber_losses_issue: this.commonService.determineDiagStatusForTagging(
        'fiber_losses_issue',
        lineStatus
      ),
      modem_line: this.commonService.determineDiagStatusForTagging(
        'modem_line',
        modemStatus
      ),
      modem_power: this.commonService.determineDiagStatusForTagging(
        'modem_power',
        modemStatus
      ),
    };

    this.gtmService.captureGTMEvent(eventInfo, false, additionalData);
  }
}
