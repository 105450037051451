import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { OBI_X_ICON } from '../../../../app/common/constants/obi-bill-charges.constants';
import { ObiBillStatementService } from './obi-bill-statement.service';
import { OmniCommonService } from 'src/app/services/omni-common.services';
import {
  PAGE_URLS,
  SESSION_KEYS,
} from 'src/app/common/constants/obi-global.constants';
import { OmniSessionService } from 'src/app/services/omni-session.service';

@Component({
  selector: 'app-obi-bill-statement',
  templateUrl: './obi-bill-statement.component.html',
  styleUrls: ['./obi-bill-statement.component.scss'],
})
export class ObiBillStatementComponent implements OnChanges {
  /**
   * Flag to display or hide the modal
   */
  @Input() open?: boolean = false;

  /**
   * Event to close the modal
   */
  @Output() close: EventEmitter<any> = new EventEmitter();

  /**
   * Variable to hold parent component data
   */
  @Input() itemData: any;

  /**
   * Reused close modal icon
   */
  readonly closeIcon = OBI_X_ICON;

  constructor(
    private obiBillStatementService: ObiBillStatementService,
    private commonService: OmniCommonService,
    private sessionService: OmniSessionService
  ) {}

  pdfSrc: string = '';

  displayPdf: boolean = false;

  /**
   * Boolean flag for showing loading modal
   */
  loading: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    const billingDetails = changes['itemData'];
    if (
      this.open &&
      billingDetails &&
      !billingDetails.firstChange &&
      billingDetails.currentValue
    ) {
      this.retrieveBillingStatementPDF(billingDetails.currentValue);
    }
  }

  onError(error: any) {
    this.commonService.navigate(PAGE_URLS.GENERIC_ERROR_PAGE_URL);
  }

  onLoadComplete(pdfData: any) {}

  private retrieveBillingStatementPDF(item: any) {
    this.pdfSrc = '';
    this.loading = true;
    const accountNumber = this.sessionService.getData(
      SESSION_KEYS.OBI_ACCOUNT_NUMBER_KEY
    );
    const mobileNumber = this.sessionService.getData(
      SESSION_KEYS.OBI_MOBILE_NUMBER
    );
    const segment = this.sessionService.getData(SESSION_KEYS.OBI_SEGMENT_KEY);
    this.obiBillStatementService
      .getBillingStatementPDF(
        accountNumber,
        mobileNumber,
        item.billDetails.billId,
        'pdf',
        segment
      )
      .subscribe({
        next: (response: any) => {
          setTimeout(() => {
            this.pdfSrc = `data:application/pdf;base64,${response.result}`;
            this.displayPdf = true;
            this.loading = false;
          }, 2000);
        },
        error: () => {
          this.loading = false;
          this.commonService.navigate(PAGE_URLS.GENERIC_ERROR_PAGE_URL);
        },
      });
  }

  toggleModal() {
    this.open = !this.open;
    this.close.emit(this.open);
  }
}
