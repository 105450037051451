import { Component } from '@angular/core';

@Component({
  selector: 'app-omni-workorder-details',
  templateUrl: './omni-workorder-details.component.html',
  styleUrls: ['./omni-workorder-details.component.scss']
})
export class OmniWorkorderDetailsComponent {

}
